import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ImageParser.module.scss';

const ImageParser = ({ src, alt, sm, md, lg, logo, full, id, ...props }) => {
  const classes = classNames({
    [css['image-parser']]: true,
    [css['image-parser--sm']]: sm,
    [css['image-parser--md']]: md,
    [css['image-parser--lg']]: lg,
    [css['image-parser--logo']]: logo,
    [css['image-parser--full']]: full
  });
  return src ? <img id={id} src={src} alt={alt} className={classes} {...props} /> : null;
};

ImageParser.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
  logo: PropTypes.bool,
  full: PropTypes.bool,
  cors: PropTypes.bool,
  id: PropTypes.string
};
ImageParser.defaultProps = {
  src: null,
  alt: 'Vobi',
  sm: false,
  md: false,
  lg: false,
  logo: false,
  full: false
};
ImageParser.displayName = 'ImageParser';

export default ImageParser;
