import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/pro-duotone-svg-icons';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import { colors, Div, fonts, spaces } from '../../styles/style';
import SimpleAccordion from '../Accordion/SimpleAccordion';
import { Paragraph, Subtitle } from '../Text/Text';
import { store } from '../../lib/config/redux-store';
import formatCurrency from '../../lib/helpers/formatCurrency';
import Dropdown from '../Dropdown/Dropdown';
import Button from '../Button/Button';
import PurchaseTable from './PurchaseTable';
import WarningBar from '../Alert/WarningBar';
import NoContent from '../NoContent/NoContent';
import PurchaseBulkActions from '../BulkActions/PurchaseBulkActions';
import { columns as purchasePdfColumns } from '../../lib/mapping/TableOrList/purchasePdfColumns';
import SimpleTable from './SimpleTable';

const RefurbishApportionment = ({
  isPrinting,
  list = [],
  openAllRefurbishes,
  setOpenAllRefurbishes,
  addItemType,
  setRemoveRefurbishModal,
  totals,
  hasPaidInstallment,
  purchaseList,
  itemObjectsMap,
  itemSettersMap,
  handleSetList,
  isEdit,
  invalidPaymentItems,
  setInvalidPaymentItems,
  emptyObj = {},
  warningText = '',
  setAddRefurbishModal,
  isPayment,
  parentColumnName = 'paymentItems',
  childrenColumnName = 'paymentItemLevels',
  isOrderBlock,
  isQuote
}) => {
  const { setup } = store.getState();
  const { refurbishItemType = {} } = setup.enums;
  if (!list?.length)
    return (
      <NoContent
        title={emptyObj?.title}
        description={emptyObj?.description}
        renderTitle={false}
        icon={faFolderOpen}
        buttonTitle="Projeto"
        onClick={() => setAddRefurbishModal(true)}
      />
    );

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const selectedItemsVirtual = useRef({});

  const rowId = rec => (rec?.type === refurbishItemType?.parent ? `level-${rec.id}` : rec.id);

  const handleSelectItem = (idRefurbish, selectedRows) => {
    setSelectedItems(() => {
      selectedItemsVirtual.current = {
        ...selectedItemsVirtual.current,
        [idRefurbish]: selectedRows
      };
      const combinedSelectedItems = Object.values(selectedItemsVirtual.current).flat();

      setSelectedRowKeys(combinedSelectedItems.map(rowId));

      return combinedSelectedItems;
    });
  };

  const handleCleanSelectedItens = () => {
    selectedItemsVirtual.current = {};
    setSelectedItems([]);
    setSelectedRowKeys([]);
  };

  const { setCreatedItems, setUpdatedItems, setDeletedItems, setCreatedLevels, setDeletedLevels } = itemSettersMap;

  const { createdItems: rawCreatedItems, createdLevels: rawCreatedLevels } = itemObjectsMap || {};
  const createdItems = rawCreatedItems || {};
  const createdLevels = rawCreatedLevels || {};

  const bulkEditItems = ({ ids, value, key }) => {
    Object.keys(selectedItemsVirtual.current).forEach(idRefurbish => {
      const itemsChanged = {};
      const idsMap = ids.reduce((acc, cur) => ({ ...acc, [cur]: true }), {});

      handleSetList(idRefurbish)(prev => {
        return prev.map(_item => {
          if (idsMap?.[_item.id]) {
            if (
              !createdItems?.[idRefurbish]?.[_item?.id] &&
              !createdLevels?.[idRefurbish]?.[_item?.id] &&
              (!_item?.idParent || !createdItems?.[_item?.idParent])
            ) {
              itemsChanged[_item.id] = true;
            }
            return {
              ..._item,
              [key]: value
            };
          }
          return _item;
        });
      });

      setUpdatedItems(prev => ({
        ...prev,
        [idRefurbish]: {
          ...(prev?.[idRefurbish] ? prev[idRefurbish] : {}),
          ...itemsChanged
        }
      }));
    });
  };

  const bulkDeleteItems = ids => {
    Object.keys(selectedItemsVirtual.current).forEach(idRefurbish => {
      const itemsDeleted = {};
      const levelsDeleted = {};
      const idsMap = {};

      const createdItemsToDelete = {};
      const createdLevelsToDelete = {};

      ids.forEach(cur => {
        const formatedId = typeof cur === 'string' ? cur.replace('level-', '') : cur;
        if (createdLevels?.[idRefurbish]?.[formatedId]) {
          createdLevelsToDelete[formatedId] = undefined;
        }
        if (createdItems?.[idRefurbish]?.[formatedId]) {
          createdItemsToDelete[formatedId] = undefined;
        }
        idsMap[formatedId] = true;
      });

      handleSetList(idRefurbish)(prev => {
        return prev.reduce((result, _item) => {
          if (idsMap?.[_item.id]) {
            itemsDeleted[_item.id] = true;
            return result;
          }

          result.push({
            ..._item,
            [childrenColumnName]: _item[childrenColumnName]?.filter(child => {
              const isDeleted = idsMap?.[child.id];
              if (isDeleted && Number(child.id)) levelsDeleted[child.id] = true;
              return !isDeleted;
            })
          });

          return result;
        }, []);
      });

      setDeletedItems(prev => ({
        ...prev,
        [idRefurbish]: {
          ...(prev?.[idRefurbish] ? prev[idRefurbish] : {}),
          ...itemsDeleted,
          ...createdItemsToDelete
        }
      }));

      setDeletedLevels(prev => ({
        ...prev,
        [idRefurbish]: {
          ...(prev?.[idRefurbish] ? prev[idRefurbish] : {}),
          ...levelsDeleted,
          ...createdLevelsToDelete
        }
      }));

      setCreatedItems(prev => ({
        ...prev,
        [idRefurbish]: { ...(prev?.[idRefurbish] ? prev[idRefurbish] : {}), ...createdItemsToDelete }
      }));

      setCreatedLevels(prev => ({
        ...prev,
        [idRefurbish]: { ...(prev?.[idRefurbish] ? prev[idRefurbish] : {}), ...createdLevelsToDelete }
      }));
    });
  };

  return (
    <Div
      padding={`${spaces.space2} ${spaces.space2} ${spaces.space6} ${spaces.space2}`}
      direction="column"
      gap={spaces.space2}
      $fullWidth
    >
      {hasPaidInstallment && warningText ? (
        <WarningBar warning={warningText} width="100%" margin={`0 0 ${spaces.space2} 0`} type="light" />
      ) : null}
      {isPrinting && (
        <Div $fullWidth justify="space-between">
          <Subtitle>Itens solicitados{list.length > 1 && ' por projeto'}</Subtitle>
        </Div>
      )}
      {list?.map(refurbish => (
        <SimpleAccordion
          isPrinting={isPrinting}
          initOpen
          forceOpen={openAllRefurbishes}
          setForceOpen={setOpenAllRefurbishes}
          title={` ${refurbish?.name}`}
          extraHeaderComponent={
            (!isPrinting && isQuote) || !isQuote ? (
              <Div gap={spaces.space1}>
                <Paragraph>Subtotal:</Paragraph>
                <Paragraph weight={fonts.weight700}>
                  {formatCurrency(totals?.[0]?.[refurbish?.id]?.value || 0, {
                    currencySymbol: 'R$'
                  })}
                </Paragraph>
                {!hasPaidInstallment && isEdit && !isOrderBlock ? (
                  <Dropdown
                    slim
                    trigger={['click']}
                    disabled={!!addItemType}
                    menu={
                      <Menu.Item>
                        <Button
                          text
                          id="removeRefurbish"
                          type="danger"
                          onClick={e => {
                            setRemoveRefurbishModal(refurbish?.id);
                            e.stopPropagation();
                          }}
                        >
                          Excluir
                        </Button>
                      </Menu.Item>
                    }
                  >
                    <FontAwesomeIcon
                      onClick={e => e.stopPropagation()}
                      color={colors.primary500}
                      icon={faEllipsisV}
                      id={`${refurbish.id}-dropdown-apportionment`}
                    />
                  </Dropdown>
                ) : null}
              </Div>
            ) : null
          }
        >
          {isPrinting ? (
            <SimpleTable
              columns={purchasePdfColumns({ removeValues: isQuote })}
              data={purchaseList[refurbish.id]}
              isExpandable={false}
              $borderBottom
            />
          ) : (
            <PurchaseTable
              tableId={`accordion-${refurbish?.name}`}
              list={purchaseList[refurbish.id] || []}
              selectedItems={selectedItems[refurbish.id] || []}
              selectedRowKeys={selectedRowKeys[refurbish.id] || []}
              idRefurbish={refurbish.id}
              paymentView={isPayment}
              itemObjectsMap={itemObjectsMap}
              itemSettersMap={itemSettersMap}
              childrenColumnName={childrenColumnName}
              parentColumnName={parentColumnName}
              setList={handleSetList(refurbish?.id)}
              height="300px"
              hideAddButtons
              splitView
              hasMultipleRefurbishes={list?.length > 1}
              isEdit={isEdit}
              noContentText={`Busque ou crie novos produtos e serviços.
            Eles serão adicionados em todos os projetos do rateio.`}
              bugReload
              invalidPaymentItems={invalidPaymentItems?.[refurbish?.id] || ''}
              setInvalidPaymentItems={resetString =>
                setInvalidPaymentItems(prev => ({ ...prev, [refurbish?.id]: resetString }))
              }
              readOnly={hasPaidInstallment || (!isPayment && !isEdit)}
              handleSelectItemExternal={selectedRows => handleSelectItem(refurbish.id, selectedRows)}
              isPrinting={isPrinting}
            />
          )}
        </SimpleAccordion>
      ))}
      <PurchaseBulkActions
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        bulkEdit={bulkEditItems}
        bulkDelete={bulkDeleteItems}
        handleCleanSelectedItens={handleCleanSelectedItens}
      />
    </Div>
  );
};

RefurbishApportionment.propTypes = {
  isPrinting: PropTypes.bool,
  list: PropTypes.arrayOf(Array),
  openAllRefurbishes: PropTypes.bool,
  setOpenAllRefurbishes: PropTypes.func,
  addItemType: PropTypes.string,
  setRemoveRefurbishModal: PropTypes.func,
  totals: PropTypes.instanceOf(Array),
  hasPaidInstallment: PropTypes.bool,
  purchaseList: PropTypes.instanceOf(Object),
  itemObjectsMap: PropTypes.instanceOf(Object),
  itemSettersMap: PropTypes.instanceOf(Object),
  handleSetList: PropTypes.func,
  isEdit: PropTypes.bool,
  invalidPaymentItems: PropTypes.instanceOf(Object),
  setInvalidPaymentItems: PropTypes.func,
  emptyObj: PropTypes.instanceOf(Object),
  warningText: PropTypes.string,
  setAddRefurbishModal: PropTypes.func,
  isPayment: PropTypes.bool,
  childrenColumnName: PropTypes.string,
  parentColumnName: PropTypes.string,
  isOrderBlock: PropTypes.bool,
  isQuote: PropTypes.bool
};

export default RefurbishApportionment;
