import dayjs from 'dayjs';
import React from 'react';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { formatDocument, formatPhoneNumber, formatZipCode } from '../../helpers/helper';
import formatCurrency from '../../helpers/formatCurrency';
import { Paragraph } from '../../../components/Text/Text';
import { Div, colors, spaces } from '../../../styles/style';
import TooltipIcon from '../../../components/Tooltip/TooltipIcon';

export const invoiceInfo = {
  natOp: {
    label: (
      <Div gap={spaces.space0}>
        <Paragraph type="small">Natureza da operação</Paragraph>
        <TooltipIcon
          iconColor={colors.primary500}
          icon={faInfoCircle}
          text="Indica a finalidade da emissão da nota, ou seja, a que operação se destina "
          style={{ padding: spaces.space0 }}
        />
      </Div>
    )
  },
  serie: { label: 'Série/NFe' },
  dhEmi: { label: 'Data da emissão', format: value => dayjs(value).format('DD/MM/YYYY') }
};

export const emit = {
  xNome: { label: 'Razão social' },
  xFant: { label: 'Nome fantasia' },
  document: { label: 'CNPJ', format: value => formatDocument(value) || '-' },
  IE: { label: 'Inscrição estadual' },
  CEP: {
    label: 'CEP',
    format: value => {
      if (!value) return '-';
      const numeric = value?.replace(/\D/g, '');
      return `${numeric?.substr(0, 5)}-${numeric?.substr(5, 3)}`;
    }
  },
  xLgr: { label: 'Endereço' },
  nro: { label: 'Número' },
  xCpl: { label: 'Complemento' },
  xBairro: { label: 'Bairro' },
  xMun: { label: 'Cidade' },
  UF: { label: 'Estado' },
  fone: { label: 'Telefone', format: value => formatPhoneNumber(value) }
};

export const dest = {
  xNome: { label: 'Nome/Razão social' },
  document: { label: 'CPF/CNPJ', format: value => formatDocument(value) || '-' },
  CEP: {
    label: 'CEP',
    format: value => {
      if (!value) return '-';
      return formatZipCode(value);
    }
  },
  xLgr: { label: 'Endereço' },
  nro: { label: 'Número' },
  xCpl: { label: 'Complemento' },
  xBairro: { label: 'Bairro' },
  xMun: { label: 'Cidade' },
  UF: { label: 'Estado' },
  fone: { label: 'Telefone', format: value => formatPhoneNumber(value) },
  email: { label: 'Email' }
};

export const location = {
  CEP: { label: 'CEP' },
  xLgr: { label: 'Endereço' },
  nro: { label: 'Número' },
  xCpl: { label: 'Complemento' },
  xBairro: { label: 'Bairro' },
  xMun: { label: 'Cidade' },
  UF: { label: 'Estado' }
};

export const shippingCompany = mod => ({
  modFrete: { label: 'Modalidade do frete', format: value => mod?.[Number(value)] || '-' },
  xNome: { label: 'Nome/Razão social' },
  CNPJ: { label: 'CPF/CNPJ', format: value => formatDocument(value) || '-' },
  IE: { label: 'Inscrição estadual' }
});

export const invoiceTotals = {
  vProd: {
    label: 'Produtos',
    format: value => (
      <Paragraph type="small">
        <strong>{formatCurrency(value || 0, { currencySymbol: 'R$' })}</strong>
      </Paragraph>
    )
  },
  vFrete: { label: 'Frete', format: value => formatCurrency(value || 0, { currencySymbol: 'R$' }) },
  vSeg: { label: 'Seguro', format: value => formatCurrency(value || 0, { currencySymbol: 'R$' }) },
  vDesc: { label: 'Desconto', format: value => formatCurrency(value || 0, { currencySymbol: 'R$' }) },
  vII: { label: 'II', format: value => formatCurrency(value || 0, { currencySymbol: 'R$' }) },
  vIPI: { label: 'IPI', format: value => formatCurrency(value || 0, { currencySymbol: 'R$' }) },
  vPIS: { label: 'PIS', format: value => formatCurrency(value || 0, { currencySymbol: 'R$' }) },
  vCOFINS: { label: 'COFINS', format: value => formatCurrency(value || 0, { currencySymbol: 'R$' }) },
  vOutro: { label: 'Outras despesas', format: value => formatCurrency(value || 0, { currencySymbol: 'R$' }) },
  vNF: {
    label: 'Total',
    format: value => (
      <Paragraph type="small">
        <strong>{formatCurrency(value || 0, { currencySymbol: 'R$' })}</strong>
      </Paragraph>
    )
  }
};

export const taxes = {
  taxCSLL: { label: 'CSLL', format: value => `${formatCurrency(value || 0)}%` },
  valueCSLL: { label: 'Valor CSLL', format: value => formatCurrency(value || 0, { currencySymbol: 'R$' }) },
  taxIR: { label: 'IR', format: value => `${formatCurrency(value || 0)}%` },
  valueIR: { label: 'Valor IR', format: value => formatCurrency(value || 0, { currencySymbol: 'R$' }) },
  taxCOFINS: { label: 'COFINS', format: value => `${formatCurrency(value || 0)}%` },
  valueCOFINS: { label: 'Valor COFINS', format: value => formatCurrency(value || 0, { currencySymbol: 'R$' }) },
  taxPIS: { label: 'PIS', format: value => `${formatCurrency(value || 0)}%` },
  valuePIS: { label: 'Valor PIS', format: value => formatCurrency(value || 0, { currencySymbol: 'R$' }) },
  taxINSS: { label: 'INSS', format: value => `${formatCurrency(value || 0)}%` },
  valueINSS: { label: 'Valor INSS', format: value => formatCurrency(value || 0, { currencySymbol: 'R$' }) },
  otherDeductions: { label: 'Outras deduções', format: value => formatCurrency(value || 0, { currencySymbol: 'R$' }) }
};

export const companyEntityInfo = {
  legalName: { label: 'Empresa' },
  cnpj: { label: 'CNPJ', format: value => formatDocument(value) || '-' },
  name: { label: 'Nome fantasia' },
  crt: { label: 'Código de Regime Tributário' },
  email: { label: 'E-mail' },
  phone: { label: 'Telefone', format: value => formatPhoneNumber(value) || '-' }
};

export const emitNfse = {
  date: { label: 'Data de competência', format: value => dayjs(value).format('DD/MM/YYYY') },
  rpsSerie: { label: 'Série do RPS' },
  rpsNumber: { label: 'Número do RPS' },
  nfeNumber: { label: 'Número da NFS-e' }
};

export const customerNfse = ({ personType }) => ({
  customerName: { label: 'Cliente', otherProps: { span: 4 } },
  personType: {
    label: 'Tipo de pessoa',
    format: value => (value === 'pj' ? 'Pessoa Jurídica' : 'Pessoa Física'),
    otherProps: { span: 4 }
  },
  customerDoc: {
    label: personType === 'pj' ? 'CNPJ' : 'CPF',
    format: value => formatDocument(value) || '-',
    otherProps: { span: 4 }
  },
  ...(personType === 'pj' ? { customer: { label: 'Razão social', otherProps: { span: 8 } } } : {}),
  email: { label: 'E-mail', otherProps: { span: 4 } },
  phone: { label: 'Telefone', format: value => formatPhoneNumber(value) || '-', otherProps: { span: 12 } },
  zipcode: {
    label: 'CEP',
    format: value => {
      if (!value) return '-';
      const numeric = value?.replace(/\D/g, '');
      return `${numeric?.substr(0, 5)}-${numeric?.substr(5, 3)}`;
    },
    otherProps: { span: 4 }
  },
  street: { label: 'Rua', otherProps: { span: 4 } },
  numberAddress: { label: 'Número', otherProps: { span: 2 } },
  complement: { label: 'Complemento', otherProps: { span: 2 } },
  neighborhood: { label: 'Bairro', otherProps: { span: 4 } },
  city: { label: 'Cidade', otherProps: { span: 4 } },
  state: { label: 'Estado', otherProps: { span: 4 } }
});

export const serviceProfile = ({ isConstructionService }) => ({
  value: {
    label: 'Valor do serviço prestado',
    format: value => formatCurrency(value || 0, { currencySymbol: 'R$' }),
    otherProps: { span: 4 }
  },
  serviceName: { label: 'Serviço prestado', otherProps: { span: 8 } },
  retainISS: { label: 'Reter ISS', format: value => (value ? 'Sim' : 'Não'), otherProps: { span: 2 } },
  taxISS: { label: 'Alíquota ISS', format: value => `${formatCurrency(value || 0)}%`, otherProps: { span: 2 } },
  serviceState: { label: 'Estado', otherProps: { span: 2 } },
  city: { label: 'Município de prestação do serviço', nestedKey: ['name'], otherProps: { span: 6 } },
  serviceDescription: { label: 'Descrição do serviço', otherProps: { span: 12 } },
  isConstructionService: {
    label: 'Serviço relacionado à construção civil',
    format: value => (value ? 'Sim' : 'Não'),
    otherProps: { span: 12 }
  },
  workCode: { label: 'Código da obra', hideField: !isConstructionService, otherProps: { span: 2 } },
  ARTCode: { label: 'Código da A.R.T', hideField: !isConstructionService, otherProps: { span: 2 } }
});

export const totals = {
  total: { label: 'Valor bruto', format: value => formatCurrency(value || 0, { currencySymbol: 'R$' }) },
  discounts: { label: 'Descontos', format: value => formatCurrency(value || 0, { currencySymbol: 'R$' }) },
  netValue: { label: 'Valor líquido', format: value => formatCurrency(value || 0, { currencySymbol: 'R$' }) }
};
