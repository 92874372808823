import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { checkApprovalAuthority } from 'vobi_lib';
import {
  faCheck,
  faLink,
  faFilePdf,
  faDollarSign,
  faHandHoldingDollar,
  faFileInvoiceDollar
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConfirmModal from '../Modal/ConfirmModal';
import Dropdown from './DropdownV2';
import CopyLinkButton from '../Button/CopyLinkButton';
import useCRUD from '../../_Hooks/useCRUD';
import DropDownButton from '../Button/DropDownButton';
import { DropdownContainer, StyledItemMenu, StyledMenu, SubMenu } from './DropdownV2.styled';
import { allDropdown } from '../../lib/mapping/Dropdown/allDropdown';
import ShareWithSupplierModal from '../Modal/ShareWithSupplierModal';
import useUrlParams from '../../_Hooks/useUrlParams';
import Button from '../Button/Button';
import { hasPermission } from '../../routes/Common/PrivateRoute';
import OrderModals from '../../_Pages/Purchases/Order/OrderModals';
import usePurchase from '../../_Hooks/usePurchase';
import { spaces, colors } from '../../styles/style';
import useViewport from '../../_Hooks/useViewport';
import RadioOptionsModal from '../Modal/RadioOptionsModal';
import OrderPaymentModal from '../Modal/OrderPaymentModal';

const PurchaseActionDropdown = ({ data, afterSubmit = f => f, model, children, handlePrint }) => {
  const {
    purchaseOrderStatus,
    purchaseSolicitationStatus,
    paymentOrderType,
    purchaseSolicitationMoveStatusObj,
    purchaseOrderMoveStatusObj
  } = useSelector(state => state.setup.enums);
  const { user = null } = useSelector(state => state.authReducer) || {};
  const { isMobile } = useViewport(window.innerWidth);
  const isOrder = model === 'order';
  const purchaseStatus = isOrder ? purchaseOrderStatus : purchaseSolicitationStatus;
  const moveStatus = isOrder ? purchaseOrderMoveStatusObj : purchaseSolicitationMoveStatusObj;
  const { approvalAuthority } = user?.company || {};
  const hasApprovalAuthority = isOrder ? approvalAuthority?.purchaseOrder : approvalAuthority?.purchaseSolicitation;

  const hasUserPaymentPermission = hasPermission(user, [
    'payment',
    'projectSupplier',
    'projectOwnBusiness',
    'financialSupplier',
    'financialOwnBusiness'
  ]);
  const hasQuotePermission = hasPermission(user, ['purchaseQuote']);
  const hasOrderPermission = hasPermission(user, ['purchaseOrder']);
  const history = useHistory();
  const [showModal, setShowModal] = useState();
  const [openPurchaseModal, setOpenPurchaseModal] = useState({ open: false, idOrder: null });
  const [openFinanceDrawer, setOpenFinanceDrawer] = useState({ open: false });

  const { handleCreate, handleUpdate, handleDelete } = useCRUD({
    model,
    immediatelyLoadData: false
  });

  const { shortPath, modalConfirmDelete } = usePurchase({ model });

  const { foundParams, clearParams } = useUrlParams({
    urlParams: ['openShareModal']
  });

  useEffect(() => {
    if (!foundParams?.openShareModal) return;
    setShowModal({ modal: 'shareModal' });
    clearParams('openShareModal');
  }, [foundParams]);

  const actions = {
    post: handleCreate,
    put: handleUpdate,
    delete: handleDelete
  };

  const handleCrudClick = (verb, params, pathOptions) => {
    return actions[verb]({
      ...params,
      refresh: false,
      displayToast: 'Operação realizada com sucesso!',
      ...(pathOptions
        ? { updatePathOptions: pathOptions }
        : {
            id: data?.id
          })
    }).then(resp => {
      if (!resp?.error && afterSubmit) {
        afterSubmit({ data });
        setShowModal();
      }
    });
  };

  const handleClick = ({ modal, params, redirectTo }) => {
    if (redirectTo) history.push(redirectTo);
    else {
      setShowModal({ modal, params });
    }
  };

  const custom = {
    copyLink: (
      <CopyLinkButton
        onClose={f => f}
        eventButton="item"
        urlContext={shortPath}
        idData={data?.splitId || data?.id}
        id="copy-link"
        text
        isSplit={!!data?.splitId}
      >
        <FontAwesomeIcon icon={faLink} color={colors.neutral400} />
        Copiar link
      </CopyLinkButton>
    ),
    exportPdf: (
      <Button onClick={() => handlePrint()} text>
        <FontAwesomeIcon icon={faFilePdf} color={colors.neutral400} />
        Exportar PDF
      </Button>
    ),
    seeSupplierPayment: (
      <Button
        onClick={() =>
          setOpenFinanceDrawer({
            open: true,
            type: data?.payment?.isCharge ? 'payment' : 'expense',
            idPayment: data?.idPayment
          })
        }
        text
        id="see-supplier-payment"
      >
        <FontAwesomeIcon icon={faDollarSign} color={colors.neutral400} />
        Ver pagamento para fornecedor
      </Button>
    ),
    chargeAdministrationFee: (
      <Button
        onClick={() => setOpenPurchaseModal({ open: true, idOrders: [data?.id], type: 'administrationFee' })}
        text
        id="charge-administration-fee"
      >
        <FontAwesomeIcon icon={faHandHoldingDollar} color={colors.neutral400} />
        Cobrar taxa de administração
      </Button>
    ),
    requestRefund: (
      <Button
        onClick={() =>
          setOpenFinanceDrawer({
            open: true,
            type: 'payment',
            idOrders: [data?.id],
            orderType: paymentOrderType.refund
          })
        }
        text
        id="request-refund"
      >
        <FontAwesomeIcon icon={faFileInvoiceDollar} color={colors.neutral400} />
        Solicitar reembolso
      </Button>
    )
  };

  const modal = {
    confirm: () => (
      <ConfirmModal
        onSubmit={() =>
          handleDelete({
            deletePathOptions: data?.splitId ? `/split/${data?.splitId}` : `/${data?.id}`,
            displayToast: 'Operação realizada com sucesso!',
            noLoading: true,
            refresh: false
          }).then(resp => {
            if (!resp?.error && afterSubmit) {
              afterSubmit({ data, isDelete: true });
            }
          })
        }
        onClose={() => setShowModal(null)}
        text={modalConfirmDelete.text}
        title={modalConfirmDelete.title}
        alertInfo={modalConfirmDelete.alertInfo}
        alertInfoPadding={spaces.space1}
        $noPadding
      />
    ),
    createOrMoveToQuote: () => (
      <RadioOptionsModal
        subtitlePadding={spaces.space2}
        open
        options={[
          {
            value: true,
            label: 'Mover o status e criar uma Cotação',
            id: 'create-and-move',
            disabled: data?.quote?.id || !hasQuotePermission,
            ...(!hasQuotePermission && { tooltip: 'Você não tem permissão para criar uma cotação.' }),
            ...(data?.quote?.id && { tooltip: 'Solicitação já possui uma cotação vinculada.' })
          },
          { value: false, label: 'Apenas mover o status para “Cotação”', id: 'only-move' }
        ]}
        title="Mover para cotação"
        subtitle="O que deseja fazer?"
        defaultValue={!data?.quote?.id && hasQuotePermission}
        onSubmit={create => {
          if (create) {
            history.push(
              `/profissional/gestao-de-compras/cotacoes/novo?solicitacao=${data?.id}${
                data.idRefurbish ? `&projeto=${data?.idRefurbish}` : ''
              }`
            );
            return null;
          }

          return handleCrudClick('put', { values: { idPurchaseStatus: purchaseStatus.quote } }, `/action/${data?.id}`);
        }}
        onClose={setShowModal}
      />
    ),
    createOrMoveToOrder: () => (
      <RadioOptionsModal
        subtitlePadding={spaces.space2}
        open
        options={[
          {
            value: true,
            label: 'Mover o status e criar Ordem de Compra/Contratação',
            id: 'create-and-move',
            disabled: !hasOrderPermission,
            ...(!hasOrderPermission && { tooltip: 'Você não tem permissão para criar Ordem de compra/Contratação.' })
          },
          { value: false, label: 'Apenas mover o status para “Compra e contratação”', id: 'only-move' }
        ]}
        title="Mover para compra e contratação"
        subtitle="O que deseja fazer?"
        defaultValue={hasOrderPermission}
        onSubmit={create => {
          if (create) {
            history.push(
              `/profissional/gestao-de-compras/ordem-de-compras/novo?solicitacao=${data?.id}${
                data.idRefurbish ? `&projeto=${data?.idRefurbish}` : ''
              }`
            );
            return null;
          }

          return handleCrudClick('put', { values: { idPurchaseStatus: purchaseStatus.buy } }, `/action/${data?.id}`);
        }}
        onClose={setShowModal}
      />
    ),
    rejectModal: () => (
      <ConfirmModal
        modalWidth="600"
        title={isOrder ? 'Recusar compra' : 'Recusar solicitação'}
        text="Se desejar, inclua um motivo ou observação para a recusa."
        onClose={setShowModal}
        onSubmit={resp =>
          handleCrudClick(
            'put',
            {
              values: {
                ...(isOrder
                  ? { idOrderStatus: purchaseStatus.rejected }
                  : { idPurchaseStatus: purchaseStatus.rejected }),
                authorityRejectionObs: resp?.observation
              }
            },
            `/action/${data?.id}`
          )
        }
        showObservation
        placeholder={`Ex: ${
          isOrder ? 'Ordem de compra' : 'Solicitação'
        } recusada pois os itens estão fora do escopo de compra.`}
        label="Observações"
      />
    ),
    moveToSent: () => (
      <RadioOptionsModal
        subtitlePadding={spaces.space2}
        open
        options={[
          {
            value: true,
            label: 'Mover o status e enviar a ordem de compra para o fornecedor',
            id: 'create-and-move'
          },
          { value: false, label: 'Apenas mover o status para “Enviado”', id: 'only-move' }
        ]}
        title="Enviar ordem de compra"
        subtitle="O que deseja fazer?"
        defaultValue
        onSubmit={create => {
          if (create) {
            setShowModal({ modal: 'shareModal' });
            return null;
          }

          return handleCrudClick('put', { values: { idOrderStatus: purchaseStatus.sent } }, `/action/${data?.id}`);
        }}
        onClose={setShowModal}
      />
    ),
    shareModal: () => (
      <ShareWithSupplierModal
        model="order"
        itemId={data?.splitId || data?.id}
        isSplit={!!data?.splitId}
        onClose={setShowModal}
        onShare={() => {
          if (data?.idOrderStatus === purchaseStatus?.sent) return;

          handleCrudClick(
            'put',
            {
              values: { idOrderStatus: purchaseStatus?.sent }
            },
            `/${data?.id}`
          );
        }}
      />
    ),
    confirmOrderModal: () => (
      <OrderPaymentModal
        idOrder={data?.splitId || data?.id}
        isSplit={!!data?.splitId}
        onClose={_data => {
          setShowModal();
          if (!_data) return;
          const { idCompanyCustomer, type, idOrder, orderDate, isSplit } = _data;
          setOpenFinanceDrawer({
            open: true,
            type,
            idOrders: idOrder,
            idCompanyCustomer,
            orderDate,
            orderType: paymentOrderType.order,
            isSplit
          });
        }}
      />
    )
  };

  const menu = (
    <StyledMenu $alignLeftOptions $minWidth="150px">
      {allDropdown[`${model}Action`]({
        data,
        purchaseOrderStatus,
        purchaseSolicitationStatus,
        hasUserPaymentPermission,
        isMobile: isMobile(),
        hasApprovalAuthority
      })?.map(option => {
        const childrenOption = option.children?.filter(c => c.show);
        return childrenOption?.length ? (
          <SubMenu
            key={`submenu-item-${option.id}`}
            title={<DropDownButton optionData={option} $fullWidth />}
            id={`submenu-item-${option.id}`}
          >
            {childrenOption?.map(child => {
              const oldStatus = isOrder ? data.idOrderStatus : data.idPurchaseStatus;
              const hasAuthority = checkApprovalAuthority({
                oldStatus,
                newStatus: child.value,
                user,
                total: data?.totalSplitValue || data?.total,
                moveStatus
              });
              const isSelected = oldStatus === child.value;
              const isDisabled = !hasAuthority && !isSelected;

              let toolTipText = null;

              if (isDisabled && hasAuthority === null)
                toolTipText = 'A partir do status atual não é possível alterar para esse status.';
              else if (isDisabled && hasAuthority === false)
                toolTipText = 'É necessário que a solicitação esteja aprovada para avançar.';

              return (
                <StyledItemMenu key={`menu-item-${child.id}`} selected={isSelected}>
                  {custom[child.button] || (
                    <Button
                      id={child.id}
                      text
                      onClick={() => {
                        if (isSelected) return null;
                        return child.verb && !child.modal
                          ? handleCrudClick(child.verb, child.params, child.pathOptions)
                          : handleClick(child);
                      }}
                      disabled={isDisabled}
                      fullWidth
                      $justifyContent="space-between"
                      tooltipText={toolTipText}
                      tooltipPlacement="left"
                    >
                      {child.label}
                      {isSelected && <FontAwesomeIcon icon={faCheck} color={colors.primary500} />}
                    </Button>
                  )}
                </StyledItemMenu>
              );
            })}
          </SubMenu>
        ) : (
          <React.Fragment key={`menu-${option.id}`}>
            <Menu.Item key={`menu-item-${option.id}`}>
              {custom[option.button] || (
                <DropDownButton
                  optionData={option}
                  id={option.id}
                  text
                  onClick={() =>
                    option.verb && !option.modal
                      ? handleCrudClick(option.verb, option.params, option.pathOptions)
                      : handleClick(option)
                  }
                />
              )}
            </Menu.Item>
          </React.Fragment>
        );
      })}
    </StyledMenu>
  );

  return (
    <>
      <div role="presentation" onClick={e => e.stopPropagation()}>
        <OrderModals
          openPurchaseModal={openPurchaseModal}
          setOpenPurchaseModal={setOpenPurchaseModal}
          openFinanceDrawer={openFinanceDrawer}
          setOpenFinanceDrawer={setOpenFinanceDrawer}
          afterSubmit={afterSubmit}
        />
        {showModal && modal[showModal.modal] ? modal[showModal.modal](showModal.params) : null}
        <DropdownContainer mobileDirection="column">
          <Dropdown slim trigger={['click']} menu={menu} padding={0}>
            {children}
          </Dropdown>
        </DropdownContainer>
      </div>
    </>
  );
};

PurchaseActionDropdown.propTypes = {
  data: PropTypes.instanceOf(Object),
  afterSubmit: PropTypes.func,
  model: PropTypes.string,
  children: PropTypes.instanceOf(Object),
  handlePrint: PropTypes.func
};

export default PurchaseActionDropdown;
