import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Button from '../Button/Button';
import { colors, spaces } from '../../styles/style';
import { FooterButtonContainer } from '../../_Pages/Payments/Payment.style';
import ActionButtonDropdown from '../Dropdown/ActionButtonDropdown';
import useViewport from '../../_Hooks/useViewport';

const PaymentFooter = ({
  currentStep,
  isView,
  isCustomerView,
  values,
  isRevision,
  isPublic,
  skipDraft,
  handleDeletePayment,
  handleShare,
  handleCustomerView,
  handleCancel,
  handleSave,
  handleBack,
  handleEdit,
  handleCancelPayment,
  setCurrentStep,
  handleRevisionAndCreate,
  validSubmit,
  loadingFooter,
  getPaymentBook,
  hasPermissionVP,
  isConciliation,
  idOrder,
  isIdOrderNullOrArray,
  showProductsConciliation,
  handleConciliateProducts,
  handleDuplicate,
  setIsPrinting,
  setOpenSendNfseDrawer,
  hasNfsePermission
}) => {
  const { idPaymentStatus, isVobiPay, id, paymentTypes, installments, ownBusiness } = values;
  const { isMobile } = useViewport(window.innerWidth);

  const hasEditPermission = !isVobiPay || (isVobiPay && hasPermissionVP);

  const { paymentStatuses, vobiPayPaymentType } = useSelector(state => state.setup.enums);
  const isDraft = idPaymentStatus === paymentStatuses.draft;
  const hasSlipBankBook =
    paymentTypes?.some(type => type?.id === vobiPayPaymentType.bankSlip) && installments.length > 1 && isVobiPay;

  const ViewFooter = (
    <>
      <FooterButtonContainer>
        <Button id="return-list" text className="withMarginRight" onClick={handleBack}>
          Voltar
        </Button>
      </FooterButtonContainer>
      {!isCustomerView && (
        <FooterButtonContainer>
          {idPaymentStatus === paymentStatuses.cancelled && (
            <Button text className="withMarginRight" onClick={handleDeletePayment} id="delete-payment" color="red500">
              Excluir
            </Button>
          )}
          {!isPublic && !isRevision && !isCustomerView && idPaymentStatus !== paymentStatuses.cancelled && (
            <>
              <ActionButtonDropdown
                showMobile
                buttonProps={{ id: 'action-button-payment' }}
                menu={
                  <div style={{ padding: spaces.space1 }}>
                    {idPaymentStatus !== paymentStatuses.draft ? (
                      <>
                        <Button type="primary" id="share-payment" onClick={handleShare} text>
                          Compartilhar
                        </Button>
                        <Button text id="customer-view" className="withMarginRight" onClick={handleCustomerView}>
                          Ver como cliente
                        </Button>
                      </>
                    ) : null}

                    {hasEditPermission && (
                      <Button text id="cancel-payment-drawer" className="withMarginRight" onClick={handleCancelPayment}>
                        Cancelar pagamento
                      </Button>
                    )}
                    {hasSlipBankBook ? (
                      <Button text id="print-payment" className="withMarginRight" onClick={getPaymentBook}>
                        Imprimir carnê
                      </Button>
                    ) : null}

                    {isMobile() ? null : (
                      <Button text id="pdf-payment" className="withMarginRight" onClick={() => setIsPrinting(true)}>
                        Exportar PDF
                      </Button>
                    )}

                    {hasEditPermission && (
                      <Button text id="duplicate-payment" className="withMarginRight" onClick={handleDuplicate}>
                        Duplicar
                      </Button>
                    )}
                    {hasNfsePermission && ownBusiness && (
                      <Button
                        text
                        id="nfse-payment"
                        className="withMarginRight"
                        onClick={() => setOpenSendNfseDrawer(true)}
                      >
                        Emitir nota fiscal
                      </Button>
                    )}
                    {hasEditPermission && (
                      <Button
                        text
                        className="withMarginRight"
                        onClick={handleDeletePayment}
                        id="delete-payment"
                        color="red500"
                      >
                        Excluir
                      </Button>
                    )}
                  </div>
                }
              />
              {hasEditPermission && (
                <Button type="primary" onClick={handleEdit} id="edit-payment-drawer">
                  Editar
                </Button>
              )}
            </>
          )}
        </FooterButtonContainer>
      )}
    </>
  );

  const RevisionFooter = (
    <>
      <Button text className="withMarginRight" onClick={handleBack}>
        Voltar
      </Button>
      <Button
        loading={loadingFooter}
        type="primary"
        onClick={() => handleSave({ isDraft: false, close: isConciliation || !isIdOrderNullOrArray })}
        id="create-payment"
      >
        Criar pagamento
      </Button>
    </>
  );

  const CreateManualFooter = (
    <>
      <Button id="return-payment" text className="withMarginRight" onClick={() => handleCancel(!hasPermissionVP)}>
        Voltar
      </Button>
      {skipDraft || isRevision ? (
        <div style={{ display: 'flex', gap: spaces.space2 }}>
          {!isConciliation && (
            <Button
              id="save-continue-payment"
              type="light"
              onClick={() => handleSave({ isDraft: false })}
              loading={loadingFooter}
            >
              Salvar
            </Button>
          )}
          <Button
            id="save-payment"
            type="primary"
            onClick={() => handleSave({ isDraft: false, close: true })}
            loading={loadingFooter}
          >
            Salvar e fechar
          </Button>
        </div>
      ) : (
        <FooterButtonContainer>
          {!isConciliation && !idOrder && (
            <Button
              type="default"
              className="withMarginRight"
              style={{ color: `${colors.primary600}` }}
              loading={loadingFooter}
              onClick={() => handleSave({ isDraft: true })}
            >
              Salvar rascunho
            </Button>
          )}

          <Button type="primary" loading={loadingFooter} onClick={handleRevisionAndCreate} id="review-payment">
            Revisar e criar
          </Button>
        </FooterButtonContainer>
      )}
    </>
  );

  const VobiPayFooter =
    currentStep > 2 ? (
      <>
        <Button text className="withMarginRight" onClick={handleBack}>
          Voltar
        </Button>
        <Button type="primary" onClick={handleRevisionAndCreate} id="create-payment">
          Revisar
        </Button>
      </>
    ) : (
      <>
        <Button text className="withMarginRight" onClick={() => (currentStep > 1 ? handleBack() : handleCancel(false))}>
          Voltar
        </Button>
        <Button
          type="primary"
          onClick={e => {
            validSubmit(e) && setCurrentStep(prev => prev + 1);
          }}
          id="create-payment"
        >
          Próximo
        </Button>
      </>
    );

  const ProductConciliationFooter = (
    <>
      <Button text className="withMarginRight" onClick={() => handleCancel(false)}>
        Fechar
      </Button>
      <Button type="primary" onClick={handleConciliateProducts} id="conciliate-products" loading={loadingFooter}>
        Avançar
      </Button>
    </>
  );

  return (
    <>
      {!id && isVobiPay && !isRevision && !showProductsConciliation && VobiPayFooter}
      {isView && !isDraft && id && !showProductsConciliation && ViewFooter}
      {!id && isRevision && !isCustomerView && !showProductsConciliation && RevisionFooter}
      {((!isView && !isRevision && !isVobiPay) || (id && !isView) || isDraft) &&
        !showProductsConciliation &&
        CreateManualFooter}
      {showProductsConciliation && ProductConciliationFooter}
    </>
  );
};

PaymentFooter.propTypes = {
  handleSave: PropTypes.func,
  idPaymentStatus: PropTypes.number,
  isView: PropTypes.bool,
  handleCancelPayment: PropTypes.func,
  handleEdit: PropTypes.func,
  values: PropTypes.instanceOf(Object),
  isRevision: PropTypes.bool,
  handleBack: PropTypes.func,
  isCustomerView: PropTypes.func,
  isPublic: PropTypes.bool,
  handleDeletePayment: PropTypes.func,
  handleShare: PropTypes.func,
  skipDraft: PropTypes.bool,
  handleCustomerView: PropTypes.func,
  handleCancel: PropTypes.func,
  currentStep: PropTypes.number,
  setCurrentStep: PropTypes.func,
  handleRevisionAndCreate: PropTypes.func,
  validSubmit: PropTypes.func,
  loadingFooter: PropTypes.bool,
  getPaymentBook: PropTypes.func,
  hasPermissionVP: PropTypes.bool,
  isConciliation: PropTypes.bool,
  idOrder: PropTypes.number,
  isIdOrderNullOrArray: PropTypes.bool,
  showProductsConciliation: PropTypes.bool,
  handleConciliateProducts: PropTypes.func,
  handleDuplicate: PropTypes.func,
  setIsPrinting: PropTypes.func,
  setOpenSendNfseDrawer: PropTypes.func,
  hasNfsePermission: PropTypes.bool
};
export default PaymentFooter;
