import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { Div, Grid, colors, spaces, fonts, breakpoints } from '../../styles/style';
import { Paragraph, Title } from '../Text/Text';
import useViewport from '../../_Hooks/useViewport';
import { capitalize } from '../../lib/helpers/helper';
import HowDoesItWorksButton from '../Button/HowDoesItWorksButton';
import Button from '../Button/Button';

const HeaderItem = styled(Div)`
  gap: ${spaces.space0};
  padding: ${spaces.space0} 0;
`;

// TODO: mudar a abordagem desse componente
const HeaderGrid = styled(Grid)`
  width: 100%;
  grid-gap: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 2px;
    background-color: ${colors.neutral100};
  }

  > *:nth-child(even) {
    padding-left: ${spaces.space2};
  }

  @media (max-width: ${breakpoints.tablet}) {
    grid-template-columns: 1fr;

    &:after {
      display: none;
    }

    > *:nth-child(even) {
      padding-left: 0;
    }
  }
`;

const PurchaseHeader = ({
  readOnly,
  code,
  data,
  refurbish,
  linkedSolicitation,
  linkedQuote,
  type = 'purchase',
  isCustomer = false,
  isPrint = false
}) => {
  const {
    purchaseSolicitationStatusProps,
    purchaseOrderStatusProps,
    purchaseQuoteStatusColor,
    priority: priorityEnum = {}
  } = useSelector(state => state.setup.enums);
  const { isMobile } = useViewport(window.innerWidth);

  const _isMobile = isMobile();

  const priorityColorsMap = {
    [priorityEnum.high.id]: colors.red500,
    [priorityEnum.average.id]: colors.orange400,
    [priorityEnum.low.id]: colors.primary600
  };

  const typeMap = {
    order: {
      title: 'Ordem de compra',
      statusColor: purchaseOrderStatusProps[data?.idOrderStatus]?.color,
      shouldRenderRefurbishLine: false,
      howDoesItWorksButtonId: 'howPurchaseOrderWorks'
    },
    purchase: {
      statusColor: purchaseSolicitationStatusProps[data?.idPurchaseStatus]?.color,
      title: 'Solicitação de compra',
      shouldRenderRefurbishLine: true,
      howDoesItWorksButtonId: 'howPurchaseRequestWorks'
    },
    quote: {
      title: 'Cotação',
      statusColor: purchaseQuoteStatusColor[data?.idQuoteStatus],
      shouldRenderRefurbishLine: false,
      howDoesItWorksButtonId: 'HowQuotationDetailsWork'
    }
  };

  const ActionHeader = (
    <HeaderItem>
      {type === 'quote' && (data?.id || data?.splitId) && (
        <Button
          id="view-as-client"
          type="primary"
          onClick={() =>
            window.open(`/qs/${data?.splitId || data?.id}${data?.splitId ? '?isSplit=true' : ''}`, '_blank')
          }
          icon={<FontAwesomeIcon size="lg" icon={faEye} />}
          text
        >
          {!isMobile() ? 'Ver como fornecedor' : ''}
        </Button>
      )}
      <HowDoesItWorksButton id={typeMap?.[type].howDoesItWorksButtonId} />
    </HeaderItem>
  );

  const title = (
    <Title id="purchase-header-title">
      {typeMap[type].title} <span style={{ color: colors.primary500 }}>#{data?.code || code}</span>
    </Title>
  );

  const NameLine = (
    <HeaderItem>
      <Paragraph color={colors.neutral700} weight={fonts.weight600}>
        Nome:
      </Paragraph>
      <Paragraph color={colors.neutral700}>{data?.name}</Paragraph>
    </HeaderItem>
  );

  const RefurbishLine = (
    <HeaderItem>
      <Paragraph color={colors.neutral700} weight={fonts.weight600}>
        {data?.refurbishesList ? 'Projetos:' : 'Projeto:'}
      </Paragraph>
      <Paragraph color={colors.neutral700}>
        {data?.refurbishesList
          ? data.refurbishesList.map((item, index) => (
              <React.Fragment key={item.id}>
                {index > 0 && <b> | </b>}
                <span>{item.name}</span>
              </React.Fragment>
            ))
          : data?.refurbish?.name}
      </Paragraph>
    </HeaderItem>
  );

  const createdByLine = data?.createdBy ? (
    <HeaderItem>
      <Paragraph color={colors.neutral700} weight={fonts.weight600}>
        Criado por:
      </Paragraph>
      <Paragraph color={colors.neutral700}>{data?.createdBy?.name}</Paragraph>
    </HeaderItem>
  ) : null;

  const ResponsibleLine = (
    <>
      {data?.responsible ? (
        <HeaderItem>
          <Paragraph color={colors.neutral700} weight={fonts.weight600}>
            Responsável:
          </Paragraph>
          <Paragraph color={colors.neutral700}>{data?.responsible?.name}</Paragraph>
        </HeaderItem>
      ) : null}
    </>
  );

  const creationDateLine = (
    <HeaderItem>
      <Paragraph color={colors.neutral700} weight={fonts.weight600}>
        Data de criação:
      </Paragraph>
      <Paragraph id="purchase-createdAt" color={colors.neutral700}>
        {dayjs(data?.createdAt).format('DD/MM/YYYY [às] HH[h]mm')}
      </Paragraph>
    </HeaderItem>
  );

  const StatusAndPriority = (
    <Div gap={spaces.space2} direction={_isMobile ? 'column' : 'row'} align={_isMobile ? 'flex-start' : 'center'}>
      <HeaderItem>
        <Paragraph color={colors.neutral700} weight={fonts.weight600}>
          Status:
        </Paragraph>
        <Paragraph color={colors[typeMap[type].statusColor || 'orange400']} id="purchase-status">
          {data?.purchaseStatus?.name || 'Rascunho'}
        </Paragraph>
      </HeaderItem>
      <HeaderItem>
        <Paragraph color={colors.neutral700} weight={fonts.weight600}>
          Prioridade:
        </Paragraph>
        <Paragraph color={priorityColorsMap[data?.priority]}>
          {capitalize(Object.values(priorityEnum).find(value => value?.id === data?.priority)?.name)}
        </Paragraph>
      </HeaderItem>
    </Div>
  );

  const DeadlineLine = (
    <HeaderItem>
      <Paragraph color={colors.neutral700} weight={fonts.weight600}>
        Prazo:
      </Paragraph>
      <Paragraph color={colors.neutral700}>{dayjs(data?.responseDate).format('DD/MM/YYYY')}</Paragraph>
    </HeaderItem>
  );

  const LinkedLine = (
    <>
      {linkedSolicitation && (
        <HeaderItem>
          <Paragraph color={colors.neutral700} weight={fonts.weight600}>
            Solicitação vinculada:
          </Paragraph>
          <Paragraph color={colors.primary500}>#{linkedSolicitation}</Paragraph>
        </HeaderItem>
      )}
      {linkedQuote && (
        <HeaderItem>
          <Paragraph color={colors.neutral700} weight={fonts.weight600}>
            Cotação vinculada:
          </Paragraph>
          <Paragraph color={colors.primary500}>#{linkedQuote}</Paragraph>
        </HeaderItem>
      )}
    </>
  );

  if (isCustomer) {
    return (
      <Div direction="column" align="flex-start" gap={spaces.space2}>
        {title}
        <Div direction="column" gap={spaces.space2} align="left">
          {RefurbishLine}
          {NameLine}
          {data?.responseDate && DeadlineLine}
          {StatusAndPriority}
        </Div>
      </Div>
    );
  }

  return readOnly ? (
    <Div justify="space-between" align="flex-start" $fullWidth padding={isPrint ? `${spaces.space2} 0` : spaces.space2}>
      <Div direction="column" align="flex-start" gap={spaces.space1} $fullWidth>
        {title}
        <HeaderGrid>
          {NameLine}
          {(refurbish || data?.refurbish || data?.refurbishesList?.length) && RefurbishLine}
          {createdByLine}
          {ResponsibleLine}
          {creationDateLine}
          {StatusAndPriority}
          {data?.responseDate && DeadlineLine}
          {LinkedLine}
        </HeaderGrid>
      </Div>
      {!_isMobile && !isPrint && ActionHeader}
    </Div>
  ) : (
    <Div justify="space-between" padding={isPrint ? `${spaces.space2} 0` : spaces.space2} $fullWidth>
      <Div
        justify="flex-start"
        gap={spaces.space3}
        wrap="wrap"
        direction={_isMobile && 'column'}
        align={_isMobile ? 'flex-start' : 'center'}
      >
        <HeaderItem>
          <strong>Código:</strong>
          <Paragraph>{data?.code || code}</Paragraph>
        </HeaderItem>
        {LinkedLine}
        <HeaderItem>
          <strong>Status:</strong>
          <Paragraph color={colors[purchaseSolicitationStatusProps[data?.idPurchaseStatus]?.color || 'orange400']}>
            {data?.purchaseStatus?.name || 'Rascunho'}
          </Paragraph>
        </HeaderItem>
      </Div>
      {!_isMobile && !isPrint && ActionHeader}
    </Div>
  );
};

PurchaseHeader.propTypes = {
  readOnly: PropTypes.bool,
  code: PropTypes.string,
  data: PropTypes.instanceOf(Object),
  refurbish: PropTypes.instanceOf(Object),
  linkedSolicitation: PropTypes.string,
  linkedQuote: PropTypes.string,
  type: PropTypes.string,
  isCustomer: PropTypes.bool,
  isPrint: PropTypes.bool
};

export default PurchaseHeader;
