import {
  faMagic,
  faCheck,
  faCopy,
  faEmptySet,
  faInboxOut,
  faTrashCan,
  faArchive,
  faTrophy,
  faUpload,
  faArrowRightArrowLeft,
  faLink
} from '@fortawesome/pro-regular-svg-icons';
import { colors, spaces } from '../../../styles/style';

const nameAsc = { label: 'Nome A-z', order: [['name', 'ASC']], verb: 'get', id: 'name1' };
const nameDesc = { label: 'Nome Z-a', order: [['name', 'DESC']], verb: 'get', id: 'name2' };
const createdAtAsc = { label: 'Criado em (crescente)', order: [['createdAt', 'ASC']], verb: 'get' };
const createdAtDesc = { label: 'Criado em (decrescente)', order: [['createdAt', 'DESC']], verb: 'get' };

const priceAsc = { label: 'Menor preço', order: ['price', 'ASC'], verb: 'get' };
const priceDesc = { label: 'Maior preço', order: ['price', 'DESC'], verb: 'get' };
const topSale = { label: 'Recomendado', order: [], verb: 'get' };

const bar = { bar: true, label: 'bar' };
const editItem = { label: 'Editar', id: 'edit', modal: 'editItem', verb: 'update', modalParams: { tab: '0' } };
const searchCatalog = {
  label: 'Procurar um item no catálogo',
  externalAction: true,
  modal: 'catalogDrawer',
  values: { type: 1 },
  verb: 'post',
  id: 'Search-item-catalog'
};

const addProduct = {
  label: 'Criar um novo produto',
  verb: 'post',
  id: 'Create-new-product',
  values: { type: 1 }
};

const addLabor = {
  label: 'Criar um novo serviço',
  id: 'Create-new-service',
  verb: 'post',
  values: { type: 2 }
};

const addComposition = {
  label: 'Criar nova composição',
  externalAction: true,
  modal: 'createComposition',
  values: { type: 4 },
  verb: 'post',
  id: 'Create-new-composition',
  roles: ['composition']
};

const addItem = [searchCatalog, addProduct, addLabor];
const addCompositionItems = isMobile => [searchCatalog, addProduct, addLabor, ...(!isMobile ? [addComposition] : [])];

const deleteAction = {
  label: 'Excluir',
  verb: 'delete',
  id: 'delete',
  modal: 'confirmModal',
  modalParams: { text: 'Deseja realmente apagar esse item?' },
  buttonParams: {
    type: 'danger'
  },
  iconProps: {
    color: colors.red500
  },
  displayToast: 'Operação realizada com sucesso!'
};

const deleteProject = {
  label: 'Excluir projeto',
  verb: 'delete',
  id: 'delete',
  modal: 'paymentDeleteModal',
  iconProps: {
    icon: faTrashCan,
    color: colors.red500
  },
  buttonParams: {
    type: 'danger'
  },
  displayToast: 'Operação realizada com sucesso!'
};

const deleteActionList = ({ item = 'item', gender = 'o' } = {}) => ({
  label: 'Excluir',
  verb: 'delete',
  id: 'delete',
  modal: 'confirmModal',
  modalParams: {
    text: `Ao excluir, ${gender} ${item} será removid${gender} permanente e ninguém mais poderá acessá-l${gender}.`,
    alertInfo: 'Essa operação não poderá ser desfeita',
    title: `Excluir ${item}`
  },
  displayToast: 'Operação realizada com sucesso!'
});

const deleteActionV2 = {
  label: 'Excluir',
  id: 'delete',
  modal: 'confirm',
  params: { text: 'Deseja realmente apagar esse item?' },
  buttonParams: {
    type: 'danger'
  },
  iconProps: {
    color: colors.red500,
    icon: faTrashCan
  }
};

const cancelProject = {
  label: 'Cancelar projeto',
  modal: 'confirmModal',
  id: 'cancel-project',
  verb: 'put',
  modalParams: {
    text: 'Deseja realmente cancelar o projeto?'
  },
  values: { idStep: 9999998 },
  iconProps: {
    icon: faEmptySet
  }
};
const duplicateProject = {
  id: 'duplicate-project',
  label: 'Duplicar projeto',
  modal: 'useAsTemplateModal',
  modalParams: {
    title: 'Novo Projeto',
    model: 'refurbish',
    pathOptions: '/duplicate',
    textHeader: `Você pode duplicar um projeto com suas etapas e tarefas, itens no orçamento,
    estrutura de pastas (sem os arquivos) e também suas anotações`,
    text: `Escolha o que deseja duplicar deste projeto.`,
    successText: 'Projeto duplicado com sucesso',
    modalType: 'Projeto',
    source: 'Projeto'
  },
  iconProps: {
    icon: faCopy
  }
};
const finishProject = {
  id: 'finish-project',
  label: 'Finalizar projeto',
  verb: 'put',
  modal: 'confirmModal',
  modalParams: {
    text: 'Deseja realmente finalizar o projeto?'
  },
  values: { idStep: 9999999 },
  iconProps: {
    icon: faCheck
  }
};
const applyTemplatetoProject = {
  label: 'Salvar como template',
  modal: 'useAsTemplateModal',
  id: 'new-Template',
  modalParams: {
    title: 'Novo template',
    model: 'template',
    pathOptions: '/refurbishToTemplate',
    textHeader: `Você pode criar um template de projeto com suas etapas e tarefas, itens no orçamento,
    estrutura de pastas (sem os arquivos) e também suas anotações. Assim, você terá sempre disponível esse
    template para utilizar em futuros projetos, sem precisar criar do zero.`,
    successText: 'Template criado com sucesso',
    text: 'Escolha o que deseja em seu template',
    modalType: 'Template',
    source: 'Template'
  },
  iconProps: {
    icon: faMagic
  }
};

const taskDetails = {
  label: 'Detalhes',
  externalFunc: 'onClickDetails'
};

const copyLinkButton = {
  id: 'copy-link',
  button: 'copyLink',
  buttonParams: {
    text: true,
    children: 'Copiar link'
  },
  iconProps: {
    color: colors.neutral400,
    icon: faLink
  }
};

const exportPdf = {
  id: 'export-pdf',
  button: 'exportPdf',
  buttonParams: {
    text: true,
    children: 'Exportar PDF'
  }
};

const installmentSlipBank = {
  button: 'exportSlipBank',
  buttonParams: {
    text: true,
    children: 'Baixar boleto',
    $padding: `0 ${spaces.space1}`
  }
};

const attachmentButton = {
  label: 'Adicionar anexo',
  button: 'uploadFile',
  id: 'add-attachment',
  stopPropagation: true,
  buttonParams: {
    multiple: true,
    id: 'add-attachment',
    buttonProps: { text: true, $noIcon: true, $padding: 0 },
    type: 'dropdown',
    text: 'Adicionar anexo',
    showGallery: false,
    listType: null,
    noMobileMargin: true
  }
};

const cancelPayment = () => ({
  label: `Cancelar pagamento`,
  modal: 'confirmModal',
  id: 'cancel-payment',
  verb: 'patch',
  modalParams: {
    modalWidth: 340,
    text: `Deseja realmente cancelar esse pagamento?`,
    alertInfo: 'Essa ação não poderá ser desfeita.'
  },
  values: { idPaymentStatus: 4 }
});

const cancelInstallment = {
  label: `Cancelar parcela`,
  modal: 'confirmModal',
  id: 'cancel-installment',
  verb: 'put',
  modalParams: {
    modalWidth: 340,
    text: `Deseja realmente cancelar essa parcela?`,
    alertInfo: 'Essa ação não poderá ser desfeita.'
  },
  values: { idInstallmentStatus: 12 }
};

const paidInstallment = {
  label: 'Marcar como pago',
  modal: 'registerPayment',
  id: 'paid-installment',
  model: 'installment'
};

const unpaidInstallment = {
  label: 'Voltar para aguardando',
  verb: 'put',
  id: 'unpaid-installment',
  model: 'installment',
  values: { idInstallmentStatus: 1, paidDate: null, idPaymentType: null, idReceipt: null }
};

const useSpecificationTemplate = {
  id: 'use-template',
  label: 'Usar template de orçamento',
  modal: 'templatesModal',
  verb: 'post',
  values: { type: 2 }
};

const unarchiveOpportunity = {
  label: 'Reativar oportunidade',
  modal: 'restoreModal',
  iconProps: { icon: faUpload }
};

const archiveOpportunity = {
  label: 'Arquivar',
  modal: 'archiveModal',
  iconProps: { icon: faArchive }
};

const winOpportunity = {
  label: 'Marcar como ganho',
  verb: 'put',
  modal: 'templatesModal',
  modalParams: {
    idStatus: 5,
    isOpportunity: true,
    isApply: true,
    isRefurbish: true,
    title: 'Parabéns! Sua oportunidade será convertida em projeto!'
  },
  iconProps: { icon: faTrophy }
};

const reactivateProject = {
  label: 'Reativar projeto',
  iconProps: {
    icon: faInboxOut
  },
  verb: 'put',
  modal: 'reactivateProjectModal',
  displayToast: 'Seu projeto foi reativado.'
};

const undoReconciliation = installment => ({
  label: 'Desfazer conciliação',
  id: 'undoReconciliation',
  modal: 'undoReconciliationModal',
  modalParams: {
    installment
  }
});

const restoreProject = {
  label: 'Reverter para oportunidade',
  modal: 'restoreModal',
  iconProps: { icon: faArrowRightArrowLeft },
  modalParams: {
    isProject: true
  }
};

export {
  nameAsc,
  nameDesc,
  createdAtAsc,
  createdAtDesc,
  priceAsc,
  priceDesc,
  topSale,
  editItem,
  deleteAction,
  deleteActionV2,
  addItem,
  cancelProject,
  duplicateProject,
  finishProject,
  applyTemplatetoProject,
  bar,
  taskDetails,
  copyLinkButton,
  exportPdf,
  attachmentButton,
  cancelPayment,
  paidInstallment,
  unpaidInstallment,
  addCompositionItems,
  useSpecificationTemplate,
  unarchiveOpportunity,
  archiveOpportunity,
  winOpportunity,
  reactivateProject,
  installmentSlipBank,
  cancelInstallment,
  deleteActionList,
  addComposition,
  undoReconciliation,
  deleteProject,
  restoreProject
};
