import React, { memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import dayjs from 'dayjs';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';

import { faInfoCircle, faReceipt } from '@fortawesome/pro-regular-svg-icons';
import TooltipIcon from '../Tooltip/TooltipIcon';
import useCRUD from '../../_Hooks/useCRUD';

import useViewport from '../../_Hooks/useViewport';

import { calcPercentageByValue, calcValuesByPercentage, getColorByStatusPayment } from '../../lib/helpers/helper';
import formatCurrency from '../../lib/helpers/formatCurrency';
import Drawer from './Drawer';
import { StyledFooter, TabContent } from './EditInstallmentDrawer.styles';
import ItemAttachmentContent from '../Content/ItemAttachmentContent';
import { colors, Div, spaces } from '../../styles/style';
import Button from '../Button/Button';
import RegisterInstallmentModal from '../Modal/RegisterInstallmentModal';
import ConfirmModal from '../Modal/ConfirmModal';
import { getPaymentLabel } from '../../lib/helpers/payment';
import Tabs, { TabPane } from '../Tabs/Tabs';
import Description from '../Content/Description';
import { mappingDescriptionConf } from '../../lib/mapping/Description/mappingDescriptionConf';
import UndoReconciliationModal from '../Modal/UndoReconciliationModal';
import { Paragraph } from '../Text/Text';
import PaymentInvoiceList from '../List/PaymentInvoiceList';
import { Context } from '../../contexts/GeneralContext';
import Label from '../Label/Label';
import { Line } from '../Line/Line';
import FlagTag from '../Tag/FlagTag';
import ItemDetailButton from '../Button/ItemDetailButton';
import ActionButtonDropdown from '../Dropdown/ActionButtonDropdown';
import OptionButton from '../Button/OptionButton';
import CenteredLoader from '../Loader/CenteredLoader';
import ApportionmentTooltip from '../Tooltip/ApportionmentTooltip';

const formId = 'drawer-installment-form';

const EditInstallmentDrawer = ({
  idRefurbish,
  onClose = f => f,
  id,
  valuesChanged,
  billType,
  isCharge,
  handleSave,
  tab = '0',
  handleRedirect,
  zIndex = 1000,
  afterSubmit
}) => {
  const { user } = useSelector(state => state.authReducer);
  const {
    userType,
    paymentStatuses,
    fineInterestDiscountType: { percentage }
  } = useSelector(state => state.setup.enums);
  const [registerPayment, setRegisterPayment] = useState(false);
  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
  const [undoReconciliation, setUndoReconciliation] = useState(false);
  const [files, setFiles] = useState([]);
  const [shouldReload, setShouldReload] = useState(false);
  const isCustomer = user?.userType === userType?.customer?.value;
  const [installmentData, setInstallmentData] = useState();
  const [selectedTab, setSelectedTab] = useState(tab || '0');
  const { installmentStatuses, billType: billTypeEnum } = useSelector(state => state.setup.enums);
  const [tooltipData, setTooltipData] = useState([]);
  const [extraValues, setExtraValues] = useState({});

  const [showConfirmPendingModal, setShowConfirmPendingModal] = useState(false);

  const [hasSupplier, setHasSupplier] = useState(false);
  const [hasCustomer, setHasCustomer] = useState(false);

  const { data, handleUpdate, handleGet, handleCreate, loading } = useCRUD({
    pathOptions: `/${id}`,
    options: {
      where: { idRefurbish },
      include: [
        'paymentType',
        'installmentStatus',
        'files',
        'bankAccount',
        {
          model: 'payment',
          include: [
            'paymentTypes',
            'supplier',
            'companyCustomer',
            {
              model: 'paymentInvoice',
              as: 'invoices',
              include: ['file', 'fileXml']
            }
          ]
        }
      ]
    },
    model: 'installment',
    immediatelyLoadData: false
  });

  const { handleGet: getTooltipData, loading: loadingTooltipData } = useCRUD({
    model: 'payment',
    immediatelyLoadData: false
  });

  useEffect(() => {
    if (id) {
      handleGet();
    }
  }, [id]);

  useEffect(() => {
    if (data?.splitInstallmentId) {
      getTooltipData({
        refetchOptions: {
          attributes: ['id', 'splitId', 'allSplitDueDateDiscount', 'allSplitInterest'],
          where: { splitId: data?.splitInstallmentId, getAll: true },
          include: [
            {
              model: 'installment',
              attributes: [
                'splitInstallmentId',
                'number',
                'price',
                'percentageSplitPrice',
                'totalSplitDiscount',
                'totalSplitInterest',
                'totalSplitFine'
              ],
              where: { number: data?.number }
            },
            { model: 'refurbish', attributes: ['name'] }
          ]
        }
      }).then(resp => {
        const { allSplitDueDateDiscount, allSplitInterest } = resp?.[0] || {};

        const result = resp.map(item => {
          const { installments, refurbish } = item;
          const { price, percentageSplitPrice } = installments[0];

          return {
            id,
            price,
            refurbish,
            percentage: percentageSplitPrice
          };
        });
        setTooltipData(result);
        setExtraValues({ allSplitDueDateDiscount, allSplitInterest });
      });
    }
  }, [data]);

  const generateReceipt = () => {
    handleCreate({
      postPathOptions: `/receipt`,
      values: {
        id: data?.id
      },
      displayToast: true,
      refresh: true
    }).then(resp => {
      if (!resp?.error && resp?.idReceipt) {
        window.open(`${window.location.origin}/rc/${resp?.idReceipt}`, '_blank');
      }
    });
  };

  const { interest, fine, dueDateDiscount, dueDateDiscountType, fineType } = data?.extraValues || {};

  const pendingPayment = installmentData?.idInstallmentStatus === installmentStatuses?.pendingPayment;
  const paidInstallment = installmentStatuses?.allPaid?.includes(installmentData?.idInstallmentStatus);

  const canChangeInstallment =
    data?.idInstallmentStatus === installmentStatuses.pendingPayment ||
    data?.idInstallmentStatus === installmentStatuses.paidManually ||
    (data?.idInstallmentStatus === installmentStatuses.paid && !data?.payment?.isVobiPay);

  const isCancelled = data?.idInstallmentStatus === installmentStatuses.cancelled;
  const canCancel =
    data?.payment?.isVobiPay && data?.idInstallmentStatus === installmentStatuses.pendingPayment && !isCustomer;

  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();

  const statusColor = getColorByStatusPayment(installmentData?.installmentStatus?.id, installmentStatuses);

  const label = getPaymentLabel({
    ...installmentData?.payment,
    billTypeEnum
  });

  const action = {
    income: 'viewIncomeDetails',
    expense: 'viewExpenseDetails'
  };

  const submitData = installment => {
    handleUpdate({
      values: installment,
      updatePathOptions: `/${installmentData.id}`
    }).then(() => {
      afterSubmit && afterSubmit();
      onClose(shouldReload, { id });
    });
  };

  const handleConfirmPending = () => {
    handleUpdate({
      updatePathOptions: `/${installmentData.id}`,
      values: {
        idInstallmentStatus: installmentStatuses.pendingPayment,
        paidDate: null,
        idPaymentType: null,
        idRefurbish: idRefurbish || data?.payment?.idRefurbish
      }
    });
  };

  const handleConfirmCancel = () => {
    handleUpdate({
      values: { idInstallmentStatus: installmentStatuses.cancelled },
      updatePathOptions: `/${installmentData.id}`
    }).then(() => {
      setShouldReload(true);
    });
  };

  const handleCancelInstallment = () => {
    setShowConfirmCancelModal(true);
  };

  useEffect(() => {
    if (data?.id) {
      const {
        files: _files,
        splitInstallmentId,
        payment,
        totalSplitPrice,
        paidSplitValue,
        totalSplitOriginalValue,
        totalSplitInterest,
        totalSplitFine,
        totalSplitDiscount
      } = data || {};
      setFiles(_files?.sort((a, b) => a.id - b.id) || []);

      setInstallmentData(
        splitInstallmentId
          ? {
              ...data,
              price: Number(totalSplitPrice),
              paidValue: Number(paidSplitValue),
              originalValue: Number(totalSplitOriginalValue),
              interest: Number(totalSplitInterest),
              fine: Number(totalSplitFine),
              discount: Number(totalSplitDiscount)
            }
          : data
      );
      setHasSupplier(!!payment?.supplier);
      setHasCustomer(!!payment?.companyCustomer);
    }
  }, [data]);

  const showActionMobile =
    (!isCancelled && canCancel) ||
    (!data?.idReceipt && paidInstallment && !isCustomer) ||
    (data?.idReceipt && paidInstallment) ||
    (!isCustomer && data?.idReconciliation);

  const mobileActions = (
    <ActionButtonDropdown
      showMobile={showActionMobile}
      menu={
        loading || loadingTooltipData ? (
          <CenteredLoader />
        ) : (
          <>
            {!isCancelled && canCancel ? (
              <OptionButton id="cancel-installment-drawer" onClick={handleCancelInstallment} text>
                Cancelar parcela
              </OptionButton>
            ) : null}
            {!data?.idReceipt && paidInstallment && !isCustomer ? (
              <OptionButton id="generate-receipt-installment" onClick={() => generateReceipt()} text>
                Emitir recibo
              </OptionButton>
            ) : null}
            {data?.idReceipt && paidInstallment ? (
              <OptionButton
                id="generate-receipt-installment"
                onClick={() => window.open(`${window.location.origin}/rc/${data?.idReceipt}`, '_blank')}
                text
              >
                Visualizar recibo
              </OptionButton>
            ) : null}
            {!isCustomer && data?.idReconciliation ? (
              <OptionButton id="open-undo-reconciliation" onClick={() => setUndoReconciliation(true)} text>
                Desfazer conciliação
              </OptionButton>
            ) : null}
          </>
        )
      }
    />
  );

  const deskActions = (
    <>
      {!isCancelled && canCancel && (
        <Button
          text
          type="danger"
          id="cancel-installment-drawer"
          className="withMarginRight"
          onClick={handleCancelInstallment}
        >
          Cancelar parcela
        </Button>
      )}

      {!data?.idReceipt && paidInstallment && !isCustomer ? (
        <Button id="generate-receipt-installment" text type="primary" onClick={() => generateReceipt()}>
          Emitir recibo
        </Button>
      ) : null}
      {data?.idReceipt && paidInstallment ? (
        <Button
          id="view-receipt-installment"
          text
          type="primary"
          onClick={() => window.open(`${window.location.origin}/rc/${data?.idReceipt}`, '_blank')}
        >
          Visualizar recibo
        </Button>
      ) : null}

      {!isCustomer && data?.idReconciliation && (
        <Button
          id="open-undo-reconciliation"
          text
          style={{ color: colors.primary500 }}
          onClick={() => setUndoReconciliation(true)}
        >
          Desfazer conciliação
        </Button>
      )}
    </>
  );

  const footer = useMemo(() => {
    return (
      <StyledFooter>
        <Button id="close-drawer" type={isCustomer ? 'primary' : 'text'} onClick={() => onClose(shouldReload, { id })}>
          Fechar
        </Button>
        <Div gap={spaces.space2}>
          {_isMobile ? mobileActions : deskActions}

          {!isCustomer &&
            canChangeInstallment &&
            !data?.idReconciliation &&
            data?.payment?.idPaymentStatus !== paymentStatuses.draft && (
              <>
                <Button
                  id="open-register-installment"
                  htmlType="submit"
                  type="light"
                  form={formId}
                  onClick={() => (pendingPayment ? setRegisterPayment(true) : setShowConfirmPendingModal(true))}
                >
                  {pendingPayment ? 'Marcar como pago' : 'Voltar para aguardando'}
                </Button>
              </>
            )}

          {!isCustomer && !isCancelled && (
            <Button
              id="submit-drawer"
              htmlType="submit"
              type="primary"
              onClick={() => submitData({ ...data, files })}
              form={formId}
            >
              Salvar
            </Button>
          )}
        </Div>
      </StyledFooter>
    );
  }, [data, files, shouldReload, loading, _isMobile, loadingTooltipData]);

  const isDefeated = dayjs(installmentData?.dueDate).isBefore(dayjs(new Date()), 'day') && pendingPayment;
  const dueDateDiscountValue = installmentData?.dueDateDiscount
    ? formatCurrency(installmentData?.dueDateDiscount, {
        currencySymbol: 'R$ '
      })
    : calcValuesByPercentage({ percentageField: dueDateDiscount, subtotal: data?.price });

  const renderDetailsTab = () => {
    return (
      <Div direction="column" gap={spaces.space2} $fullWidth>
        <Div direction="column" align="stretch" $fullWidth>
          {!isCustomer && installmentData?.isReconciled ? (
            <Div justify="end" margin={`0 0 ${spaces.space1} 0`}>
              <FlagTag
                value={
                  !installmentData?.idReconciliation && data?.payment?.isVobiPay
                    ? 'CONCILIADO AUTOMATICAMENTE'
                    : 'LANÇAMENTO CONCILIADO'
                }
                className="success"
              />
            </Div>
          ) : null}
          <Div justify="space-between" align="baseline">
            <Label>Descrição:</Label>
            <Paragraph type="small" id="installment-description">
              {installmentData?.description}
            </Paragraph>
          </Div>
          <Div justify="space-between" align="baseline">
            <Label>Status da parcela:</Label>
            <Div gap={spaces.space2}>
              <Paragraph type="small" color={isDefeated ? colors?.red500 : statusColor} id="installment-status">
                {isDefeated ? 'Vencido' : installmentData?.installmentStatus?.name}
              </Paragraph>

              {installmentData?.idReceipt && paidInstallment ? (
                <ItemDetailButton
                  icon={faReceipt}
                  tooltipProps={{ id: `show-receipt-installment-${installmentData?.id}` }}
                  titleTooltip="Ver recibo"
                  onClick={() => window.open(`${window.location.origin}/rc/${installmentData?.idReceipt}`, '_blank')}
                />
              ) : null}
            </Div>
          </Div>

          {installmentStatuses?.paidVobiPay?.includes(installmentData?.idInstallmentStatus) &&
          installmentData?.originalValue > 0 &&
          Number(installmentData?.originalValue) !== Number(installmentData?.price) ? (
            <Div justify="space-between" align="baseline">
              <Label>Valor original:</Label>
              <Paragraph type="small" id="installment-originalValue">
                {formatCurrency(installmentData?.originalValue, { currencySymbol: 'R$' })}
              </Paragraph>
            </Div>
          ) : null}
          <Div justify="space-between" align="baseline">
            <Label>
              {`${
                installmentStatuses?.paidVobiPay?.includes(installmentData?.idInstallmentStatus)
                  ? 'Valor pago:'
                  : 'Valor da parcela:'
              }`}
            </Label>
            <Div gap={spaces.space1}>
              <Paragraph type="small" id="installment-price">
                {formatCurrency(installmentData?.totalSplitPrice || installmentData?.price, { currencySymbol: 'R$' })}
              </Paragraph>
              {tooltipData?.length > 0 && (
                <TooltipIcon
                  tooltipColor={colors.white}
                  tooltipProps={{ overlayInnerStyle: { padding: spaces.space1 }, placement: 'left' }}
                  icon={faInfoCircle}
                  iconColor={colors.primary500}
                  text={<ApportionmentTooltip list={tooltipData} extraValues={extraValues} />}
                />
              )}
            </Div>
          </Div>
          <Div justify="space-between" align="baseline">
            <Label>Vencimento da parcela:</Label>
            <Paragraph type="small" id="installment-due-date">
              {dayjs(installmentData?.dueDate).format('DD/MM/YYYY')}
            </Paragraph>
          </Div>
          <Div justify="space-between" align="baseline">
            <Label>Formas de pagamento:</Label>

            <Paragraph type="small" id="installment-payment-types">
              {installmentData?.payment?.paymentTypes.length
                ? installmentData?.payment?.paymentTypes?.map((type, index) => {
                    const arrLength = installmentData?.payment?.paymentTypes.length;
                    const divider = arrLength === 1 || index === arrLength - 1 ? '' : ', ';
                    return `${type?.name}${divider}` || '-';
                  })
                : '-'}
            </Paragraph>
          </Div>

          {pendingPayment ? (
            <>
              {installmentData?.interest ? (
                <Div justify="space-between" align="baseline">
                  <Label>Juros ao mês:</Label>
                  <Paragraph type="small" id="installment-interest">
                    {`${formatCurrency(interest)}%`} (
                    {formatCurrency(installmentData?.interest, {
                      currencySymbol: 'R$ '
                    })}
                    )
                  </Paragraph>
                </Div>
              ) : null}
              {installmentData?.fine ? (
                <Div justify="space-between" align="baseline">
                  <Label>Multa:</Label>
                  <Paragraph type="small" id="installment-fine">
                    {fineType === percentage.value
                      ? `${formatCurrency(fine)}%`
                      : `${calcPercentageByValue({ field: fine, subtotal: data?.price })}%`}{' '}
                    (
                    {formatCurrency(installmentData?.fine, {
                      currencySymbol: 'R$ '
                    })}
                    )
                  </Paragraph>
                </Div>
              ) : null}
              {dueDateDiscount ? (
                <Div justify="space-between" align="baseline">
                  <Label>Desconto no pagamento:</Label>
                  <Paragraph type="small" id="installment-due-date-discount">
                    {dueDateDiscountType === percentage.value
                      ? `${formatCurrency(dueDateDiscount)}%`
                      : dueDateDiscountValue}{' '}
                    até{' '}
                    {dayjs(installmentData?.dueDate)
                      .subtract(installmentData?.payment?.dueDateLimitDays, 'days')
                      .format('DD/MM/YYYY')}
                  </Paragraph>
                </Div>
              ) : null}
            </>
          ) : (
            <>
              {Number(installmentData?.interest) ? (
                <Div justify="space-between" align="baseline" padding={`${spaces.space3} 0 0 0`}>
                  <Label>Juros aplicado:</Label>
                  <Paragraph type="small" id="installment-interest">
                    {formatCurrency(installmentData?.interest, {
                      currencySymbol: 'R$ '
                    })}
                  </Paragraph>
                </Div>
              ) : null}
              {Number(installmentData?.fine) ? (
                <Div justify="space-between" align="baseline">
                  <Label>Multa aplicada:</Label>
                  <Paragraph type="small" id="installment-fine">
                    {formatCurrency(installmentData?.fine, {
                      currencySymbol: 'R$ '
                    })}
                  </Paragraph>
                </Div>
              ) : null}
              {Number(installmentData?.discount) ? (
                <Div justify="space-between" align="baseline">
                  <Label>Desconto no pagamento:</Label>
                  <Paragraph type="small" id="installment-due-date-discount">
                    {formatCurrency(installmentData?.discount, {
                      currencySymbol: 'R$ '
                    })}
                  </Paragraph>
                </Div>
              ) : null}
            </>
          )}

          <Div justify="space-between" align="baseline" padding={`${spaces.space3} 0 0 0`}>
            <Label>Data de pagamento:</Label>
            <Paragraph type="small" id="installment-paid-date">
              {installmentData?.paidDate ? dayjs(installmentData?.paidDate).format('DD/MM/YYYY') : '-'}
            </Paragraph>
          </Div>
          <Div justify="space-between" align="baseline">
            <Label>Pago com:</Label>
            <Paragraph type="small" id="installment-payment-type">
              {installmentData?.paymentType?.name || '-'}
            </Paragraph>
          </Div>
          {(installmentData?.asaasCreditDate || installmentData?.asaasEstimatedCreditDate) && (
            <Div justify="space-between" align="baseline">
              <Label>Data de liquidação:</Label>
              <Paragraph type="small" id="liquid-date">
                {installmentData?.asaasCreditDate || installmentData?.asaasEstimatedCreditDate
                  ? dayjs(installmentData?.asaasCreditDate || installmentData?.asaasEstimatedCreditDate).format(
                      'DD/MM/YYYY'
                    )
                  : ''}
              </Paragraph>
            </Div>
          )}
          <Div justify="space-between" align="baseline">
            <Label>Conta:</Label>
            <Paragraph type="small" id="installment-bank-account">
              {installmentData?.bankAccount?.name || '-'}
            </Paragraph>
          </Div>
          <Div justify="space-between" align="baseline" padding={`${spaces.space3} 0 0 0`}>
            <Label>Nome {label}:</Label>
            <Div>
              <Paragraph type="small" id="installment-payment-name">
                {installmentData?.payment?.name}
              </Paragraph>
              {handleRedirect && (
                <TooltipIcon
                  onClick={() => {
                    const modePaymentOpen =
                      installmentData?.payment?.idPaymentStatus === paymentStatuses.draft
                        ? 'editPayment'
                        : 'viewPaymentDetails';
                    handleRedirect({
                      modal: installmentData?.payment?.isCharge
                        ? modePaymentOpen
                        : action[installmentData?.payment?.billType],
                      idPayment: installmentData?.payment?.id,
                      billType: installmentData?.payment?.billType,
                      isCharge: installmentData?.payment?.isCharge,
                      splitId: installmentData?.payment?.splitId,
                      isSplit: !!installmentData?.payment?.splitId
                    });
                    onClose(false, true, false);
                  }}
                  tooltipProps={{ placement: 'topLeft' }}
                  text="Ver pagamento"
                  style={{ color: colors.primary600, paddingLeft: spaces.space2 }}
                  icon={faArrowUpRightFromSquare}
                  id="view-payment"
                />
              )}
            </Div>
          </Div>
          <Div justify="space-between" align="baseline">
            <Label>Id {label}:</Label>
            <Paragraph type="small">{`#${installmentData?.payment?.id}`}</Paragraph>
          </Div>
          <Div justify="space-between" align="baseline">
            <Label>Data de criação:</Label>
            <Paragraph type="small">
              {dayjs(installmentData?.payment.createdAt).format('DD/MM/YYYY [às] HH:mm:ss')}
            </Paragraph>
          </Div>
          {installmentData?.payment?.createdBy?.name && (
            <Div justify="space-between" align="baseline">
              <Label>Criado por:</Label>
              <Paragraph type="small">{installmentData?.payment?.createdBy?.name}</Paragraph>
            </Div>
          )}
        </Div>
        <Line />
        <ItemAttachmentContent
          data={{ files }}
          readOnly={isCustomer || isCancelled}
          idRefurbish={idRefurbish}
          setFiles={setFiles}
          textEditor={false}
          hasDescription={false}
          maxSize="10mb"
          onAddFile={() => setShouldReload(true)}
          onRemoveFile={() => setShouldReload(true)}
        />
        <Line />
        <Context
          data={{
            values: { ...installmentData, invoices: installmentData?.payment?.invoices },
            isView: true,
            installmentView: true,
            billType: installmentData?.payment?.billType,
            isCharge: installmentData?.payment?.isCharge,
            refreshData: handleGet
          }}
        >
          <PaymentInvoiceList />
        </Context>
      </Div>
    );
  };

  const renderPayerTab = ({ key, type, payerData }) => {
    return (
      <TabPane key={key} tab={`Dados do ${type === 'supplier' ? 'fornecedor' : 'cliente'}`}>
        <TabContent>
          {hasSupplier && (
            <Description
              title="Dados bancários"
              mapping={mappingDescriptionConf?.bank || {}}
              data={data?.payment?.supplier || {}}
              background={null}
              noPadding
            />
          )}
          <Description
            style={{ paddingTop: 16, borderTop: hasSupplier ? `1px solid ${colors.neutral100}` : '0' }}
            title={type === 'supplier' ? 'Dados da empresa' : ''}
            mapping={type === 'supplier' ? mappingDescriptionConf.supplier : mappingDescriptionConf.companyCustomer}
            data={payerData}
            background={null}
            noPadding
          />
        </TabContent>
      </TabPane>
    );
  };

  return (
    installmentData && (
      <>
        <Drawer
          zIndex={!_isMobile && zIndex}
          open
          title={
            <Div gap={spaces.space0}>
              Detalhes da parcela
              {installmentData?.id ? (
                <Paragraph type="small" color={colors.neutral400} id="installment-id">
                  #{installmentData?.id}
                </Paragraph>
              ) : null}
            </Div>
          }
          onClose={() => onClose(shouldReload, { id })}
          formId={formId}
          width={_isMobile ? '100%' : 604}
          footer={footer}
        >
          <div>
            {!hasSupplier && !hasCustomer ? (
              renderDetailsTab()
            ) : (
              <Tabs activeKey={selectedTab} onChange={setSelectedTab} noHorizontalPadding>
                <TabPane key="0" tab="Detalhes">
                  <TabContent>{renderDetailsTab()}</TabContent>
                </TabPane>
                {hasSupplier &&
                  renderPayerTab({
                    key: '1',
                    type: 'supplier',
                    payerData: data?.payment?.supplier || {}
                  })}
                {hasCustomer &&
                  renderPayerTab({
                    key: '2',
                    type: 'customer',
                    payerData: data?.payment?.companyCustomer || {}
                  })}
              </Tabs>
            )}
          </div>
        </Drawer>
        {registerPayment && (
          <RegisterInstallmentModal
            onClose={() => {
              setRegisterPayment(false);
            }}
            installment={installmentData}
            onSubmit={(_, reload) => {
              setShouldReload(reload);
              setRegisterPayment(false);
            }}
            valuesChanged={valuesChanged}
            billType={billType}
            isCharge={isCharge}
            handleSave={handleSave}
            afterSubmit={handleGet}
          />
        )}
        {showConfirmPendingModal && (
          <ConfirmModal
            text="Deseja voltar a parcela para status 'Aguardando'?"
            onSubmit={(_, reload) => {
              handleConfirmPending();
              setShouldReload(reload);
              setShowConfirmPendingModal(false);
            }}
            onClose={() => setShowConfirmPendingModal(false)}
          />
        )}
        {showConfirmCancelModal && (
          <ConfirmModal
            title="Cancelar Parcela"
            alertInfo="Essa ação não poderá ser desfeita."
            text="Deseja realmente cancelar essa parcela?"
            onSubmit={handleConfirmCancel}
            onClose={() => setShowConfirmCancelModal(false)}
            modalWidth={340}
          />
        )}
        {undoReconciliation && (
          <UndoReconciliationModal
            installment={installmentData}
            onClose={reload => {
              setUndoReconciliation(false);
              if (reload) {
                setShouldReload(true);
                handleGet();
              }
            }}
          />
        )}
      </>
    )
  );
};

EditInstallmentDrawer.propTypes = {
  idRefurbish: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valuesChanged: PropTypes.bool,
  billType: PropTypes.string,
  isCharge: PropTypes.bool,
  handleSave: PropTypes.func,
  tab: PropTypes.string,
  handleRedirect: PropTypes.func,
  zIndex: PropTypes.number,
  afterSubmit: PropTypes.func
};

export default memo(EditInstallmentDrawer);
