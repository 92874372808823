import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { faShareAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash';

import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import qs from 'qs';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Button from '../../components/Button/Button';
import { colors, Div, spaces } from '../../styles/style';
import { RightHeaderContent, StatusContainer, ShowModalEmptyPayment, EmptyText } from './Payment.style';
import useViewport from '../../_Hooks/useViewport';
import ShareModuleModal from '../../components/Modal/ShareModuleModal';
import useFormState from '../../_Hooks/useFormState';
import useCRUD from '../../_Hooks/useCRUD';
import {
  getColorByStatusPayment,
  sumByField,
  validateFineInterestDiscount,
  validatePercentage,
  validatePurchaseTableItems
} from '../../lib/helpers/helper';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import formatCurrency from '../../lib/helpers/formatCurrency';
import { paymentSchema, vobiPaySchema } from '../../lib/mapping/Form/paymentSchema';
import formatNumber from '../../lib/helpers/formatNumber';
import LoadingModal from '../../components/Modal/LoadingModal';
import Modal from '../../components/Modal/Modal';
import CustomerViewAction from '../../components/Action/CustomerViewAction';
import {
  getValidationErrorMessage,
  extractInitialOrderData,
  mountOrderItems,
  conciliateProducts,
  prepareDataToDuplicate
} from '../../lib/helpers/payment';
import Drawer from '../../components/Drawer/Drawer';
import useReplaceUrl from '../../_Hooks/useReplaceUrl';
import PaymentContent from './PaymentContent';
import PaymentFooter from '../../components/Footer/PaymentFooter';
import CenteredLoader from '../../components/Loader/CenteredLoader';
import { updateCompany } from '../../store/auth/actions/action';
import { Paragraph } from '../../components/Text/Text';
import eventBus from '../../lib/helpers/eventBus';
import { hasPermission } from '../../routes/Common/PrivateRoute';
import useItemWithAppropriation from '../../_Hooks/useItemWithAppropriation';
import SendNfseDrawer from '../../components/Drawer/SendNfseDrawer';

let _d;
let interval;
const Payment = ({
  isView,
  isPublic,
  idRefurbish: refurbishId,
  onClose,
  idCompanyCustomer,
  id,
  action,
  selectedItems,
  isConciliation,
  initialData,
  idOrder,
  orderDate,
  feePercentage,
  orderType,
  withoutImportXML,
  paymentToCreate: _paymentToCreate,
  showProductsConciliation: _showProductsConciliation,
  idToDuplicate,
  ...props
}) => {
  const location = useLocation();
  const { paymentCreated } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const history = useHistory();
  const { user } = useSelector(state => state.authReducer) || {};
  const { plans, permissions, enums } = useSelector(state => state.setup) || {};
  const idRefurbish = _paymentToCreate?.idRefurbish || refurbishId;
  const {
    paymentStatuses,
    refurbishStatus,
    refurbishItemType,
    userType,
    maxLimitPayment,
    paymentOrderType,
    fineInterestDiscountType,
    paymentInvoiceSource,
    installmentStatuses
  } = enums || {};
  const dispatch = useDispatch();
  const vobiPayPermission = hasPermission(user, ['vobipay'], plans, permissions);
  const chargePermission = hasPermission(user, ['vobipayCharge'], plans, permissions);
  const hasPermissionVP = !!vobiPayPermission && !!chargePermission;

  const hasNfsePermission = hasPermission(user, ['emitNfse'], plans, permissions);

  const isCustomer = user?.userType === userType?.customer?.value;

  const [_isView, setIsView] = useState(isView);
  const [isRevision, setIsRevision] = useState(false);
  const [extraValueErrors, setExtraValueErrors] = useState({});
  const [isCustomerView, setCustomerView] = useState(isCustomer);
  const [showConfirmModal, setShowConfirmModal] = useState({ open: false });
  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [openSendNfseDrawer, setOpenSendNfseDrawer] = useState(false);
  const [totalInstallmentError, setTotalInstallmentError] = useState('');
  const [shareModal, setShareModal] = useState({ paymentCreated, paymentId: '' });
  const [_refurbish, setRefurbish] = useState(null);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [statusColor, setStatusColor] = useState(false);
  const [selectedTab, setSelectedTab] = useState('0');
  const [_id, setId] = useState(id);
  const [_idToDuplicate, setIdToDuplicate] = useState(idToDuplicate);
  const [reloadClose, setReloadClose] = useState(false);

  const isIdOrderNullOrArray = !idOrder || Array.isArray(idOrder);
  const [currentStep, setCurrentStep] = useState(!isConciliation && isIdOrderNullOrArray ? 0 : 1);
  const [loadingFooter, setLoadingFooter] = useState(false);
  const [_user, setUser] = useState(user);
  const [_isVobiPay, setIsVobiPay] = useState(false);
  const [noCredit, setNoCredit] = useState(false);
  const { itemObjectsMap, itemSettersMap, clearStates } = useItemWithAppropriation();
  const [creatingFromOrder, setCreatingFromOrder] = useState(false);
  const [showProductsConciliation, setShowProductsConciliation] = useState(_showProductsConciliation);
  const [paymentToCreate, setPaymentToCreate] = useState(_paymentToCreate);
  const [showConfirmCreateItemsModal, setShowConfirmCreateItemsModal] = useState(false);
  const [data, setData] = useState(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [invalidPaymentItems, setInvalidPaymentItems] = useState('');

  const textPaymentDelete = `Ao excluir, o pagamento será removido permanente
  da sua conta e ninguém mais poderá acessá-lo.`;
  const textDeletePaymentIsOrder = `Ao excluir lançamentos financeiros associados à ordem de compra,
  a ordem de compra voltará para o status "Aberta".`;

  const { loading, handleGet, handleUpdate, handleCreate, handleDelete } = useCRUD({
    model: `payment${isPublic ? '/public' : ''}`,
    pathOptions: _id ? `/${_id}` : '',
    immediatelyLoadData: false
  });

  const { handleUpdate: handleUpdateViewedBy } = useCRUD({
    model: `viewed/payment`,
    pathOptions: `/${_id}`,
    immediatelyLoadData: false
  });

  const { handleGet: handleGetCheck } = useCRUD({
    model: `payment${isPublic ? '/public' : ''}`,
    pathOptions: _id ? `/${_id}` : '',
    options: {
      include: [{ model: 'installment' }],
      attributes: ['updatedAt', 'paidValue', 'openAmount'],
      where: { ...((idRefurbish && { idRefurbish }) || {}) }
    },
    immediatelyLoadData: false
  });

  const { handleGet: handleGetItems, loading: loadingItems } = useCRUD({
    model: 'refurbish-items/bulk/payment',
    immediatelyLoadData: false
  });

  const { handleGet: handleGetStatus } = useCRUD({
    model: `payment${isPublic ? '/public' : ''}`,
    pathOptions: _id ? `/${_id}` : '',
    options: {
      attributes: [
        'idPaymentStatus',
        'paidValue',
        'openAmount',
        'cancelledValue',
        'allInterest',
        'allDueDateDiscount',
        'total',
        'taxes',
        'shipping',
        'otherTaxes',
        'discount',
        'isCharge'
      ],
      include: ['paymentStatus', 'installments', 'paymentItems']
    },
    immediatelyLoadData: false
  });

  const { handleGet: handleGetPaymentBook } = useCRUD({
    model: 'pay',
    pathOptions: `/payment-book`,
    immediatelyLoadData: false
  });

  const { loading: companyLoading, data: company } = useCRUD({
    model: 'company',
    pathOptions: `/${user?.idCompany}`,
    options: {
      attributes: ['id', 'stepPayAccount', 'accountStatus', 'idAsaas']
    },
    immediatelyLoadData: !isCustomerView && !isPublic
  });

  const { handleGet: handleGetOrder, loading: loadingOrder } = useCRUD({
    model: 'order',
    immediatelyLoadData: false,
    options: {
      include: [
        'paymentTypes',
        { model: 'orderItems', include: [{ model: 'orderItemLevel', include: ['refurbishItem'] }] }
      ],
      where: {
        id: idOrder
      }
    }
  });

  const { handleCreate: handleBulkCreateItems } = useCRUD({
    model: 'item',
    pathOptions: `/bulk`,
    immediatelyLoadData: false
  });

  const _formatNumber = number => (number ? formatNumber(number) : null);

  const _paymentSchema = useMemo(() => {
    if (_id) return paymentSchema();
    return currentStep === 2 ? vobiPaySchema(maxLimitPayment) : paymentSchema(_isVobiPay);
  }, [currentStep, _isVobiPay, data]);

  const isVobiPayCancelled = !!isPublic && data?.isVobiPay && data?.paymentStatus?.id === paymentStatuses?.cancelled;

  const {
    formState,
    handleBlur,
    handleChange,
    setField,
    isValid,
    handleSubmit,
    setTouched,
    valuesChanged,
    reset
  } = useFormState(
    data
      ? {
          ...data,
          extraValues: null,
          billingDate: data?.billingDate || dayjs(_d).format('YYYY-MM-DD')
        }
      : {
          name: initialData?.name || '',
          billingDate: initialData?.billingDate || orderDate || dayjs(_d).format('YYYY-MM-DD'),
          dueDate: dayjs(_d).format('YYYY-MM-DD'),
          idRefurbish: Number(idRefurbish) || undefined,
          idCompanyCustomer,
          installments: [],
          paymentItems: initialData?.paymentItems || [],
          paymentTypes: [],
          total: initialData?.total || 0,
          idCompany: user?.company?.id,
          company: user?.company,
          billType: 'income',
          isCharge: true,
          installmentCount: 1,
          customerDoc: '',
          customerEmail: '',
          customerPhone: '',
          idPaymentBankAccount: initialData?.idPaymentBankAccount,
          ownBusiness: initialData?.ownBusiness,
          isVobiPay: initialData?.isVobiPay,
          ...(idOrder ? { paymentOrderType: paymentOrderType[orderType] } : {}),
          paymentComplete: true
        },
    _paymentSchema,
    true
  );

  const { values, touched, errors } = formState;

  const skipDraft = (_id && values?.idPaymentStatus !== 1) || !values?.ownBusiness;

  const handleLoad = () =>
    handleGet({
      refetchPathOptions: _id || _idToDuplicate ? `/${_id || _idToDuplicate}` : '',
      refetchOptions: {
        where: { ...((idRefurbish && { idRefurbish }) || {}) },
        include: [
          { model: 'installment', include: ['installmentStatus', 'paymentType', 'files'] },
          'refurbish',
          {
            model: 'PaymentItem',
            separate: true,
            include: [
              'units',
              {
                model: 'paymentItemLevel',
                include: ['refurbishItem']
              }
            ]
          },
          {
            model: 'paymentInvoice',
            as: 'invoices',
            attributes: [
              'id',
              'number',
              'date',
              'idPayment',
              'accessKey',
              'isImported',
              'source',
              'situationCode',
              'idFile',
              'idFileXml',
              'type'
            ],
            include: ['file', 'fileXml']
          },
          'paymentTypes',
          'paymentStatus',
          'company',
          'companyCustomer',
          'files',
          'supplier'
        ],
        order: [
          ['installments', 'dueDate', 'asc'],
          ['invoices', 'id', 'asc']
        ]
      }
    }).then(resp => {
      if (!resp?.isCharge) {
        toast.error('Desculpe. Página não encontrada.');
        history.push('/profissional/dashboard');
      }
      const _data = _idToDuplicate ? prepareDataToDuplicate(resp) : resp;
      setData(_data);
    });

  useEffect(() => {
    _d = new Date();

    if (selectedItems?.length > 0) {
      handleGetItems({
        refetchOptions: {
          where: {
            id: selectedItems,
            idRefurbish
          }
        }
      }).then(({ paymentItems, supplier }) => {
        setField('ownBusiness')(false);
        setField('paymentItems')(paymentItems);
        if (supplier) setField('idSupplier')(supplier);
      });
    }

    if (idOrder) {
      handleGetOrder().then(orderList => {
        const _orderList = mountOrderItems({
          list: orderList,
          idOrder,
          feePercentage
        });

        const { idRefurbish: _idRefurbish, paymentInfo } = orderList?.[0] || {};
        const {
          orderDate: _orderDate,
          idOrder: _idOrder,
          idSupplier,
          shipping,
          taxes,
          discount,
          paymentTypes
        } = extractInitialOrderData({ ...orderList?.[0], id: idOrder }, paymentOrderType?.order === orderType);
        setField('paymentItems')(_orderList);
        setCreatingFromOrder(true);

        setField('orderDate')(_orderDate);
        setField('idOrder')(_idOrder);
        setField('idSupplier')(idSupplier);
        shipping && setField('shipping')(shipping);
        taxes && setField('taxes')(taxes);
        discount && setField('discount')(discount);
        paymentTypes && setField('paymentTypes')(paymentTypes || []);
        setField('idRefurbish')(_idRefurbish);
        if (paymentOrderType?.order === orderType) setField('installments')(paymentInfo?.list || []);
      });
    }

    if (_id && (isPublic || isCustomer)) {
      handleUpdateViewedBy({
        refresh: false,
        verb: 'patch'
      });
    }
  }, []);

  useEffect(() => {
    if (data?.refurbish) setRefurbish(data.refurbish);
    setStatusColor(getColorByStatusPayment(data?.paymentStatus?.id, paymentStatuses));
  }, [data]);

  useEffect(() => {
    if (_id || idToDuplicate) handleLoad();
  }, [_id, idToDuplicate]);

  const handleCheck = () => {
    if (data?.isVobiPay) {
      handleGetCheck().then(checkData => {
        const checkPayment = {
          updatedAt: checkData?.updatedAt,
          paidValue: checkData?.paidValue,
          openAmount: checkData?.openAmount
        };
        const actualPayment = {
          updatedAt: data?.updatedAt,
          paidValue: data?.paidValue,
          openAmount: data?.openAmount
        };
        if (!isEqual(checkPayment, actualPayment)) {
          handleLoad();
        }
      });
    }
  };

  const { isMobile } = useViewport(window.innerWidth);

  const isTotalInstallmentValid = () => {
    const { installments, total, allInterest = 0, allDueDateDiscount = 0, paymentComplete } = values;
    const _total = paymentComplete
      ? parseFloat(total.toFixed(2)) - parseFloat(allInterest.toFixed(2)) + parseFloat(allDueDateDiscount.toFixed(2))
      : total;
    const totalOfInstallments = parseFloat(sumByField(installments, 'price', 'originalValue').toFixed(2));
    if (totalOfInstallments > _total || totalOfInstallments < _total) {
      setTotalInstallmentError(
        `O valor total das parcelas deve ser ${formatCurrency(total, {
          currencySymbol: 'R$ '
        })}`
      );
      return false;
    }
    if (totalInstallmentError) setTotalInstallmentError('');
    return true;
  };

  const { returnToOriginalUrl, replaceToUrl } = useReplaceUrl({
    preventTrigger: !!isPublic || action === 'novo',
    urlToReplace: `/pg/${action}${_id ? `/${_id}` : ''}`
  });

  const handleClose = (refresh, payment) => {
    returnToOriginalUrl();
    if (user?.company?.pix !== _user?.company?.pix || user?.company?.incomeValue !== _user?.company?.incomeValue)
      dispatch(updateCompany({ ..._user?.company }));
    onClose(refresh || reloadClose, payment);
    return null;
  };

  const _onClose = forceClose => {
    if (valuesChanged && !_isView) {
      setShowConfirmModal({ open: true, forceClose });
      return;
    }
    handleClose(!!valuesChanged);
  };

  const handleBack = () => {
    if (values?.id) {
      _onClose();
    } else if (isRevision) {
      setIsRevision(false);
    } else if (values?.isVobiPay && currentStep > 1) {
      setCurrentStep(prev => prev - 1);
    } else if (!values?.id && currentStep === 1 && !isConciliation && isIdOrderNullOrArray) {
      reset();
      setCurrentStep(prev => prev - 1);
    } else _onClose();
  };

  const handleCancel = (forceClose = false) => {
    _onClose(forceClose);
  };

  const handleConfirm = () => {
    const isReconciled = data?.installments?.some(installment => installment?.idReconciliation);
    if (isReconciled) {
      toast.error(
        `Não é possível cancelar lançamento com parcelas conciliadas.
        Para cancelar você deve primeiro desfazer a conciliação das parcelas na tela de extrato.`
      );
      return;
    }
    handleUpdate({
      values: { id: data.id, idPaymentStatus: 4 },
      refresh: false,
      verb: 'patch',
      displayToast: true
    }).then(() => {
      handleClose(true, data.id);
    });
  };

  useEffect(() => {
    setIsVobiPay(values?.isVobiPay);
  }, [values?.isVobiPay]);

  const handleCustomerView = () => {
    if (values?.isVobiPay) window.open(`${window.location.origin}/c/${values?.id}`, '_blank');
    else setCustomerView(true);
  };

  const handleBackFromCustomerView = () => {
    setCustomerView(false);
  };

  const validSubmit = e => {
    const isExtraValuesWithErrors = Object.keys(extraValueErrors).length !== 0;
    const totalInstallmentIsValid = values?.installments?.length ? isTotalInstallmentValid() : true;
    const paymentItemsIsValid = (values?.paymentItems?.length ?? 0) > 0;
    const hasInvoiceInvalid =
      values?.invoices?.length > 0 && values?.invoices?.some(invoice => !invoice.number?.trim() && !invoice.deleted);

    if (!validatePurchaseTableItems({ values, setInvalidItems: setInvalidPaymentItems })) {
      return false;
    }

    if (
      !isValid() ||
      isExtraValuesWithErrors ||
      !totalInstallmentIsValid ||
      !paymentItemsIsValid ||
      hasInvoiceInvalid
    ) {
      toast.error(
        getValidationErrorMessage({
          isView: _isView,
          isRevision,
          totalInstallmentIsValid,
          paymentItemsIsValid,
          hasInvoiceInvalid
        })
      );
      handleSubmit(f => f)(e);
      return false;
    }
    if (values?.isVobiPay) {
      const errorMessageFID =
        _id || currentStep === 3 ? validateFineInterestDiscount({ values, types: fineInterestDiscountType }) : false;

      if (errorMessageFID) {
        toast.error(errorMessageFID);
        handleSubmit(f => f)(e);
        return false;
      }

      if (values?.total < maxLimitPayment?.minimumInstallmentValue.value) {
        toast.error(`O valor mínimo da cobrança deve ser de R$${maxLimitPayment?.minimumInstallmentValue.label}`);
        handleSubmit(f => f)(e);
        return false;
      }

      if (noCredit) {
        toast.error(getValidationErrorMessage({ isView: _isView, isRevision, totalInstallmentIsValid }));
        handleSubmit(f => f)(e);
        return false;
      }
    }

    return true;
  };

  const handleRevisionAndCreate = e => {
    document.getElementById('main').scrollTo({ top: 0, behavior: 'smooth' });

    if (validSubmit(e)) {
      setIsRevision(true);
    }
  };

  const refreshStatus = () => {
    setLoadingFooter(true);

    if (!_id) return setLoadingFooter(false);

    return handleGetStatus({
      refetchPathOptions: `/${_id}`
    }).then(
      ({
        idPaymentStatus,
        paymentStatus,
        paidValue,
        openAmount,
        cancelledValue,
        allInterest,
        allDueDateDiscount,
        total
      }) => {
        setField('idPaymentStatus')(idPaymentStatus, true);
        setField('paymentStatus')(paymentStatus, true);
        setField('paidValue')(paidValue || 0);
        setField('openAmount')(openAmount || 0);
        setField('cancelledValue')(cancelledValue || 0);
        setField('allInterest')(allInterest || 0, true);
        setField('allDueDateDiscount')(allDueDateDiscount || 0, true);
        setField('total')(total || 0, true);
        setStatusColor(getColorByStatusPayment(idPaymentStatus, paymentStatuses));
        setLoadingFooter(false);
      }
    );
  };

  const handleSave = ({ isDraft, close = false } = {}) => {
    if (!isDraft && !validSubmit()) {
      return;
    }

    if (!validatePercentage(values?.paymentItems) && !values?.ownBusiness) {
      toast.error('A soma das apropriações deve ser igual a 100%.');
      return;
    }
    setLoadingFooter(true);
    const func = _id ? handleUpdate : handleCreate;
    let idPaymentStatus = values.paymentStatus?.id || paymentStatuses?.open;
    if (isDraft) idPaymentStatus = paymentStatuses?.draft;
    else if (isRevision || idPaymentStatus === paymentStatuses?.draft) idPaymentStatus = paymentStatuses?.open;
    const haveBillingManager = values?.isVobiPay && Object.values(values?.billingChannels || {})?.includes(true);

    func({
      values: {
        ...values,
        idPaymentStatus,
        isCharge: true,
        discount: _formatNumber(values.discount),
        taxes: _formatNumber(values.taxes),
        shipping: _formatNumber(values.shipping),
        idPaymentBankAccount: values?.idPaymentBankAccount || null,
        ...(_id ? itemObjectsMap : {}),
        orderDate,
        idToDuplicate: _idToDuplicate
      },
      refresh: false,
      noLoading: isDraft
    }).then(resp => {
      setLoadingFooter(false);
      if (resp?.error) return;
      clearStates();
      const { payment } = resp || {};
      const _resp = payment || resp;
      const _payment = isConciliation ? resp : _resp;
      eventBus.dispatch('updateGuide');
      if (!isDraft) {
        if (close) handleClose(true, _payment);
        replaceToUrl(`/pg/visualizar/${_payment?.id}`);
        setIsRevision(false);
        setIsView(true);
        setId(_payment?.id);
        setIdToDuplicate(null);
        setReloadClose(true);
      }
      if (isRevision) {
        toast.success(
          `Pagamento criado ${haveBillingManager ? 'e já estamos notificando seu cliente.' : 'com sucesso.'}`
        );
        !_payment?.isVobiPay && setShareModal({ paymentId: _payment.id, paymentCreated: true });
        refreshStatus();
      } else if (isDraft) {
        toast.success('Rascunho salvo com sucesso.');
        handleClose(true);
      } else {
        toast.success(`Pagamento ${_id ? 'editado' : 'criado'} com sucesso.`);

        if (close) handleClose(true, _payment);
        else if (_id) handleLoad();
      }
    });
  };

  const handleShareModalClose = () => {
    setShareModal({ paymentCreated: false });
  };

  const handleShare = () => {
    setShareModal({ paymentId: _id, paymentCreated: false });
  };

  const handleDeletePayment = () => {
    setShowConfirmDeleteModal(true);
  };

  const showModalLoading = () => {
    setShowLoadingModal(true);
    setTimeout(() => {
      handleClose(true, data.id);
      setShowLoadingModal(false);
    }, 1500);
  };

  const handleConfirmDelete = () => {
    if (_id) {
      const isIntegrated = values?.invoices?.some(i => i?.source === paymentInvoiceSource.integrated);
      const isPaidVobiPay = values?.installments?.some(
        i => i?.idPaymentAsaas && installmentStatuses.paidVobiPay.includes(i?.idInstallmentStatus)
      );
      if (isIntegrated || isPaidVobiPay) {
        toast.error('Não é possível excluir lançamento contendo parcelas pagas pela Vobi Pay ou notas emitidas.');
        return;
      }

      const isReconciled = values?.installments?.some(i => i?.idReconciliation);
      if (isReconciled) {
        toast.error(
          `Não é possível excluir lançamento com parcelas conciliadas.
          Para excluir você deve primeiro desfazer a conciliação das parcelas na tela de extrato.`
        );
        return;
      }
      handleDelete({ refresh: false, values: { event: 'invoice-deleted' }, displayToast: true }).then(() => {
        showModalLoading();
      });
    }
  };
  const handleCancelPayment = () => {
    setShowConfirmCancelModal(true);
  };

  const handleEdit = () => {
    replaceToUrl(`/pg/editar/${_id}`);
    setIsView(false);
  };

  const handleDuplicate = () => {
    replaceToUrl(`/pg/novo`);
    setId(null);
    setIsView(false);
    setData(prepareDataToDuplicate(data));
  };

  const renderTitle = () => {
    const getTitle = () => {
      const label = `Pagamento`;
      if (showProductsConciliation) return 'Vincular itens';
      return !_id ? `Criar ${label.toLocaleLowerCase()}` : label;
    };

    return (
      <Div gap={spaces.space0} wrap="wrap">
        {getTitle()}
        {isMobile() && _id && data?.idPaymentStatus !== paymentStatuses?.draft && (
          <>
            <CustomerViewAction withoutMargin onClick={handleCustomerView} showIcon idRefurbish={idRefurbish} />
            <Button type="primary" onClick={handleShare} text>
              <FontAwesomeIcon size="lg" icon={faShareAlt} />
            </Button>
          </>
        )}

        <Paragraph type="small" color={colors.neutral400}>
          {_id ? ` #${_id}` : ''}
        </Paragraph>
      </Div>
    );
  };

  const getPaymentBook = () => {
    return handleGetPaymentBook({
      refetchPathOptions: `/payment-book/${values?.idInstallmentAsaas}`,
      responseType: 'arraybuffer'
    }).then(resp => {
      const arrayBuffer = resp;
      const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      window.open(url, '_blank');
      URL.revokeObjectURL(url);
    });
  };

  const handleConciliateProducts = () => {
    const hasItemsToCreate = paymentToCreate?.products?.some(item => !item.suggestion && !item.item);

    if (hasItemsToCreate) {
      setShowConfirmCreateItemsModal(true);
      return;
    }
    conciliateProducts({
      handleBulkCreateItems,
      paymentToCreate,
      refurbishItemType,
      setField,
      setLoading: setLoadingFooter,
      setShowProductsConciliation,
      setShowConfirmCreateItemsModal
    });
  };
  const handleConfirmCreateItem = () => {
    conciliateProducts({
      handleBulkCreateItems,
      paymentToCreate,
      refurbishItemType,
      setField,
      setLoading: setLoadingFooter,
      setShowProductsConciliation
    });
    setShowConfirmCreateItemsModal(false);
  };
  const disableRefurbish =
    !!idRefurbish || (!!_id && values?.idPaymentStatus !== paymentStatuses?.draft) || creatingFromOrder;
  const contextData = {
    data,
    values,
    setField,
    isView: _isView || isRevision,
    isRevision,
    touched,
    errors,
    handleChange,
    handleBlur,
    statusColor,
    extraValueErrors,
    setExtraValueErrors,
    isCustomerView,
    isPublic,
    isCharge: true,
    handleLoad,
    handleCancel,
    totalInstallmentError,
    _refurbish,
    setRefurbish,
    isCreate: !_id,
    setTouched,
    onSubmitDropdown: refreshStatus,
    valuesChanged,
    handleSave,
    disableRefurbish,
    isBulk: selectedItems?.length,
    currentStep,
    handleCustomerView,
    skipDraft,
    handleDeletePayment,
    handleCancelPayment,
    handleEdit,
    handleBack,
    handleRevisionAndCreate,
    setCurrentStep,
    validSubmit,
    handleShare,
    company,
    loadingFooter,
    getPaymentBook,
    _user,
    setUser,
    isCancelled: isVobiPayCancelled,
    hasPermissionVP,
    chargePermission,
    setNoCredit,
    isCustomer,
    isConciliation,
    idOrder,
    isIdOrderNullOrArray,
    feePercentage,
    itemObjectsMap,
    itemSettersMap,
    showProductsConciliation,
    setShowProductsConciliation,
    handleConciliateProducts,
    paymentToCreate,
    setPaymentToCreate,
    handleDuplicate,
    isPrinting,
    setIsPrinting,
    loadingItems: loadingItems || loadingOrder,
    invalidPaymentItems,
    setInvalidPaymentItems,
    setOpenSendNfseDrawer,
    hasNfsePermission
  };

  useEffect(() => {
    interval = setInterval(() => {
      if (isPublic) {
        handleCheck();
      }
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [data]);

  useEffect(() => {
    const elementToScroll = document.querySelector('.ant-drawer-body section');
    if (elementToScroll) elementToScroll.scrollTo(0, 0);
  }, [currentStep, isRevision]);

  return (
    <>
      {!isPublic ? (
        <Drawer
          open
          formId="PaymentForm"
          title={renderTitle()}
          onClose={() => _onClose(true)}
          padding="0px"
          width={isMobile() ? '100%' : '1080px'}
          extraContentOnRight
          noTitlePadding
          footer={currentStep > 0 || _id || showProductsConciliation ? <PaymentFooter {...contextData} /> : <div />}
          headerExtraContent={() => {
            return (
              <RightHeaderContent>
                {values.paymentStatus?.name && (
                  <StatusContainer $withMarginRight={!isPublic}>
                    Status:{' '}
                    <span style={{ color: statusColor }} id="payment-status">
                      {values.paymentStatus?.name}
                    </span>
                  </StatusContainer>
                )}
              </RightHeaderContent>
            );
          }}
        >
          {loading || companyLoading || loadingOrder ? (
            <CenteredLoader />
          ) : (
            <PaymentContent
              contextData={contextData}
              idRefurbish={idRefurbish}
              id={_id}
              _isView={_isView}
              handleBackFromCustomerView={handleBackFromCustomerView}
              isCustomer={isCustomer}
              closeDrawer={() => _onClose(true)}
              isMobile={isMobile}
              isRevision={isRevision}
              props={props}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              withoutImportXML={withoutImportXML}
            />
          )}
        </Drawer>
      ) : (
        <PaymentContent
          contextData={contextData}
          idRefurbish={idRefurbish}
          id={_id}
          _isView={_isView}
          handleBackFromCustomerView={handleBackFromCustomerView}
          isCustomer={isCustomer}
          closeDrawer={() => _onClose(true)}
          isMobile={isMobile}
          isRevision={isRevision}
          props={props}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          withoutImportXML={withoutImportXML}
        />
      )}

      <LoadingModal open={showLoadingModal} title="Estamos excluindo seu pagamento." />
      {shareModal?.paymentId && (
        <ShareModuleModal
          model="payment"
          itemId={shareModal?.paymentId}
          idRefurbish={_refurbish.id}
          setRefurbish={setRefurbish}
          onClose={handleShareModalClose}
          isOpportunity={refurbishStatus?.opportunity?.includes(data?.refurbish?.idStatus)}
        />
      )}

      {showConfirmCancelModal && (
        <ConfirmModal
          title="Cancelar pagamento"
          alertInfo="Essa operação não poderá ser desfeita"
          text="Ao cancelar, o pagamento continuará visível para você e para seu cliente."
          onSubmit={handleConfirm}
          onClose={() => setShowConfirmCancelModal(false)}
          modalWidth={340}
        />
      )}
      {!loading && (!data || isVobiPayCancelled) && _isView && (
        <ShowModalEmptyPayment>
          <Modal open hideFooter closable={false} width={832} height={128} sectionStyle={{ marginTop: spaces.space4 }}>
            <EmptyText id="empty-payment-text">
              {`O pagamento ${isMobile() ? '' : `#${_id}`} não está disponível`}
            </EmptyText>
          </Modal>
        </ShowModalEmptyPayment>
      )}
      {showConfirmDeleteModal && (
        <ConfirmModal
          title="Excluir pagamento"
          alertInfo="Essa operação não poderá ser desfeita"
          text={values?.isOrderPayment ? textDeletePaymentIsOrder : textPaymentDelete}
          onSubmit={handleConfirmDelete}
          onClose={() => setShowConfirmDeleteModal(false)}
          modalWidth={340}
        />
      )}

      {showConfirmCreateItemsModal && (
        <ConfirmModal
          title="Confirmar vínculos"
          alertInfo="Essa operação não poderá ser desfeita"
          text="Itens do XML não vinculados com os itens da plataforma serão criados e salvos em “Minha Biblioteca”."
          onSubmit={handleConfirmCreateItem}
          onClose={() => setShowConfirmCreateItemsModal(false)}
          submitText="Salvar e criar itens"
          cancelText="Voltar"
        />
      )}
      {showConfirmModal.open && (
        <ConfirmModal
          title="Alterações não salvas"
          text={`Você possui alterações que não foram salvas. ${
            !values?.isVobiPay ? 'Salve ou descarte as alterações para continuar.' : ''
          }`}
          alertInfo="Essa ação não poderá ser desfeita."
          submitText="Salvar"
          showDiscardButton
          onSubmit={() => handleSave({ isDraft: !skipDraft, close: true })}
          onClose={(_, opts) => {
            if (showConfirmModal?.forceClose && opts?.discard) {
              return handleClose(false);
            }
            if (!_id && currentStep === 1 && opts?.discard && !isConciliation && isIdOrderNullOrArray) {
              setShowConfirmModal({ open: false });
              return handleBack();
            }
            if (opts?.discard) handleClose(false);
            return setShowConfirmModal({ open: false });
          }}
          hideSubmit={values?.isVobiPay}
        />
      )}
      {openSendNfseDrawer && (
        <SendNfseDrawer
          idPayment={values?.id}
          value={values?.total}
          date={values?.billingDate}
          open={openSendNfseDrawer}
          onClose={refresh => {
            setOpenSendNfseDrawer(false);
            if (refresh) handleLoad();
          }}
        />
      )}
    </>
  );
};

Payment.propTypes = {
  isView: PropTypes.bool,
  isPublic: PropTypes.bool,
  idRefurbish: PropTypes.number,
  onClose: PropTypes.func,
  idCompanyCustomer: PropTypes.number,
  id: PropTypes.string,
  idToDuplicate: PropTypes.string,
  action: PropTypes.string,
  selectedItems: PropTypes.instanceOf(Array),
  isConciliation: PropTypes.bool,
  initialData: PropTypes.instanceOf(Object),
  idOrder: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  orderDate: PropTypes.string,
  feePercentage: PropTypes.number,
  orderType: PropTypes.string,
  withoutImportXML: PropTypes.bool,
  callbackModal: PropTypes.instanceOf(Object),
  showProductsConciliation: PropTypes.bool,
  paymentToCreate: PropTypes.instanceOf(Object)
};

export default Payment;
