import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import CenteredLoader from '../../components/Loader/CenteredLoader';
import useCRUD from '../../_Hooks/useCRUD';
import InvoiceContent from '../../components/Content/InvoiceContent';
import InvoiceNfseContent from '../../components/Content/InvoiceNfseContent';

const InvoicePage = ({ id, initialData }) => {
  const { typeNf } = useSelector(state => state.setup.enums);

  const [isNfse, setIsNfse] = useState(initialData?.id && initialData?.type === typeNf.nfse);

  const { data, handleGet, loading, setData } = useCRUD({
    model: 'paymentInvoice',
    immediatelyLoadData: false,
    options: {
      include: [{ model: 'city', required: false, as: 'serviceCity' }, 'file', 'fileXml']
    },
    pathOptions: `/${id}`
  });

  useEffect(() => {
    if (!data) return;
    setIsNfse(data?.id && data?.type === typeNf.nfse);
  }, [data]);

  useEffect(() => {
    if (id) handleGet();
    else setData(initialData);
  }, [id, initialData]);

  return loading ? (
    <CenteredLoader />
  ) : (
    <>{isNfse ? <InvoiceNfseContent data={data} /> : <InvoiceContent data={data} />}</>
  );
};

InvoicePage.propTypes = {
  id: PropTypes.number,
  initialData: PropTypes.instanceOf(Object)
};
export default InvoicePage;
