import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { Tooltip } from 'antd';
import Button from '../Button/Button';
import { useContextHook as useRefurbish } from '../../contexts/Context';
import { saveRefurbish } from '../../store/refurbish/actions/action';
import { updateUser } from '../../store/auth/actions/action';
import useViewport from '../../_Hooks/useViewport';
import { hasPermission } from '../../routes/Common/PrivateRoute';
import useCRUD from '../../_Hooks/useCRUD';
import Modal from '../Modal/Modal';
import CenteredLoader from '../Loader/CenteredLoader';
import AnalyticsServiceFactory from '../../lib/analytics/analytics';

const CustomerViewAction = ({
  onClick,
  showIcon = true,
  isProject,
  padding,
  idRefurbish,
  showText = false,
  ...params
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isMobile } = useViewport(window.innerWidth);
  const { user } = useSelector(state => state.authReducer);
  const { userType: userTypeEnum } = useSelector(state => state.setup.enums) || {};
  const { data: refurbish } = useRefurbish();
  const { plans, permissions } = useSelector(state => state.setup);
  const analytics = AnalyticsServiceFactory();

  const { handleGet: setIsCustomerView, loading } = useCRUD({
    model: 'auth/customer-view',
    baseUrl: process.env.REACT_APP_AUTH_API_URL || process.env.REACT_APP_API_URL,
    immediatelyLoadData: false
  });

  const permissionToView = hasPermission(
    user,
    [isProject ? 'customerView' : 'opportunityCustomerView'],
    plans,
    permissions
  );
  const handleClick = () => {
    setIsCustomerView({
      refetchOptions: {
        isCustomerView: true
      }
    }).then(response => {
      if (!response?.jwt) return;

      analytics.track({
        event: 'customer-view-mode-started',
        eventProperties: { idCompany: user?.idCompany, idRefurbish, idUser: user?.id }
      });
      dispatch(saveRefurbish(refurbish));
      dispatch(
        updateUser({
          ...user,
          userType: userTypeEnum.customer.value,
          isCustomerView: true,
          type: 'cliente',
          isOpportunityView: !isProject,
          jwt: response?.jwt
        })
      );
      history.push('/cliente/dashboard');
    });
  };

  return permissionToView ? (
    <>
      <Tooltip placement="bottom" title="Veja o projeto como seu cliente vê e tenha a mesma experiência dele.">
        <span style={{ padding }}>
          <Button
            id="view-as-client"
            type="primary"
            onClick={onClick || handleClick}
            icon={
              showIcon ? <FontAwesomeIcon size={showText ? 'sm' : 'lg'} icon={faEye} color={params?.iconColor} /> : null
            }
            text
            {...params}
          >
            {(!isMobile() || showText) && 'Ver como cliente'}
          </Button>
        </span>
      </Tooltip>
      <Modal
        open={loading}
        closable={false}
        hideCloseBtn
        hideFooter
        title="Estamos carregando a visão de cliente"
        width="500px"
        height="240px"
      >
        <CenteredLoader text="Aguarde..." />
      </Modal>
    </>
  ) : null;
};

CustomerViewAction.propTypes = {
  onClick: PropTypes.func,
  showIcon: PropTypes.bool,
  isProject: PropTypes.bool,
  padding: PropTypes.string,
  idRefurbish: PropTypes.number,
  showText: PropTypes.bool
};

export default CustomerViewAction;
