import styled from 'styled-components';
import { breakpoints, colors, fonts, spaces } from '../../styles/style';

import background from '../Images/login-background.svg';

const LoginContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const BackgroundContainer = styled.div`
  position: relative;
  width: 50%;
  min-height: 100%;
  height: auto;
  background: url(${background}) no-repeat;
  background-size: cover;
`;

const ForegroundImage = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 80%;
  max-width: 1000px;
  min-width: 500px;
`;

const SideMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  z-index: 2;

  @media (min-width: ${breakpoints.mobile}) {
    width: ${props => (props.$fullWidth ? '100%' : '50%')};
    justify-content: space-between;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spaces.space2} ${spaces.space3};
  border-bottom: 1px solid ${colors.neutral100};
`;

const HeaderContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${spaces.space2};

  svg {
    margin-left: -${spaces.space2};
  }

  @media (min-width: ${breakpoints.desktop}) {
    width: 80%;
    max-width: ${props => (props.$fullWidth ? '100%' : '470px')};
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${spaces.space3};
  margin-top: auto;
  font-size: ${fonts.sizeXs};

  .ant-typography {
    padding: 0 ${spaces.space1};

    a {
      text-decoration: none;
      color: ${colors.primary300};
      font-weight: ${fonts.weight600};
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    width: 80%;
    max-width: 470px;
    align-self: center;
    padding: ${spaces.space3} 0;
  }
`;

export { LoginContentContainer, BackgroundContainer, ForegroundImage, SideMenu, Header, HeaderContent, Footer };
