import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/pro-regular-svg-icons';
import { units } from '../../lib/mapping/Form/extraFormSchema';
import { compositionInlineFieldsMapping, itemSchemaV2 } from '../../lib/mapping/Form/itemSchemaV2';
import { bdiValuesColumns, columns } from '../../lib/mapping/TableOrList/compositionItemsColumns';
import { hasPermission } from '../../routes/Common/PrivateRoute';
import { Div, fonts } from '../../styles/style';
import useCRUD from '../../_Hooks/useCRUD';
import useViewport from '../../_Hooks/useViewport';
import ListDrawer from '../Drawer/ListDrawer';
import EditItemModal from '../Modal/EditItemModal';
import { allDropdown } from '../../lib/mapping/Dropdown/allDropdown';
import TableOrListV2 from '../List/TableOrListV2';
import AddTableButtonV2 from '../Button/AddTableButtonV2';
import Button from '../Button/Button';
import Form from '../Form/Form';
import { Table } from '../Table/VirtualizedTable.styled';

const useCompositionForm = ({ data, handleCreate, setListItems, list, itemType, setItemType }) => {
  const handleFormSubmit = values => {
    const checkItemExists = (items, idToCheck) => items.some(item => item.idItem === idToCheck);

    const itemExists = checkItemExists(list, values.idItem);
    if (itemExists && values.idItem) {
      toast.error('Este item já está presente na composição');
      return;
    }

    if (!data?.id) {
      const price = Number(values.price) || 0;
      const bdi = Number(values.bdi) || 0;
      const quantity = Number(values.quantity) || 1;
      const totalBdi = (price * (1 + bdi / 100) * quantity).toFixed(2);
      const total = price * quantity;

      setListItems(prev => {
        const tempId = `temp-${prev.length}`;
        return [
          ...prev,
          {
            ...values,
            tempId,
            type: itemType,
            price,
            bdi,
            quantity,
            totalBdi: Number(totalBdi),
            total: Number(total)
          }
        ];
      });
    } else {
      handleCreate({
        type: itemType,
        ...values,
        id: values?.idItem,
        internalId: values?.idItem,
        idUnit: values?.idUnit || 1
      });
    }
    setItemType(null);
  };

  return { handleFormSubmit };
};

const CompositionItems = ({
  isCustomer,
  idReference,
  model,
  handleLoadItems,
  list,
  setListItems,
  handleCreate,
  setOpenCatalog,
  setExtraDrawerStyle = f => f,
  renderColumns,
  readOnly,
  isTemplate,
  idCompany,
  parentData,
  externalTriggerProps,
  data,
  hideProductService,
  isLibrary,
  isSimple
}) => {
  const { refurbishItemType, specificationDefaultColumnsToShow } = useSelector(state => state.setup.enums);
  const { user } = useSelector(state => state.authReducer);
  const { plans, permissions } = useSelector(state => state.setup);
  const expandedRows = useRef([]);
  const { isMobile } = useViewport(window.innerWidth);
  const { viewport: heightViewport } = useViewport(window.innerHeight);
  const [showUnitItem, setShowUnitItem] = useState(false);
  const [forceCellUpdate, setForceCellUpdate] = useState('');
  const [editItem, setEditItem] = useState();
  const _hasPermissionUnitEdit = hasPermission(user, ['units'], plans, permissions);
  const [loading, setLoading] = useState(false);
  const { setEditOrCreateComposition, setPreventSave } = externalTriggerProps || {};
  const isLinked = data?.idItem || data?.externalBaseCode;
  const [itemType, setItemType] = useState(null);

  const _isMobile = isMobile();

  const [initForBugReload, setInitForBugReload] = useState(null);

  useLayoutEffect(() => {
    if (!list?.length) return;
    if (initForBugReload === null || initForBugReload) setInitForBugReload(p => !p);
  }, [initForBugReload, list]);

  const { list: unitList, handleGet: getUnit } = useCRUD({
    model: 'unit',
    options: { where: { idCompany, isActive: true }, order: [['idCompany'], ['name', 'desc']] },
    immediatelyLoadData: !isCustomer
  });

  const { handleDelete, handleUpdate } = useCRUD({
    model,
    immediatelyLoadData: false
  });

  const { handleDelete: deleteRelation, handleUpdate: updateRelation } = useCRUD({
    model: 'relation-item',
    immediatelyLoadData: false
  });

  const refetchLists = column => () => {
    const loadList = {
      unit: getUnit
    };
    loadList[column]();
  };

  const handleItemClick = clickData => {
    const { modal, id, values } = clickData;

    if (modal === 'catalogDrawer') {
      setOpenCatalog(true);
      setExtraDrawerStyle(156);
    }
    if (modal === 'createComposition') {
      setEditOrCreateComposition(true);
    }
    if (id === 'Create-new-product' || id === 'Create-new-service') {
      setItemType(values.type);
    }
  };

  const AddButton = useCallback(
    params => (
      <AddTableButtonV2
        {...params}
        buttonId="add-composition-item"
        model={allDropdown.addCompositionItems(_isMobile)}
        type="dropdown"
        onSubmit={handleItemClick}
        customButton={
          <Button id="add-composition-item" text style={{ alignSelf: 'start' }}>
            <FontAwesomeIcon icon={faAdd} />
            Adicionar item
          </Button>
        }
      />
    ),
    []
  );

  const handleChange = (row, field) => value => {
    setPreventSave(true);
    const notRelationItem = row?.idRefurbish || row?.idTemplate || field !== 'quantity';

    if (!data?.id) {
      const itemId = row?.tempId || row?.idItem || row?.id;

      setListItems(prev =>
        prev.map(item => {
          const currentId = item?.tempId || item?.idItem || item?.id;
          if (currentId === itemId) {
            const newItem = {
              ...item,
              [field]: value,
              isRelationItem: !notRelationItem
            };

            if (field === 'price' || field === 'bdi' || field === 'quantity') {
              const price = field === 'price' ? value || 0 : item?.price || 0;
              const bdi = field === 'bdi' ? value || 0 : item?.bdi || 0;
              const quantity = field === 'quantity' ? value || 1 : item?.quantity || 1;
              newItem.totalBdi = (parseFloat(price) * (1 + parseFloat(bdi) / 100) * parseFloat(quantity)).toFixed(2);
              newItem.total = price * quantity;
            }
            return newItem;
          }
          return item;
        })
      );
      handleLoadItems(false);
      setPreventSave(false);
      return;
    }

    const update = notRelationItem ? handleUpdate : updateRelation;
    update({
      id: notRelationItem ? row?.id : row?.relationItem?.id,
      values: { [field]: value },
      updateOptions: {},
      refresh: false
    }).then(() => {
      setPreventSave(false);
      handleLoadItems(false);
    });
  };

  const handleEditItem = itemToEdit => {
    if (data?.id && !isSimple && !_isMobile) {
      if (itemToEdit?.type === refurbishItemType.composition) {
        setEditOrCreateComposition(itemToEdit);
        return;
      }
      if (itemToEdit?.id > 0 && !isMobile()) {
        setEditItem(itemToEdit);
      }
    }
  };

  const _handleDelete = row => {
    if (!data?.id) {
      const itemId = row?.tempId || row?.idItem || row?.id;
      setListItems(prev =>
        prev.filter(item => {
          const currentId = item?.tempId || item?.idItem || item?.id;
          return currentId !== itemId;
        })
      );
      return;
    }

    const { id, relationItem, idParent, idRefurbish, idTemplate } = row;
    const notRelationItem = idRefurbish || idTemplate;
    const deleteFn = notRelationItem ? handleDelete : deleteRelation;
    if (!id) return;
    setForceCellUpdate('actions');
    setLoading(prev => !prev);
    deleteFn({ id, values: { idParent: relationItem?.idParent || idParent }, refresh: false }).then(resp => {
      setLoading(prev => !prev);
      if (!resp?.error) {
        toast.success('Item removido da composição com sucesso.');
        handleLoadItems(false);
      }
    });
  };

  useEffect(() => {
    setForceCellUpdate('unit');
  }, [unitList]);

  const _columns = columns({
    expandedRows,
    refurbishItemType,
    isCustomer,
    isLibrary,
    handleChange,
    onRemoveClick: _handleDelete,
    isMobile: isMobile(),
    refetchLists,
    unitList,
    hasPermission: _hasPermissionUnitEdit,
    setShowUnitItem,
    forceCellUpdate,
    loading,
    idReference,
    columnsToShow: renderColumns,
    readOnly,
    parentData,
    isLinked,
    isSimple,
    isEdit: !!data?.id
  });

  const _bdiValuesColumns = bdiValuesColumns({
    isMobile: isMobile(),
    hideProductService,
    isSimple
  });

  const { handleFormSubmit } = useCompositionForm({
    data,
    handleCreate,
    setListItems,
    list,
    itemType,
    setItemType
  });

  const getScrollSize = () => {
    let y;

    if (isSimple || _isMobile) {
      y = '100%';
    } else if (heightViewport.width < 800) {
      y = '196px';
    } else {
      y = `calc(100vh - ${data?.id ? 728 : 738}px)`;
    }

    return {
      x: '100%',
      y
    };
  };

  const renderBDIValuesTable = () => {
    if (_isMobile) return null;

    let dataSource = [];
    if (isCustomer && renderColumns) {
      const renderCost = renderColumns?.totalProductRaw || renderColumns?.totalLaborRaw || renderColumns?.total;
      const renderBdi = renderColumns?.bdi;
      const renderTotals = renderColumns?.totalProduct || renderColumns?.totalLabor || renderColumns?.totalBdi;

      if (renderCost) {
        dataSource.push({
          rowName: 'Custo',
          totalProduct: renderColumns?.totalProductRaw ? data?.totalProductRaw : null,
          totalLabor: renderColumns?.totalLaborRaw ? data?.totalLaborRaw : null,
          totalBdi: renderColumns?.total ? data?.total : null
        });
      }
      if (renderBdi) {
        dataSource.push({
          rowName: 'BDI',
          isBdi: true,
          totalProduct:
            renderColumns?.totalProduct && renderColumns?.totalProductRaw
              ? (data?.totalProduct / data?.totalProductRaw - 1) * 100 || 0
              : null,
          totalLabor:
            renderColumns?.totalLabor && renderColumns?.totalLaborRaw
              ? (data?.totalLabor / data?.totalLaborRaw - 1) * 100 || 0
              : null,
          totalBdi: renderColumns?.bdi ? data?.calculatedBdi || data?.bdi : null
        });
      }
      if (renderTotals) {
        dataSource.push({
          rowName: 'Total',
          totalProduct: renderColumns?.totalProduct ? data?.totalProduct : null,
          totalLabor: renderColumns?.totalLabor ? data?.totalLabor : null,
          totalBdi: renderColumns?.totalBdi ? data?.totalBdi : null
        });
      }
    } else {
      dataSource = [
        ...(!isLibrary
          ? [
              {
                rowName: 'Custo',
                totalProduct: data?.totalProductRaw,
                totalLabor: data?.totalLaborRaw,
                totalBdi: data?.total
              },
              {
                rowName: 'BDI',
                isBdi: true,
                totalProduct: (data?.totalProduct / data?.totalProductRaw - 1) * 100 || 0,
                totalLabor: (data?.totalLabor / data?.totalLaborRaw - 1) * 100 || 0,
                totalBdi: data?.calculatedBdi || data?.bdi
              },
              {
                rowName: 'Total',
                totalProduct: data?.totalProduct,
                totalLabor: data?.totalLabor,
                totalBdi: data?.totalBdi
              }
            ]
          : [
              {
                rowName: 'Total',
                totalProduct: data?.totalProductRaw,
                totalLabor: data?.totalLaborRaw,
                totalBdi: data?.price
              }
            ])
      ];
    }

    return (
      (!isCustomer || dataSource.length > 0) && (
        <Table
          style={{ marginLeft: 'auto' }}
          dataSource={dataSource}
          columns={_bdiValuesColumns}
          pagination={false}
          border
          $customHover="inherit"
          $withCommonRow
          borderRow
          rowSelection={false}
        />
      )
    );
  };

  return (
    <>
      <Div direction={_isMobile ? 'column' : 'row'} $fullWidth>
        {!initForBugReload ? (
          <TableOrListV2
            id="compositionTable"
            list={list}
            columns={_columns}
            scroll={getScrollSize()}
            hideSelectAll
            rowSelection={false}
            refreshColumns
            withCommonRow
            $customHover="inherit"
            $footerWeight={fonts.weight400}
            $paddingBottom={!isSimple ? '100px' : null}
            footer={
              !isCustomer && !readOnly && !isLinked && itemType !== null
                ? () => (
                    <Form
                      schema={itemSchemaV2({ isMobile: _isMobile })}
                      mapping={compositionInlineFieldsMapping({
                        refurbishItemTypeEnum: refurbishItemType,
                        isTemplate,
                        idReference,
                        type: itemType,
                        handleCancel: () => setItemType(null),
                        handleConfirm: handleFormSubmit
                      })}
                      onSubmit={() => handleCreate}
                      displayButtons={false}
                    />
                  )
                : undefined
            }
            onClick={handleEditItem}
            noContentMobile={!list?.length ? 'Nenhum item encontrado' : null}
          />
        ) : null}
        {showUnitItem && (
          <ListDrawer
            onClose={() => {
              setShowUnitItem(false);
              refetchLists('unit')();
            }}
            model="unit"
            where={{ isActive: true }}
            tab="units"
            title="Configuração das unidades"
            subtitle="Unidades"
            renderTabs={units}
            // eslint-disable-next-line max-len
            textToDelete="Ao excluir esse item, ele será removido permanentemente da sua lista e de todos os produtos e serviços em que estiver sendo utilizado."
          />
        )}
        {editItem?.id ? (
          <EditItemModal
            {...editItem}
            idReference={idReference}
            model={idReference ? model : 'item'}
            inCludeRefurbish={false}
            onClose={item => {
              handleLoadItems(item || false);
              setEditItem(null);
            }}
            renderColumns={renderColumns || specificationDefaultColumnsToShow}
            isLibrary={model === 'item'}
            isTemplate={isTemplate}
            readOnly={readOnly}
            disableInsideCompositionActions={isLinked || isLibrary}
          />
        ) : null}
      </Div>
      {renderColumns?.price || !isCustomer ? (
        <Div justify="space-between" align="top">
          {!isSimple && !isLinked ? <AddButton /> : null}
          {data?.id && renderBDIValuesTable()}
        </Div>
      ) : null}
    </>
  );
};

CompositionItems.propTypes = {
  isCustomer: PropTypes.bool,
  idReference: PropTypes.number,
  handleLoadItems: PropTypes.func,
  model: PropTypes.string,
  list: PropTypes.arrayOf(Array),
  handleCreate: PropTypes.func,
  setOpenCatalog: PropTypes.func,
  handleDelete: PropTypes.func,
  handleUpdate: PropTypes.func,
  setExtraDrawerStyle: PropTypes.func,
  renderColumns: PropTypes.instanceOf(Object),
  readOnly: PropTypes.bool,
  isTemplate: PropTypes.bool,
  idCompany: PropTypes.number,
  parentData: PropTypes.instanceOf(Object),
  externalTriggerProps: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Object),
  hideProductService: PropTypes.bool,
  isLibrary: PropTypes.bool,
  isSimple: PropTypes.bool,
  handleInputItemChange: PropTypes.func,
  setListItems: PropTypes.func,
  isEdit: PropTypes.bool
};

export default CompositionItems;
