import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Space, Switch, Tooltip } from 'antd';
import * as dayjs from 'dayjs';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { toast } from 'react-toastify';
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons';

import { faChartPie, faCircleInfo, faCopy, faEdit, faUserCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContextHook } from '../../contexts/GeneralContext';
import Input from '../Input/Input';
import Select, { SelectWithBadge } from '../Select/Select';
import DatePicker from '../Datepicker/Datepicker';
import Label from '../Label/Label';
import { Logo } from '../CoverImage/CoverImage.style';
import { Div, ShowHideElement, colors, fonts, spaces } from '../../styles/style';
import useCRUD from '../../_Hooks/useCRUD';
import { InstallmentStatus, DataContainer } from './PaymentBasicForm.styled';
import formatNumber from '../../lib/helpers/formatNumber';
import formatCurrency from '../../lib/helpers/formatCurrency';
import TooltipIcon from '../Tooltip/TooltipIcon';
import ListDrawer from '../Drawer/ListDrawer';
import { bankAccount, paymentCostCenter } from '../../lib/mapping/Form/extraFormSchema';
import { hasPermission } from '../../routes/Common/PrivateRoute';
import AddSelectButton from '../Button/AddSelectButton';
import MaskedInput from '../Input/NewMaskedInput';
import EditOrCreateCustomerOrSupplier from '../Drawer/EditOrCreateCustomerOrSupplier';
import SimpleAccordion from '../Accordion/SimpleAccordion';
import useViewport from '../../_Hooks/useViewport';
import Segment from '../Select/Segmented';
import {
  getViewDate,
  formatDocument,
  formatPhoneNumber,
  addressConcat,
  removeHtmlTags,
  buildRecursiveInclude
} from '../../lib/helpers/helper';
import { BadgeText, Error, Paragraph, Subtitle } from '../Text/Text';
import { columns } from '../../lib/mapping/TableOrList/lastViewColumns';
import ListModal from '../Modal/ListModal';
import FinanceEdit from '../Drawer/FinanceEdit';
import { Line } from '../Line/Line';
import { Content } from '../../_Pages/Payments/Payment.style';
import TreeSelectWrapper from '../Table/TreeSelectWrapper';
import Drawer from '../Drawer/Drawer';
import Button from '../Button/Button';
import FinancialCategoryList from '../List/FinancialCategoryList';
import EditOrCreateCategoryDrawer from '../Drawer/EditOrCreateCategoryDrawer';
import ApportionmentDrawer from '../Drawer/ApportionmentDrawer';
import ConfirmModal from '../Modal/ConfirmModal';
import ApportionmentTooltip from '../Tooltip/ApportionmentTooltip';

const selectOpts = { onlyMine: true, where: { isActive: true }, order: ['name'] };
const ITEMS_PAGE = 6;

const PaymentBasicForm = () => {
  const { refurbishItemType, paymentPermissions, paymentTypeValues, refurbishStatus } = useSelector(
    state => state.setup.enums
  );
  const { projectOwnBusiness, financialOwnBusiness } = paymentPermissions || {};
  const [showCustomerDrawer, setCustomerDrawer] = useState(false);
  const [showSupplierDrawer, setSupplierDrawer] = useState(false);

  const { handleGet: getCustomer } = useCRUD({
    model: 'companyCustomer',
    immediatelyLoadData: false
  });

  const { handleGet: getSupplier } = useCRUD({
    model: 'supplier',
    options: { onlyMine: true, where: { isActive: true } },
    immediatelyLoadData: false
  });

  const { handleGet: getRefurbish } = useCRUD({
    model: 'refurbish',
    immediatelyLoadData: false
  });

  const {
    setField,
    isView,
    touched,
    errors,
    handleChange,
    handleBlur,
    values,
    statusColor,
    isCharge,
    _refurbish,
    setRefurbish,
    handleCancel,
    isPublic,
    isCustomerView,
    disableRefurbish,
    changeRefurbish,
    isBulk,
    isCustomer,
    isConciliation,
    idOrder,
    isIdOrderNullOrArray,
    feePercentage,
    setIsPrinting,
    apportionmentDrawer,
    setApportionmentDrawer,
    paymentConciliated
  } = useContextHook();
  const {
    id,
    paymentStatus,
    name,
    idFinancialCategory,
    idCompanyCustomer,
    idSupplier,
    idRefurbish,
    company,
    companyCustomer,
    billingDate,
    value,
    billType,
    idPaymentCostCenter,
    idPaymentBankAccount,
    ownBusiness,
    supplier,
    paymentTypes,
    recurrenceId,
    installments,
    isVobiPay,
    customerDoc,
    customerEmail,
    customerPhone,
    viewedBy,
    paymentComplete,
    total,
    paymentItems,
    idRefurbishItemLevel,
    paymentOrderType,
    newSupplier,
    apportionmentList,
    isApportionment,
    completeApportionmentList,
    paymentRecurrence,
    allSplitDueDateDiscount,
    allSplitInterest
  } = values || {};
  const billTypeIncome = billType === 'income';
  const [_companyCustomer, setCompanyCustomer] = useState(companyCustomer);
  const [_supplier, setSupplier] = useState(supplier);
  const [showFinancials, setShowFinancials] = useState(false);
  const { plans, permissions } = useSelector(state => state.setup) || {};
  const { user } = useSelector(state => state.authReducer) || {};
  const { financialCategoriesName } = useSelector(state => state.setup.enums) || {};
  const [showBankAccount, setShowBankAccount] = useState(false);
  const [showPaymentCostCenter, setShowPaymentCostCenter] = useState(false);
  const [showViewedByModal, setShowViewedByModal] = useState(false);
  const [createFinancialCategory, setCreateFinancialCategory] = useState(false);
  const [offset, setOffset] = useState(1);
  const [viewedByFiltered, setViewedByFiltered] = useState(viewedBy?.slice(0, ITEMS_PAGE));
  const [createBankAccount, setCreateBankAccount] = useState(false);
  const [switchApportionmentModal, setSwitchApportionmentModal] = useState(false);
  const [hasRefurbishItems, setHasRefurbishItems] = useState(false);
  const showEditCustomer = _companyCustomer && billTypeIncome && !isPublic && !isCustomerView && id;
  const hasAdministrativePermission = hasPermission(user, ['financialOwnBusiness', 'projectOwnBusiness'], plans);
  const hasSupplierPermission = hasPermission(user, ['financialSupplier', 'projectSupplier'], plans);
  const hasPaymentToMyCompany = hasPermission(user, [projectOwnBusiness, financialOwnBusiness], plans, permissions);
  const hasPaymentToSupplier = hasPermission(user, ['projectSupplier'], plans, permissions);
  const _customerPhone = _companyCustomer?.phone || customerPhone;
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();
  const lastView = getViewDate({ viewedBy });
  const income = !isCharge && billType === 'income';
  const showPaymentLevel = (!paymentComplete || isCharge) && !income && ownBusiness && idRefurbish;
  const concatenatedSupplier = addressConcat(_supplier);
  const concatenatedCompanyCustomer = addressConcat(_companyCustomer);
  const concatenatedCompany = addressConcat(company);
  const isRecurrence = recurrenceId || paymentRecurrence;

  const optionsCategory = {
    ...selectOpts,
    where: { ...selectOpts.where, billType, idParent: null },
    include: buildRecursiveInclude({ model: 'financialCategory', idCompany: user?.idCompany })
  };

  const { list: categoryList, handleGet: getCategories } = useCRUD({
    model: 'financialCategory',
    options: optionsCategory,
    immediatelyLoadData: false
  });

  const flattenCategories = categories =>
    categories?.flatMap(category => [category, ...(category?.children ? flattenCategories(category?.children) : [])]);

  useState(() => {
    if (!isPublic && !isCustomerView)
      getCategories().then(categories => {
        if (!isCharge || id || idFinancialCategory) return;
        const flattenedList = flattenCategories(categories);

        setField('idFinancialCategory')(
          flattenedList?.find(
            category => !category?.children?.length && category.name === financialCategoriesName?.serviceSelling
          )?.id,
          true
        );
      });
  }, []);

  let disabledText = 'Esta funcionalidade não está disponível no seu plano atual';
  if (!hasAdministrativePermission || !hasSupplierPermission)
    disabledText = 'Você não tem permissão para criar esse tipo de pagamento';
  else if (Array.isArray(idOrder) && feePercentage)
    disabledText = 'Pagamentos de taxa de administração não podem ativar a opção fornecedor';
  else if (Array.isArray(idOrder)) disabledText = 'Pagamentos de reembolso não podem ativar a opção fornecedor';
  else if (idOrder)
    disabledText =
      'Pagamentos criados a partir da aprovação de ordem de compra não podem ser alterados para meu negócio';

  const handleChangeRefurbish = val => {
    if (setRefurbish) setRefurbish(null);
    setField('idRefurbish')(val);
    setField('refurbish')(null);
  };

  useEffect(() => {
    setHasRefurbishItems(paymentItems?.length > 0 && idRefurbish);
  }, [paymentItems]);

  const handleChangeCompanyCustomer = val => {
    setCompanyCustomer(null);
    setField('idCompanyCustomer')(val);
    setField('companyCustomer')(null);
  };

  const handleChangeSupplier = val => {
    setSupplier(null);
    setField('idSupplier')(val);
    setField('supplier')(null);
  };

  useEffect(() => {
    if (idRefurbish && !isView) {
      getRefurbish({ refetchPathOptions: `/${idRefurbish}` }).then(res => {
        if (!res) {
          toast.error(`Projeto/Oportunidade vinculado a esse pagamento não foi encontrado.`);
          handleCancel && handleCancel();
          return;
        }
        if (setRefurbish) setRefurbish(res);

        if (res.idCompanyCustomer && !idCompanyCustomer) setField('idCompanyCustomer')(res.idCompanyCustomer);
      });
    }
  }, [idRefurbish]);

  useEffect(() => {
    if (idCompanyCustomer && !isPublic && !isCustomer) {
      getCustomer({ refetchPathOptions: `/${idCompanyCustomer}` }).then(res => {
        if (setCompanyCustomer) setCompanyCustomer(res);
      });
    }
  }, [idCompanyCustomer]);

  useEffect(() => {
    if (idSupplier && !isPublic && !isCustomer) {
      getSupplier({ refetchPathOptions: `/${idSupplier}` }).then(res => {
        if (setSupplier) setSupplier(res);
      });
    }
  }, [idSupplier]);

  useEffect(() => {
    if (ownBusiness === undefined && !id && !isBulk && !paymentOrderType) {
      setField('ownBusiness')(!!hasPaymentToMyCompany);
    }
  }, [hasPaymentToMyCompany]);

  useEffect(() => {
    if (!ownBusiness) {
      setField('idPaymentBankAccount')(null);
      setField('idPaymentCostCenter')(null);
    }
  }, [ownBusiness]);

  const changeApportionment = (list, cancel) => {
    const _list = list.reduce((acc, item) => {
      if (item?.refurbish?.id) acc[item.refurbish.id] = item;
      return acc;
    }, {});
    setField('paymentRecurrence')(false);
    setField('isRecurrence')(false);
    setField('idRefurbishItemLevel')(null);
    setField('isApportionment')(!cancel);
    cancel && setField('idRefurbish')(list?.[0]?.refurbish?.id || idRefurbish || null);
    setField('apportionmentList')(cancel ? null : _list);
    setApportionmentDrawer({ open: false });
  };
  const handleSwitchPaymentItems = (val, confirm) => {
    if ((isApportionment || completeApportionmentList) && !confirm) {
      setSwitchApportionmentModal({ open: true, val });
      return;
    }
    setField('paymentRecurrence')(false);
    if (!val) {
      setField('value')(total);
    } else {
      setField('apportionmentList')([]);
    }
    setField('paymentComplete')(val);
    isApportionment && setField('paymentItems')([]);
    setField('isApportionment')(false);
    setField('completeApportionmentList')(null);
    setSwitchApportionmentModal({ open: false });
  };

  useEffect(() => {
    if (!isApportionment || !apportionmentList) return;
    const updatedSplits = {};

    Object.keys(apportionmentList)?.forEach(key => {
      const percentage = parseFloat(apportionmentList[key].percentageSplitValue) / 100;
      const newValue = (value * percentage).toFixed(2);
      updatedSplits[key] = {
        ...apportionmentList[key],
        value: newValue
      };
    });

    setField('apportionmentList')(updatedSplits, true);
  }, [value]);

  const RenderChargeNameInput = () => {
    return (
      <Col md={8} xs={24} key="chargeNameInput">
        <Label htmlFor="paymentName">Nome do pagamento *</Label>
        {isCharge && (
          <TooltipIcon
            style={{ color: colors.primary600, marginLeft: '8px' }}
            icon={faInfoCircle}
            color={colors.primary600}
            text="O nome será exibido para o cliente e te ajuda a identificar o pagamento."
            trigger={['hover', 'click']}
          />
        )}
        <Input
          type="text"
          id="paymentName"
          size="middle"
          name="paymentName"
          placeholder="Ex: Projeto estrutural"
          value={name}
          onChange={handleChange('name')}
          onBlur={handleBlur('name')}
        />
        {touched.name && errors?.name && <Error>{errors?.name}</Error>}
      </Col>
    );
  };

  const RenderFinancialCategoryInput = () => {
    return (
      <Col md={8} xs={24} key="financialCategory">
        <Label>Categoria financeira</Label>
        {!showFinancials && !createFinancialCategory && (
          <TreeSelectWrapper
            id="financialCategory"
            value={idFinancialCategory}
            placeholder="Selecione a categoria"
            onChange={setField('idFinancialCategory')}
            levelList={categoryList}
            isParentMap
            withoutEditableDiv
            allowClear
            openCustomCreate={setCreateFinancialCategory}
            dropdownRender={menu => (
              <>
                {menu}
                <AddSelectButton onClick={() => setShowFinancials(true)} />
              </>
            )}
          />
        )}
        {touched.idFinancialCategory && errors?.idFinancialCategory && <Error>{errors?.idFinancialCategory}</Error>}
      </Col>
    );
  };

  const renderDateFinancialInput = useMemo(() => {
    return (
      <Col md={8} xs={24} key="dateFinancialInput">
        <Label>Data de competência *</Label>
        <TooltipIcon
          style={{ color: colors.primary600, marginLeft: '8px' }}
          icon={faInfoCircle}
          color={colors.primary600}
          text="Referente a data em que o lançamento foi gerado, por exemplo a data de fechamento de um contrato
          ou data em que a venda foi realizada, independente da data de pagamento das parcelas."
          size="lg"
          trigger={['hover', 'click']}
        />
        <DatePicker
          disabled={paymentStatus?.id === 3}
          id="billingDate"
          name="billingDate"
          style={{ width: '100%' }}
          format="DD/MM/YYYY"
          value={billingDate ? dayjs(billingDate) : dayjs()}
          allowClear={false}
          onChange={date => {
            setField('billingDate')(date?.format('YYYY-MM-DD'));
            !id && isVobiPay && setField('dueDate')(date?.format('YYYY-MM-DD'));
          }}
          onBlur={handleBlur('billingDate')}
        />
        {touched.billingDate && errors?.billingDate && <Error>{errors?.billingDate}</Error>}
      </Col>
    );
  }, [billingDate]);

  const openApportionment = () => {
    if (value > 0.01) {
      setApportionmentDrawer({ open: true, type: 'simple' });
      return;
    }
    toast.error('O valor deve ser maior que zero');
  };

  const renderProjectOpportunityInput =
    isApportionment && billType === 'expense' ? (
      <Col md={8} xs={24} key="projectOpportunityInput">
        <Label>Projeto/Oportunidade</Label>

        <ShowHideElement idHover="apportionmentDiv" currentId="apportionmentDiv">
          <Button
            className="hide-element"
            style={{ borderColor: colors.neutral200 }}
            width="100%"
            onClick={() => setApportionmentDrawer({ open: true, type: paymentComplete ? 'complete' : 'simple' })}
          >
            <Div $fullWidth justify="space-between">
              <Tooltip
                color={colors.white}
                overlayInnerStyle={{ padding: spaces.space1, width: '336px' }}
                title={
                  apportionmentList && !completeApportionmentList ? (
                    <ApportionmentTooltip
                      list={Object.values(apportionmentList || {})}
                      extraValues={{ allSplitDueDateDiscount, allSplitInterest }}
                    />
                  ) : (
                    ''
                  )
                }
              >
                <BadgeText
                  count={`${Object.values(apportionmentList || {}).length ||
                    Object.values(completeApportionmentList || {}).length ||
                    1} projetos`}
                  background={colors.primary100}
                  $textColor={colors.primary500}
                />
              </Tooltip>
              <FontAwesomeIcon className="elementHover" icon={faEdit} color={colors.primary500} />
            </Div>
          </Button>
        </ShowHideElement>

        {touched.idRefurbish && errors?.idRefurbish && <Error>{errors?.idRefurbish}</Error>}
      </Col>
    ) : (
      <Col md={8} xs={24} key="projectOpportunityInput">
        <Label>Projeto/Oportunidade{isCharge ? ' *' : ''}</Label>
        <Select
          name="paymentRefurbish"
          id="paymentRefurbish"
          model="refurbish"
          modelOptions={{
            ...selectOpts,
            ...(!isCharge && { where: { ...selectOpts?.where, idStatus: refurbishStatus.project } }),
            order: [['name', 'asc']]
          }}
          placeholder="Selecione o projeto/oportunidade"
          value={idRefurbish}
          disabled={disableRefurbish || hasRefurbishItems}
          onChange={val => {
            handleChangeRefurbish(val);
            if (changeRefurbish) {
              changeRefurbish.current = val;
            }
            setField('idRefurbishItemLevel')(null);
          }}
          onBlur={handleBlur('idRefurbish')}
          allowClear
          showSearch
          disabledText={
            hasRefurbishItems &&
            `O lançamento possui itens do orçamento do projeto,
      para alterar o projeto você deve remover os itens.`
          }
          dropdownRender={menu => (
            <>
              {menu}
              {billType === 'expense' && !isRecurrence ? (
                <AddSelectButton
                  title="Habilitar rateio"
                  icon={faChartPie}
                  disabled={paymentComplete && !completeApportionmentList}
                  onClick={() => openApportionment()}
                />
              ) : null}
            </>
          )}
        />
        {touched.idRefurbish && errors?.idRefurbish && <Error>{errors?.idRefurbish}</Error>}
      </Col>
    );

  const RenderPaidDate = useMemo(() => {
    const installmentStatus = installments[0]?.paidDate
      ? dayjs(installments[0]?.paidDate).format('DD/MM/YYYY')
      : installments[0]?.installmentStatus?.name;
    return (
      <Col md={8} xs={24}>
        <Label>Data do pagamento</Label>
        <InstallmentStatus>{installmentStatus}</InstallmentStatus>
      </Col>
    );
  }, [isCharge, idRefurbish, _refurbish, touched]);

  const _columns = columns({ isMobile: _isMobile });

  const handlePagination = _offset => {
    setOffset(_offset);
    const start = (_offset - 1) * ITEMS_PAGE;
    const end = start + ITEMS_PAGE;
    const _viewedByFiltered = viewedBy?.slice(start, end);
    setViewedByFiltered(_viewedByFiltered);
  };

  const handleCloseModal = () => {
    setShowViewedByModal(false);
    handlePagination(1);
  };

  const RenderClientInput = () => {
    return (
      <Col md={8} xs={24} key="clientInput">
        <Label style={{ transition: 'visibility 0.3s' }}>Cliente{isCharge ? '*' : ''}</Label>
        {showEditCustomer && (
          <TooltipIcon
            style={{ color: colors.primary600, marginLeft: spaces.space1 }}
            text="Editar informações do cliente"
            icon={faPenToSquare}
            onClick={() => setCustomerDrawer(true)}
          />
        )}
        {!showCustomerDrawer && (
          <Select
            name="paymentcompanyCustomer"
            id="paymentcompanyCustomer"
            model="companyCustomer"
            modelOptions={selectOpts}
            placeholder="Selecione o cliente"
            value={idCompanyCustomer}
            onChange={handleChangeCompanyCustomer}
            onBlur={handleBlur('idCompanyCustomer')}
            allowClear
            showSearch
            allowCreate
            allowSearchByExtraPropsOnOption
            extraPropsOnOptions={['doc']}
            customLabel={option => {
              return option.doc ? `${option.name} - ${formatDocument(option.doc)}` : option.name;
            }}
          />
        )}
        {touched.idCompanyCustomer && errors?.idCompanyCustomer && <Error>{errors?.idCompanyCustomer}</Error>}
      </Col>
    );
  };

  const RenderSupplierInput = useMemo(() => {
    return (
      <Col md={8} xs={24} key="suppliers">
        <Label style={{ transition: 'visibility 0.3s' }}>Fornecedor</Label>
        {!showCustomerDrawer && (
          <SelectWithBadge
            name="paymentsupplier"
            id="paymentsupplier"
            model="supplier"
            modelOptions={selectOpts}
            placeholder="Selecione o fornecedor"
            value={idSupplier}
            onChange={handleChangeSupplier}
            onBlur={handleBlur('idSupplier')}
            allowClear
            showSearch
            allowCreate
            createOption={newSupplier}
            badgeTooltip="O novo fornecedor importado da nota fiscal será criado nos seus contatos ao salvar"
          />
        )}
        {touched.idSupplier && errors?.idSupplier && <Error>{errors?.idSupplier}</Error>}
      </Col>
    );
  }, [idSupplier]);

  const RenderAccountInput = () => {
    return (
      <Col md={8} xs={24} key="accountInput" style={{ visibility: ownBusiness || !isCharge || 'hidden' }}>
        <Label>Conta</Label>
        <TooltipIcon
          style={{ color: colors.primary600, marginLeft: '8px' }}
          tooltipProps={{ overlayStyle: { minWidth: '270px' } }}
          icon={faInfoCircle}
          color={colors.primary600}
          text="Você pode adicionar uma conta bancária ou informar que a transação saiu do caixa do negócio."
          trigger={['hover', 'click']}
        />
        {!createBankAccount && (
          <Select
            name="bankAccount"
            id="paymentBankAccount"
            model="bankAccount"
            modelOptions={{
              where: {
                isFinancialEntry: true,
                ...(billType !== paymentTypeValues.expense.value ? { isVobiPay: false } : {}),
                isActive: true
              },
              order: [['name', 'asc']]
            }}
            placeholder="Selecione a conta"
            value={idPaymentBankAccount}
            onChange={setField('idPaymentBankAccount')}
            onBlur={handleBlur('idPaymentBankAccount')}
            allowClear
            showSearch
            allowCreate
            openCustomCreate={setCreateBankAccount}
            disabled={isConciliation || paymentConciliated}
            dropdownRender={menu => (
              <>
                {menu}
                <AddSelectButton onClick={() => window.open('/profissional/cadastro-financeiro/contas', '_blank')} />
              </>
            )}
            createParams={{ billType, isActive: true, isFinancialEntry: true }}
          />
        )}
      </Col>
    );
  };

  const RenderCostCenterInput = () => {
    return (
      <Col md={8} xs={24} key="costCenterInput" style={{ visibility: ownBusiness || !isCharge || 'hidden' }}>
        <Label>Centro de custo</Label>
        {!showPaymentCostCenter && (
          <Select
            name="paymentCostCenter"
            id="paymentCostCenter"
            model="paymentCostCenter"
            modelOptions={{
              ...selectOpts,
              order: [['name', 'asc']]
            }}
            placeholder="Centro de custo"
            value={idPaymentCostCenter}
            onChange={setField('idPaymentCostCenter')}
            onBlur={handleBlur('idPaymentCostCenter')}
            allowClear
            showSearch
            allowCreate
            dropdownRender={menu => (
              <>
                {menu}
                <AddSelectButton onClick={() => setShowPaymentCostCenter(true)} />
              </>
            )}
            createParams={{ billType, isActive: true }}
          />
        )}
      </Col>
    );
  };

  const RenderPaymentTypeInput = () => {
    return (
      <Col md={8} xs={24} key="paymentTypeInput">
        <Label>{`Forma de pagamento ${isCharge ? 'aceitável' : ''}`}</Label>
        <Select
          multiple
          name="paymentTypes"
          id="paymentTypes"
          model="paymentType"
          modelOptions={{
            where: { id: { ne: 0 } },
            order: [['name', 'asc']]
          }}
          placeholder="Selecione"
          value={paymentTypes?.map(item => item?.id || item?.value)}
          onChange={setField('paymentTypes')}
          onBlur={handleBlur('paymentTypes')}
          showArrow
          allowClear
          showSearch
          noHeight
        />
      </Col>
    );
  };

  const RenderPaymentItemLevel = isApportionment ? null : (
    <Col md={8} xs={24} key="paymentItemLevel">
      <Label>Apropriação</Label>
      <TooltipIcon
        style={{ color: colors.primary600, marginLeft: spaces.space1 }}
        icon={faInfoCircle}
        color={colors.primary600}
        text="Selecione o nível do seu orçamento referente aos valores desse lançamento financeiro."
        trigger={['hover', 'click']}
      />
      <Select
        disabled={isView || !idRefurbish}
        name="idRefurbishItemLevel"
        id="idRefurbishItemLevel"
        model="refurbishItems"
        disabledText="Adicione um projeto que contenha níveis para habilitar a apropriação."
        modelOptions={{
          where: { type: refurbishItemType.parent, idRefurbish, idParent: { eq: null } },
          order: [['name', 'asc']]
        }}
        prevValidateFields={['idRefurbish']}
        placeholder="Selecione"
        value={idRefurbishItemLevel}
        onChange={setField('idRefurbishItemLevel')}
        onBlur={handleBlur('idRefurbishItemLevel')}
        showArrow
        allowClear
        showSearch
        noHeight
      />
    </Col>
  );

  const RenderValueInput = () => {
    return (
      <Col md={8} xs={24} key="valueInput">
        <Label>Valor *</Label>
        <MaskedInput
          disabled={paymentStatus?.id === 3}
          placeholder=""
          id="valueInput"
          type="currency"
          onChange={e => {
            return e.target.value && setField('value')(formatNumber(e.target.value));
          }}
          onBlur={handleBlur('value')}
          defaultValue={formatCurrency(value, {
            currencySymbol: 'R$ '
          })}
          maskOptions={{
            prefix: 'R$',
            thousandsSeparatorSymbol: '.',
            allowDecimal: true
          }}
          style={{ height: '32px', flex: 2 }}
        />
        {touched.value && errors?.value && <Error>{errors?.value}</Error>}
      </Col>
    );
  };

  const renderPaymentSegmentedInput = useMemo(() => {
    const optionsPayment = [
      { value: 'myCompany', label: 'Meu negócio', disabled: !hasPaymentToMyCompany },
      { value: 'supplier', label: 'Fornecedor', disabled: !hasPaymentToSupplier }
    ];

    const handleSegmentedChange = e => {
      if (e === 'myCompany') {
        handleChangeSupplier(null);
      }
      setField('ownBusiness')(e === 'myCompany');
    };

    return (
      <Col md={8} xs={24}>
        <Label>Pagamento para *</Label>
        <TooltipIcon
          style={{ color: colors.primary600, marginLeft: spaces.space1 }}
          icon={faInfoCircle}
          color={colors.primary600}
          text="Informe se o cliente irá realizar o pagamento para seu negócio ou para outro fornecedor."
          trigger={['hover', 'click']}
        />

        <Tooltip
          title={!hasPaymentToMyCompany || !hasPaymentToSupplier || idOrder ? disabledText : ''}
          placement={!hasPaymentToMyCompany || !isIdOrderNullOrArray ? 'topLeft' : 'topRight'}
        >
          <Segment
            id="paymentTo"
            options={optionsPayment}
            value={ownBusiness && hasPaymentToMyCompany && isIdOrderNullOrArray ? 'myCompany' : 'supplier'}
            onChange={handleSegmentedChange}
            disabled={isConciliation || idOrder}
            width="100%"
          />
        </Tooltip>
      </Col>
    );
  }, [ownBusiness]);

  const renderBasicForm = () => {
    if (isVobiPay) {
      return (
        <>
          {renderProjectOpportunityInput}
          {renderDateFinancialInput}
          {RenderFinancialCategoryInput()}
          {RenderCostCenterInput()}
          {RenderPaymentItemLevel}
        </>
      );
    }
    if (isCharge && !isVobiPay) {
      return (
        <>
          {renderPaymentSegmentedInput}
          {!ownBusiness ? RenderSupplierInput : RenderFinancialCategoryInput()}
          {renderProjectOpportunityInput}
          {RenderClientInput()}
          {renderDateFinancialInput}
          {ownBusiness && id && RenderAccountInput()}
          {ownBusiness && id && RenderCostCenterInput()}
          {id && RenderPaymentTypeInput()}
          {id && showPaymentLevel && RenderPaymentItemLevel}
        </>
      );
    }

    return (
      <>
        {!isCharge && !paymentComplete && RenderValueInput()}
        {renderDateFinancialInput}
        {RenderFinancialCategoryInput()}
        {!apportionmentDrawer?.open && renderProjectOpportunityInput}
        {billTypeIncome ? RenderClientInput() : RenderSupplierInput}
        {id && !isCharge && recurrenceId && RenderPaidDate}
        {id && RenderAccountInput()}
        {id && RenderCostCenterInput()}
        {id && RenderPaymentTypeInput()}
        {id && showPaymentLevel && RenderPaymentItemLevel}
      </>
    );
  };

  const SwitchPaymentItems =
    billType === 'income' ? (
      <div />
    ) : (
      <Div gap={spaces.space1}>
        <Switch checked={!!paymentComplete} onChange={checked => handleSwitchPaymentItems(checked)} />
        <Paragraph type="small">Habilitar produtos/serviços</Paragraph>
        <TooltipIcon
          text={`Habilite a opção de Produtos/Serviços para detalhar itens na
      despesa e fazer o rateio da apropriação entre os níveis do orçamento`}
          icon={faCircleInfo}
          iconColor={colors.primary500}
        />
      </Div>
    );

  const handleCopy = () => {
    setTimeout(() => navigator.clipboard.writeText(_supplier?.pixKey), 0);
    toast.success('Chave pix copiada.');
  };

  return (
    <>
      {isView ? (
        <Content>
          <Div justify="space-between" $fullWidth gap={spaces.space1} wrap="wrap">
            <Div justify="space-between" $fullWidth={_isMobile}>
              <Subtitle id="payment-name">{name}</Subtitle>
              {id && lastView && !isPublic && !isCustomer && isVobiPay && (
                <TooltipIcon
                  style={{ marginLeft: spaces.space1, padding: spaces.space1 }}
                  tooltipProps={{ overlayStyle: { minWidth: _isMobile ? '320px' : '420px' }, id: 'viewed-by' }}
                  icon={faUserCheck}
                  color={colors.primary600}
                  text={`
                        Fatura visualizada pela última vez em: ${lastView}. Clique para acessar o histórico de
                        visualização.
                      `}
                  onClick={() => setShowViewedByModal(true)}
                />
              )}
            </Div>
            <Div gap={spaces.space2}>
              {id && (
                <div className="showMobile hide-on-print">
                  <Paragraph type="small">
                    Data de criação: {dayjs(values.createdAt).format('DD/MM/YYYY [às] HH:mm:ss')}
                  </Paragraph>
                  <Paragraph>
                    Status: <span style={{ color: statusColor }}>{paymentStatus?.name}</span>
                  </Paragraph>
                </div>
              )}
              {billingDate && (
                <Paragraph type="small">Data de competência: {dayjs(billingDate).format('DD/MM/YYYY')}</Paragraph>
              )}
              {id && !_isMobile && isPublic ? (
                <Button className="hide-on-print" ghost onClick={() => setIsPrinting(true)}>
                  Exportar PDF
                </Button>
              ) : null}
            </Div>
          </Div>
          <DataContainer>
            <div className="companyData">
              {company?.logo && (
                <div className="logoContainer">
                  <Logo $image={company?.logo} />
                </div>
              )}
              <Div gap={spaces.space1} direction="column" align="flex-start">
                <Subtitle weight={fonts.weight600}>
                  {company?.name || company?.legalName || 'Dados da empresa não informado'}
                </Subtitle>
                {company?.phone && <Paragraph type="small">{company?.phone}</Paragraph>}
                {company?.email && <Paragraph type="small">{company?.email}</Paragraph>}
                <Paragraph type="small">CNPJ/CPF: {formatDocument(company?.cnpj || '-')}</Paragraph>
                <Paragraph type="small">{concatenatedCompany}</Paragraph>
              </Div>
            </div>
            <div className="customerData">
              <Div
                gap={spaces.space1}
                direction="column"
                align="flex-start"
                margin={!isMobile() && `0 ${spaces.space2} 0 0`}
              >
                <Div gap={spaces.space1}>
                  <Subtitle weight={fonts.weight600}>Informações do cliente</Subtitle>
                  {showEditCustomer && (
                    <TooltipIcon
                      style={{ color: colors.primary600 }}
                      text="Editar informações do cliente"
                      icon={faPenToSquare}
                      onClick={() => setCustomerDrawer(true)}
                    />
                  )}
                </Div>
                {_companyCustomer?.name ? (
                  <Paragraph type="small" id="customer-name">
                    {_companyCustomer?.name}
                    {_customerPhone ? ` - ${formatPhoneNumber(_customerPhone) || _customerPhone}` : ''}
                  </Paragraph>
                ) : (
                  <Paragraph type="small">Cliente não informado</Paragraph>
                )}
                {(_companyCustomer?.email || customerEmail) && (
                  <Paragraph type="small">{customerEmail || _companyCustomer?.email}</Paragraph>
                )}
                {(_companyCustomer?.doc || customerDoc) && (
                  <Paragraph type="small">CNPJ/CPF: {formatDocument(customerDoc || _companyCustomer?.doc)}</Paragraph>
                )}
                {concatenatedCompanyCustomer && <Paragraph type="small">{concatenatedCompanyCustomer}</Paragraph>}
                {_refurbish?.name && <Paragraph type="small">Projeto: {_refurbish.name}</Paragraph>}
              </Div>
            </div>

            {!ownBusiness && isView && idSupplier && (
              <div className="supplierData">
                <Div gap={spaces.space1} direction="column" align="flex-start">
                  <Div gap={spaces.space1}>
                    <Subtitle weight={fonts.weight600}>Dados do fornecedor</Subtitle>
                    {showEditCustomer && (
                      <TooltipIcon
                        style={{ color: colors.primary600 }}
                        text="Editar informações do fornecedor"
                        icon={faPenToSquare}
                        onClick={() => setSupplierDrawer(true)}
                      />
                    )}
                  </Div>
                  {(_supplier?.legalName || _supplier?.name) && (
                    <Paragraph type="small">{_supplier?.legalName || _supplier?.name}</Paragraph>
                  )}
                  {(_supplier?.responsibleName || _supplier?.phone) && (
                    <Paragraph type="small">
                      {_supplier?.phone ? `${formatPhoneNumber(_supplier?.phone) || _supplier?.phone}` : ''}
                      {_supplier?.responsibleName && _supplier?.phone ? ' - ' : ''}
                      {_supplier?.responsibleName}
                    </Paragraph>
                  )}
                  {!_supplier?.name && !_supplier?.legalName && (
                    <Paragraph type="small">Fornecedor não informado</Paragraph>
                  )}

                  {_supplier?.email && <Paragraph type="small">{_supplier?.email}</Paragraph>}
                  {_supplier?.document && (
                    <Paragraph type="small">CNPJ/CPF: {formatDocument(_supplier?.document)}</Paragraph>
                  )}
                  {_supplier?.website && (
                    <a href={_supplier?.website} target="blank" style={{ fontSize: fonts.sizeSm }}>
                      {_supplier?.website}
                    </a>
                  )}
                  {concatenatedSupplier && <Paragraph type="small">{concatenatedSupplier}</Paragraph>}

                  {_supplier?.bankName && <Paragraph type="small">Banco: {_supplier?.bankName}</Paragraph>}

                  {_supplier?.agency && _supplier?.account && (
                    <Div justify="space-between" $fullWidth>
                      <Paragraph type="small">Agência: {_supplier?.agency}</Paragraph>
                      <Paragraph type="small">Conta: {_supplier?.account}</Paragraph>
                    </Div>
                  )}
                  {_supplier?.pixKey && (
                    <Div justify="space-between" gap={spaces.space1} $fullWidth>
                      <Paragraph type="small">Pix: {_supplier?.pixKey}</Paragraph>
                      <TooltipIcon
                        style={{ color: colors.primary600 }}
                        text="Copiar chave pix"
                        icon={faCopy}
                        onClick={handleCopy}
                      />
                    </Div>
                  )}
                  {_supplier?.paymentOptions && (
                    <Paragraph type="small">
                      Outros meios de pagamento: {removeHtmlTags(_supplier?.paymentOptions)}
                    </Paragraph>
                  )}
                </Div>
              </div>
            )}
          </DataContainer>
        </Content>
      ) : (
        <>
          <Content>
            {_isMobile && (
              <div>
                {paymentStatus?.name && (
                  <div className="showMobile">
                    Status: <span style={{ color: statusColor }}>{paymentStatus?.name}</span>
                  </div>
                )}
                {id && (
                  <Paragraph type="small">
                    Data de criação: {dayjs(values.createdAt).format('DD/MM/YYYY [às] HH:mm:ss')}
                  </Paragraph>
                )}
              </div>
            )}

            <Row gutter={[16, 16]}>
              {RenderChargeNameInput()}
              {renderBasicForm()}
            </Row>
          </Content>
          {!id && !isVobiPay && (
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
              <Line />
              <SimpleAccordion title="Mais informações" withPaddingContainer extraHeaderComponent={SwitchPaymentItems}>
                <Row gutter={[16, 16]}>
                  {ownBusiness && RenderAccountInput()}
                  {ownBusiness && RenderCostCenterInput()}
                  {RenderPaymentTypeInput()}
                  {showPaymentLevel && RenderPaymentItemLevel}
                </Row>
              </SimpleAccordion>
            </Space>
          )}
        </>
      )}
      {showCustomerDrawer && (
        <EditOrCreateCustomerOrSupplier
          id={_companyCustomer?.id}
          onClose={() => setCustomerDrawer(false)}
          setData={setCompanyCustomer}
        />
      )}
      {createBankAccount && (
        <FinanceEdit
          onClose={(_, idBankAccount) => {
            setCreateBankAccount(false);
            setField('idPaymentBankAccount')(idBankAccount);
          }}
          model="bankAccount"
          name={createBankAccount}
        />
      )}
      {createFinancialCategory && (
        <EditOrCreateCategoryDrawer
          onClose={(_, _idFinancialCategory) => {
            setCreateFinancialCategory(false);
            setField('idFinancialCategory')(_idFinancialCategory);
            getCategories({ refetchOptions: optionsCategory });
          }}
          defaultValues={{ name: createFinancialCategory, billType }}
          disableEditBillType
        />
      )}
      {showSupplierDrawer && (
        <EditOrCreateCustomerOrSupplier
          id={_supplier?.id}
          onClose={() => setSupplierDrawer(false)}
          setData={setSupplier}
          model="supplier"
        />
      )}
      {showFinancials && (
        <Drawer
          title="Categorias financeiras"
          open
          padding="0px"
          formId="customerForm"
          onClose={() => {
            setShowFinancials(false);
            getCategories({ refetchOptions: optionsCategory });
          }}
          footer={
            <Button
              style={{ marginLeft: 'auto' }}
              id="close-financial-drawer"
              type="primary"
              onClick={() => {
                setShowFinancials(false);
                getCategories({ refetchOptions: optionsCategory });
              }}
            >
              Fechar
            </Button>
          }
          zIndex={1100}
          width={794}
        >
          <FinancialCategoryList isDrawer billTypeDefault={billType} actualId={idFinancialCategory} shouldForceRender />
        </Drawer>
      )}
      {showBankAccount && (
        <ListDrawer
          onClose={() => setShowBankAccount(false)}
          model="bankAccount"
          where={{ isActive: true, isVobiPay: [null, false] }}
          tab="bankAccount"
          title="Configuração das contas"
          subtitle="Conta"
          renderTabs={bankAccount}
          typeText="conta"
        />
      )}
      {showPaymentCostCenter && (
        <ListDrawer
          onClose={() => setShowPaymentCostCenter(false)}
          model="paymentCostCenter"
          where={{ isActive: true }}
          tab="costCenter"
          title="Configuração dos centros de custos"
          subtitle="Centro de custo"
          renderTabs={paymentCostCenter}
          typeText="centro de custo"
        />
      )}
      {showViewedByModal && (
        <ListModal
          columns={_columns}
          list={viewedByFiltered}
          handlePagination={handlePagination}
          showModal={showViewedByModal}
          title="Visualizações da cobrança"
          length={viewedBy?.length}
          offset={offset}
          handleCloseModal={handleCloseModal}
          itensPage={ITEMS_PAGE}
        />
      )}

      {apportionmentDrawer?.open && apportionmentDrawer?.type === 'simple' ? (
        <ApportionmentDrawer
          changeApportionment={changeApportionment}
          onClose={() => setApportionmentDrawer({ open: false })}
          originalRefurbish={idRefurbish}
          total={value}
          apportionmentList={apportionmentList}
          hasInstallmentPaid={!installments.every(i => i.idInstallmentStatus === 1)}
        />
      ) : null}

      {switchApportionmentModal?.open ? (
        <ConfirmModal
          alertInfo="Essa ação não poderá ser desfeita."
          description={`Você está ${
            !paymentComplete ? 'habilitando' : 'desabilitando'
          } os itens no lançamento. Todos os ${
            !paymentComplete ? 'valores do rateio de projetos' : 'itens preenchidos'
          } serão perdidos.`}
          onSubmit={() => handleSwitchPaymentItems(switchApportionmentModal.val, true)}
          onClose={() => setSwitchApportionmentModal({ open: false })}
        />
      ) : null}
    </>
  );
};

export default PaymentBasicForm;
