import React from 'react';
import { useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { checkApprovalAuthority } from 'vobi_lib';
import Button from '../Button/Button';
import PurchaseActionDropdown from '../Dropdown/PurchaseActionDropdown';
import Action from '../Button/Action';

const PurchaseFooterHelper = ({
  model = 'purchase',
  isReview,
  data,
  getPurchase,
  baseUrl,
  id,
  handleSave,
  setIsReview,
  validateSubmit,
  handlePrint
}) => {
  const history = useHistory();
  const { user = {} } = useSelector(state => state.authReducer) || {};
  const { purchaseOrderStatus = {}, purchaseOrderMoveStatusObj = {} } = useSelector(state => state.setup.enums) || {};
  const { location } = history;

  const EditButton = () => (
    <Button
      id="purchase-request-edit"
      type="primary"
      onClick={() => {
        if (isReview) setIsReview(false);
        if (id) history.push(`${baseUrl}/editar/${id}${location.search || ''}`);
      }}
    >
      Editar
    </Button>
  );

  const EditTextButton = () => (
    <Button
      id="purchase-request-edit"
      text
      onClick={() => {
        if (isReview) setIsReview(false);
        if (id) history.push(`${baseUrl}/editar/${id}${location.search || ''}`);
      }}
    >
      Editar
    </Button>
  );

  const SaveDraftButton = () => (
    <Button id="purchase-request-save-draft" text onClick={() => handleSave()}>
      Salvar rascunho
    </Button>
  );

  const CreateButton = () => (
    <Button
      id="purchase-request-create"
      type="primary"
      width="120px"
      onClick={() =>
        validateSubmit({
          callback: () => {
            handleSave();
            setIsReview(false);
          }
        })
      }
    >
      Criar
    </Button>
  );

  const SaveButton = () => (
    <Button
      id="purchase-request-save"
      type="primary"
      width="120px"
      onClick={() =>
        validateSubmit({
          callback: () => {
            handleSave();
            setIsReview(false);
          }
        })
      }
    >
      Salvar
    </Button>
  );

  const ActionsButton = () => (
    <PurchaseActionDropdown
      data={data}
      model={model}
      handlePrint={handlePrint}
      afterSubmit={resp => {
        if (resp?.isDelete) {
          history.push('/profissional/gestao-de-compras/solicitacoes');
        } else {
          getPurchase();
        }
      }}
    >
      <Action id="purchase-actions" />
    </PurchaseActionDropdown>
  );

  const ReviewButton = () => (
    <Button
      id="purchase-request-review"
      type="primary"
      width="120px"
      onClick={() =>
        validateSubmit({
          callback: () => setIsReview(true)
        })
      }
    >
      Revisar e criar
    </Button>
  );

  const SaveOrderButton = () => (
    <Button
      id="save-open-order"
      text
      onClick={() =>
        validateSubmit({
          callback: () => {
            handleSave({ status: purchaseOrderStatus?.open });
            setIsReview(false);
          }
        })
      }
    >
      Salvar
    </Button>
  );

  // eslint-disable-next-line react/prop-types
  const SaveAndSendOrderButton = ({ total }) => {
    const hasAuthority = checkApprovalAuthority({
      oldStatus: purchaseOrderStatus.open,
      newStatus: purchaseOrderStatus.sent,
      user,
      total,
      moveStatus: purchaseOrderMoveStatusObj
    });
    return (
      <Button
        id="save-and-send-order"
        type="primary"
        width="120px"
        disabled={!hasAuthority}
        tooltipText={!hasAuthority ? 'Você não possui alçada necessária para essa ação' : ''}
        onClick={() =>
          validateSubmit({
            callback: () => {
              handleSave({
                status: purchaseOrderStatus?.sent,
                extraQuery: 'openShareModal=true'
              });
              setIsReview(false);
            }
          })
        }
      >
        Salvar e enviar
      </Button>
    );
  };

  return {
    EditButton,
    EditTextButton,
    SaveDraftButton,
    SaveButton,
    CreateButton,
    ActionsButton,
    ReviewButton,
    SaveOrderButton,
    SaveAndSendOrderButton
  };
};

export default PurchaseFooterHelper;
