import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { faCheck, faX, faArrowRotateLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import * as dayjs from 'dayjs';
import { checkApprovalAuthority } from 'vobi_lib';
import { Div, spaces, colors } from '../../styles/style';
import Button from '../Button/Button';
import { Paragraph, Subtitle } from '../Text/Text';
import ConfirmModal from '../Modal/ConfirmModal';

const ApprovalAuthoritySection = ({
  data = {},
  statusKey,
  changeStatus,
  statusEnum,
  moveStatus,
  isPrinting,
  isOrder
}) => {
  const { user = {} } = useSelector(state => state.authReducer) || {};
  const { authorityApprovalStatus, groupPermission } = useSelector(state => state.setup.enums);
  const isOpen = authorityApprovalStatus.open.includes(data?.[statusKey]);
  const isReproved = authorityApprovalStatus.reproved.includes(data?.[statusKey]);
  const { authorityUser, authorityDate, authorityRejectionObs } = data || {};
  const [showModal, setShowModal] = useState();
  const canUndo =
    user.userApprovalAuthorities[isOrder ? 'purchaseOrder' : 'purchaseSolicitation'] ||
    user?.isOwner ||
    user.groupPermission === groupPermission.admin;

  const hasAuthority = newStatus =>
    checkApprovalAuthority({
      oldStatus: data?.[statusKey],
      newStatus,
      user,
      total: data?.totalSplitValue || data?.total,
      moveStatus
    });

  const handleReject = resp => {
    changeStatus({ [statusKey]: statusEnum.rejected, authorityRejectionObs: resp?.observation });
  };

  return (
    <>
      {showModal && (
        <ConfirmModal
          modalWidth="600"
          title={isOrder ? 'Recusar compra' : 'Recusar solicitação'}
          text="Se desejar, inclua um motivo ou observação para a recusa."
          onClose={setShowModal}
          onSubmit={handleReject}
          showObservation
          placeholder={`Ex: ${
            isOrder ? 'Ordem de compra' : 'Solicitação'
          } recusada pois os itens estão fora do escopo de compra.`}
          label="Observações"
        />
      )}
      <Div direction="column" align="start" gap={spaces.space1}>
        <Subtitle>Aprovação</Subtitle>

        {!isOpen && !authorityUser?.id && (
          <Div direction="column" gap={spaces.space0} align="start">
            <Paragraph type="small">
              {`A ${
                isOrder ? 'Ordem de compra' : 'Solicitação'
              } não possui aprovador pois avançou quando as alçadas estavam desativadas.`}
            </Paragraph>
          </Div>
        )}

        {!isReproved && authorityUser?.id && (
          <>
            <Div gap={spaces.space1}>
              <Paragraph color={colors.green600}>Aprovado</Paragraph>
              <Paragraph>
                por {authorityUser?.name} em {dayjs(authorityDate).format('DD/MM/YYYY [às] HH:mm')}
              </Paragraph>
              {hasAuthority(statusEnum.open) && !isPrinting ? (
                <Button
                  type="primary"
                  text
                  onClick={() => changeStatus({ [statusKey]: statusEnum.open })}
                  tooltipText="Desfazer aprovação"
                >
                  <FontAwesomeIcon icon={faArrowRotateLeft} />
                </Button>
              ) : null}
            </Div>
          </>
        )}

        {isReproved && (
          <>
            <Div direction="column" gap={spaces.space0} align="start">
              <Div gap={spaces.space1}>
                <Paragraph color={colors.red500}>Recusado</Paragraph>
                <Paragraph>
                  por {authorityUser?.name} em {dayjs(authorityDate).format('DD/MM/YYYY [às] HH:mm')}
                </Paragraph>
                {hasAuthority(statusEnum.open) && !isPrinting && canUndo ? (
                  <Button
                    type="primary"
                    text
                    onClick={() => changeStatus({ [statusKey]: statusEnum.open })}
                    tooltipText="Desfazer recusa"
                  >
                    <FontAwesomeIcon icon={faArrowRotateLeft} />
                  </Button>
                ) : null}
              </Div>
              <Paragraph>
                <b>Observações:</b> {authorityRejectionObs || '-'}
              </Paragraph>
            </Div>
          </>
        )}

        {isOpen ? (
          <>
            <Paragraph color={colors.orange600}>Aguardando aprovação</Paragraph>
            {!isPrinting && (
              <Div gap={spaces.space1}>
                <Button
                  type="primary"
                  ghost
                  onClick={() => changeStatus({ [statusKey]: statusEnum.approved })}
                  {...(!hasAuthority(statusEnum.approved) && {
                    disabled: true,
                    tooltipText: 'Você não possui permissão de alçada de aprovação.'
                  })}
                >
                  <FontAwesomeIcon icon={faCheck} />
                  Aprovar
                </Button>
                <Button
                  ghost
                  onClick={() => setShowModal(true)}
                  {...(!hasAuthority(statusEnum.rejected) && {
                    type: 'primary',
                    disabled: true,
                    tooltipText: 'Você não possui permissão de alçada de aprovação.'
                  })}
                >
                  <FontAwesomeIcon icon={faX} size="xs" />
                  Recusar
                </Button>
              </Div>
            )}
          </>
        ) : null}
      </Div>
    </>
  );
};

ApprovalAuthoritySection.propTypes = {
  data: PropTypes.instanceOf(Object),
  statusEnum: PropTypes.instanceOf(Object),
  statusKey: PropTypes.string,
  changeStatus: PropTypes.func,
  moveStatus: PropTypes.instanceOf(Object),
  hasLimit: PropTypes.bool,
  isPrinting: PropTypes.bool,
  isOrder: PropTypes.bool
};

export default ApprovalAuthoritySection;
