import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import { Link } from 'react-router-dom';
import LogoIcon from '../Icons/Logo';
import { Paragraph } from '../Text/Text';
import css from './LoginContent.module.scss';
import {
  BackgroundContainer,
  Footer,
  ForegroundImage,
  Header,
  HeaderContent,
  LoginContentContainer,
  SideMenu
} from './LoginContent.styled';

import useViewport from '../../_Hooks/useViewport';
import LoginContentImages from './LoginContentImages';
import foreground from '../Images/login-refurbish-tab.svg';
import { colors } from '../../styles/style';

const LoginContent = ({
  refurbish,
  linkTo = {},
  RenderAction = () => null,
  noHeader,
  isBriefing = false,
  children,
  noImage,
  isInvite,
  noTerms = false
}) => {
  const { isMobile } = useViewport(window.innerWidth);

  const _isMobile = isMobile();

  const classesImg = classNames({
    [css.shell__img]: true,
    [css['shell__img--briefing']]: isBriefing
  });

  const { platformTerms } = useSelector(state => state.setup.systemData) || {};

  const companyLogo = refurbish?.provider?.company?.logo;
  const legalName = refurbish?.provider?.company?.legalName;

  return (
    <LoginContentContainer>
      <SideMenu $fullWidth={noImage}>
        {!noHeader && (
          <Header>
            <HeaderContent $fullWidth={noImage}>
              <Link to={{ pathname: `/login`, search: linkTo.search }}>
                {companyLogo ? (
                  <div className={css['shell__company-logo']}>
                    <img src={companyLogo} alt={legalName || 'Vobi'} />
                  </div>
                ) : (
                  <LogoIcon scale={0.65} />
                )}
              </Link>
              {linkTo?.url && (
                <Link to={{ pathname: linkTo.url, search: linkTo.search }}>
                  <Paragraph color={colors.primary600}>
                    {linkTo.description}
                    <FontAwesomeIcon icon={faLongArrowRight} />
                  </Paragraph>
                </Link>
              )}
              <RenderAction />
            </HeaderContent>
          </Header>
        )}
        {children}
        <Footer>
          {!noTerms && (
            <Paragraph>
              Leia os
              <a target="_blank" rel="noopener noreferrer" href={platformTerms}>
                {' '}
                termos de uso
              </a>{' '}
              da Vobi.
            </Paragraph>
          )}
          <LoginContentImages isInvite={isInvite} />
        </Footer>
      </SideMenu>
      {!_isMobile && !noImage && (
        <>
          {isBriefing ? (
            <div className={classesImg}>
              <div>
                <h4>Formulário de Briefing </h4>
              </div>
              <div />
            </div>
          ) : (
            <BackgroundContainer>
              <ForegroundImage src={foreground} alt="Aba de um projeto na Vobi" />
            </BackgroundContainer>
          )}
        </>
      )}
    </LoginContentContainer>
  );
};

LoginContent.propTypes = {
  linkTo: PropTypes.instanceOf(Object),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  noHeader: PropTypes.bool,
  isBriefing: PropTypes.bool,
  refurbish: PropTypes.instanceOf(Object),
  RenderAction: PropTypes.func,
  noImage: PropTypes.bool,
  isInvite: PropTypes.bool,
  noTerms: PropTypes.bool
};

export default LoginContent;
