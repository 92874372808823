import React from 'react';
import * as Yup from 'yup';
import { Divider } from 'antd';
import { idReference, name, simpleName } from '../schema';
import { Subtitle, Paragraph } from '../../../components/Text/Text';
import { Div, spaces } from '../../../styles/style';
import WarningBar from '../../../components/Alert/WarningBar';
import { renderPriceOrPercentage } from '../../helpers/helper';
import formatBdi from '../../helpers/formatBdi';

const serviceProfileSchema = Yup.object().shape({
  name,
  municipalCode: simpleName,
  cnae: simpleName,
  idTransactionNature: idReference
});

const serviceProfileMapping = ({ cnpj }) => {
  return {
    warning: {
      type: 'custom',
      Component: () => (
        <WarningBar
          warning="Recomendamos a validação dos dados a serem preenchidos com o seu contador ou prefeitura."
          width="100%"
        />
      ),
      xs: 24,
      md: 24
    },
    dataTitle: {
      type: 'custom',
      Component: () => <Subtitle>Dados gerais</Subtitle>,
      xs: 24,
      md: 24
    },
    name: {
      name: `Nome do serviço`,
      required: true,
      type: 'text',
      placeholder: 'Nome do serviço',
      xs: 24,
      md: 24
    },
    municipalCode: {
      name: `Código municipal do serviço`,
      required: true,
      type: 'text',
      nameTooltip: `O código municipal do serviço é um identificador numérico da sua
      atividade na sua localidade. Você pode identificá-lo no PDF ou xml da última nota fiscal emitida.`,
      placeholder: 'Ex: 0.00/000000000',
      xs: 24,
      md: 8
    },
    cnae: {
      name: `Código CNAE`,
      required: true,
      type: 'cnae',
      nameTooltip: `Código da Classificação Nacional de Atividades Econômicas vinculado ao
      serviço cadastrado na prefeitura.`,
      placeholder: 'Ex: 0000-0/00',
      xs: 24,
      md: 8
    },
    idTransactionNature: {
      name: `Natureza de operação`,
      required: true,
      type: 'select',
      model: 'companyEntity/natOp',
      modelOne: 'transactionNatures',
      modelOptions: { where: { cnpj } },
      nameTooltip: `Define se a nota fiscal gera imposto a pagar e quem é o responsável por esse tributo.
      Verifique essa informação no seu cadastro na prefeitura ou com sua contabilidade.`,
      placeholder: 'Selecione',
      xs: 24,
      md: 8
    },
    divider1: {
      type: 'custom',
      Component: () => <Divider />,
      xs: 24,
      md: 24
    },
    taxTitle: {
      type: 'custom',
      Component: () => (
        <Div direction="column" align="start" gap={spaces.space1}>
          <Subtitle>Cálculo de impostos</Subtitle>
          <Paragraph type="small">Informe as alíquotas dos impostos.</Paragraph>
        </Div>
      ),
      xs: 24,
      md: 24
    },
    iss: {
      name: `ISS`,
      type: 'text',
      mask: 'number',
      placeholder: '0,00%',
      formatter: val => (val ? `${renderPriceOrPercentage(val)}` : ''),
      parseFunc: val => formatBdi(val),
      render: val => renderPriceOrPercentage(val),
      xs: 8,
      md: 8
    },
    pis: {
      name: `PIS`,
      type: 'text',
      mask: 'number',
      placeholder: '0,00%',
      formatter: val => (val ? `${renderPriceOrPercentage(val)}` : ''),
      parseFunc: val => formatBdi(val),
      xs: 8,
      md: 8
    },
    cofins: {
      name: `COFINS`,
      type: 'text',
      mask: 'number',
      placeholder: '0,00%',
      formatter: val => (val ? `${renderPriceOrPercentage(val)}` : ''),
      parseFunc: val => formatBdi(val),
      xs: 8,
      md: 8
    },
    csll: {
      name: `CSLL`,
      type: 'text',
      mask: 'number',
      placeholder: '0,00%',
      formatter: val => (val ? `${renderPriceOrPercentage(val)}` : ''),
      parseFunc: val => formatBdi(val),
      xs: 8,
      md: 8
    },
    inss: {
      name: `INSS`,
      type: 'text',
      mask: 'number',
      placeholder: '0,00%',
      formatter: val => (val ? `${renderPriceOrPercentage(val)}` : ''),
      parseFunc: val => formatBdi(val),
      render: val => renderPriceOrPercentage(val),
      xs: 8,
      md: 8
    },
    ir: {
      dataIndex: 'bdi',
      name: 'IR',
      type: 'text',
      mask: 'number',
      placeholder: '0,00%',
      formatter: val => (val ? `${renderPriceOrPercentage(val)}` : ''),
      parseFunc: val => formatBdi(val),
      xs: 8,
      md: 8
    },

    divider2: {
      type: 'custom',
      Component: () => <Divider />,
      xs: 24,
      md: 24
    },
    descriptionTitle: {
      type: 'custom',
      Component: () => (
        <Div direction="column" align="start" gap={spaces.space1}>
          <Subtitle>Descrição</Subtitle>
          <Paragraph type="small">Discriminação do serviço que será exibido no XML/PDF da nota fiscal.</Paragraph>
        </Div>
      ),
      xs: 24,
      md: 24
    },
    description: {
      name: `Descrição do serviço`,
      type: 'textarea',
      placeholder: 'Ex: Gerenciamento de obra, inclusive serviços técnicos e auxiliares',
      xs: 24,
      md: 24
    },
    isDefault: {
      type: 'checkbox',
      customLabel: 'Definir como serviço padrão',
      elementTooltip: 'Perfil de serviço a ser selecionado automaticamente nas emissões de notas fiscais.',
      xs: 24,
      md: 24
    }
  };
};

export { serviceProfileMapping, serviceProfileSchema };
