import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Button from '../Button/Button';
import Form from '../Form/Form';

import { StyledModal, FormContainer } from './ShareModuleModal.style';
import { mappingForm, schema } from '../../lib/mapping/Form/shareModuleSchema';
import useCRUD from '../../_Hooks/useCRUD';
import mapping from './ShareModuleModalMapping';
import { buildQueryString } from '../../lib/helpers/helper';

const ShareWithSupplierModal = ({ itemId, onClose, model, onShare = f => f, isSplit }) => {
  const [inputKey, setInputKey] = useState(false);
  const [loading, setLoading] = useState(false);

  const { shareLinkTag, key, urlText } = mapping()[model];

  const { handleCreate } = useCRUD({
    model,
    pathOptions: '/send-email-share',
    immediatelyLoadData: false
  });

  const handleCopyToClipboard = () => {
    const input = document.getElementById('link-input');
    setTimeout(() => navigator.clipboard.writeText(input.value), 0);
    onShare();
    toast.success('Link copiado!');
  };

  const queryParts = [isSplit && { key: 'isSplit', value: true }];

  const query = buildQueryString(queryParts);

  const link = `${window.location.origin}/${shareLinkTag}/${itemId}${query}`;

  const onSubmit = formData => {
    if (formData.email === '') {
      toast.error('É obrigatório inserir um e-mail!');
      return;
    }
    setLoading(true);
    handleCreate({
      values: {
        [key]: itemId,
        isSplit,
        ...formData
      },
      refresh: false,
      displayToast: 'Link enviado com sucesso'
    }).then(resp => {
      setLoading(false);
      if (!resp?.error) {
        onShare();
        setInputKey(!inputKey);
      } else toast.error('Não foi possível enviar, tente novamente!');
    });
  };

  return (
    <div role="presentation" onClick={e => e.stopPropagation()}>
      <StyledModal
        sectionStyle={{
          display: 'flex',
          flexDirection: 'column'
        }}
        footer={
          <>
            <span />
            <Button type="text" onClick={() => onClose(false)} id="close-share-modal">
              Fechar
            </Button>
          </>
        }
        title="Confirmação de envio"
        open
        width="640px"
        onClose={() => onClose(false)}
      >
        <FormContainer>
          <Form
            id="emailSharingForm"
            data={{ email: '', link }}
            key={inputKey}
            mapping={mappingForm({
              handleCopyToClipboard,
              link,
              loading,
              urlText,
              onShare,
              emailText: 'Envie a ordem de compra criada para o email do seu fornecedor',
              linkText: 'Ou envie o link da ordem de compra para o seu fornecedor acessar'
            })}
            schema={schema}
            displayButtons={false}
            onSubmit={onSubmit}
          />
        </FormContainer>
      </StyledModal>
    </div>
  );
};

ShareWithSupplierModal.propTypes = {
  model: PropTypes.string.isRequired,
  itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func,
  onShare: PropTypes.func,
  isSplit: PropTypes.bool
};

export default ShareWithSupplierModal;
