import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  faTrashAlt,
  faEllipsisV,
  faBox,
  faGrid2,
  faTag,
  faBarsProgress,
  faDownload,
  faBarsStaggered
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faPlus } from '@fortawesome/pro-solid-svg-icons';

import { toast } from 'react-toastify';

import { Menu } from 'antd';
import useCRUD from '../../_Hooks/useCRUD';
import useViewport from '../../_Hooks/useViewport';

import { getLevelListFromItems, sumByField } from '../../lib/helpers/helper';

import BulkAction from './BulkAction';
import ConfirmModal from '../Modal/ConfirmModal';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import Dropdown from '../Dropdown/DropdownV2';

import { BulkActionsChildrenContainer, ModalItem, IconDropdown } from './SpecificationBulkActions.styles';
import MobileSelectMenu from './MobileSelectMenu';
import { hasPermission } from '../../routes/Common/PrivateRoute';
import BubbleModalButton from '../Button/BubbleModalButton';
import SelectDropdown from '../Dropdown/SelectDropdown';
import { spaces } from '../../styles/style';
import RadioOptionsModal from '../Modal/RadioOptionsModal';
import TreeSelectWrapper from '../Table/TreeSelectWrapper';
import Payment from '../../_Pages/Payments/Payment';
import { ButtonBulkAction } from './BulkActions.styles';
import ProgressModal from '../Modal/ProgressModal';
import { Paragraph } from '../Text/Text';
import Bill from '../../_Pages/Payments/Bill';

const SpecificationBulkActions = ({
  idRefurbish,
  selectedItems,
  selectedRowKeys,
  setSelectedRowKeys,
  setSelectedItems,
  list,
  handleLoad,
  showStatus,
  items,
  showAll,
  supplierList,
  groupList,
  categoryList,
  isGrouped,
  isTemplate,
  handleClickExportPdf,
  idCompany,
  levelList = []
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [showConfirmRemoveParentModal, setShowConfirmRemoveParentModal] = useState(false);
  const [showConfirmParentOrItemsRemoveModal, setShowConfirmParentOrItemsRemoveModal] = useState(false);
  const [selectedRowKeysObj, setSelectedRowKeysObj] = useState({});
  const [showPaymentDrawer, setShowPaymentDrawer] = useState(false);
  const [showBillDrawer, setShowBillDrawer] = useState(false);
  const [showLevelDropdown, setShowLevelDropdown] = useState(false);

  useEffect(() => {
    const obj = {};
    selectedRowKeys.forEach(key => {
      obj[key] = true;
    });

    setSelectedRowKeysObj(obj);
  }, [selectedRowKeys]);

  const { isMobile } = useViewport(window.innerWidth);

  const { refurbishItemStatus: status } = useSelector(state => state.setup.systemData);
  const { user } = useSelector(state => state.authReducer);
  const { userType, refurbishItemType, paymentPermissions } = useSelector(state => state.setup.enums) || {};
  const {
    financialSupplier,
    financialOwnBusiness,
    financialIncome,
    financialExpense,
    projectExpense,
    projectSupplier,
    projectOwnBusiness
  } = paymentPermissions || {};
  const isCustomer = user.userType === userType.customer.value;
  const { plans, permissions } = useSelector(state => state.setup);

  const financeAdmPermissions = [financialSupplier, financialOwnBusiness, financialIncome, financialExpense];
  const hasPermissionAdministrative = hasPermission(user, financeAdmPermissions, plans, permissions);
  const hasPermissionFinancialProject = hasPermission(user, [projectSupplier, projectOwnBusiness], plans, permissions);

  const hasPaymentPermission = hasPermissionAdministrative || hasPermissionFinancialProject;

  const hasExpensePermission = hasPermission(user, [projectExpense, financialExpense], plans, permissions);

  let selectedIds = [...selectedRowKeys];

  const typesHash = selectedItems.reduce((acc, cur) => ({ ...acc, [cur.id]: cur.type }), {});
  selectedIds = selectedIds.filter(id => typesHash[id] && typesHash[id] !== refurbishItemType.parent);

  const statusList = status.map(priority => ({ value: priority.value, label: priority.label }));

  const { handleChunks, progress } = useCRUD({
    model: isTemplate ? 'template-item' : `refurbish-items`,
    immediatelyLoadData: false
  });

  const filterItem = value => {
    return value.type && value.type !== refurbishItemType.parent;
  };

  const counter = selectedItems?.filter(filterItem)?.length;
  const handleSelectItem = (key, _items) => {
    setSelectedRowKeys(key);
    setSelectedItems(_items);
  };

  const reloadList = (data, selectedK = [], selectedI = []) => {
    handleLoad(data) && handleSelectItem(selectedK, selectedI);
  };

  const handleChange = values => {
    return handleChunks({ values: { ids: selectedIds, ...(!isTemplate && { idRefurbish }), ...values } }).then(resp => {
      const { failedCount, successCount } = resp || {};
      if (failedCount)
        toast.error(
          `${failedCount} ite${failedCount > 1 ? 'ns não puderam ser alterados' : 'm não pode ser alterado'}`
        );
      if (successCount)
        toast.success(
          `${successCount} ite${successCount > 1 ? 'ns foram alterados com sucesso.' : 'm foi alterado com sucesso.'}`
        );
      if (isGrouped) {
        return reloadList();
      }

      return reloadList(null, selectedRowKeys, selectedItems);
    });
  };

  const handleBulkDelete = onlyItems => {
    const onlyItemsIds = onlyItems?.map(item => item.id);
    return handleChunks({
      values: { ids: onlyItemsIds },
      deleteOptions: {
        where: { idRefurbish }
      },
      verb: 'delete'
    }).then(resp => {
      const { failedCount, successCount } = resp || {};
      if (failedCount) toast.error(`${failedCount} ite${failedCount > 1 ? 'ns' : 'm'} não puderam ser excluídos.`);
      if (successCount) toast.success('Operação realizada com sucesso.');
      reloadList();
    });
  };

  const handleClose = () => {
    setSelectedRowKeys([]);
    setSelectedItems([]);
  };
  const total = useMemo(() => (items ? sumByField(items, 'totalBdi') : null), [items]);

  const selectedTotal = useMemo(() => sumByField(selectedItems?.filter(filterItem), 'totalBdi'), [
    selectedItems,
    selectedRowKeysObj
  ]);

  if (selectedItems.length <= 0) return null;

  return isCustomer && !showStatus ? null : (
    <BulkAction
      id="specification-bulk-action"
      counter={counter}
      total={total}
      selectedTotal={selectedTotal}
      handleClose={handleClose}
      showTotal={showAll}
      forceOpen={selectedItems.length > 0}
    >
      {!isMobile() && (
        <BulkActionsChildrenContainer>
          {!isCustomer && (
            <>
              {!isTemplate &&
                (hasPaymentPermission || hasExpensePermission ? (
                  <Dropdown
                    trigger={['click']}
                    menu={
                      <Menu>
                        {hasPaymentPermission ? (
                          <Menu.Item>
                            <Button text type="primary" onClick={() => setShowPaymentDrawer(true)} id="new-payment">
                              Novo pagamento
                            </Button>
                          </Menu.Item>
                        ) : null}
                        {hasExpensePermission ? (
                          <Menu.Item>
                            <Button text type="primary" onClick={() => setShowBillDrawer(true)} id="new-expense">
                              Nova despesa
                            </Button>
                          </Menu.Item>
                        ) : null}
                      </Menu>
                    }
                  >
                    <ButtonBulkAction id="NewPaymentBulkAction" type="ghost">
                      + Financeiro
                    </ButtonBulkAction>
                  </Dropdown>
                ) : (
                  <BubbleModalButton feature={hasPermissionAdministrative ? 'financial' : 'financialSupplier'}>
                    <ButtonBulkAction id="NewPaymentBulkAction" type="ghost">
                      + Financeiro <FontAwesomeIcon icon={faLock} />
                    </ButtonBulkAction>
                  </BubbleModalButton>
                ))}
              <SelectDropdown
                onClick={value => handleChange({ field: 'idSupplier', value })}
                options={{ where: { idCompany }, order: [['name', 'asc']] }}
                tooltip="Fornecedor"
                icon="faBox"
                showClean={false}
                canCreate={false}
                isBulk
                model="supplier"
              />
              <SelectDropdown
                onClick={value => handleChange({ field: 'idRefurbishGroup', value })}
                options={{ where: { idCompany }, order: [['name', 'asc']] }}
                tooltip="Grupo"
                icon="faGrid2"
                showClean={false}
                canCreate={false}
                isBulk
                model="refurbishGroup"
              />
              <SelectDropdown
                onClick={value => handleChange({ field: 'idCostCenter', value })}
                options={{ onlyMine: true, where: { idCompany }, order: [['name', 'asc']] }}
                tooltip="Categoria"
                icon="faTag"
                showClean={false}
                canCreate={false}
                isBulk
                model="costCenter"
              />
            </>
          )}

          {showStatus && !user?.anonymous && !isTemplate && (
            <SelectDropdown
              onClick={value => handleChange({ field: 'status', value })}
              options={{ options: { where: { idCompany }, order: [['name', 'asc']] } }}
              tooltip="Status"
              icon="faBarsProgress"
              showClean={false}
              canCreate={false}
              isBulk
              loadList={statusList}
            />
          )}

          {!isCustomer && (
            <>
              {!isTemplate && (
                <IconDropdown id="btn-export" text="Exportar PDF" icon={faDownload} onClick={handleClickExportPdf} />
              )}
              <IconDropdown
                text="Excluir itens selecionados"
                icon={faTrashAlt}
                onClick={() => {
                  const hasItemSelected = selectedItems?.some(item => item.type !== refurbishItemType.parent);
                  const hasParentSelected = selectedItems?.some(item => item.type === refurbishItemType.parent);
                  if (hasParentSelected && hasItemSelected) setShowConfirmParentOrItemsRemoveModal(true);
                  else if (hasParentSelected && !hasItemSelected) setShowConfirmRemoveParentModal(true);
                  else setShowConfirmModal(true);
                }}
              />
              <Dropdown
                trigger={['click']}
                onClick={() => setShowLevelDropdown(true)}
                placement="topLeft"
                open={showLevelDropdown}
                menu={
                  <div style={{ width: 324 }}>
                    <TreeSelectWrapper
                      placeholder="Escolha um nível"
                      onChange={value => {
                        handleChange({ field: 'idParent', value });
                        setShowLevelDropdown(false);
                      }}
                      onBlur={() => setShowLevelDropdown(false)}
                      levelList={levelList.length ? levelList : getLevelListFromItems(list, refurbishItemType.parent)}
                      opened={showLevelDropdown}
                    />
                  </div>
                }
              >
                <IconDropdown
                  text={!showLevelDropdown && 'Mudar nível dos itens selecionados'}
                  icon={faBarsStaggered}
                />
              </Dropdown>
            </>
          )}
        </BulkActionsChildrenContainer>
      )}

      {isMobile() && (
        <>
          <Button
            text
            onClick={e => {
              e.stopPropagation();
              setShowOptionsModal(true);
            }}
          >
            <FontAwesomeIcon icon={faEllipsisV} size="2x" />
          </Button>

          {showOptionsModal && (
            <Modal title="Opções" open hideFooter onClose={() => setShowOptionsModal(false)}>
              {!isCustomer && (
                <>
                  {!isTemplate &&
                    (hasPaymentPermission || hasExpensePermission ? (
                      <Dropdown
                        trigger={['click']}
                        withoutZIndex
                        menu={
                          <Menu>
                            {hasPaymentPermission ? (
                              <Menu.Item>
                                <Button text type="primary" onClick={() => setShowPaymentDrawer(true)} id="new-payment">
                                  Novo pagamento
                                </Button>
                              </Menu.Item>
                            ) : null}
                            {hasExpensePermission ? (
                              <Menu.Item>
                                <Button text type="primary" onClick={() => setShowBillDrawer(true)} id="new-expense">
                                  Nova despesa
                                </Button>
                              </Menu.Item>
                            ) : null}
                          </Menu>
                        }
                      >
                        <ModalItem id="NewPaymentBulkAction">
                          <FontAwesomeIcon icon={faPlus} />
                          <Paragraph type="large">Financeiro</Paragraph>
                        </ModalItem>
                      </Dropdown>
                    ) : (
                      <BubbleModalButton feature={hasPermissionAdministrative ? 'financial' : 'financialSupplier'}>
                        <ModalItem id="NewPaymentBulkAction">
                          <FontAwesomeIcon icon={faPlus} />
                          <Paragraph type="large">Financeiro</Paragraph>
                          <FontAwesomeIcon icon={faLock} />
                        </ModalItem>
                      </BubbleModalButton>
                    ))}
                  <Dropdown
                    menu={
                      <MobileSelectMenu
                        onClick={(field, option) => handleChange({ field, value: option.value || option.id })}
                        options={[{ label: '-', value: 1 }, ...supplierList]}
                        field="idSupplier"
                      />
                    }
                  >
                    <ModalItem>
                      <FontAwesomeIcon icon={faBox} />
                      <Paragraph type="large">Alterar fornecedor</Paragraph>
                    </ModalItem>
                  </Dropdown>

                  <Dropdown
                    menu={
                      <MobileSelectMenu
                        onClick={(field, option) => handleChange({ field, value: option.value || option.id })}
                        options={[{ label: '-', value: 1 }, ...groupList]}
                        field="idGroup"
                      />
                    }
                  >
                    <ModalItem>
                      <FontAwesomeIcon icon={faGrid2} />
                      <Paragraph type="large">Alterar grupo</Paragraph>
                    </ModalItem>
                  </Dropdown>

                  <Dropdown
                    menu={
                      <MobileSelectMenu
                        onClick={(field, option) => handleChange({ field, value: option.value || option.id })}
                        options={[{ label: '-', value: 1 }, ...categoryList]}
                        field="idCostCenter"
                      />
                    }
                  >
                    <ModalItem>
                      <FontAwesomeIcon icon={faTag} />
                      <Paragraph type="large">Alterar categoria</Paragraph>
                    </ModalItem>
                  </Dropdown>
                </>
              )}

              {showStatus && !user?.anonymous && (
                <Dropdown
                  menu={
                    <MobileSelectMenu
                      onClick={(field, option) => handleChange({ field, value: option.value || option.id })}
                      options={statusList}
                      field="status"
                    />
                  }
                >
                  <ModalItem>
                    <FontAwesomeIcon icon={faBarsProgress} />
                    <Paragraph type="large">Alterar status</Paragraph>
                  </ModalItem>
                </Dropdown>
              )}

              {!isCustomer && (
                <ModalItem onClick={() => setShowConfirmModal(true)}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                  <Paragraph type="large">Excluir itens selecionados</Paragraph>
                </ModalItem>
              )}
            </Modal>
          )}
        </>
      )}
      {showConfirmModal && (
        <ConfirmModal
          text={`Tem certeza que deseja apagar ${counter} ite${counter > 1 ? 'ns' : 'm'} selecionado${
            counter > 1 ? 's' : ''
          }?`}
          onSubmit={() => {
            handleBulkDelete(selectedItems.filter(item => !selectedRowKeys.includes(item.idParent)));
            handleClose();
            setShowConfirmModal(false);
          }}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
      {showPaymentDrawer && (
        <Payment
          onClose={() => setShowPaymentDrawer(false)}
          idRefurbish={idRefurbish}
          action="novo"
          selectedItems={selectedIds}
          withoutImportXML
        />
      )}
      {showBillDrawer && (
        <Bill
          onClose={() => setShowBillDrawer(false)}
          idRefurbish={idRefurbish}
          action="novo"
          selectedItems={selectedIds}
          billType="expense"
          paymentComplete
        />
      )}
      {showConfirmParentOrItemsRemoveModal && (
        <RadioOptionsModal
          defaultValue
          open={showConfirmParentOrItemsRemoveModal}
          title="Excluir itens do Orçamento"
          subtitle="O que você deseja excluir?"
          subtitlePadding={spaces.space2}
          onClose={() => setShowConfirmParentOrItemsRemoveModal(false)}
          onSubmit={deleteOnlyItems => {
            if (deleteOnlyItems) handleBulkDelete(selectedItems.filter(item => item.type !== refurbishItemType.parent));
            else handleBulkDelete(selectedItems.filter(item => !selectedRowKeys.includes(item.idParent)));
            setShowConfirmParentOrItemsRemoveModal(false);
          }}
          options={[
            {
              value: true,
              id: 'only-items',
              label: 'Excluir somente itens dentro do orçamento (composições, produtos e serviços)'
            },
            {
              value: false,
              id: 'all',
              label:
                'Excluir itens, níveis e subníveis selecionados (todos os itens' +
                ' associados aos níveis serão excluídos)'
            }
          ]}
        />
      )}
      {showConfirmRemoveParentModal && (
        <ConfirmModal
          text="Tem certeza que deseja apagar os níveis/subníveis selecionados?"
          onSubmit={() => {
            handleBulkDelete(selectedItems.filter(item => !selectedRowKeys.includes(item.idParent)));
            handleClose();
            setShowConfirmRemoveParentModal(false);
          }}
          onClose={() => setShowConfirmRemoveParentModal(false)}
        />
      )}
      <ProgressModal progress={progress} open={!!progress} />
    </BulkAction>
  );
};

SpecificationBulkActions.propTypes = {
  idRefurbish: PropTypes.number,
  selectedItems: PropTypes.instanceOf(Array),
  selectedRowKeys: PropTypes.instanceOf(Array),
  setSelectedRowKeys: PropTypes.func,
  setSelectedItems: PropTypes.func,
  handleLoad: PropTypes.func,
  showStatus: PropTypes.bool,
  items: PropTypes.instanceOf(Array),
  showAll: PropTypes.bool,
  isGrouped: PropTypes.bool,
  supplierList: PropTypes.instanceOf(Array),
  groupList: PropTypes.instanceOf(Array),
  categoryList: PropTypes.instanceOf(Array),
  isTemplate: PropTypes.bool,
  list: PropTypes.instanceOf(Array),
  handleClickExportPdf: PropTypes.func,
  idCompany: PropTypes.number,
  levelList: PropTypes.instanceOf(Array)
};

export default SpecificationBulkActions;
