import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import { Divider } from 'antd';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { colors, Div, spaces } from '../../styles/style';
import { Paragraph } from '../Text/Text';
import Card from '../Card/Card';
import { DynamicColumn, InlineDivider } from './MobileCardItemV2.style';
import { useContextHook } from '../../contexts/GeneralContext';
import TooltipIcon from '../Tooltip/TooltipIcon';
import DefaultFooter from '../MobileFooter/DefaultFooter';

const MobileCardItemV2 = ({
  item,
  columns,
  index,
  childrenColumnName,
  readOnlyMobile,
  renderTitleMobile = true,
  handleLoad,
  onClick,
  handleChange,
  marginMobile,
  cardPadding,
  mobileItemFooter = DefaultFooter
}) => {
  const { seeMoreRows } = useContextHook();

  const renderFooter = mobileItemFooter && (item?.[childrenColumnName]?.length || !readOnlyMobile);

  const getNestedProperty = col => {
    if (!Array.isArray(col.dataIndex) || col.dataIndex?.length === 1) return item[col.dataIndex];

    return col.dataIndex?.reduce((acc, curr) => {
      if (acc) return acc[curr];
      return acc;
    }, item);
  };

  const shouldRenderColumn = col => {
    if (col.disableRenderWhenNull && !item[col.dataIndex?.[0]]?.length) return false;
    return item[col.dataIndex?.[0]];
  };

  return (
    <Card
      key={`mobile-card-item-${item.id}`}
      bodyJustify="flex-start"
      bodyBackgroundColor={colors.neutral50}
      footerBackgroundColor={colors.neutral50}
      footerPadding="0px"
      onClick={onClick}
      bodyPadding={cardPadding}
      footer={
        renderFooter
          ? mobileItemFooter({
              item,
              index,
              childrenColumnName,
              readOnlyMobile,
              onClick,
              handleChange,
              handleLoad
            })
          : null
      }
    >
      <Div wrap="wrap" gap={spaces.space1} justify="space-between">
        {columns
          .filter(col => !col.disableRenderWhenNull || shouldRenderColumn(col))
          .map(col =>
            seeMoreRows && !seeMoreRows[item?.id] && col.showOnlyWithSeeMore ? null : (
              <React.Fragment key={col.key}>
                {readOnlyMobile && col.inlineMobile && (
                  <Divider
                    style={{
                      width: `calc(100% + ${spaces.space4})`,
                      margin: `0 -${spaces.space2} 0`,
                      backgroundColor: colors.neutral100
                    }}
                  />
                )}
                <DynamicColumn
                  $mobileWidth={col.mobileWidth}
                  $minHeight={col.disableMinHeight || col.inlineMobile || !renderTitleMobile}
                  $marginMobile={marginMobile}
                >
                  <InlineDivider
                    inline={col.inlineMobile}
                    $readOnly={readOnlyMobile}
                    justify={col.justify}
                    $mobileDivWidth={col.mobileDivWidth}
                    $inlinePadding={col.inlinePadding}
                  >
                    {renderTitleMobile && (
                      <div style={{ marginBottom: col.disableMinHeight ? 0 : spaces.space0 }}>
                        <Paragraph type="small">{col.title}</Paragraph>
                        {col.tooltip && (
                          <TooltipIcon
                            text={col.tooltip}
                            icon={faInfoCircle}
                            iconColor={colors.primary500}
                            style={{ marginLeft: spaces.space1 }}
                            tooltipProps={{
                              placement: 'bottomLeft'
                            }}
                          />
                        )}
                      </div>
                    )}
                    {col.render ? col.render(getNestedProperty(col), item, index) : <div>{getNestedProperty(col)}</div>}
                  </InlineDivider>
                </DynamicColumn>
              </React.Fragment>
            )
          )}
      </Div>
    </Card>
  );
};

MobileCardItemV2.propTypes = {
  item: PropTypes.instanceOf(Object),
  columns: PropTypes.instanceOf(Array),
  index: PropTypes.number,
  childrenColumnName: PropTypes.string,
  readOnlyMobile: PropTypes.bool,
  renderTitleMobile: PropTypes.bool,
  handleLoad: PropTypes.func,
  onClick: PropTypes.func,
  handleChange: PropTypes.func,
  marginMobile: PropTypes.string,
  cardPadding: PropTypes.string,
  mobileItemFooter: PropTypes.func
};

export default React.memo(MobileCardItemV2, isEqual);
