import { Radio, Space, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Modal from './Modal';

const RadioOptionsModal = ({
  onClose,
  title = 'Confirmar ação',
  modalWidth = 500,
  options,
  defaultValue,
  onSubmit,
  open,
  subtitle,
  subtitlePadding = 0
}) => {
  const [radioValue, setRadioValue] = useState(defaultValue);

  return (
    <Modal
      open={open}
      center
      title={title}
      subtitle={
        subtitle ||
        'Caso esteja importando itens com códigos que já existem na sua biblioteca, o que deseja fazer com esses itens?'
      }
      onSubmit={() => onSubmit(radioValue)}
      onClose={() => onClose(false)}
      width={modalWidth}
      subtitlePadding={subtitlePadding}
    >
      <Space size={12} direction="vertical">
        <Radio.Group size="small" value={radioValue} onChange={e => setRadioValue(e.target.value)}>
          <Space direction="vertical">
            {options.map(({ label, value, id, disabled, tooltip }) =>
              tooltip ? (
                <Tooltip title={tooltip}>
                  <Radio id={id} value={value} key={`opt${value}`} disabled={disabled}>
                    {label}
                  </Radio>
                </Tooltip>
              ) : (
                <Radio id={id} value={value} key={`opt${value}`} disabled={disabled}>
                  {label}
                </Radio>
              )
            )}
          </Space>
        </Radio.Group>
      </Space>
    </Modal>
  );
};

RadioOptionsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  modalWidth: PropTypes.number,
  options: PropTypes.instanceOf(Array),
  defaultValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(Object)]),
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  subtitle: PropTypes.string,
  subtitlePadding: PropTypes.number
};

export default RadioOptionsModal;
