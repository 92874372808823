import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Divider, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

import PurchaseHeader from '../../../components/Header/PurchaseHeader';
import { Div, colors, spaces } from '../../../styles/style';
import CenteredLoader from '../../../components/Loader/CenteredLoader';
import usePurchase from '../../../_Hooks/usePurchase';
import PurchaseForm from '../../../components/Form/PurchaseForm';
import { Subtitle } from '../../../components/Text/Text';
import { buildQueryString, getObjectWithSelectedFields } from '../../../lib/helpers/helper';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import ConfirmModalAlertInfoBuilder from '../../../components/Modal/ConfirmModalAlertInfoBuilder';
import { ComponentToPdf } from '../../../lib/helpers/exportToPdf';
import useUrlParams from '../../../_Hooks/useUrlParams';
import useViewport from '../../../_Hooks/useViewport';
import { saveHeaderName } from '../../../store/headerName/actions/action';
import { quoteMapping, quoteSchema } from '../../../lib/mapping/Form/quoteSchema';
import { Context } from '../../../contexts/GeneralContext';
import ObservationAndFilesComponent from '../ObservationAndFilesComponent';
import QuoteFooter from '../../../components/Footer/QuoteFooter';
import Tabs, { TabPane } from '../../../components/Tabs/Tabs';
import QuoteSuppliersTable from '../../../components/Table/QuoteSuppliersTable';
import AddQuoteSuppliersDrawer from '../../../components/Drawer/AddQuoteSuppliersDrawer';
import useCRUD from '../../../_Hooks/useCRUD';
import QuotationsMap from '../../../components/Table/QuotationsMap';
import SendQuoteToSupplierModal from '../../../components/Modal/SendQuoteToSupplierModal';
import TooltipIcon from '../../../components/Tooltip/TooltipIcon';
import useIsCustomer from '../../../_Hooks/useIsCustomer';
import ViewEntityInfo from '../../../components/Description/ViewEntityInfo';
import PurchaseApportionment from '../../../components/Tabs/PurchaseApportionment';
import useItemWithAppropriation from '../../../_Hooks/useItemWithAppropriation';
import AddressApportionment from '../../../components/Form/AddressApportionment';

const TitleText = ({ ...props }) => <Subtitle type="secondary" {...props} />;

const TooltipWrapperComponent = ({ showTooltip, children, ...props }) =>
  showTooltip ? <Tooltip {...props}>{children}</Tooltip> : children;

TooltipWrapperComponent.propTypes = {
  showTooltip: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
};

const Quotation = ({ isView }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { purchaseQuoteStatus = {} } = useSelector(state => state.setup.enums) || {};
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();
  const isCustomer = useIsCustomer();

  const [list, setList] = useState([]);
  const [addressFormValues, setAddressFormValues] = useState({});
  const [saveAddressToProject, setSaveAddressToProject] = useState(false);
  const [linkedSolicitation, setLinkedSolicitation] = useState('');
  const [files, setFiles] = useState([]);
  const [modalProps, setModalProps] = useState(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [isQuoteLoading, setIsQuoteLoading] = useState(true);
  const [isAddressLoading, setIsAddressLoading] = useState(true);
  const [code, setCode] = useState('');
  const [isDraft, setIsDraft] = useState(false);
  const [quoteStatus, setQuoteStatus] = useState();
  const [refreshTable, setRefreshTable] = useState(false);
  const [showSendToSuppliers, setShowSendToSuppliers] = useState(false);

  const [selectedTab, setSelectedTab] = useState('1');
  const [subtotal, setSubtotal] = useState(0);
  const [reloadStatus, setReloadStatus] = useState(false);

  const [showAddQuoteSuppliersDrawer, setShowAddQuoteSuppliersDrawer] = useState(false);

  const baseUrl = '/profissional/gestao-de-compras/cotacoes';

  const { foundParams, loading: loadingUrlParams, clearParams } = useUrlParams({
    urlParams: ['print', 'pedido', 'isSplit']
  });

  const { itemObjectsMap, itemSettersMap } = useItemWithAppropriation();

  const forceAddressFormUpdate = useRef(false);
  const printComponentRef = useRef();

  const {
    data: quote,
    loading,
    refurbish,
    idRefurbish,
    forceFormUpdate,
    canEditRefurbish,
    purchaseFormValues,
    modelGet: getQuote,
    setPurchaseFormValues,
    handleSave,
    validateSubmit,
    getCode,
    observation,
    setObservation,
    isSplit,
    ...appropriationProps
  } = usePurchase({
    model: 'quote',
    modelOptions: {
      include: [
        'purchaseStatus',
        'refurbish',
        'company',
        'responsible',
        'files',
        {
          model: 'purchase',
          attributes: ['id', 'code']
        },
        {
          model: 'quoteItems',
          include: [
            {
              model: 'unit',
              as: 'units'
            },
            'item',
            {
              model: 'quoteItemLevel',
              include: ['refurbishItem'],
              attributes: [
                'id',
                'name',
                'price',
                'quantity',
                'percentage',
                'idQuoteItem',
                'idRefurbishItem',
                'type',
                'idParent'
              ]
            }
          ]
        }
      ],
      order: [['quoteItems', 'id', 'desc']]
    },
    list,
    setList,
    baseUrl,
    itemObjectsMap,
    setAddressFormValues,
    forceAddressFormUpdate,
    setLinkedSolicitation,
    setModalProps,
    itemSettersMap
  });

  const { handleGet: refreshQuoteStatus } = useCRUD({
    model: `quote`,
    options: { attributes: ['idQuoteStatus'] },
    immediatelyLoadData: false
  });

  const { handleGet: getQuoteSuppliers } = useCRUD({
    model: `quoteSuppliers`,
    options: {
      include: [{ model: 'supplier', attributes: ['id', 'name', 'email'] }],
      attributes: ['id', 'idSupplier'],
      where: {
        [isSplit ? 'splitId' : 'idQuote']: id
      }
    },
    immediatelyLoadData: false
  });

  const isEditable = purchaseQuoteStatus?.editable?.includes(quote?.idQuoteStatus);
  const readOnly = isView || (id && !isEditable) || isCustomer;

  const isLoadingForPrint = loading || loadingUrlParams || isQuoteLoading || isAddressLoading;

  useEffect(() => {
    if (quote) {
      setAddressFormValues(prev => ({
        ...prev,
        ...getObjectWithSelectedFields(quote, [
          'zipcode',
          'street',
          'number',
          'complement',
          'state',
          'city',
          'neighborhood'
        ])
      }));
      forceAddressFormUpdate.current = true;

      setLinkedSolicitation(quote?.purchase?.code);
      setFiles(quote?.files || []);
      setObservation(quote?.observation || '');

      forceAddressFormUpdate.current = true;
      setIsQuoteLoading(false);
      setIsDraft(quote?.idQuoteStatus === purchaseQuoteStatus.draft);
      setQuoteStatus(quote?.purchaseStatus);
      if (quote?.idQuoteStatus !== purchaseQuoteStatus.draft && isView) setSelectedTab('3');
    }
  }, [quote]);

  useEffect(() => {
    if (reloadStatus) {
      refreshQuoteStatus({
        refetchPathOptions: `/${quote?.id}`,
        refetchOptions: {
          include: ['purchaseStatus']
        }
      }).then(newQuote => {
        if (newQuote?.error) return;
        setQuoteStatus(newQuote?.purchaseStatus);
      });
      setReloadStatus(false);
    }
  }, [reloadStatus]);

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    onAfterPrint: () => setIsPrinting(false)
  });

  useEffect(() => {
    if (quote) {
      setIsAddressLoading(false);
    }
  }, [addressFormValues]);

  useEffect(() => {
    if (foundParams?.print && !isLoadingForPrint) {
      setIsPrinting(true);
      clearParams('print');
    }
    if (foundParams?.pedido && !isLoadingForPrint) {
      getQuoteSuppliers().then(quoteSuppliers => {
        const supplierUniqueObjects = isSplit
          ? Array.from(new Map(quoteSuppliers.map(item => [item.idSupplier, item])).values())
          : quoteSuppliers;
        setShowSendToSuppliers(supplierUniqueObjects);
        clearParams('pedido');
      });
    }
  }, [foundParams?.print, isLoadingForPrint]);

  useEffect(() => {
    if (isPrinting) {
      appropriationProps.setOpenAllRefurbishes(true);
      appropriationProps.setTabView(appropriationProps.tabEnum.totals);
    }
  }, [isPrinting]);

  useEffect(() => {
    if (!appropriationProps.openAllRefurbishes && isPrinting) {
      handlePrint();
      appropriationProps.setTabView(appropriationProps.tabEnum.list);
    }
  }, [appropriationProps.openAllRefurbishes]);

  useEffect(() => {
    let _subtotal = 0;
    list?.forEach(i => {
      _subtotal += Number(i.quantity) * Number(i.price);
    });

    setSubtotal(Number(_subtotal?.toFixed(2) || 0));
  }, [list]);

  useEffect(() => {
    let title = 'Editar cotação';
    let pathName = ['/cotacoes/editar/'];

    if (!id) {
      title = 'Criar cotação';
      pathName = ['/cotacoes/novo/'];
      getCode().then(_code => setCode(_code));
    } else {
      getQuote().then(_quote => setCode(_quote?.code));
    }

    dispatch(
      saveHeaderName(isView ? { name: 'Detalhes', pathName: ['/cotacoes/visualizar/'] } : { name: title, pathName })
    );
  }, []);

  const addExtraFieldsToCallback = saveData => ({
    ...saveData,
    extraFields: {
      ...addressFormValues,
      saveAddressToProject,
      files,
      total: subtotal
    }
  });
  const { company } = quote || {};

  const Header = props => (
    <PurchaseHeader
      readOnly={readOnly}
      code={code}
      data={{ ...quote, purchaseStatus: quoteStatus, idQuoteStatus: quoteStatus?.id }}
      refurbish={refurbish}
      priority={purchaseFormValues?.priority}
      linkedSolicitation={linkedSolicitation}
      isCustomer={isCustomer}
      type="quote"
      {...props}
    />
  );

  if (loading) return <CenteredLoader />;

  if (isCustomer) {
    return (
      <Div overflow="auto" direction="column" align="left" justify="center" gap={spaces.space2} $fullHeight>
        {Header()}
        <Divider style={{ margin: 0 }} />
        <TitleText>Mapa de Cotação</TitleText>
        <QuotationsMap
          header={props => Header(props)}
          idQuote={id}
          quote={quote}
          readOnly={readOnly}
          isCustomer={isCustomer}
        />
      </Div>
    );
  }

  return (
    <>
      <Div direction="column" align="normal" justify="space-between" $fullHeight>
        <ComponentToPdf ref={printComponentRef}>
          <Div direction="column" align="initial" margin={`0 0 ${spaces.space2} 0`} $backgroundColor={colors.white}>
            {isPrinting && (
              <Div padding={isPrinting && spaces.space2} direction="column" align="flex-start">
                <Subtitle type="secondary" margin={`${spaces.space2} 0px 0px ${spaces.space2}`} $maxHeight="120px">
                  Cotação {code} - {isSplit ? quote.name : refurbish?.name || quote?.refurbish?.name}
                </Subtitle>
                <Divider style={{ margin: `${spaces.space2} 0 0` }} />

                {company && (
                  <Div $fullWidth gap={spaces.space2} margin={`${spaces.space3} 0px 0px ${spaces.space2}`}>
                    {company?.logo && (
                      <img style={{ width: '100px', height: '100px' }} alt={company?.name} src={company?.logo} />
                    )}
                    <ViewEntityInfo data={company} isPrinting={isPrinting} />
                  </Div>
                )}
              </Div>
            )}
            {isPrinting && <Divider style={{ margin: `${spaces.space2} 0 0` }} />}

            {!isPrinting && Header()}
            {(!readOnly || _isMobile) && <Divider style={{ margin: `${_isMobile ? spaces.space3 : 0} 0 0 0` }} />}

            {!readOnly && (
              <PurchaseForm
                forceFormUpdate={forceFormUpdate}
                canEditRefurbish={canEditRefurbish}
                purchaseFormValues={purchaseFormValues}
                setPurchaseFormValues={setPurchaseFormValues}
                schema={quoteSchema}
                mapping={quoteMapping}
                hasSupplier
                style={{ padding: _isMobile ? `${spaces.space1} ${spaces.space2} ${spaces.space2}` : spaces.space2 }}
              />
            )}

            {isPrinting ? (
              <PurchaseApportionment
                isPrinting={isPrinting}
                isView={isView}
                model="quote"
                itemObjectsMap={itemObjectsMap}
                itemSettersMap={itemSettersMap}
                isMobile={_isMobile}
                {...appropriationProps}
              />
            ) : (
              <Tabs activeKey={selectedTab} onChange={setSelectedTab} $tabsNavRelative>
                <TabPane key="1" tab="Dados">
                  {selectedTab === '1' && (
                    <PurchaseApportionment
                      isPrinting={isPrinting}
                      isView={isView}
                      model="quote"
                      itemObjectsMap={itemObjectsMap}
                      itemSettersMap={itemSettersMap}
                      isMobile={_isMobile}
                      {...appropriationProps}
                    />
                  )}
                </TabPane>

                <TabPane
                  disabled={isDraft || !quote?.id || !isView}
                  key="2"
                  tab={
                    <TooltipWrapperComponent
                      showTooltip={isDraft || !quote?.id || !isView}
                      title={
                        isDraft || !quote?.id
                          ? 'Fornecedores serão habilitados após preencher os dados da cotação e adicionar fornecedores'
                          : 'Salve a edição de Dados para acessar'
                      }
                    >
                      Fornecedores
                    </TooltipWrapperComponent>
                  }
                >
                  {selectedTab === '2' && (
                    <Div
                      direction="column"
                      align="flex-start"
                      padding={`${spaces.space3} ${spaces.space2} ${_isMobile ? spaces.space3 : 0}`}
                      gap={spaces.space1}
                    >
                      <Div gap={spaces.space1} margin={`0 0 ${spaces.space1}`}>
                        <Subtitle type="secondary">Fornecedores</Subtitle>
                        <TooltipIcon
                          text="Lista de fornecedores adicionados para o processo de cotação"
                          icon={faInfoCircle}
                          iconColor={colors.primary500}
                        />
                      </Div>
                      <Context data={{ refreshTable, setRefreshTable, idRefurbish }}>
                        <QuoteSuppliersTable
                          idQuote={quote?.id}
                          reload={() => {
                            setReloadStatus(true);
                          }}
                          splitId={quote?.splitId}
                        />
                      </Context>
                    </Div>
                  )}
                </TabPane>

                <TabPane
                  disabled={isDraft || !quote?.id || !isView}
                  tab={
                    <TooltipWrapperComponent
                      showTooltip={isDraft || !quote?.id || !isView}
                      title={
                        isDraft || !quote?.id
                          ? `Mapa de cotações será habilitado após
                          preencher os dados da cotação
                          e adicionar fornecedores`
                          : 'Salve a edição de Dados para acessar'
                      }
                    >
                      Mapa de cotações
                    </TooltipWrapperComponent>
                  }
                  key="3"
                >
                  {selectedTab === '3' && (
                    <Div padding={`${spaces.space2} ${spaces.space2} ${spaces.space1}`}>
                      <Context data={{ idRefurbish }}>
                        <QuotationsMap
                          idQuote={id}
                          quote={quote}
                          isCustomer={isCustomer}
                          header={props => Header(props)}
                          reload={(allQuote = false) => {
                            if (allQuote) getQuote();
                            else setReloadStatus(true);
                          }}
                        />
                      </Context>
                    </Div>
                  )}
                </TabPane>
              </Tabs>
            )}

            {(selectedTab === '1' || isPrinting) && (
              <>
                <Divider style={{ margin: `${isPrinting ? spaces.space2 : 0} 0 0` }} />
                <Context
                  data={{
                    addressFormValues,
                    saveAddressToProject,
                    setSaveAddressToProject,
                    setAddressFormValues,
                    forceAddressFormUpdate,
                    referObj: quote,
                    refurbish,
                    isPrinting,
                    readOnly
                  }}
                >
                  <AddressApportionment
                    listRefurbish={appropriationProps.listRefurbish}
                    refurbishesAddress={appropriationProps.refurbishesAddress}
                    setRefurbishesAddress={appropriationProps.setRefurbishesAddress}
                    tooltipText={`Ao salvar a cotação, o endereço cadastrado no projeto
                      será atualizado para o endereço de entrega informado`}
                    forceAddressFormUpdate={forceAddressFormUpdate}
                    referObj={quote}
                    isPrinting={isPrinting}
                    readOnly={readOnly}
                  />
                </Context>
                {(!isPrinting || observation) && (
                  <>
                    <Divider style={{ margin: `${isPrinting ? spaces.space2 : 0} 0 0` }} />
                    <Context
                      data={{
                        files,
                        setFiles,
                        observation,
                        setObservation,
                        readOnly
                      }}
                    >
                      <ObservationAndFilesComponent />
                    </Context>
                  </>
                )}
              </>
            )}
          </Div>
        </ComponentToPdf>
        <Context
          data={{
            data: { ...quote, purchaseStatus: quoteStatus, idQuoteStatus: quoteStatus?.id },
            isEditable,
            handleSave: saveData => handleSave(addExtraFieldsToCallback(saveData)),
            isDraft,
            isView,
            validateSubmit: saveData => validateSubmit(addExtraFieldsToCallback(saveData)),
            handleExportPdf: () => setIsPrinting(true),
            handleSaveAndAddSuppliers: () => setShowAddQuoteSuppliersDrawer(true),
            purchaseQuoteStatus,
            isMobile: _isMobile,
            queryString: buildQueryString([
              idRefurbish && { key: 'projeto', value: idRefurbish },
              isSplit && { key: 'isSplit', value: true }
            ]),
            reload: () => {
              setRefreshTable(true);
              setReloadStatus(true);
            }
          }}
        >
          <QuoteFooter />
        </Context>
      </Div>
      {showAddQuoteSuppliersDrawer && (
        <AddQuoteSuppliersDrawer
          idQuote={quote?.id}
          onClose={() => setShowAddQuoteSuppliersDrawer(false)}
          createOrUpdateQuote={() =>
            handleSave(addExtraFieldsToCallback({ returning: true, status: purchaseQuoteStatus.open }))
          }
          isDraft={isDraft}
          splitId={quote?.splitId}
        />
      )}
      {showSendToSuppliers && (
        <SendQuoteToSupplierModal
          onClose={() => {
            getQuote();
            setShowSendToSuppliers(false);
          }}
          quoteSupplierList={showSendToSuppliers}
          idQuote={quote?.splitId || quote?.id}
          isSplit={!!quote?.splitId}
        />
      )}
      {modalProps?.show && (
        <ConfirmModal
          open
          title={`${id ? 'Salvar alterações' : 'Criar Cotação'} `}
          alertInfo={
            modalProps.text && (
              <ConfirmModalAlertInfoBuilder alertText={modalProps.text} afterAlertList={modalProps.list} />
            )
          }
          alertInfoPadding={spaces.space1}
          onClose={() => setModalProps(null)}
          onSubmit={() => (modalProps?.callback ? modalProps.callback() : setModalProps(null))}
        />
      )}
    </>
  );
};

Quotation.propTypes = {
  isView: PropTypes.bool
};

export default Quotation;
