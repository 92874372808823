import React from 'react';
import * as Yup from 'yup';
import { Divider } from 'antd';
import { Subtitle } from '../../../components/Text/Text';
import { spaces } from '../../../styles/style';
import { name, requiredCnpj, requiredEmail, phone, requiredAddress, idReference } from '../schema';

const { zipcode, street, state, number } = requiredAddress;

const companyEntitySchema = Yup.object().shape({
  cnpj: requiredCnpj,
  legalName: name,
  name,
  crt: idReference,
  email: requiredEmail,
  phone,
  zipcode,
  street,
  neighborhood: Yup.string()
    .nullable()
    .required('Campo obrigatório'),
  number,
  state,
  cityCode: idReference
});

const companyEntityMapping = (id, isMobile) => ({ state: _state }) => {
  return {
    companyDataTitle: {
      type: 'custom',
      Component: () => <Subtitle>Dados do negócio</Subtitle>,
      xs: 24,
      md: 24
    },
    cnpj: {
      name: `CNPJ`,
      type: 'cnpj',
      required: true,
      disabled: !!id,
      placeholder: 'Ex: 00.000.000/0001-00',
      xs: 24,
      md: 12
    },
    legalName: {
      name: 'Razão Social',
      type: 'text',
      required: true,
      placeholder: 'Razão Social',
      xs: 24,
      md: 12
    },
    name: {
      name: 'Nome fantasia',
      type: 'text',
      required: true,
      placeholder: 'Nome fantasia',
      xs: 24,
      md: 12
    },
    crt: {
      name: 'Código de Regime Tributário',
      type: 'select',
      required: true,
      placeholder: 'Selecione',
      dataType: 'crtList',
      xs: 24,
      md: 12
    },
    email: {
      name: 'E-mail',
      required: true,
      type: 'text',
      placeholder: 'Ex: empresa@empresa.com.br',
      xs: 24,
      md: 12
    },
    phone: {
      name: 'Telefone',
      type: 'phone',
      placeholder: '(00) 0000-0000',
      xs: 24,
      md: 12
    },
    ...(!isMobile
      ? {
          divider: {
            type: 'custom',
            Component: () => <Divider style={{ margin: `${spaces.space1} 0` }} />,
            xs: 24,
            md: 24
          }
        }
      : {}),
    addressTitle: {
      type: 'custom',
      Component: () => <Subtitle>Endereço</Subtitle>,
      xs: 24,
      md: 24
    },
    zipcode: {
      name: 'CEP',
      type: 'zipCode',
      required: true,
      placeholder: 'Informe o CEP',
      xs: 24,
      md: 12
    },
    street: {
      name: 'Rua',
      type: 'text',
      required: true,
      placeholder: 'Rua',
      xs: 18,
      md: 12
    },
    number: {
      name: 'Número',
      type: 'text',
      required: true,
      placeholder: 'Número',
      xs: 6,
      md: 12
    },
    complement: {
      name: 'Complemento',
      type: 'text',
      placeholder: 'Complemento',
      xs: 24,
      md: 12
    },
    neighborhood: {
      name: 'Bairro',
      type: 'text',
      required: true,
      placeholder: 'Bairro',
      xs: 24,
      md: 12
    },
    state: {
      name: 'Estado',
      dataType: 'states',
      type: 'select',
      required: true,
      placeholder: 'Estado',
      xs: 24,
      md: 12
    },
    cityCode: {
      name: 'Município',
      type: 'select',
      required: true,
      model: 'city',
      modelOptions: { where: { state: _state } },
      prevValidateFields: ['state'],
      placeholder: 'Município',
      disabled: !_state,
      parent: { name: 'state' },
      xs: 24,
      md: 12
    }
  };
};

export { companyEntityMapping, companyEntitySchema };
