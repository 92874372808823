import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { checkApprovalAuthority } from 'vobi_lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEye, faFilePdf } from '@fortawesome/pro-regular-svg-icons';
import Button from '../Button/Button';
import Dropdown from './DropdownV2';
import { DropdownContainer, StyledItemMenu, StyledMenu, SubMenu } from './DropdownV2.styled';
import useCRUD from '../../_Hooks/useCRUD';
import useViewport from '../../_Hooks/useViewport';
import { colors } from '../../styles/style';
import SendQuoteToSupplierModal from '../Modal/SendQuoteToSupplierModal';
import useIsCustomer from '../../_Hooks/useIsCustomer';
import SpreadsheetImport from '../DataImport/SpreadsheetImport';
import { useContextHook as useRefurbish } from '../../contexts/GeneralContext';
import ImportInstructionModal from '../Modal/ImportInstructionsModal';
import { hasPermission } from '../../routes/Common/PrivateRoute';
import { allDropdown } from '../../lib/mapping/Dropdown/allDropdown';
import DropDownButton from '../Button/DropDownButton';

const QuoteSupplierActionDropdown = ({
  quoteSupplier,
  handleLoad,
  handleOpenEditQuoteSupplierDrawer,
  children,
  isDrawer,
  handlePrint,
  handleApproveOrReject,
  isPublicRoute
}) => {
  const { status: oldStatus, idQuote, id, supplier, approved: isApproved, splitId } = quoteSupplier || {};

  const { idRefurbish } = useRefurbish();
  const { quoteSupplierMoveStatus, quoteSupplierStatus } = useSelector(state => state.setup.enums);
  const [openImportModel, setOpenImportModel] = useState(false);
  const [showModal, setShowModal] = useState();
  const { user } = useSelector(state => state.authReducer);
  const { plans, permissions } = useSelector(state => state.setup);

  const hasPurchaseOrderPermission = hasPermission(user, ['purchaseOrder'], plans, permissions);

  const history = useHistory();

  const { isMobile: isMobileFn } = useViewport(window.innerWidth);
  const isMobile = isMobileFn();
  const isCustomer = useIsCustomer();

  const { handleDelete, handleUpdate } = useCRUD({
    model: 'quoteSuppliers',
    immediatelyLoadData: false
  });

  const actions = {
    put: handleUpdate,
    delete: handleDelete
  };

  const deleteQuoteSupplier = () => {
    handleDelete({ id, refresh: false, displayToast: true }).then(() => handleLoad({ close: true }));
  };

  const handleCrudClick = (verb, params, pathOptions) => {
    return actions[verb]({
      ...params,
      refresh: false,
      displayToast: 'Operação realizada com sucesso!',
      ...(pathOptions
        ? { updatePathOptions: pathOptions }
        : {
            id: quoteSupplier?.id
          })
    }).then(resp => {
      if (!resp?.error) {
        setShowModal();
        handleLoad?.({ status: resp?.status });
      }
    });
  };

  const handleClick = ({ modal, params, redirectTo }) => {
    if (redirectTo) history.push(redirectTo);
    else {
      setShowModal({ modal, params });
    }
  };

  const custom = {
    seeDetails: (
      <Button onClick={() => handleOpenEditQuoteSupplierDrawer()} text>
        <FontAwesomeIcon icon={faEye} color={colors.neutral400} />
        Ver detalhes
      </Button>
    ),
    exportPdf: (
      <Button onClick={() => handlePrint()} text>
        <FontAwesomeIcon icon={faFilePdf} color={colors.neutral400} />
        Exportar resposta em PDF
      </Button>
    )
  };

  const modal = {
    sendQuote: () => (
      <SendQuoteToSupplierModal
        idQuoteSupplier={id}
        onClose={() => setShowModal()}
        name={supplier.name}
        email={supplier.email}
        idQuote={splitId || idQuote}
        isSplit={!!splitId}
      />
    ),
    importInstruction: () => (
      <ImportInstructionModal
        model={showModal?.params?.model}
        onClose={() => setShowModal()}
        goNext={() => {
          setOpenImportModel(showModal?.params?.model);
          setShowModal();
        }}
      />
    )
  };

  let menu;

  if (isCustomer) {
    menu = (
      <StyledMenu $alignLeftOptions>
        {quoteSupplierStatus.canApprove.includes(oldStatus) && (
          <>
            {!isApproved && !isPublicRoute && (
              <Menu.Item key="quote-suppliers-approve">
                <Button onClick={() => handleApproveOrReject(true)} text>
                  Aprovar
                </Button>
              </Menu.Item>
            )}
            {isApproved && !isPublicRoute && (
              <Menu.Item key="quote-suppliers-reject">
                <Button onClick={() => handleApproveOrReject(false)} text>
                  Reprovar
                </Button>
              </Menu.Item>
            )}
          </>
        )}
      </StyledMenu>
    );
  } else {
    menu = (
      <StyledMenu $alignLeftOptions $minWidth="150px">
        {allDropdown
          .quoteSupplierAction({
            data: quoteSupplier,
            quoteSupplierStatus,
            hasPurchaseOrderPermission,
            idRefurbish,
            isDrawer,
            isMobile
          })
          ?.map(option => {
            const childrenOption = option.children;
            return childrenOption?.length ? (
              <SubMenu
                key={`submenu-item-${option.id}`}
                title={<DropDownButton optionData={option} $fullWidth />}
                id={`submenu-item-${option.id}`}
              >
                {childrenOption?.map(child => {
                  const canMove = checkApprovalAuthority({
                    oldStatus,
                    newStatus: child.value,
                    user,
                    moveStatus: quoteSupplierMoveStatus
                  });
                  const isSelected = oldStatus === child.value;
                  const isDisabled = !canMove && !isSelected;

                  const toolTipText =
                    isDisabled && !canMove && 'A partir do status atual não é possível alterar para esse status.';

                  return (
                    <StyledItemMenu key={`menu-item-${child.id}`} selected={isSelected}>
                      <Button
                        id={child.id}
                        text
                        onClick={() => {
                          if (isSelected) return null;
                          return child.verb && !child.modal
                            ? handleCrudClick(child.verb, child.params, child.pathOptions)
                            : handleClick(child);
                        }}
                        disabled={isDisabled}
                        fullWidth
                        $justifyContent="space-between"
                        tooltipText={toolTipText}
                        tooltipPlacement="left"
                      >
                        {child.label}
                        {isSelected && <FontAwesomeIcon icon={faCheck} color={colors.primary500} />}
                      </Button>
                    </StyledItemMenu>
                  );
                })}
              </SubMenu>
            ) : (
              <React.Fragment key={`menu-${option.id}`}>
                <Menu.Item key={`menu-item-${option.id}`}>
                  {custom[option.button] || (
                    <DropDownButton
                      optionData={option}
                      id={option.id}
                      text
                      onClick={() => {
                        if (option?.id === 'delete') return deleteQuoteSupplier();
                        return option.verb && !option.modal
                          ? handleCrudClick(option.verb, option.params, option.pathOptions)
                          : handleClick(option);
                      }}
                    />
                  )}
                </Menu.Item>
              </React.Fragment>
            );
          })}
      </StyledMenu>
    );
  }
  return (
    <div
      role="presentation"
      onClick={e => e.stopPropagation()}
      id={`dropdown-quoteSupplier-${quoteSupplier?.supplier?.name || 'drawer'}`}
    >
      <DropdownContainer mobileDirection="column">
        <Dropdown slim trigger={['click']} menu={menu} padding={0}>
          {children}
        </Dropdown>
      </DropdownContainer>
      {showModal && modal[showModal.modal] ? modal[showModal.modal](showModal.params) : null}

      <SpreadsheetImport
        model="quoteSuppliers"
        isOpen={openImportModel === 'quoteSuppliers'}
        onClose={() => {
          setOpenImportModel(false);
        }}
        body={{ idQuoteSupplier: id, idQuote }}
        onLoad={handleLoad}
        showToastError={false}
        toKebabCase={false}
        canNotify={false}
      />
      <SpreadsheetImport
        model="quoteItemSuppliers"
        isOpen={openImportModel === 'quoteItemSuppliers'}
        onClose={() => {
          setOpenImportModel(false);
        }}
        body={{ idQuoteSupplier: id, idQuote }}
        onLoad={handleLoad}
        showToastError={false}
        toKebabCase={false}
        canNotify={false}
      />
    </div>
  );
};

QuoteSupplierActionDropdown.propTypes = {
  quoteSupplier: PropTypes.instanceOf(Object),
  handleLoad: PropTypes.func,
  handleOpenEditQuoteSupplierDrawer: PropTypes.func,
  children: PropTypes.instanceOf(Object),
  isDrawer: PropTypes.bool,
  handlePrint: PropTypes.func,
  handleApproveOrReject: PropTypes.func,
  isPublicRoute: PropTypes.bool
};

export default QuoteSupplierActionDropdown;
