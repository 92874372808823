import React from 'react';
import {
  faFilePlus,
  faFileArrowUp,
  faRectangleVerticalHistory,
  faFileArrowDown,
  faFileInvoiceDollar,
  faMoneyCheckDollarPen,
  faCartShopping,
  faMessagesDollar
} from '@fortawesome/pro-solid-svg-icons';
import { Paragraph } from '../../../components/Text/Text';

const emptyStateMapping = {
  workReport: ({ redirect }) => ({
    title: 'Diário de obra',
    subTitle: 'Registre o progresso da obra através de fotos e acompanhe em tempo real seu canteiro de obras.',
    doubtsText: `Se você quer saber mais sobre como funciona cada etapa ou tem dúvidas pertinentes
      a algum tipo de projeto, saiba mais.`,
    actions: [
      {
        subtitleAction: 'Começar do zero',
        actionText: 'Crie um novo relatório.',
        icon: faFilePlus,
        onClick: redirect
      }
    ]
  }),
  payment: ({ redirect }) => ({
    title: 'Pagamentos',
    subTitle: `Registre todos os pagamentos do seu cliente aqui. Receba lembretes semanais,
        gerencie cobranças do cliente para o seu negócio ou os pagamentos do cliente para outros fornecedores.`,
    doubtsText: 'Se você quer saber mais sobre como funcionam os pagamentos ou ainda está com dúvidas, saiba mais.',
    actions: [
      {
        subtitleAction: 'Começar agora',
        actionText: 'Crie um novo pagamento.',
        icon: faFilePlus,
        onClick: redirect
      }
    ]
  }),

  financialCategory: ({ redirect }) => ({
    title: 'Categorias',
    subTitle: `Crie categorias e subcategorias de acordo com seu negócio.
    As categorias são refletidas nos relatórios financeiros.`,
    doubtsText: `Se você quer saber mais sobre como funcionam as categorias ou ainda tem alguma dúvida,
        clique em saiba mais.`,
    actions: [
      {
        subtitleAction: 'Começar agora',
        actionText: 'Crie uma nova categoria.',
        icon: faFileInvoiceDollar,
        onClick: redirect
      }
    ]
  }),
  specification: ({ newSpecificationOnClick, templateOnClick, importOnClick, isMobile }) => ({
    model: 'specification',
    title: 'Novo orçamento',
    subTitle: `Crie ou importe um orçamento e tenha acesso aos itens, fornecedores,
        grupos, categorias, valores, quantidades, unidades e muito mais!`,
    doubtsText: `Se você quer saber mais sobre como funciona o orçamento
        ou ainda tem alguma dúvida, clique em saiba mais.`,
    mobileText: 'Para começar a desenvolver o seu orçamento, acesse a plataforma no computador.',
    actions: [
      {
        subtitleAction: 'Começar do zero',
        actionText: 'Começar orçamento em branco.',
        icon: faFilePlus,
        onClick: newSpecificationOnClick
      },
      {
        subtitleAction: 'Utilizar modelo',
        actionText: ' Acelerar seu trabalho a partir de um modelo pronto.',
        icon: faRectangleVerticalHistory,
        onClick: templateOnClick
      },
      {
        subtitleAction: 'Importar',
        actionText: 'Importar um orçamento do Excel.',
        icon: faFileArrowUp,
        onClick: importOnClick,
        openBeforeModal: true,
        ...(isMobile && { hideMobile: isMobile })
      }
    ]
  }),
  planning: ({ redirect }) => ({
    title: 'Planejamento de obra',
    subTitle: `Com o seu cronograma de obra configurado, você terá visibilidade e controle sobre o andamento
     do seu projeto. As etapas e itens do seu orçamento são refletidas aqui.`,
    doubtsText: `Se você quer saber mais sobre como funcionam o cronograma de obra
      ou ainda está com dúvidas, clique em saiba mais.`,
    actions: [
      {
        subtitleAction: 'Começar agora',
        actionText: 'Configure o seu orçamento para começar.',
        icon: faFilePlus,
        onClick: redirect
      }
    ]
  }),
  measurement: ({ redirect, configureSpecification }) => ({
    title: 'Medições',
    subTitle: configureSpecification
      ? `Registre as medições e controle o andamento da obra.
      Para registrar medições primeiro é necessário configurar itens no seu orçamento.`
      : `Registre as medições e controle o andamento da obra.
      Tenha um acompanhamento completo do avanço físico e financeiro do seu projeto.`,
    doubtsText: `Se você quer saber mais sobre como funcionam o cronograma de obra
      ou ainda está com dúvidas, clique em saiba mais.`,
    actions: [
      {
        id: 'add-measurement',
        subtitleAction: configureSpecification ? 'Configurar orçamento' : 'Nova medição',
        actionText: configureSpecification
          ? 'Configure o seu orçamento para começar'
          : 'Crie a sua primeira medição para começar',
        icon: faFilePlus,
        onClick: () => redirect(configureSpecification)
      }
    ]
  }),
  conciliation: ({ importOnClick, importOfx, modelImport }) => ({
    title: 'Você não possui lançamentos pendentes para conciliar',
    subTitle: `Garanta que todas as informações na Vobi estão atualizadas conforme o seu fluxo financeiro.
      Baixe o extrato bancário da sua conta bancária para começar`,
    doubtsText: `Quer aprender tudo sobre conciliação ou precisa de ajuda para começar? Clique em saiba mais.`,
    actions: [
      {
        subtitleAction: 'Importar OFX',
        actionText: 'Busque o OFX no seu banco digital. É a forma mais segura de conciliar.',
        icon: faFilePlus,
        onClick: importOfx
      },
      {
        subtitleAction: 'Importar planilha',
        actionText: 'Monte a planilha e importe os dados financeiros que desejar.',
        icon: faRectangleVerticalHistory,
        onClick: importOnClick
      },
      {
        subtitleAction: 'Baixar modelo',
        actionText: 'Acesse o modelo em Excel para importação via Planilha.',
        icon: faFileArrowDown,
        onClick: modelImport
      }
    ]
  }),
  purchase: ({ redirect }) => ({
    title: 'Solicitações de compra',
    subTitle: `Crie uma solicitação de compra para solicitar itens que devem ser comprados para sua obra.`,
    doubtsText: `Se você quer saber mais sobre como funciona a gestão de compras ou ainda tem alguma dúvida,
      clique em saiba mais.`,
    actions: [
      {
        id: 'add-purchase-order',
        subtitleAction: 'Nova solicitação',
        actionText: 'Crie um nova solicitação de compra.',
        icon: faFilePlus,
        onClick: redirect
      }
    ]
  }),
  order: ({ redirect }) => ({
    title: 'Ordens de compra',
    subTitle: `Crie uma ordem de compra para obter os itens que devem ser comprados para sua obra.`,
    doubtsText: `Se você quer saber mais sobre como funciona a gestão de compras ou ainda tem alguma dúvida,
      clique em saiba mais.`,
    actions: [
      {
        id: 'add-purchase-order',
        subtitleAction: 'Nova ordem',
        actionText: 'Crie um nova ordem de compra.',
        icon: faFilePlus,
        onClick: redirect
      }
    ]
  }),
  budgetedAccomplished: ({ redirect }) => ({
    title: 'Orçado x Realizado',
    subTitle: `Para ver o relatório de Orçado x Realizado, preencha os níveis do orçamento para visualizar
    dados orçados e registre os pagamentos para acompanhar o que foi efetivamente realizado.`,
    doubtsText: `Se você quer saber mais sobre como funciona o relatório
    Orçado x Realizado ou ainda tem alguma dúvida, clique em saiba mais.`,
    actions: [
      {
        id: 'add-specification',
        subtitleAction: 'Orçamento',
        actionText: 'Crie orçamentos para seu projeto',
        icon: faFilePlus,
        onClick: redirect
      }
    ]
  }),
  purchaseOverview: ({ newSpecificationClick, newSolicitationClick, newOrderClick }) => ({
    title: 'Panorama de compras',
    subTitle: `Crie um novo orçamento, solicite uma nova compra, crie uma nova cotação ou adicione uma nova ordem
     de compra para começar a utilizar o Panorama.`,
    doubtsText: `Se você quer saber mais sobre como funciona a gestão de compras ou ainda tem alguma dúvida,
      clique em saiba mais.`,
    actions: [
      {
        subtitleAction: 'Novo orçamento',
        actionText: 'Crie orçamentos para seu projeto',
        icon: faFileInvoiceDollar,
        onClick: newSpecificationClick
      },
      {
        subtitleAction: 'Nova solicitação',
        actionText: 'Solicite itens para compra',
        icon: faMoneyCheckDollarPen,
        onClick: newSolicitationClick
      },
      {
        subtitleAction: 'Nova compra',
        actionText: 'Crie ordens de compra',
        icon: faCartShopping,
        onClick: newOrderClick
      }
    ]
  }),
  'report-common': ({ redirect, isSCurve }) => {
    const title = isSCurve ? 'Curva S' : 'Cronograma Físico-Financeiro';
    const subTitle = isSCurve
      ? `Com o seu relatório de Curva S, você terá visibilidade e controle sobre o
      andamento da sua obra e previsão do desembolso esperado para cada período.`
      : `Com o seu cronograma físico-financeiro configurado, você terá visibilidade e
      controle sobre o andamento do seu projeto. As etapas e itens do seu orçamento são refletidas aqui. `;
    return {
      title,
      subTitle,
      doubtsText: `Se você quer saber mais sobre como funciona ${
        isSCurve ? 'a Curva S' : 'o cronograma físico-financeiro'
      }  ou ainda está com dúvidas,
    clique em saiba mais.`,
      actions: [
        {
          id: 'add-specification',
          subtitleAction: 'Começar agora',
          actionText: 'Configure o orçamento e planejamento do seu Projeto para começar',
          icon: faFilePlus,
          onClick: redirect
        }
      ]
    };
  },

  'report-payment': ({ redirect, isExpense, isSCurve }) => {
    const title = isSCurve ? 'Curva S' : 'Cronograma Físico-Financeiro';
    const subTitle = `Você não possui lançamentos financeiros cadastrados nesse projeto.
    Para visualizar o relatório de desembolso financeiro realizado, cadastre ${
      isExpense ? 'uma despesa' : 'um pagamento'
    }.`;
    return {
      title,
      subTitle,
      doubtsText: `Se você quer saber mais sobre como funciona ${
        isSCurve ? 'a Curva S' : 'o cronograma físico-financeiro'
      }  ou ainda está com dúvidas,
    clique em saiba mais.`,
      actions: [
        {
          id: 'add-payment',
          subtitleAction: isExpense ? 'Nova despesa' : 'Novo pagamento',
          actionText: isExpense
            ? 'Cadastre as despesas para visualizar o relatório de desembolso financeiro.'
            : 'Cadastre os pagamentos para visualizar o relatório de desembolso financeiro.',
          icon: faFilePlus,
          onClick: redirect
        }
      ]
    };
  },

  'report-expense': ({ redirect, isSCurve }) =>
    emptyStateMapping['report-payment']({ redirect, isExpense: true, isSCurve }),

  'report-measurement': ({ redirect, isSCurve }) => {
    const title = isSCurve ? 'Curva S' : 'Cronograma Físico-Financeiro';
    const subTitle = `Você não possui nenhuma medição cadastrada nesse projeto. Para visualizar o relatório de
    avanço físico realizado, cadastre as medições.`;
    return {
      title,
      subTitle,
      doubtsText: `Se você quer saber mais sobre como funciona ${
        isSCurve ? 'a Curva S' : 'o cronograma físico-financeiro'
      }  ou ainda está com dúvidas,
    clique em saiba mais.`,
      actions: [
        {
          id: 'add-measurement',
          subtitleAction: 'Nova medição',
          actionText: 'Cadastre as medições de avanço físico da obra para visualizar o relatório.',
          icon: faFilePlus,
          onClick: redirect
        }
      ]
    };
  },

  quote: ({ redirect }) => ({
    title: 'Cotações',
    subTitle:
      'Crie uma nova Cotação para efetuar pedidos e comparar propostas de diferentes fornecedores no Mapa de Cotação.',
    doubtsText: `Se você quer saber mais sobre como funciona a gestão de cotações ou ainda tem alguma dúvida,
    clique em saiba mais.`,
    actions: [
      {
        id: 'add-quotation',
        subtitleAction: 'Nova cotação',
        actionText: 'Realize pedidos de cotações',
        icon: faMessagesDollar,
        onClick: redirect
      }
    ]
  }),

  dre: ({ redirect }) => ({
    title: 'DRE Gerencial',
    CustomSubtitle: (
      <Paragraph type="small" align="center">
        Para visualizar o DRE de acordo com a realidade da sua empresa, <b>ajuste as categorias</b> do seu financeiro.
      </Paragraph>
    ),
    doubtsText: `Se você quer saber mais sobre como funciona o DRE Gerencial ou ainda tem alguma dúvida,
    clique em saiba mais.`,
    actions: [
      {
        id: 'go-to-categories',
        subtitleAction: 'Ir para categorias',
        actionText: 'Configure suas categorias para visualizar o DRE.',
        icon: faFileInvoiceDollar,
        onClick: redirect
      }
    ]
  }),
  invoice: ({ redirect }) => ({
    title: 'Cadastro de nova empresa',
    subTitle: `Informe os dados da sua empresa para habilitar a emissão e busca automática de notas fiscais.`,
    doubtsText: `Se você quer saber como funciona o módulo de notas fiscais ou ainda tem alguma dúvida,
    clique em saiba mais.`,
    actions: [
      {
        id: 'add-company-entity',
        subtitleAction: 'Nova empresa',
        icon: faFilePlus,
        onClick: redirect
      }
    ]
  })
};

export default emptyStateMapping;
