import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Drawer from './Drawer';
import { colors, Div, fonts, spaces } from '../../styles/style';
import InvoicePage from '../../_Pages/Payments/invoicePage';
import { Subtitle } from '../Text/Text';
import { RightHeaderContent } from '../../_Pages/Payments/Payment.style';
import Button from '../Button/Button';
import ActionButtonDropdown from '../Dropdown/ActionButtonDropdown';
import { downloadFile } from '../../lib/helpers/helper';
import CancelNfseModal from '../Modal/CancelNfseModal';

const InvoiceDrawer = ({ invoiceSelected, setInvoiceSelected }) => {
  const { typeNf, nfseStatus, invoiceStatus } = useSelector(state => state.setup.enums);

  const [cancelModal, setCancelModal] = useState(false);

  const { id, type, situationCode, file, fileXml, idFile, idFileXml, accessKey, payment } = invoiceSelected || {};

  const isNfse = id && type === typeNf.nfse;
  const status = nfseStatus[situationCode] || nfseStatus[invoiceStatus.authorized];

  const redirectPayment = () => {
    const { isCharge } = payment || {};
    const incomeUrl = `${window.location.origin}/r/editar/${payment?.id}`;
    const paymentUrl = `${window.location.origin}/pg/visualizar/${payment?.id}`;
    window.open(isCharge ? paymentUrl : incomeUrl, '_blank');
  };

  return (
    <>
      <Drawer
        padding="0px"
        width={992}
        onClose={() => setInvoiceSelected(null)}
        headerStyle={{ fontSize: fonts.sizeLg, fontWeight: fonts.weight600, color: colors.neutral600 }}
        open
        extraContentOnRight
        footer={
          <>
            <Button id="return-list" text className="withMarginRight" onClick={() => setInvoiceSelected(null)}>
              Fechar
            </Button>
            <Div gap={spaces.space1}>
              {isNfse && situationCode === 100 ? (
                <Button
                  text
                  className="withMarginRight"
                  id="delete-invoice"
                  color="red500"
                  onClick={() => setCancelModal(true)}
                >
                  Cancelar nota fiscal
                </Button>
              ) : null}
              {(idFile || idFileXml || payment?.id) && (
                <div>
                  <ActionButtonDropdown
                    showMobile
                    buttonProps={{ id: 'action-button-invoice' }}
                    menu={
                      <div style={{ padding: spaces.space1 }}>
                        {idFile ? (
                          <Link to={{ pathname: file?.fullpath }} target="_blank">
                            <Button id="download-pdf" text>
                              Exportar PDF
                            </Button>
                          </Link>
                        ) : null}

                        {idFileXml ? (
                          <Button
                            id="download-xml"
                            onClick={() => downloadFile(fileXml?.fullpath, fileXml?.filename)}
                            text
                          >
                            Exportar XML
                          </Button>
                        ) : null}
                        {payment?.id ? (
                          <Button id="view-payment" onClick={() => redirectPayment()} text>
                            Ver pagamento
                          </Button>
                        ) : null}
                      </div>
                    }
                  />
                </div>
              )}
            </Div>
          </>
        }
        title={
          <>
            <Subtitle id="title-drawer" size={fonts.sizeLg}>
              Detalhes da Nota fiscal{' '}
            </Subtitle>
            {isNfse ? (
              <span style={{ color: colors.neutral400, fontSize: fonts.sizeSm }} id="payment-id">
                #{id}
              </span>
            ) : null}
          </>
        }
        headerExtraContent={() => {
          return isNfse ? (
            <RightHeaderContent>
              <Subtitle size={fonts.sizeSm}>
                Status:{' '}
                <span style={{ color: status?.color }} id="status">
                  {status?.label}
                </span>
              </Subtitle>
            </RightHeaderContent>
          ) : null;
        }}
        subtitle={`Chave de acesso: ${accessKey || ''}`}
        showSubmit={false}
      >
        <InvoicePage id={!invoiceSelected.new ? id : null} initialData={invoiceSelected} />
      </Drawer>
      {cancelModal && <CancelNfseModal onClose={() => setCancelModal(false)} id={id} />}
    </>
  );
};

InvoiceDrawer.propTypes = {
  invoiceSelected: PropTypes.instanceOf(Object),
  setInvoiceSelected: PropTypes.func
};

export default InvoiceDrawer;
