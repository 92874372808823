import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Drawer from './Drawer';
import FormV2 from '../Form/FormV2';
import { serviceProfileMapping, serviceProfileSchema } from '../../lib/mapping/Form/serviceProfileSchema';
import useCRUD from '../../_Hooks/useCRUD';
import CenteredLoader from '../Loader/CenteredLoader';
import { spaces } from '../../styles/style';
import ConfirmModal from '../Modal/ConfirmModal';

const ServiceProfileDrawer = ({ id, idCompanyEntity, cnpj, name, open, onClose }) => {
  const [showChangedModal, setShowChangedModal] = useState(false);
  const [touched, setTouched] = useState(false);
  const { data, handleCreate, handleUpdate, loading } = useCRUD({
    model: 'serviceProfile',
    pathOptions: id ? `/${id}` : '',
    immediatelyLoadData: !!id
  });

  const handleSubmit = submitData => {
    const func = id ? handleUpdate : handleCreate;
    func({
      values: submitData,
      refresh: false,
      displayToast: `Perfil de serviço ${id ? 'atualizado' : 'criado'} com sucesso!`
    }).then(resp => {
      if (resp?.error) return null;
      return onClose(true);
    });
  };

  return (
    <>
      <Drawer
        title={id ? 'Editar perfil de serviço' : 'Cadastrar novo perfil de serviço'}
        width={800}
        padding={`${spaces.space3} ${spaces.space2}`}
        formId="service-profile"
        open={open}
        onClose={() => {
          if (touched) setShowChangedModal(true);
          else onClose(false);
        }}
      >
        {loading ? (
          <CenteredLoader />
        ) : (
          <FormV2
            id="service-profile"
            schema={serviceProfileSchema}
            mapping={serviceProfileMapping({ cnpj })}
            data={{ ...data, ...(name ? { name } : {}), idCompanyEntity }}
            displayButtons={false}
            columnStyle
            onSubmit={handleSubmit}
            onTouchedChanged={values => {
              if (values) setTouched(prev => ({ ...prev, ...values }));
            }}
          />
        )}
      </Drawer>
      {showChangedModal && (
        <ConfirmModal
          title="Alterações não salvas"
          text={`Você possui alterações que não foram salvas.
            Continue ou descarte as alterações para continuar.`}
          alertInfo="Essa ação não poderá ser desfeita."
          submitText="Continuar"
          showDiscardButton
          onSubmit={() => setShowChangedModal(false)}
          onClose={(_, opts) => {
            setShowChangedModal(false);
            if (opts?.discard) onClose(false);
          }}
        />
      )}
    </>
  );
};

ServiceProfileDrawer.propTypes = {
  id: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  idCompanyEntity: PropTypes.string,
  cnpj: PropTypes.string,
  name: PropTypes.string
};

export default ServiceProfileDrawer;
