import styled from 'styled-components';
import { colors } from '../../styles/style';

export const Line = styled.div`
  list-style: none;
  display: list-item;
  border: 0;
  height: 1px;
  background: ${colors.neutral100};
  width: 100%;
`;
