import React from 'react';
import { faBookmark, faCircleCheck, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { faCirclePlus, faFile } from '@fortawesome/pro-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import formatCurrency from '../../helpers/formatCurrency';
import { colors, Div, spaces } from '../../../styles/style';
import ImageInput from '../../../components/Input/ImageInput';
import Button from '../../../components/Button/Button';
import { Paragraph } from '../../../components/Text/Text';
import { replaceDecimalNumber } from '../../helpers/helper';
import TooltipIcon from '../../../components/Tooltip/TooltipIcon';

const columns = ({
  onSelect,
  refurbishItemType,
  itemsOnListMap,
  isMobile,
  isAppropriation,
  priceWithBdi,
  loadingAddLibrary,
  hidePrice
}) => {
  const codeColumn = {
    title: 'Código',
    dataIndex: 'code',
    key: 'code',
    width: 64,
    sorter: false
  };
  const nameColumn = {
    title: isAppropriation ? 'Nível' : 'Item',
    dataIndex: 'name',
    width: hidePrice ? 488 : 360,
    mobileWidth: '100%',
    key: 'name',
    render: (val, row) => {
      const itemImage = refurbishItemType?.product === row?.type ? 'boxOpen' : 'screwdriverWrench';
      return (
        <Div>
          {row.type && row.type !== refurbishItemType.parent && (
            <ImageInput key={`image${row.id}`} id={row.id} value={itemImage} disabled />
          )}
          <Div key={`type${row.id}`} gap={spaces.space1}>
            <Paragraph>{val || '-'}</Paragraph>
            {row.idItem ? (
              <Tooltip title={row.item?.idCompany ? 'Vinculado com a biblioteca' : row.item?.source}>
                <FontAwesomeIcon className="linked-icon" icon={faBookmark} size="lg" color={colors.neutral500} />
              </Tooltip>
            ) : null}
            {+row?.purchasedQuantity ? (
              <TooltipIcon
                tooltipProps={{ overlayStyle: { minWidth: '300px' } }}
                icon={faFile}
                text={`Item já solicitado em outro(s) pagamento(s) Quantidade: ${+row?.purchasedQuantity}`}
                iconColor={colors.primary600}
              />
            ) : null}
          </Div>
        </Div>
      );
    }
  };
  const qtdColumn = {
    title: 'Qtd.',
    dataIndex: 'quantity',
    key: 'quantity',
    width: 80,
    sorter: false,
    render: val => formatCurrency(val, { decimalCount: replaceDecimalNumber(val) })
  };
  const unitColumn = {
    title: 'Unidade',
    dataIndex: 'units',
    key: 'units',
    width: 64,
    sorter: false,
    render: (val, row) => {
      const unit = row?.item?.units || row?.units;

      return <div>{unit?.name || '-'}</div>;
    }
  };
  const priceColumn = {
    title: priceWithBdi ? 'Preço un.' : 'Custo previsto un.',
    dataIndex: priceWithBdi ? 'priceBdi' : 'price',
    key: priceWithBdi ? 'priceBdi' : 'price',
    type: 'number',
    width: 128,
    sorter: false,
    render: (val, row) => {
      return <div key={`price${row.id}`}>{formatCurrency(val, { currencySymbol: 'R$' })}</div>;
    }
  };
  const actionColumn = {
    title: 'Ação',
    key: 'action',
    width: 60,
    style: { textAlign: 'right' },
    render: (val, row, index) => {
      const selected = itemsOnListMap?.[row?.key?.split('_')?.[1]];
      const selectedIcon = selected ? faCircleCheck : faCirclePlus;

      return (
        <Button
          id={`add-shopping-item${selected ? '-selected' : ''}-${index + 1}`}
          shape="circle"
          type="primary"
          roundMaxHeight={spaces.space3}
          text
          onClick={e => {
            e.stopPropagation();
            !selected && !loadingAddLibrary && onSelect({ ...row, purchaseDate: dayjs() }, index);
          }}
        >
          <FontAwesomeIcon
            icon={loadingAddLibrary ? faSpinnerThird : selectedIcon}
            size="lg"
            spin={loadingAddLibrary}
            style={{ color: colors.primary600 }}
          />
        </Button>
      );
    }
  };

  if (isMobile) {
    const mobileColumns = [nameColumn, codeColumn, unitColumn, qtdColumn];

    if (!hidePrice) mobileColumns.push(priceColumn);

    return mobileColumns;
  }

  const desktopColumns = [codeColumn, nameColumn, qtdColumn, unitColumn];

  if (!hidePrice) desktopColumns.push(priceColumn);

  desktopColumns.push(actionColumn);

  return desktopColumns;
};

export { columns };
