import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';
import useCRUD from '../../_Hooks/useCRUD';

import './RichTextEditor.module.scss';
import { colors, fonts, spaces } from '../../styles/style';
import { uploadMultipart } from '../../lib/helpers/uploadMultipart';

const baseURL = process.env.REACT_APP_API_URL || '';

const defaultPlugins = [
  ' paste  searchreplace autolink autosave save directionality code visualblocks ',
  'visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking',
  'toc insertdatetime advlist lists wordcount textpattern noneditable help charmap emoticons'
];

const defaultToolbar =
  ' bold italic underline strikethrough | forecolor backcolor |' +
  ' alignleft aligncenter alignright alignjustify | numlist bullist | image link table |' +
  ' h1 h2 h3 h4 h5 h6 hr | emoticons';

const RichTextEditor = ({
  onChange = f => f,
  onBlur = f => f,
  value = '',
  width = '100%',
  height = null,
  plugins = defaultPlugins,
  toolbar = defaultToolbar,
  quickbar = '',
  menubar = false,
  toolbarSticky = false,
  imageCaption = true,
  toolbarMode = 'sliding',
  isPublic,
  outputFormat,
  disabled = false,
  images = [],
  id = 'rich-text-editor',
  ...otherProps
}) => {
  const { handleCreate: uploadFile } = useCRUD({
    model: 'media',
    pathOptions: '/start',
    immediatelyLoadData: false
  });

  const [imageArray, setImageArray] = useState(images || []);
  const handleImageUpload = (blobInfo, onSuccess, onError) => {
    const fileObject = blobInfo.blob();
    if (!fileObject.name) return;

    const file = {
      lastModified: fileObject.lastModified,
      lastModifiedDate: fileObject.lastModifiedDate,
      name: fileObject.name,
      size: fileObject.size,
      type: fileObject.type
    };

    uploadFile({ values: { file, limitSize: 10000000 }, refresh: false })
      .then(data => {
        const { signUrls, uploadId } = data;
        return uploadMultipart(fileObject, signUrls).then(parts => {
          uploadFile({ values: { file, uploadId, parts }, postPathOptions: '/complete', refresh: false }).then(f => {
            setImageArray(prevState => [...prevState, f?.location?.replace(/&/g, '&amp;')]);
            onSuccess && onSuccess(f?.location);
          });
        });
      })
      .catch(err => {
        onError && onError(err);
      });
  };

  const defaultQuickbar = 'bold italic underline | quicklink quickimage';

  return (
    <Editor
      apiKey="5zjzs05r8zfpd7qnhxajh7tqde31my0s1i4d1cxxiz37a92l"
      outputFormat={outputFormat}
      disabled={disabled}
      init={{
        selector: 'textarea',
        height: height || 500,
        width,
        menubar,
        toolbar_sticky: toolbarSticky,
        image_caption: imageCaption,
        toolbar_mode: toolbarMode,
        plugins,
        toolbar,
        quickbars_selection_toolbar: quickbar || defaultQuickbar,
        images_upload_handler: handleImageUpload,
        images_upload_url: `${baseURL}/media`,
        content_style: `body { font-size: ${fonts.sizeMd}; font-family: muli, sans-serif;  color: ${colors.neutral700};
        margin: ${spaces.space1} !important;
        p {
          margin: 0px !important;
        }}
        body::before {color: ${colors.neutral400} !important; font-weight: ${fonts.weight300};}`,
        setup: editor => {
          editor.on('init', () => {
            // eslint-disable-next-line no-param-reassign
            editor.getBody().onclick = a => {
              const url = a?.target?.href;
              if (url) {
                window.open(url, '_blank');
              }
            };
          });
        },
        ...otherProps
      }}
      onEditorChange={(content, editor) => {
        if (JSON.stringify(value).replace(/amp;/g, '') === JSON.stringify(content).replace(/amp;/g, '')) return;
        onChange(content, imageArray, editor);
      }}
      onBlur={onBlur}
      value={value}
      id={id}
    />
  );
};

RichTextEditor.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  plugins: PropTypes.oneOfType([PropTypes.array]),
  toolbar: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  quickbar: PropTypes.string,
  toolbarMode: PropTypes.string,
  menubar: PropTypes.bool,
  toolbarSticky: PropTypes.bool,
  imageCaption: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isPublic: PropTypes.bool,
  outputFormat: PropTypes.string,
  content_style: PropTypes.string,
  disabled: PropTypes.bool,
  images: PropTypes.instanceOf(Array),
  id: PropTypes.string
};

export default RichTextEditor;
