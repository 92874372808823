import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Drawer from './Drawer';
import FormV2 from '../Form/FormV2';
import { sendNfseMapping, sendNfseSchema } from '../../lib/mapping/Form/sendNfseSchema';
import useCRUD from '../../_Hooks/useCRUD';
import CenteredLoader from '../Loader/CenteredLoader';
import { Div, spaces } from '../../styles/style';
import CompanyEntity from './CompanyEntity';
import AlertBanner from '../Alert/AlertBanner';
import { findIncompleteCompanyEntity } from '../../lib/helpers/helper';
import { mountNfseServiceProfile, recalculateNfseValues } from '../../lib/helpers/payment';
import WarningBar from '../Alert/WarningBar';
import { Paragraph, Subtitle } from '../Text/Text';
import ServiceProfileDrawer from './ServiceProfileDrawer';
import ConfirmModal from '../Modal/ConfirmModal';

const SendNfseDrawer = ({ idPayment, companyCustomer, value, date, open, onClose }) => {
  const [readOnly, setReadOnly] = useState(false);
  const [openCompanyDrawer, setOpenCompanyDrawer] = useState({});
  const [openServiceDrawer, setOpenServiceDrawer] = useState({ open: false, name: null });
  const { invoiceAlertTexts } = useSelector(state => state.setup.enums);
  const forceUpdate = useRef();
  const [companyEntitySelected, setCompanyEntitySelected] = useState({});
  const [companyCustomerSelected, setCompanyCustomerSelected] = useState({});
  const [serviceProfileSelected, setServiceProfileSelected] = useState({});
  const [incompleteRegister, setIncompleteRegister] = useState({});
  const [showChangedModal, setShowChangedModal] = useState(false);
  const [alertState, setAlertState] = useState({});
  const { id: idCompanyCustomer, doc: customerDoc, number: numberAddress, name, ...initialCompanyCustomer } =
    companyCustomer || {};

  const [data, setData] = useState({
    date,
    value,
    total: value,
    discounts: 0,
    taxCSLL: 0,
    netValue: value,
    retainISS: false,
    isConstructionService: false,
    ...(companyCustomer?.id && {
      ...companyCustomer,
      idCompanyCustomer,
      customerDoc,
      numberAddress,
      ...initialCompanyCustomer
    })
  });

  const { data: nfse, loading, handleCreate } = useCRUD({
    model: 'paymentInvoice',
    immediatelyLoadData: false
  });

  const { handleGet: getServiceProfile, loading: loadingServiceProfile } = useCRUD({
    model: 'serviceProfile',
    immediatelyLoadData: false
  });

  const { handleGet: getCompanyEntities, loading: loadingCompanyEntity, totalItems: totalCompanyEntities } = useCRUD({
    model: 'companyEntity',
    immediatelyLoadData: true
  });

  const loadingSendNfse = loading || loadingCompanyEntity || loadingServiceProfile;

  const getAlertContent = () => {
    if (totalCompanyEntities <= 0) {
      return {
        title: invoiceAlertTexts.titleNoCompany,
        subtitle: invoiceAlertTexts.subtitleNoCompany,
        buttonText: 'Cadastrar empresa',
        showAlertBanner: true
      };
    }

    if (incompleteRegister?.pendingNfse) {
      return {
        title: invoiceAlertTexts.titlePendingNfse,
        subtitle: invoiceAlertTexts.subtitlePendingNfse,
        buttonText: 'Ir para configurações',
        showAlertBanner: true
      };
    }

    if (incompleteRegister?.nfseIncomplete) {
      return {
        title: invoiceAlertTexts.titleNfseIncomplete,
        subtitle: invoiceAlertTexts.subtitleNfseIncomplete,
        buttonText: 'Ir para configurações',
        showAlertBanner: true
      };
    }

    if (incompleteRegister?.hasCertificateExpired) {
      return {
        title: invoiceAlertTexts.titleHasCertificateExpired,
        subtitle: invoiceAlertTexts.subtitleHasCertificateExpired,
        buttonText: 'Cadastrar certificado digital',
        showAlertBanner: true
      };
    }

    return null;
  };

  useEffect(() => {
    if (nfse) setData(nfse || {});
  }, [nfse]);

  useEffect(() => {
    if (!companyEntitySelected?.id) return;
    getServiceProfile({
      refetchOptions: {
        where: { idCompanyEntity: companyEntitySelected?.id, isDefault: true }
      }
    }).then(serviceProfile => {
      if (serviceProfile?.length > 0) {
        const service = serviceProfile[0];

        setData(prev => ({
          ...prev,
          ...mountNfseServiceProfile(service, {
            ...prev,
            taxCOFINS: undefined,
            taxPIS: undefined,
            taxCSLL: undefined,
            taxINSS: undefined,
            taxIR: undefined
          })?.formData,
          idServiceProfile: service?.id
        }));
        setServiceProfileSelected(service || {});
        forceUpdate.current = true;
        return;
      }
      setData(prev => ({ ...prev, idServiceProfile: null }));
      setServiceProfileSelected({});
    });
  }, [companyEntitySelected]);

  const handleSubmit = submitData => {
    if (!readOnly) {
      setReadOnly(true);
    } else {
      handleCreate({ values: { ...submitData, idPayment }, postPathOptions: '/emit-invoice', refresh: false }).then(
        resp => {
          if (resp?.error) return null;
          setShowChangedModal(false);
          toast.success(
            <div>
              <Subtitle>Nota fiscal enviada</Subtitle>
              <Paragraph type="small">
                Sua nota fiscal foi enviada para a prefeitura para processamento, isso pode levar alguns minutos.
                Avisaremos assim que for finalizada.
              </Paragraph>
            </div>
          );

          return onClose(true);
        }
      );
    }
  };

  const closeOrBack = () => (!readOnly ? setShowChangedModal(true) : setReadOnly(false));

  useEffect(() => {
    setIncompleteRegister(findIncompleteCompanyEntity([companyEntitySelected]));
  }, [companyEntitySelected]);

  useEffect(() => {
    setAlertState(getAlertContent());
  }, [incompleteRegister]);

  const formDescription = values => {
    setData(prev => {
      const { update, formData } = recalculateNfseValues(
        { ...prev, companyCustomerSelected, companyEntitySelected, serviceProfileSelected },
        values
      );
      forceUpdate.current = update;
      return formData;
    });
  };

  return (
    <>
      <Drawer
        title="Emitir Nota Fiscal"
        width={992}
        padding={spaces.space2}
        formId="sendNfseForm"
        submitText={readOnly ? 'Emitir nota fiscal' : 'Revisar e emitir'}
        cancelText={readOnly ? 'Voltar' : 'Cancelar'}
        disabledSubmit={alertState?.showAlertBanner}
        open={open}
        onClose={closeOrBack}
      >
        {loadingSendNfse ? (
          <CenteredLoader />
        ) : (
          <Div direction="column" align="start" gap={spaces.space2} $fullWidth>
            {readOnly && (
              <WarningBar type="light" warning="Revise suas informações para emissão da nota." width="100%" />
            )}
            {alertState?.showAlertBanner && (
              <AlertBanner
                showAlert
                title={alertState?.title}
                subtitle={alertState?.subtitle}
                buttonText={alertState?.buttonText}
                onClick={() => setOpenCompanyDrawer({ open: true, id: companyEntitySelected?.id })}
              />
            )}
            <FormV2
              id="sendNfseForm"
              schema={sendNfseSchema}
              mapping={sendNfseMapping({
                readOnly,
                setCompanyEntitySelected,
                setCompanyCustomerSelected,
                setServiceProfileSelected,
                openServiceDrawer,
                openCustomCreate: searchValue => setOpenServiceDrawer({ open: true, name: searchValue })
              })}
              data={data}
              displayButtons={false}
              columnStyle
              onSubmit={handleSubmit}
              forceUpdate={forceUpdate}
              customToast="Por favor, verifique os erros de formulário para prosseguir."
              keepOldValues
              onFormChange={formDescription}
              autoComplete="off"
            />
          </Div>
        )}
      </Drawer>

      {openCompanyDrawer.open && (
        <CompanyEntity
          id={openCompanyDrawer.id}
          open={openCompanyDrawer.open}
          onClose={company => {
            getCompanyEntities();
            if (company) setCompanyEntitySelected(company);
            setOpenCompanyDrawer({ open: false, id: null });
          }}
        />
      )}

      {openServiceDrawer.open && (
        <ServiceProfileDrawer
          cnpj={companyEntitySelected?.cnpj}
          idCompanyEntity={companyEntitySelected?.id}
          name={openServiceDrawer.name}
          open={openServiceDrawer.open}
          onClose={() => setOpenServiceDrawer({ open: false, name: null })}
        />
      )}
      {showChangedModal && (
        <ConfirmModal
          title="Alterações não salvas"
          text="Você possui alterações que não foram salvas. Continue a edição ou descarte as alterações."
          alertInfo="Essa ação não poderá ser desfeita."
          submitText="Continuar edição"
          showDiscardButton
          onSubmit={() => setShowChangedModal(false)}
          onClose={(_, opts) => {
            setShowChangedModal(false);
            if (opts?.discard) onClose(false);
          }}
        />
      )}
    </>
  );
};

SendNfseDrawer.propTypes = {
  idPayment: PropTypes.string,
  value: PropTypes.number,
  date: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  companyCustomer: PropTypes.instanceOf(Object)
};

export default SendNfseDrawer;
