import { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Qs from 'qs';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { getUserAnonymousId } from '../lib/analytics/analytics';
import { decrypt } from './decrypt';

const _baseUrl = process.env.REACT_APP_API_URL;

axios.defaults.withCredentials = true;

const getValue = values => {
  if (values instanceof FormData || Array.isArray(values)) return values;

  const { lastModifiedBy, ..._values } = values || {};

  return _values;
};

const useCRUD = ({
  model,
  baseUrl = _baseUrl,
  options = {},
  pathOptions = '',
  immediatelyLoadData = true,
  headerOptions = {},
  showToast = true
}) => {
  const history = useHistory();
  const { user } = useSelector(state => state.authReducer) || {};
  const { pathname } = history.location;
  const isRequestCancelled = useRef(false);
  const [data, setData] = useState(null);
  const [list, setList] = useState([]);
  const [mapping, setMapping] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(immediatelyLoadData);
  const [error, setError] = useState('');
  const [progress, setProgress] = useState(0);

  const withCredentials = (baseUrl || '').indexOf('/v2') < 0;

  const headers = options.headers || {
    'Content-Type': headerOptions.contentType || 'application/json',
    'Access-Control-Allow-Origin': '*'
  };

  if (user?.anonymous) {
    headers['Anonymous-Id'] = getUserAnonymousId();
  }

  if (user?.jwt) headers.Authorization = `Bearer ${user.jwt}`;

  const throwError = useCallback((errorParam, displayToast = true) => {
    const decodedError = decrypt({ data: errorParam?.response?.data, user });

    if (!isRequestCancelled.current) {
      setLoading(false);
    }
    if (errorParam?.code === 'ECONNABORTED') {
      return { error: { message: 'timeout', statusCode: 504, code: '0000' } };
    }

    const { code = '', statusCode, logout: shouldLogout, message, redirectUrl, refresh } = decodedError || {};

    if (code && shouldLogout) {
      return pathname === '/login' ? null : history.push(`/logout`);
    }

    if (code && redirectUrl) {
      history.push(redirectUrl);
    }

    if (code && refresh) {
      window.location.reload(true);
    }

    const errMsg = message || `Ocorreu um erro ${code}`;
    setError(decodedError);
    if (showToast && displayToast && statusCode && statusCode !== 500 && !redirectUrl) toast.error(errMsg);
    return { error: { message: errMsg, code, redirectUrl } };
  }, []);

  const paramsSerializer = ({ offset, limit, ...params }) => {
    return Qs.stringify(
      {
        // TODO change offset value to use page in component
        offset: offset && limit > 0 && offset > 0 ? (offset - 1) * limit : 0,
        limit,
        ...params
      },
      { strictNullHandling: true }
    );
  };

  const handleGet = useCallback(
    ({
      refetchOptions,
      refetchPathOptions,
      generateLoading = true,
      refresh = true,
      displayToast,
      keepState,
      responseType
    } = {}) => {
      if (!refresh) {
        setLoading(false);
        return;
      }
      if (generateLoading && !isRequestCancelled.current) setLoading(true);

      // eslint-disable-next-line consistent-return
      return axios
        .get(`${baseUrl}/${model}${refetchPathOptions || pathOptions}`, {
          paramsSerializer,
          params: refetchOptions || options,
          headers,
          withCredentials,
          ...(responseType && { responseType })
        })
        .then(resp => {
          const responseData = decrypt({ data: resp?.data, user });
          if (responseType) return responseData;

          const { rows, count: _totalItems = 0, data: __data, allowData = false, mapping: _mapping = null } =
            responseData || {};

          const isList =
            Array.isArray(responseData) || (typeof responseData?.count === 'number' && rows && Array.isArray(rows));

          const _list = isList ? rows || responseData : [];
          const _data = (!isList && responseData?.constructor === Object) || allowData ? __data || responseData : null;

          if (!isRequestCancelled.current && !keepState) {
            setMapping(_mapping);
            setList(_list);
            setTotalItems(_totalItems);
            setData(_data);
          }

          return isList ? _list : _data;
        })
        .catch(err => throwError(err, displayToast))
        .finally(() => {
          if (!isRequestCancelled.current) setLoading(false);
        });
    },
    [model, pathOptions, isRequestCancelled]
  );

  useEffect(() => {
    isRequestCancelled.current = false;
    if (immediatelyLoadData && !isRequestCancelled.current) handleGet({}).then();
    return () => {
      isRequestCancelled.current = true;
    };
  }, [immediatelyLoadData, handleGet, isRequestCancelled.current]);

  const handleCreate = useCallback(
    ({
      values,
      postOptions,
      postPathOptions,
      customHeader,
      displayToast,
      refresh = true,
      noLoading,
      customCatch,
      timeout
    }) => {
      setError();
      if (!isRequestCancelled.current) setLoading(!noLoading);

      const _values = getValue(values);
      const isFormData = values instanceof FormData;

      return axios
        .post(`${baseUrl}/${model}${postPathOptions || pathOptions}`, _values, {
          headers: {
            ...headers,
            ...customHeader,
            ...(isFormData && { 'Content-Type': 'multipart/form-data' }),
            ...(postPathOptions === '/login' && { Authorization: undefined })
          },
          paramsSerializer,
          params: postOptions,
          ...(timeout && { timeout }),
          withCredentials
        })
        .then(resp => {
          const responseData = decrypt({ data: resp?.data, user });
          handleGet({
            refetchOptions: postOptions,
            generateLoading: false,
            refresh
          });

          if (showToast && displayToast) {
            toast.success(typeof displayToast === 'string' ? displayToast : 'Operação realizada com sucesso.');
          }
          return responseData;
        })
        .catch(err => {
          if (customCatch) {
            const newError = {
              ...err,
              response: { ...err?.response, data: decrypt({ data: err?.response?.data, user }) }
            };
            throw newError;
          } else {
            return throwError(err, displayToast);
          }
        });
    },
    [data, handleGet, model, baseUrl, options]
  );

  const handleDelete = useCallback(
    ({ id, values, deleteOptions, deletePathOptions, displayToast, refresh = true, noLoading, returnDeletedData }) => {
      setError();
      const url = `${baseUrl}/${model}${id ? `/${id}` : ''}${deletePathOptions || pathOptions}`;
      if (!isRequestCancelled.current) setLoading(!noLoading);

      const _values = getValue(values);

      return axios
        .delete(url, {
          data: _values,
          paramsSerializer(params) {
            return Qs.stringify(params, { strictNullHandling: true });
          },
          headers,
          params: deleteOptions || options,
          withCredentials
        })
        .then(resp => {
          const responseData = decrypt({ data: resp?.data, user });
          if (showToast && displayToast) {
            toast.success(typeof displayToast === 'string' ? displayToast : 'Operação realizada com sucesso.');
          }
          if (data && data.length && Array.isArray(data)) {
            setData(data?.filter(dataItem => dataItem.id !== id));
          }
          if (returnDeletedData) return responseData;
          return handleGet({
            generateLoading: false,
            refetchOptions: deleteOptions,
            refresh
          });
        })
        .catch(err => throwError(err, displayToast));
    },
    [data, model]
  );

  const handleUpdate = useCallback(
    ({
      id = '',
      values,
      updatePathOptions = '',
      displayToast,
      updateOptions,
      refresh = true,
      noLoading,
      verb = 'put'
    }) => {
      setError();
      // eslint-disable-next-line no-nested-ternary
      const idPath = !id ? '' : id.toString().includes('/') ? id : `/${id}`;
      const url = `${baseUrl}/${model}${idPath}${updatePathOptions || pathOptions}`;
      if (!isRequestCancelled.current) setLoading(!noLoading);
      const _values = getValue(values);

      return axios[verb](url, _values, {
        paramsSerializer({ offset, limit, ...params }) {
          return Qs.stringify(
            {
              // TODO change offset value to use page in component
              offset: offset && limit > 0 && offset > 0 ? (offset - 1) * limit : 0,
              limit,
              ...params
            },
            { strictNullHandling: true }
          );
        },
        params: updateOptions || options,
        headers,
        withCredentials
      })
        .then(resp => {
          const responseData = decrypt({ data: resp?.data, user });
          if (showToast && displayToast) {
            toast.success(typeof displayToast === 'string' ? displayToast : 'Operação realizada com sucesso.');
          }
          handleGet({
            refetchOptions: updateOptions,
            refetchPathOptions: updatePathOptions,
            generateLoading: false,
            refresh
          });
          return responseData;
        })
        .catch(err => throwError(err, displayToast));
    },
    [handleGet, model, options]
  );

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  let successCount = 0;
  let failedCount = 0;
  let response = [];

  const executeRequests = ({ chunks, url, values, updateOptions, deleteOptions, index = 0, verb }) => {
    const totalChunks = chunks?.length;
    const total = ((index + 1) / totalChunks) * 100;
    setProgress(total.toFixed());

    if (index >= totalChunks) {
      return { successCount, failedCount, response };
    }

    const chunk = chunks[index];

    const config = {
      paramsSerializer(params) {
        return Qs.stringify(params, { strictNullHandling: true });
      },
      params: deleteOptions || updateOptions || options,
      headers,
      withCredentials
    };

    const axiosSend = {
      put: [{ ids: chunk, ...values }, config],
      delete: [{ data: { ids: chunk, ...values }, ...config }]
    };

    return axios[verb](url, ...axiosSend[verb])
      .then(resp => {
        const responseData = decrypt({ data: resp?.data, user });

        if (responseData?.error) {
          failedCount += chunk?.length || 0;
        } else {
          successCount += chunk?.length || 0;
          response = response.concat(responseData);
        }

        return executeRequests({ chunks, url, values, updateOptions, index: index + 1, verb });
      })
      .catch(() => {
        return executeRequests({ chunks, url, values, updateOptions, index: index + 1, verb });
      });
  };

  const handleChunks = useCallback(
    ({ values, displayToast, updateOptions, deleteOptions, verb = 'put', chunkSize = 40 }) => {
      const { ids, ...clonedObj } = values || {};
      if (!Array.isArray(ids) || !ids?.length) {
        toast.error('Ocorreu um erro ao realizar a operação, fale com nosso suporte.');
        return Promise.resolve();
      }
      const chunks = chunkArray(ids, chunkSize);
      const url = `${baseUrl}/${model}/bulk`;
      const _values = getValue(clonedObj);
      return executeRequests({ chunks, url, values: _values, updateOptions, deleteOptions, verb })
        .then(resp => {
          setProgress(100);
          setTimeout(() => setProgress(), 1000);
          if (showToast && displayToast) toast.success('Operação realizada com sucesso.');
          return resp;
        })
        .catch(err => throwError(err, displayToast));
    },
    [model, options]
  );

  return {
    data,
    list,
    mapping,
    totalItems,
    setTotalItems,
    setLoading,
    loading,
    options,
    error,
    setError,
    setData,
    setList,
    handleGet,
    handleUpdate,
    handleDelete,
    handleCreate,
    handleChunks,
    progress
  };
};

export default useCRUD;
