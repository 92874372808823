import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import { colors, fonts, radius, spaces } from '../../styles/style';

const AlertDivConfirmModal = styled.div`
  display: flex;
  align-items: ${props => props.align || 'center'};
  padding: ${props => props.padding || `0 ${spaces.space1}`};
  justify-content: ${props => props.$justifyContent || 'flex-start'};
  gap: ${spaces.space1};
  border: 1px solid ${colors.orange100};
  background-color: ${colors.orange50};
  border-radius: ${radius.radius1};
  width: ${props => props.width || 'fit-content'};
  margin: ${props => props.margin || 'auto'};
  ${props => props.$minWidth && `min-width: ${props.$minWidth};`}
  min-height: ${spaces.space4};
  span {
    color: ${colors.neutral600};
    font-size: ${fonts.sizeSm};
    font-weight: ${fonts.weight500};
  }
  svg {
    color: ${colors.orange500};
  }
  ${props =>
    props.type === 'light' &&
    css`
      background-color: ${colors.primary50};
      border: 1px solid ${colors.primary100};
      svg {
        color: ${colors.primary500};
      }
    `}
`;

const WarningBar = ({ id, warning, icon = faCircleInfo, iconProps, ...props }) => {
  return (
    <AlertDivConfirmModal {...props}>
      <FontAwesomeIcon icon={icon} id={id} {...iconProps} />
      <span>{warning}</span>
    </AlertDivConfirmModal>
  );
};

WarningBar.propTypes = {
  warning: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  id: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  iconProps: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default WarningBar;
