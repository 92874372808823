import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import styled from 'styled-components';
import Text from 'antd/lib/typography/Text';
import { hasPermission } from '../../routes/Common/PrivateRoute';
import menuMapping from './userMenuMapping';
import useViewport from '../../_Hooks/useViewport';

import ChangePasswordDrawer from '../Drawer/ChangePasswordDrawer';

import UserEventService from '../../lib/gtm/user';
import BubbleModalButton from '../Button/BubbleModalButton';
import Button from '../Button/Button';
import { colors, fonts, radius, spaces } from '../../styles/style';
import RefurbishListModal from '../Modal/RefurbishListModal';

const userEventService = UserEventService();

const UpgradeOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props?.$isMobile ? `${colors.neutral75};` : `${colors.neutral100}`)};
  width: 100%;
  height: 64px;
  border-top: ${props => (props?.$isMobile ? `0` : `1px solid ${colors.primary100}`)};
  border-bottom: ${props => (props?.$isMobile ? `0` : `1px solid ${colors.primary100}`)};
  div {
    font-size: ${fonts.sizeXs};
    white-space: normal;
    width: 112px;
    text-align: center;
  }
  button {
    border-radius: ${radius.radius1} !important;
    padding: ${spaces.space1};
    &:hover {
      border-color: ${colors.neutral100} !important;
    }
  }
`;

const UserMenuOptions = () => {
  const history = useHistory();
  const { user } = useSelector(state => state.authReducer);
  const { plans, permissions } = useSelector(state => state.setup);
  const { userType, planType } = useSelector(state => state.setup.enums);
  let _open = false;
  let _openRefurbish = false;
  const [open, setOpen] = useState(false);
  const [openRefurbish, setOpenRefurbish] = useState(false);
  const { isMobile } = useViewport(window.innerWidth);

  const isProvider = user.userType === userType.provider.value;
  const isCustomer = user.userType === userType.customer.value;

  const handleCancel = () => {
    _open = false;
    _openRefurbish = false;
    setOpen(_open);
    setOpenRefurbish(_openRefurbish);
  };

  const handleChangeRefurbish = e => {
    e.preventDefault();
    _openRefurbish = true;
    setOpenRefurbish(_openRefurbish);
  };

  const handleChangePassword = e => {
    if (e) {
      e.preventDefault();
    }
    _open = true;
    setOpen(_open);
  };

  const handleLogout = () => {
    userEventService.onLogout(user);
    return history.push('/logout');
  };

  const hasUserPermission = hasPermission(user, ['users'], plans, permissions);
  const hasCompanyPermission = hasPermission(user, ['company'], plans, permissions);
  const isFree = user?.company?.idPlan === planType.free.value;

  const _menuMapping = menuMapping({
    handleChangeRefurbish,
    isCustomer,
    isProvider,
    isMobile,
    hasUserPermission,
    hasCompanyPermission,
    handleChangePassword,
    isFree,
    handleLogout
  });

  return {
    ChangePasswordDrawer: () => (
      <ChangePasswordDrawer open={open} onCancel={handleCancel} onSubmit={() => setOpen(false)} />
    ),
    RefurbishListDrawer: () =>
      openRefurbish && isCustomer && !isMobile() ? (
        <RefurbishListModal open={openRefurbish} onCancel={handleCancel} onSubmit={() => setOpenRefurbish(false)} />
      ) : (
        <></>
      ),
    options: _menuMapping,
    params: { isCustomer, isMobile, openRefurbish, handleCancel, user },
    UpgradeOption: () => (
      <UpgradeOption $isMobile={isMobile()} key="plan-upgrade-avatar-button">
        <div className={`${isMobile() ? 'upgrade-div' : ''}`}>
          <Text>Conheça todos os nossos planos!</Text>
        </div>
        <BubbleModalButton>
          <Button id="plan-upgrade-avatar-button" type="primary">
            Upgrade
          </Button>
        </BubbleModalButton>
      </UpgradeOption>
    ),
    isModalOpen: open || openRefurbish
  };
};

export default UserMenuOptions;
