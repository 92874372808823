import React, { memo, useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faLayerGroup, faScrewdriverWrench } from '@fortawesome/pro-solid-svg-icons';
import { Menu } from 'antd';
import {
  faXmark,
  faMessage,
  faShareNodes,
  faEllipsis,
  faBookmark,
  faCopy,
  faTrashCan,
  faInfoCircle,
  faArrowRotateRight,
  faLink,
  faPenToSquare,
  faArrowLeft,
  faPlus
} from '@fortawesome/pro-regular-svg-icons';

import ItemAttachmentContent from '../Content/ItemAttachmentContent';
import Description from '../Content/Description';
import { mappingDescriptionConf } from '../../lib/mapping/Description/mappingDescriptionConf';
import { Container, FormContainer } from './EditItemModal.styles';
import { colors, Div, spaces } from '../../styles/style';
import formatCurrency from '../../lib/helpers/formatCurrency';
import formatBdi from '../../lib/helpers/formatBdi';
import formatNumber from '../../lib/helpers/formatNumber';
import useCRUD from '../../_Hooks/useCRUD';
import CenteredLoader from '../Loader/CenteredLoader';
import EditOrCreateCustomerOrSupplier from '../Drawer/EditOrCreateCustomerOrSupplier';
import Button from '../Button/Button';
import ConfirmModal from './ConfirmModal';
import CatalogDrawer from '../Drawer/CatalogDrawer';
import ConfirmModalAlertInfoBuilder from './ConfirmModalAlertInfoBuilder';
import { getCatalogDrawerDisplayToast } from '../../lib/helpers/helper';
import useViewport from '../../_Hooks/useViewport';
import Drawer from '../Drawer/Drawer';
import { Subtitle, Title } from '../Text/Text';
import DrawerHeaderComponents from '../Drawer/DrawerComponents/DrawerHeaderComponents';
import useFilter from '../../_Hooks/useFilter';
import DrawerComments, { calcDrawerWidth } from '../Drawer/DrawerComponents/DrawerComments';
import DrawerDropdownShareMenu from '../Drawer/DrawerComponents/DrawerDropdownShareMenu';
import DrawerTabs from '../Drawer/DrawerComponents/DrawerTabs';
import FileUpload from '../File/FileUpload';
import { itemMappingV2, itemSchemaV2 } from '../../lib/mapping/Form/itemSchemaV2';
import FormV2 from '../Form/FormV2';
import TooltipIcon from '../Tooltip/TooltipIcon';
import EditableInput from '../Input/EditableInput';
import WarningBar from '../Alert/WarningBar';
import LinkedItemTag from '../Tag/LinkedItemTag';
import { StyledMenu } from '../Dropdown/DropdownV2.styled';

const EditItemModal = ({
  id,
  idReference,
  onClose = f => f,
  afterSubmit = f => f,
  model = 'refurbish-items',
  readOnly,
  tab = '0',
  isTemplate,
  groupedReference,
  type,
  renderColumns,
  isLibrary = false,
  onlyLibraryFields = false,
  libraryProps,
  isChildrenDrawer,
  initialValues,
  isProductConciliation,
  createInLibraryAndAddToRefurbish,
  disableInsideCompositionActions = false
}) => {
  const { user } = useSelector(state => state.authReducer);
  const { userType, refurbishItemType, refurbishItemStatus } = useSelector(state => state.setup.enums);
  const isCustomer = user?.userType === userType.customer.value;
  const isUserAnonymous = user?.anonymous;
  const hideSupplier = !renderColumns?.idSupplier && (isCustomer || isUserAnonymous);

  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [name, setName] = useState(initialValues?.name || '');
  const [observation, setObservation] = useState('');
  const [checkedValue, setCheckedValue] =
    libraryProps?.checkedValue !== undefined
      ? [libraryProps.checkedValue, libraryProps.setCheckedValue]
      : useState(true);
  const [selectedTab, setSelectedTab] = useState(tab || '0');
  const [linkedData, setLinkedData] = useState();
  const [editableData, setEditableData] = useState();
  const [showEditLibraryItemModal, setShowEditLibraryItemModal] = useState(false);
  const [confirmModalProps, setConfirmModalProps] = useState();
  const [linkedItem, setLinkedItem] = useState();
  const [editSupplierId, setEditSupplierId] = useState();
  const [showCatalogDrawer, setShowCatalogDrawer] = useState(false);
  const [isEditableFormValid, setIsEditableFormValid] = useState(true);
  const forceFormUpdate = useRef(false);

  const { storedFilters, updateFilters } = useFilter({
    persistKey: 'show-comments-side-component-refurbish-items'
  });
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();

  let initialShowComments = storedFilters !== null ? storedFilters?.showCommentsSideComponent : true;
  if (isUserAnonymous || _isMobile || libraryProps?.isLibrary || !id || isTemplate || isLibrary)
    initialShowComments = false;

  const [showComponents, setShowComponents] = useState({
    showComments: initialShowComments,
    showOptions: false
  });

  useEffect(() => {
    if (showComponents.showComments !== storedFilters?.showCommentsSideComponent && !libraryProps?.isLibrary)
      updateFilters({ ...storedFilters, showCommentsSideComponent: showComponents.showComments });
  }, [showComponents.showComments]);

  const detailTitle = {
    [refurbishItemType.labor]: { name: 'Serviço', icon: faScrewdriverWrench },
    [refurbishItemType.product]: { name: 'Produto', icon: faBoxOpen },
    [refurbishItemType.composition]: { name: 'Composição', icon: faLayerGroup }
  };
  const createTitle = {
    [refurbishItemType.labor]: { name: 'Novo serviço', icon: faScrewdriverWrench },
    [refurbishItemType.product]: { name: 'Novo produto', icon: faBoxOpen }
  };

  const { data, loading, handleGet, handleUpdate, handleCreate, handleDelete } = useCRUD({
    model,
    pathOptions: id ? `/${id}` : '',
    options: libraryProps?.options || {
      include: [
        'supplier',
        'parent',
        { attributes: ['id', 'name'], model: 'Unit', as: 'units' },
        ...(isTemplate || !idReference ? [] : ['files']),
        ...(!isTemplate && !isLibrary ? [{ model: 'comments', attributes: ['id'], as: 'comments' }] : []),
        {
          model: 'costCenter',
          as: 'costCenter',
          attributes: ['id', 'name']
        },
        {
          model: 'refurbishGroup',
          as: 'refurbishGroup',
          attributes: ['id', 'name']
        },
        ...(isLibrary
          ? []
          : [
              {
                model: isTemplate ? 'template' : 'refurbish',
                attributes: ['version', 'laborBdi', 'productBdi']
              },
              {
                model: 'Item',
                as: 'item',
                include: [{ attributes: ['id', 'name'], model: 'Unit', as: 'units' }]
              }
            ])
      ],
      where: { idRefurbish: idReference }
    },
    immediatelyLoadData: !!id
  });

  const { handleGet: getLibraryItem } = useCRUD({
    model: 'item',
    immediatelyLoadData: false
  });

  const hasSupplier = data?.supplier?.id && data?.supplier?.id !== 1;

  useEffect(() => {
    setLinkedData(data);
    forceFormUpdate.current = true;

    if (data?.id) {
      setFiles(data.files || []);
      setImages(data.images || []);
      setName(data.name || '');
      setObservation(data.observation || '');
      setLinkedItem(data?.externalBaseCode && !data?.idItem ? data : data?.item);
      setEditableData(data);
    }
  }, [data]);

  const propsForTrack = { idCompany: user?.idCompany, idRefurbish: data?.idRefurbish, idUser: user?.id };

  const handleSaveLink = itemToSave => {
    handleUpdate({
      values: {
        idItem: itemToSave?.id || null,
        externalBaseCode: itemToSave?.externalBaseCode || null,
        ...(itemToSave && { type: itemToSave?.type }),
        event: 'item-nature-changed',
        action: itemToSave ? 'attached' : 'detached'
      },
      refresh: false,
      displayToast: getCatalogDrawerDisplayToast(itemToSave)
    }).then(resp => {
      if (!resp?.error) {
        handleGet();
      }
    });
  };

  const getSubmitValues = () => {
    const { bdi, height, width, length, weight, idUnit, code, ...submitData } = editableData || {};
    const formattedBdi = formatBdi(bdi);

    return {
      ...submitData,
      ...(formatCurrency(data?.bdi || 0) !== formatCurrency(formattedBdi) && { bdi: formattedBdi }),
      image: null,
      price: formatNumber(submitData.price),
      observation,
      files,
      type: data?.type || type,
      autoCode: !id && checkedValue,
      quantity: formatNumber(submitData?.quantity || 0),
      ...(data?.status !== submitData.status &&
        submitData.status === refurbishItemStatus.approved.value && { isApproved: true }),
      ...(!linkedItem && {
        height: formatNumber(height),
        width: formatNumber(width),
        length: formatNumber(length),
        weight: formatNumber(weight),
        idUnit: idUnit || 1,
        name,
        code,
        images
      })
    };
  };

  const handleAddToLibrary = async () => {
    if (!isEditableFormValid) return;
    const values = getSubmitValues();

    handleUpdate({
      values,
      refresh: false
    }).then(resp => {
      if (!resp?.error) {
        handleCreate({
          values: {
            idCompany: user?.idCompany,
            id: data.id
          },
          displayToast: 'Item atualizado e adicionado na biblioteca com sucesso!',
          postPathOptions: '/copy'
        });
        afterSubmit(resp?.rows?.[0] || resp);
      }
    });
  };

  const handleSubmit = ({ reopen = false } = {}) => {
    if (!isEditableFormValid) return;
    const values = getSubmitValues();

    if (!id) {
      handleCreate({
        values,
        refresh: false,
        postOptions: {
          include: [
            'supplier',
            ...(isTemplate ? [] : ['files']),
            {
              model: 'costCenter',
              as: 'costCenter',
              attributes: ['id', 'name']
            },
            {
              model: 'refurbishGroup',
              as: 'refurbishGroup',
              attributes: ['id', 'name']
            }
          ]
        },
        displayToast: 'Item adicionado com sucesso.'
      }).then(resp => {
        if (!resp?.error) {
          if (createInLibraryAndAddToRefurbish) {
            handleCreate({
              values: {
                idCompany: user?.idCompany,
                id: resp.id
              },
              displayToast: 'Item criado na biblioteca e adicionado no orçamento com sucesso!',
              postPathOptions: '/copy'
            }).then(copyResp => {
              if (!reopen) onClose(copyResp);
              setLinkedData(null);
              setEditableData(null);
              setFiles([]);
              setImages([]);
              setObservation('');
              afterSubmit(copyResp);
              setName('');
            });
          } else {
            if (!reopen) onClose(resp);
            setLinkedData(null);
            setEditableData(null);
            setFiles([]);
            setImages([]);
            setObservation('');
            afterSubmit(resp);
            setName('');
          }
        }
      });
    } else {
      handleUpdate({
        values,
        refresh: false,
        displayToast: 'Item atualizado com sucesso.'
      }).then(resp => {
        if (!resp?.error) {
          onClose(resp?.rows?.[0] || resp);
          afterSubmit(resp?.rows?.[0] || resp);
        }
      });
    }
  };

  const _type = linkedItem ? linkedItem?.type : data?.type || type;

  const handleConfirmModal = confirmModalType => {
    const confirmModalPropsMap = {
      unlinkItem: {
        title: 'Desvincular item',
        alertInfo: 'Ao desvincular esse item, ele perderá a relação com a Biblioteca',
        preInfoDescription: 'Deseja concluir ação?',
        onSubmit: () => handleSaveLink(null)
      },
      editLibraryItem: {
        title: 'Todas as ocorrências serão editadas',
        alertInfo: (
          <ConfirmModalAlertInfoBuilder
            alertText={`Ao editar esse item, os
            seguintes campos de todas as aparições desse item serão alteradas:`}
            afterAlertList={[
              'Nome do item',
              'Unidade',
              'Código',
              ...(_type === refurbishItemType.product ? ['Imagem', 'Dimensões', 'Peso'] : [])
            ]}
          />
        ),
        preInfoDescription: 'Deseja confirmar?',
        onSubmit: () => handleSubmit({})
      }
    };
    if (id) {
      setConfirmModalProps(confirmModalPropsMap[confirmModalType]);
    } else {
      confirmModalPropsMap[confirmModalType].onSubmit();
    }
  };

  const handleLinkClick = () => {
    if (linkedItem) {
      handleConfirmModal('unlinkItem');
      return;
    }
    setShowCatalogDrawer(true);
  };

  const DetailsContent = useMemo(() => {
    const _data = editableData || data || initialValues || {};
    const _linkedData = _data || linkedData || data?.item || data || initialValues || {};

    const transformedLinkedData = {
      ..._linkedData,
      units: _linkedData?.item?.units || _linkedData?.units,
      class: _linkedData?.item?.class || _linkedData?.class,
      height: formatCurrency(_linkedData?.height),
      width: formatCurrency(_linkedData?.width),
      length: formatCurrency(_linkedData?.length),
      weight: formatCurrency(_linkedData?.weight),
      code: _linkedData?.code
    };

    return (
      <>
        <FormContainer>
          <FormV2
            id="itemForm"
            mapping={itemMappingV2({
              id: data?.id,
              isMobile: _isMobile,
              userType,
              refurbishItemTypeEnum: refurbishItemType,
              isTemplate,
              idReference,
              linkedItem,
              type: _type,
              isLibrary,
              checkedValue,
              setCheckedValue,
              onlyLibraryFields,
              parentType: data?.parent?.type || data?.parent?.[0]?.type,
              isCustomer,
              createInLibraryAndAddToRefurbish,
              columnsToShow: renderColumns,
              readOnly,
              isSinapi: !!data?.externalBaseCode
            })}
            isFormValid={setIsEditableFormValid}
            onFormChange={setEditableData}
            data={transformedLinkedData}
            schema={itemSchemaV2}
            displayButtons={false}
            readOnly={isCustomer || readOnly}
          />
        </FormContainer>
      </>
    );
  }, [linkedItem, groupedReference, data, checkedValue, linkedData]);

  const drawerFooter = (
    <>
      <Button onClick={onClose} text loading={loading}>
        Cancelar
      </Button>
      {!readOnly && !isCustomer && (
        <div style={{ display: 'flex', gap: spaces.space1 }}>
          {!isLibrary && id && !isProductConciliation && !linkedItem && !isTemplate && (
            <Button
              id={`link-item${isChildrenDrawer && id ? `-${id}` : ''}`}
              type="light"
              loading={loading}
              onClick={handleAddToLibrary}
            >
              Adicionar na biblioteca e salvar
            </Button>
          )}
          {isLibrary && !data?.id && !_isMobile && (
            <Button
              id={`submit-drawer${isChildrenDrawer && id ? `-${id}` : ''}`}
              type="light"
              loading={loading}
              onClick={() => handleSubmit({ reopen: true })}
            >
              Salvar e adicionar outro
            </Button>
          )}
          <Button
            id={`submit-drawer${isChildrenDrawer && id ? `-${id}` : ''}`}
            type="primary"
            loading={loading}
            onClick={() => (isLibrary ? handleConfirmModal('editLibraryItem') : handleSubmit({}))}
          >
            {createInLibraryAndAddToRefurbish ? 'Criar na biblioteca e adicionar no orçamento' : 'Salvar'}
          </Button>
        </div>
      )}
    </>
  );

  const urlContext =
    (!isLibrary && 'o') ||
    (_type === refurbishItemType.product && 'lp') ||
    (_type === refurbishItemType.composition && 'lc') ||
    (_type === refurbishItemType.labor && 'll');

  const editOptionsMenu = () => {
    const editItemObj = {
      id: 'edit-library-item',
      icon: faPenToSquare,
      text: 'Editar na biblioteca',
      onClick: () => {
        setShowEditLibraryItemModal(true);
        setShowComponents({ ...showComponents, showOptions: false });
      },
      shouldDisplayWhen: !isLibrary && linkedItem && !data?.externalBaseCode
    };

    const duplicateItemObj = {
      id: 'duplicate-refurbish-item',
      icon: faCopy,
      text: 'Duplicar',
      onClick: async () => {
        const resp = await handleCreate({
          values: { id },
          postPathOptions: '/duplicate'
        });
        if (!resp?.error) {
          handleGet();
          onClose(false);
        }
      },
      shouldDisplayWhen: !disableInsideCompositionActions
    };

    const updateLibraryObj = {
      id: 'update-from-library',
      icon: faArrowRotateRight,
      text: data?.externalBaseCode ? 'Restaurar valores SINAPI' : 'Atualizar item com a biblioteca',
      onClick: () => {
        setConfirmModalProps({
          title: 'Atualizar item',
          alertInfo: (
            <ConfirmModalAlertInfoBuilder
              alertText={`Ao atualizar esse item, os seguintes campos serão
                substituídos pelas informações do item na ${data?.externalBaseCode ? 'SINAPI' : 'Biblioteca'}:`}
              afterAlertList={[
                'Custo',
                'Fornecedor',
                'Marca',
                'Grupo',
                'Categoria',
                'Link',
                'Descrição',
                ...(_type === refurbishItemType.composition ? ['Itens dentro da composição'] : [])
              ]}
            />
          ),
          preInfoDescription: 'Deseja atualizar as informações do item?',
          onSubmit: async () => {
            if (data?.externalBaseCode) {
              const updateResp = await handleUpdate({
                updatePathOptions: `/${data?.id}/setChildrenFromItem`,
                displayToast: 'Operação realizada com sucesso!',
                values: {
                  externalBaseCode: data?.externalBaseCode,
                  event: 'item-nature-changed',
                  action: 'updated'
                },
                refresh: false
              });

              if (!updateResp?.error) {
                setLinkedData(null);
                setEditableData(null);
                setFiles([]);
                setImages([]);
                setName('');
                setObservation('');
                handleGet();
              }
              return;
            }

            if (!data?.idItem) return;

            const resp = await getLibraryItem({
              refetchPathOptions: `/${data.idItem}`,
              refetchOptions: {
                attributes: ['price', 'idSupplier', 'idRefurbishGroup', 'idCostCenter', 'brand', 'link', 'description']
              }
            });

            if (!resp) return;

            const updateResp = await handleUpdate({
              updatePathOptions: `/${data?.id}/setChildrenFromItem`,
              displayToast: 'Operação realizada com sucesso!',
              values: { ...resp, idItem: data.idItem },
              refresh: false
            });

            if (!updateResp?.error) {
              setLinkedData(null);
              setEditableData(null);
              setFiles([]);
              setImages([]);
              setName('');
              setObservation('');
              handleGet();
            }
          }
        });
      },
      shouldDisplayWhen: !isLibrary && linkedItem
    };

    const linkRefurbishItemObj = {
      id: 'link-refurbish-item',
      icon: faBookmark,
      text: 'Vincular com item da biblioteca',
      onClick: () => {
        handleLinkClick();
        setShowComponents({ ...showComponents, showOptions: false });
      },
      shouldDisplayWhen: !isLibrary && !linkedItem && !isProductConciliation
    };

    const deleteItemObj = {
      id: 'delete-item',
      icon: faTrashCan,
      text: 'Excluir',
      type: 'danger',
      onClick: () => {
        setShowComponents({ ...showComponents, showDeleteModal: true });
      },
      shouldDisplayWhen: !disableInsideCompositionActions
    };

    const menuItems = [editItemObj, duplicateItemObj, updateLibraryObj, linkRefurbishItemObj, deleteItemObj];

    return (
      <>
        <StyledMenu>
          {_isMobile && (
            <DrawerDropdownShareMenu
              urlContext={urlContext}
              id={id}
              analyticsProps={propsForTrack}
              customEvent="refurbish-item-shared"
              isMobile
            />
          )}
          {menuItems
            .filter(item => item.shouldDisplayWhen)
            .map(item => (
              <Menu.Item key={item.id}>
                <Button
                  onClick={item.onClick}
                  id={item.id}
                  text
                  type={item.type}
                  $fullWidth
                  $justifyContent="flex-start"
                >
                  <Div width={spaces.space3} height={spaces.space3} align="center" justify="center">
                    <FontAwesomeIcon icon={item.icon} />
                  </Div>
                  {item.text}
                </Button>
              </Menu.Item>
            ))}
        </StyledMenu>
      </>
    );
  };

  const headerComponents = [
    {
      icon: faMessage,
      title: 'Comentários',
      hasBadge: true,
      badgeCount: data?.comments?.length || 0,
      showOnMobile: true,
      shouldDisplayWhen: !isTemplate && !isUserAnonymous && !isLibrary && !!data?.id && !readOnly,
      isPressed: showComponents.showComments,
      onClick: () =>
        setShowComponents({
          ...showComponents,
          showComments: !showComponents.showComments
        })
    },
    {
      icon: faShareNodes,
      title: 'Compartilhar',
      menu: () => (
        <DrawerDropdownShareMenu
          urlContext={urlContext}
          id={id}
          analyticsProps={propsForTrack}
          customEvent="refurbish-item-shared"
        />
      ),
      isOpen: showComponents?.showShareDropdown,
      onClick: v => setShowComponents({ ...showComponents, showShareDropdown: v }),
      shouldDisplayWhen: !isTemplate && !isUserAnonymous && !!data?.id && !onlyLibraryFields
    },
    {
      icon: faEllipsis,
      title: 'Opções',
      showOnMobile: true,
      menu: editOptionsMenu,
      isOpen: showComponents?.showOptions,
      onClick: v => setShowComponents({ ...showComponents, showOptions: v }),
      shouldDisplayWhen:
        !isUserAnonymous &&
        !!data?.id &&
        !onlyLibraryFields &&
        !isCustomer &&
        !readOnly &&
        !(isLibrary && disableInsideCompositionActions)
    },
    {
      icon: faXmark,
      title: 'Fechar',
      onClick: () => onClose(false)
    }
  ];

  const imageObj = (
    <>
      <Div padding={spaces.space2} direction="column" align="flex-start" $fullWidth>
        <Div gap={spaces.space1}>
          <Subtitle>Imagens</Subtitle>
          <TooltipIcon
            icon={faInfoCircle}
            iconColor={colors.primary500}
            tooltipProps={{ placement: 'right' }}
            text="Tamanho máximo: 50mb"
          />
        </Div>
      </Div>
      <Div
        margin={`-${spaces.space1} ${images.length > 0 || readOnly || !!linkedItem ? spaces.space2 : spaces.space1} 0`}
      >
        <FileUpload
          id="refurbish-item-image-input"
          name="refurbish-item-image-input"
          onChange={newImages => {
            setImages([...newImages]);
          }}
          onRemove={(_, index) => {
            const newImages = [...images];
            newImages.splice(index, 1);
            if (newImages.length > 0) {
              setImages(newImages);
            } else {
              setImages([]);
            }
          }}
          multiple
          initialFiles={images.length !== 0 ? [...images] : [...(data?.images || [])]}
          textArray
          text="Adicionar imagem"
          galleryType="new"
          galleryProps={{
            disabled: !!linkedItem || readOnly
          }}
          icon={faPlus}
          buttonProps={{
            type: 'primary',
            $noIcon: true,
            ...(!!linkedItem || readOnly ? { style: { display: 'none' } } : {})
          }}
          buttonObrigatory
          listType={null}
          fullWidth
          onlyButtonOnEmpty={!linkedItem && !readOnly}
          displayLabelOnlyWhenNotEmpty
        />
      </Div>
    </>
  );

  const fileObj = (
    <Div padding={spaces.space2} $fullWidth>
      <ItemAttachmentContent
        readOnly={isCustomer || readOnly}
        setObservation={setObservation}
        hideTitle
        hasDescription={false}
        data={{ files, observation }}
        setFiles={setFiles}
      />
    </Div>
  );

  const supplierObj = (
    <Div padding={spaces.space2} direction="column" $fullWidth align="flex-start">
      {_isMobile ? (
        <Div padding={`0 0 ${spaces.space2} 0`}>
          <Title> Dados do fornecedor</Title>
        </Div>
      ) : null}
      <Description
        title="Dados bancários"
        mapping={mappingDescriptionConf.bank}
        column={_isMobile ? 1 : 3}
        data={data?.supplier || {}}
        background={null}
        className="form-component"
        noPadding
      />
      <Description
        title="Dados da empresa"
        mapping={mappingDescriptionConf.supplier}
        column={_isMobile ? 1 : 3}
        data={data?.supplier || {}}
        background={null}
        className="form-component"
        noPadding
        extra={
          hasSupplier && !readOnly && !isCustomer ? (
            <Button text onClick={() => setEditSupplierId(data?.supplier?.id)}>
              <FontAwesomeIcon icon={faPenToSquare} color={colors.primary600} />
            </Button>
          ) : null
        }
      />
    </Div>
  );

  const sectionsMap = {
    onlyAttachments: [
      {
        key: '0',
        title: 'Anexos',
        component: fileObj
      }
    ],
    onlySupplier: [
      {
        key: '0',
        title: 'Dados do fornecedor',
        component: supplierObj
      }
    ],
    imageWithSupplier: [
      {
        key: '0',
        title: 'Imagens',
        component: imageObj
      },
      {
        key: '2',
        title: 'Dados do fornecedor',
        component: supplierObj
      }
    ],
    onlyImage: [
      {
        key: '0',
        title: 'Imagens',
        component: imageObj
      }
    ],
    imageWithAttachments: [
      {
        key: '0',
        title: 'Imagens',
        component: imageObj
      },
      {
        key: '1',
        title: 'Anexos',
        component: fileObj
      }
    ],
    all: [
      {
        key: '0',
        title: 'Anexos',
        component: fileObj
      },
      {
        key: '1',
        title: 'Imagens',
        component: imageObj
      },
      {
        key: '2',
        title: 'Dados do fornecedor',
        component: supplierObj
      }
    ],
    noImage: [
      {
        key: '0',
        title: 'Anexos',
        component: fileObj
      },
      {
        key: '1',
        title: 'Dados do fornecedor',
        component: supplierObj
      }
    ]
  };

  const getKey = () => {
    const isProduct = type === refurbishItemType.product;
    const isLabor = type === refurbishItemType.labor;

    if (onlyLibraryFields) {
      return isProduct ? 'onlyImage' : '';
    }

    if (isLibrary || isTemplate) {
      if (isLabor) return 'onlySupplier';
      if (isProduct) return 'imageWithSupplier';
      return '';
    }

    if (!renderColumns?.idSupplier && isCustomer) {
      return isProduct && renderColumns?.image ? 'imageWithAttachments' : 'onlyAttachments';
    }

    if (isProduct) {
      return !renderColumns?.image && isCustomer ? 'noImage' : 'all';
    }

    return 'noImage';
  };

  const sections = sectionsMap[getKey()] || [];

  return (
    <Drawer
      open
      title={
        <Div gap={spaces.space1}>
          {_isMobile && (
            <Div onClick={onClose} padding={spaces.space1}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Div>
          )}
          <Div width={spaces.space4} height={spaces.space4} align="center" justify="center">
            <FontAwesomeIcon
              icon={(id ? detailTitle[_type] : createTitle[_type])?.icon}
              style={{ color: colors.neutral300 }}
            />
          </Div>
          <Title>{(id ? detailTitle[_type] : createTitle[_type])?.name}</Title>
          {!onlyLibraryFields && !_isMobile && (
            <LinkedItemTag
              linkedItem={linkedItem}
              isLibrary={isLibrary}
              isEdit={data?.id}
              isSinapi={data?.externalBaseCode}
            />
          )}
        </Div>
      }
      onClose={() => onClose(false)}
      width={calcDrawerWidth(showComponents?.showComments, _isMobile)}
      padding="0"
      closeIcon={false}
      hideFooter={readOnly}
      extra={<DrawerHeaderComponents components={headerComponents} />}
      footer={drawerFooter}
    >
      {loading ? (
        <CenteredLoader />
      ) : (
        <>
          <ConfirmModal
            open={confirmModalProps}
            onClose={() => setConfirmModalProps(null)}
            title={confirmModalProps?.title}
            alertInfo={confirmModalProps?.alertInfo}
            description={confirmModalProps?.description}
            alertInfoPadding={`${spaces.space1} ${spaces.space1}`}
            onSubmit={confirmModalProps?.onSubmit}
          />
          {showCatalogDrawer && (
            <CatalogDrawer
              open
              shouldConfirm
              tabsToOpen={[0, 1]}
              cardProps={{ addTooltipText: 'Vincular', addIcon: faLink, iconStyle: { margin: '-3.5px' } }}
              disableTypeFilter
              newItemTypeRedirect={_type}
              fixedType={refurbishItemType.productLabor}
              onClose={() => setShowCatalogDrawer(false)}
              subtitle="Busque o item da biblioteca para vincular com o item selecionado"
              onSubmit={val => {
                setShowCatalogDrawer(false);
                handleSaveLink(val);
              }}
            />
          )}
          {showEditLibraryItemModal && (
            <EditItemModal
              id={linkedItem?.id}
              type={linkedItem?.type}
              onlyLibraryFields
              isLibrary
              onlyLabor
              model="item"
              isChildrenDrawer
              onClose={() => {
                setShowEditLibraryItemModal(false);
              }}
              afterSubmit={() => {
                handleGet();
              }}
            />
          )}
          <Container>
            <Div direction="row" $fullHeight>
              <Div direction="column" align="flex-start" $fullHeight $fullWidth>
                {isLibrary && data?.id && (
                  <WarningBar
                    type="light"
                    padding={spaces.space1}
                    margin={`${spaces.space2} ${spaces.space2} 0 ${spaces.space2}`}
                    align="flex-start"
                    width={`calc(100% - ${spaces.space4})`}
                    warning={
                      onlyLibraryFields
                        ? // eslint-disable-next-line max-len
                          'Ao editar os campos abaixo, essas informações são atualizadas em todos os orçamentos onde o item aparece.'
                        : `Ao editar ${
                            type === refurbishItemType.product
                              ? 'o nome, código, unidade, imagens, peso ou as dimensões do produto'
                              : 'o nome, código, ou unidade do serviço'
                          }, essas informações são atualizadas em todos os orçamentos onde o item aparece.`
                    }
                    style={{ flexShrink: 0 }}
                  />
                )}
                {!onlyLibraryFields && _isMobile && (
                  <Div padding={`${spaces.space2} ${spaces.space2} 0`}>
                    <LinkedItemTag
                      linkedItem={linkedItem}
                      isLibrary={isLibrary}
                      isEdit={data?.id}
                      isSinapi={data?.externalBaseCode}
                    />
                  </Div>
                )}
                <Div $fullWidth>
                  <EditableInput
                    id="input-name"
                    value={name}
                    onChange={newName => {
                      setName(newName);
                    }}
                    disabled={isCustomer || linkedItem || readOnly}
                    initEdit={!data?.id}
                    placeholder={!data?.id ? `Nome do ${detailTitle[type]?.name.toLowerCase()}` : '-'}
                    className="form-component-name"
                    textArea
                  />
                </Div>
                {DetailsContent}
                {!(onlyLibraryFields && type === refurbishItemType.labor) && (
                  <DrawerTabs sections={sections} selectedTab={selectedTab} onTabChange={setSelectedTab} />
                )}
              </Div>
              {!isTemplate && !isLibrary && (
                <DrawerComments
                  show={showComponents.showComments}
                  onToggle={value => {
                    setShowComponents({ ...showComponents, showComments: value });
                  }}
                  data={data}
                  model={model}
                  tooltipText="Os comentários são compartilhados entre os módulos de Orçamento e Planejamento.
                Tudo que você comentar aqui também será apresentado no Planejamento"
                  isMobile={_isMobile}
                />
              )}
              {showComponents?.showDeleteModal && (
                <ConfirmModal
                  text="Deseja realmente excluir esse item?"
                  onClose={() => setShowComponents({ ...showComponents, showDeleteModal: false })}
                  onSubmit={() => {
                    handleDelete({ displayToast: 'Operação realizada com sucesso!', refresh: false }).then(resp => {
                      if (!resp?.error) {
                        handleGet();
                        onClose(false);
                      }
                    });
                  }}
                />
              )}
            </Div>
          </Container>
          {hasSupplier && !hideSupplier && editSupplierId && (
            <EditOrCreateCustomerOrSupplier
              id={editSupplierId}
              model="supplier"
              onClose={() => {
                setEditSupplierId(null);
                handleGet();
              }}
            />
          )}
        </>
      )}
    </Drawer>
  );
};

EditItemModal.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  idReference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func,
  model: PropTypes.string,
  readOnly: PropTypes.bool,
  tab: PropTypes.string,
  isTemplate: PropTypes.bool,
  groupedReference: PropTypes.instanceOf(Object),
  type: PropTypes.number,
  renderColumns: PropTypes.instanceOf(Object),
  isLibrary: PropTypes.bool,
  onlyLibraryFields: PropTypes.bool,
  libraryProps: PropTypes.instanceOf(Object),
  afterSubmit: PropTypes.func,
  isChildrenDrawer: PropTypes.bool,
  initialValues: PropTypes.instanceOf(Object),
  createInLibraryAndAddToRefurbish: PropTypes.bool,
  isProductConciliation: PropTypes.bool,
  disableInsideCompositionActions: PropTypes.bool
};

export default memo(EditItemModal);
