import React from 'react';
import * as Yup from 'yup';
import { Paragraph, Subtitle } from '../../../components/Text/Text';
import { Div, spaces } from '../../../styles/style';
import { idReference, simpleName, requiredNumber } from '../schema';

const nfseConfigSchema = Yup.object().shape({
  NFSe: Yup.boolean().required(),
  federalTaxRegime: Yup.number()
    .nullable()
    .when('NFSe', {
      is: true,
      then: idReference
    }),
  cnae: Yup.string()
    .nullable()
    .when('NFSe', {
      is: true,
      then: simpleName
    }),
  im: Yup.string()
    .nullable()
    .when('NFSe', {
      is: true,
      then: simpleName
    }),
  isCulturalIncentive: Yup.boolean()
    .nullable()
    .when('NFSe', {
      is: true,
      then: Yup.boolean()
        .nullable()
        .required('Campo obrigatório')
    }),
  serialNumber: Yup.string()
    .nullable()
    .when('NFSe', {
      is: true,
      then: simpleName
    }),
  rpsNumber: Yup.number()
    .nullable()
    .when('NFSe', {
      is: true,
      then: requiredNumber
    })
    .when('NFSe', {
      is: false,
      then: Yup.number()
        .transform((value, originalValue) => (originalValue === '' ? undefined : value))
        .nullable()
    })
});

const typeItems = [
  { label: 'Sim', value: true },
  { label: 'Não', value: false }
];

const nfseConfigMapping = () => ({ NFSe: _NFSe }) => {
  return {
    NFSe: {
      type: 'switch',
      customLabel: 'Habilitar emissão de NFS-e',
      elementTooltip: 'Ative a emissão de notas fiscais de serviço de forma integrada às suas receitas.'
    },
    infosTitle: {
      type: 'custom',

      Component: () => (
        <Div direction="column" gap={spaces.space1} align="start">
          <Subtitle>Informações fiscais</Subtitle>
          <Paragraph type="small">
            Recomendamos a validação dos dados a serem preenchidos com o seu contador ou prefeitura.
          </Paragraph>
        </Div>
      ),
      xs: 24,
      md: 24
    },
    federalTaxRegime: {
      name: `Regime especial de tributação`,
      type: 'select',
      required: _NFSe,
      nameTooltip: `Modalidade de tributação em que sua empresa está inserida.
        Você encontra esta informação no seu cadastro na prefeitura, ou com sua contabilidade.`,
      dataType: 'federalTaxRegimeList',
      placeholder: 'Selecione',
      xs: 24,
      md: 8
    },
    cnae: {
      name: 'Código CNAE principal',
      type: 'cnae',
      required: _NFSe,
      nameTooltip: `Classifica a atividade econômica de uma empresa.
        Ele pode ser facilmente identificado no comprovante de inscrição e de situação
        cadastral ou no xml da última nota fiscal emitida.`,
      placeholder: 'Ex: 0000-0/00',
      xs: 24,
      md: 8
    },
    im: {
      name: 'Inscrição Municipal',
      type: 'text',
      required: _NFSe,
      nameTooltip: `É o número de identificação municipal da sua empresa na prefeitura.
        Você pode encontrar esta informação em seu cadastro na prefeitura, ou em sua última nota fiscal emitida.`,
      placeholder: 'Ex: 0.000.000/000-0',
      xs: 24,
      md: 8
    },
    isCulturalIncentive: {
      name: 'Incentivador cultural',
      type: 'radioSwitch',
      required: _NFSe,
      nameTooltip: `Selecione 'Sim' caso sua empresa seja classificada como incentivadora cultural.
        Isso também poderá afetar a apuração de impostos da sua empresa.`,
      items: typeItems,
      defaultValue: false,
      xs: 24,
      md: 8,
      width: '100%'
    },
    serialNumber: {
      name: 'Número de série',
      type: 'text',
      required: _NFSe,
      nameTooltip: `O padrão da série de RPS pode variar de acordo com a prefeitura,
        podendo ser formado por números ou letras (exemplo: 1,2,NFS,A). Saiba mais`,
      placeholder: 'Ex: 1',
      xs: 24,
      md: 8
    },
    rpsNumber: {
      name: 'Número do RPS',
      type: 'maskedNumber',
      mask: 'number',
      maskOptions: {
        thousandsSeparatorSymbol: '',
        allowDecimal: false,
        decimalSymbol: '',
        decimalLimit: 0,
        integerLimit: 9
      },
      required: _NFSe,
      nameTooltip: `Documento com as informações do serviço prestado
      enviado para a prefeitura autorizar a emissão da nota fiscal de serviço.
        Você pode encontrar esta informação no seu cadastro na prefeitura, ou na sua última nota fiscal emitida.`,
      placeholder: 'Ex: 1',
      xs: 24,
      md: 8
    }
  };
};

export { nfseConfigMapping, nfseConfigSchema };
