import React, { useMemo, useState } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { useSelector } from 'react-redux';
import SimpleAccordion from '../Accordion/SimpleAccordion';
import { useContextHook } from '../../contexts/GeneralContext';
import { columns } from '../../lib/mapping/TableOrList/paymentInvoiceColumns';
import { TableWrapper } from './PaymentInvoiceList.styles';
import { Div, colors, spaces } from '../../styles/style';
import { Paragraph, Subtitle } from '../Text/Text';
import Button from '../Button/Button';
import TooltipIcon from '../Tooltip/TooltipIcon';
import SimpleTable from '../Table/SimpleTable';
import HowDoesItWorksButton from '../Button/HowDoesItWorksButton';
import useCRUD from '../../_Hooks/useCRUD';
import ImportInvoiceXMLModal from '../Modal/ImportInvoiceXMLModal';
import { Content } from '../../_Pages/Payments/Payment.style';
import WarningBar from '../Alert/WarningBar';
import SendNfseDrawer from '../Drawer/SendNfseDrawer';
import InvoiceDrawer from '../Drawer/InvoiceDrawer';
import { hasPermission } from '../../routes/Common/PrivateRoute';

const PaymentInvoiceList = () => {
  const { handleCreate } = useCRUD({ model: 'payment', immediatelyLoadData: false });
  const { values, isView, setField, isCreate, installmentView, billType, isCharge, refreshData } = useContextHook();
  const { user } = useSelector(state => state.authReducer);
  const { plans, permissions } = useSelector(state => state.setup);
  const [invoiceSelected, setInvoiceSelected] = useState(null);
  const [isImporting, setIsImporting] = useState(false);
  const [error, setError] = useState(null);
  const [invoicesToCreate, setInvoicesToCreate] = useState(null);
  const [openSendNfseDrawer, setOpenSendNfseDrawer] = useState(false);
  const _hasNFSePermission = hasPermission(user, ['emitNfse'], plans, permissions);
  const {
    installmentStatuses,
    paymentStatuses,
    nfseStatus,
    nfeStatus,
    invoiceStatus,
    paymentInvoiceSource,
    typeNf
  } = useSelector(state => state.setup.enums || {});

  const _billType = values?.billType || billType;

  const canSendNfse =
    values?.id &&
    (installmentView
      ? _billType === 'income' &&
        values?.payment?.ownBusiness &&
        values?.idInstallmentStatus !== installmentStatuses.cancelled
      : _billType !== 'expense' && values?.ownBusiness && values?.idPaymentStatus !== paymentStatuses.cancelled);

  const handleChange = ({ id, field, value }) => {
    const _invoices = [...values?.invoices];
    const index = _invoices.findIndex(item => item.id === id);
    _invoices[index][field] = value;
    setField('invoices')(_invoices);
  };

  const getTooltipText = () => {
    if (isCharge) return 'Adicione a nota fiscal no pagamento para visualizar nas parcelas.';
    if (_billType === 'income') return 'Adicione a nota fiscal na receita para visualizar nas parcelas.';
    return 'Adicione a nota fiscal na despesa para visualizar nas parcelas.';
  };
  const handleNewInvoice = () => {
    const _invoices = [...(values?.invoices ?? []), { id: uuidV4(), number: null, date: null, new: true }];
    setField('invoices')(_invoices);
  };

  const handleRemove = id => {
    const _invoices = [...values?.invoices];
    const index = _invoices.findIndex(item => item.id === id);
    if (_invoices[index].new) _invoices.splice(index, 1);
    else _invoices[index].deleted = true;
    setField('invoices')(_invoices);
  };

  const handleOpenXML = row => {
    setInvoiceSelected(row);
  };
  const _columns = columns({
    isView,
    handleChange,
    handleRemove,
    handleOpenXML,
    nfseStatus,
    nfeStatus,
    invoiceStatus,
    paymentInvoiceSource,
    typeNf
  });

  const attachFile = e => {
    const file = e.target.files[0];
    if (!file) return;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file?.name);
    formData.append('type', 'invoiceListImport');
    setIsImporting(true);
    handleCreate({ values: formData, postPathOptions: '/nfe', refresh: false, customCatch: true })
      .then(resp => {
        const { invoices } = resp.payment;
        const invoicesFromField = values?.invoices ?? [];
        const _invoices = [...invoicesFromField, ...invoices];
        const alreadyImported = invoicesFromField.find(item => item.accessKey === invoices[0].accessKey);
        if (resp?.error || alreadyImported) {
          setError(
            resp?.error || {
              code: 'API-B-00222',
              extraInfo: {
                invoiceKey: alreadyImported.accessKey,
                serie: alreadyImported?.number
              }
            }
          );
          setInvoicesToCreate(_invoices);
        } else {
          setField('invoices')(_invoices);
        }
      })
      .catch(err => {
        const { data } = err?.response || {};
        setError(data);
      })
      .finally(() => {
        setIsImporting(false);
      });
  };

  const handleImportClick = () => {
    document.getElementById('fileInput').value = null;
    document.getElementById('fileInput').click();
  };

  const Table = useMemo(() => {
    const _invoices = values?.invoices?.filter(item => !item.deleted);
    return (
      <Div direction="column" align="start" gap={spaces.space2}>
        {canSendNfse && _hasNFSePermission && (
          <WarningBar type="light" warning="Emita sua nota fiscal sem precisar sair da plataforma!" width="100%" />
        )}
        {_invoices?.length > 0 ? (
          <TableWrapper>
            <SimpleTable columns={_columns} pagination={false} data={_invoices} isExpandable={false} />
          </TableWrapper>
        ) : (
          <Div>
            <Paragraph type="small">
              {!isView
                ? 'Adicione a nota fiscal manualmente ou importe um XML da NF-e para extrair os dados automaticamente.'
                : 'Nenhuma nota fiscal adicionada.'}
            </Paragraph>
          </Div>
        )}
        {canSendNfse && _hasNFSePermission && (
          <Button id="send-nfse-button" type="primary" align="start" onClick={() => setOpenSendNfseDrawer(true)}>
            Emitir NFS-e
          </Button>
        )}
      </Div>
    );
  }, [values?.invoices, isView]);

  return (
    <>
      {isView && !installmentView ? (
        <SimpleAccordion
          title="Notas Fiscais"
          initOpen={isCreate || values?.invoices?.length > 0}
          id="payment-attachments"
          withPaddingContainer
        >
          {Table}
        </SimpleAccordion>
      ) : (
        <Content padding={installmentView ? 0 : null}>
          {installmentView ? (
            <Div>
              <Subtitle>Notas Fiscais</Subtitle>
              <TooltipIcon
                text={getTooltipText()}
                icon={faInfoCircle}
                iconColor={colors.primary500}
                style={{ marginLeft: spaces.space1 }}
              />
            </Div>
          ) : (
            <Subtitle>Notas Fiscais</Subtitle>
          )}

          {Table}
          {!isView && (
            <Div gap={spaces.space2}>
              <Button type="primary" minWidth="120px" onClick={handleNewInvoice}>
                Adicionar
              </Button>
              <Button type="light" minWidth="120px" onClick={handleImportClick} loading={isImporting}>
                Importar XML
              </Button>
              <HowDoesItWorksButton id="howXMLWorks" style={{ padding: '0px' }} />
              <input type="file" id="fileInput" style={{ display: 'none' }} onChange={attachFile} accept=".xml" />
            </Div>
          )}
        </Content>
      )}
      {invoiceSelected && <InvoiceDrawer invoiceSelected={invoiceSelected} setInvoiceSelected={setInvoiceSelected} />}
      {error && (
        <ImportInvoiceXMLModal
          onClose={() => setError(null)}
          initialError={error}
          onImportSuccess={() => {
            setField('invoices')(invoicesToCreate);
            setError(null);
          }}
        />
      )}
      {openSendNfseDrawer && (
        <SendNfseDrawer
          idPayment={installmentView ? values?.idPayment : values?.id}
          value={installmentView ? values?.price : values?.total}
          date={installmentView ? values?.dueDate : values?.billingDate}
          companyCustomer={values?.companyCustomer || values?.payment?.companyCustomer}
          open={openSendNfseDrawer}
          onClose={refresh => {
            setOpenSendNfseDrawer(false);
            if (refresh && refreshData) refreshData();
          }}
        />
      )}
    </>
  );
};

export default PaymentInvoiceList;
