import * as Yup from 'yup';

import { password, confirmNewPassword } from '../schema';

const schema = Yup.object().shape({
  newPassword: password,
  confirmNewPassword
});

export default schema;
