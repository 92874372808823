import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Divider, Row, Switch, Upload } from 'antd';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import styled from 'styled-components';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { faEye, faEyeSlash, faUpload } from '@fortawesome/pro-solid-svg-icons';
import { capitalize } from 'vobi_lib';
import Button from '../Button/Button';
import AttachmentList from '../List/AttachmentList';
import useCRUD from '../../_Hooks/useCRUD';
import CenteredLoader from '../Loader/CenteredLoader';
import { colors, Div, fonts, spaces } from '../../styles/style';
import Input from '../Input/Input';
import useFormState from '../../_Hooks/useFormState';
import { simpleName } from '../../lib/mapping/schema';
import Label from '../Label/Label';
import { Paragraph, Subtitle } from '../Text/Text';
import TooltipIcon from '../Tooltip/TooltipIcon';

const schema = Yup.object().shape({
  password: Yup.string().when('files', {
    is: files => Array.isArray(files) && files.length > 0,
    then: simpleName
  }),
  // eslint-disable-next-line func-names
  files: Yup.array().test('files-required-if-password', 'É necessário adicionar o certificado', function(value) {
    const { password } = this.parent || {};
    if (password && value.length <= 0) {
      toast.error('É necessário adicionar o certificado');
      return false;
    }
    return true;
  }),
  // eslint-disable-next-line func-names
  access: Yup.string().test('access-required', 'Campo obrigatório', function(value) {
    const { accessPassword } = this.parent || {};
    if (accessPassword) {
      return !!value;
    }
    return true;
  }),
  // eslint-disable-next-line func-names
  accessPassword: Yup.string().test('accessPassword-required', 'Campo obrigatório', function(value) {
    const { access } = this.parent || {};
    if (access) {
      return !!value;
    }
    return true;
  })
});

const StyledUpload = styled(Upload)`
  width: 100%;
  div {
    width: 100%;
  }
`;

const CredentialSection = ({ companyEntity, setCompany, isSendNFSe, loadingAll, setLoadingAll }) => {
  const initState = {
    ...(companyEntity?.userName ? { access: companyEntity?.userName } : {}),
    password: '',
    files: [],
    cnpj: companyEntity?.cnpj
  };

  const paragraphText = isSendNFSe
    ? `Autenticação necessária para emissões fiscais no seu município.
      Pode ser realizada através de certificado digital e/ou usuário e senha.`
    : `Autenticação necessária para integração com a Sefaz Nacional.
      Deve ser realizada através de certificado digital.`;

  const { handleCreate, handleUpdate, loading: _loading } = useCRUD({
    model: 'companyEntity',
    immediatelyLoadData: false
  });

  useEffect(() => {
    setLoadingAll(_loading);
  }, [_loading]);

  const { formState, handleBlur, handleChange, setField, handleSubmit } = useFormState(initState, schema, false, true);
  const { values, errors } = formState;

  const [withAccessPassword, setWithAccessPassword] = useState(companyEntity?.withCredentials);

  const handleFocus = () => {
    if (!withAccessPassword) return;
    setWithAccessPassword(false);
    handleChange('accessPassword', '');
  };

  const attachFile = file => {
    const _file = new File([file], 'certificado.pfx', {
      contentType: file.type,
      lastModified: file.lastModified
    });
    setField('files')([_file]);
    return false;
  };

  const handleChangeRadio = e => {
    return handleUpdate({
      updatePathOptions: '/updateAccount',
      values: { NFe: e, cnpj: companyEntity?.cnpj },
      refresh: false,
      displayToast: `Busca automática de NF-e ${e ? 'habilitada' : 'desabilitada'} com sucesso!`
    }).then(resp => {
      if (resp?.error) return;
      setCompany(resp);
    });
  };

  const handleSave = e => {
    handleSubmit(() => {
      setLoadingAll(true);
      const file = values?.files[0];
      const fileValues = new FormData();
      file && fileValues.append('file', file);

      const { access, accessPassword, password, cnpj } = values;

      password && fileValues.append('password', password);
      cnpj && fileValues.append('cnpj', cnpj);
      access && fileValues.append('access', access);
      accessPassword && fileValues.append('accessPassword', accessPassword);
      fileValues.append('withCertificate', companyEntity?.withCertificate);

      return handleCreate({
        values: fileValues,
        postPathOptions: '/sendCredentials',
        displayToast: false,
        noLoading: true,
        refresh: false
      }).then(resp => {
        setLoadingAll(false);
        if (resp?.error || resp?.error?.erro) {
          const { message } = resp?.error || resp?.error?.erro || {};
          toast.error(message || 'Erro ao enviar certificado');
          return;
        }

        setCompany({
          ...companyEntity,
          withCertificate: !!file,
          withCredentials: !!access
        });

        const message = [file && 'certificado', access && 'usuário'].filter(Boolean);
        toast.success(`${capitalize(message.join(' e '))} enviado com sucesso`);
      });
    }, e);
  };

  const searchNFe = !isSendNFSe && (
    <Div gap={spaces.space1}>
      <Switch
        name="switch"
        type="switch"
        size="small"
        checked={companyEntity?.NFe || false}
        onChange={handleChangeRadio}
        loading={_loading || loadingAll}
      />
      <Div gap={spaces.space0}>
        <Paragraph type="small">Habilitar busca automática de NF-e recebidas</Paragraph>
        <TooltipIcon
          tooltipProps={{ placement: 'right' }}
          icon={faInfoCircle}
          text={`Ative a consulta automática de notas fiscais de produto (NF-e) emitidas contra o seu CNPJ.
              Certifique-se de que os dados do negócio e o Certificado Digital A1 estão preenchidos corretamente.`}
          iconColor={colors.primary500}
        />
      </Div>
    </Div>
  );

  const CertificatePasswordInput = (
    <Div direction="column" align="start" gap={spaces.space1} $fullWidth>
      <Div gap={spaces.space0}>
        <Label color={colors.neutral600} fontWeight={fonts.weight500} marginBottom="0">
          Senha do certificado
        </Label>
        {!isSendNFSe && companyEntity?.NFe && (
          <Paragraph type="small" color={colors.red500}>
            *
          </Paragraph>
        )}
      </Div>

      <Input
        type="password"
        id="password"
        name="password"
        autoComplete="new-password"
        autoCapitalize="off"
        autoCorrect="off"
        spellCheck="false"
        status={errors.password ? 'error' : ''}
        iconRender={visible =>
          visible ? (
            <FontAwesomeIcon icon={faEye} color={colors.neutral300} />
          ) : (
            <FontAwesomeIcon icon={faEyeSlash} color={colors.neutral300} />
          )
        }
        placeholder="Informe a senha do seu certificado"
        onChange={handleChange('password')}
        onBlur={handleBlur('password')}
        value={values.password}
      />
    </Div>
  );

  const AddCertificate = (
    <Div direction="column" align="start" gap={spaces.space1} $fullWidth>
      <Div gap={spaces.space0}>
        <Label color={colors.neutral600} fontWeight={fonts.weight500} marginBottom="0">
          Certificado digital
        </Label>
        {!isSendNFSe && companyEntity?.NFe && (
          <Paragraph type="small" color={colors.red500}>
            *
          </Paragraph>
        )}
        <TooltipIcon
          tooltipProps={{ placement: 'right' }}
          icon={faInfoCircle}
          text="Importe seu certificado digital (Modelo A1 e-CNPJ)."
          iconColor={colors.primary500}
          size="sm"
        />
      </Div>
      {!companyEntity?.withCertificate ? (
        <StyledUpload accept=".pfx" beforeUpload={attachFile} style={{ width: '100%' }} itemRender={() => null}>
          <Button type="primary" ghost fullWidth icon={<FontAwesomeIcon icon={faUpload} />}>
            Adicionar certificado digital
          </Button>
        </StyledUpload>
      ) : null}
    </Div>
  );

  const UserAccessInput = (
    <Div direction="column" align="start" gap={spaces.space1} $fullWidth>
      <Div gap={spaces.space0}>
        <Label color={colors.neutral600} fontWeight={fonts.weight500} marginBottom="0">
          Usuário
        </Label>
        <TooltipIcon
          tooltipProps={{ placement: 'right' }}
          icon={faInfoCircle}
          text="Informe o usuário e senha que você utiliza para acesso ao site do portal nacional."
          iconColor={colors.primary500}
          size="sm"
        />
      </Div>
      <Input
        type="text"
        id="access"
        name="access"
        status={errors.access ? 'error' : ''}
        iconRender={visible =>
          visible ? (
            <FontAwesomeIcon icon={faEye} color={colors.neutral300} />
          ) : (
            <FontAwesomeIcon icon={faEyeSlash} color={colors.neutral300} />
          )
        }
        placeholder="Nome de usuário"
        onChange={handleChange('access')}
        onBlur={handleBlur('access')}
        value={values.access}
      />
    </Div>
  );

  const HasFiles = () => {
    return values?.files.length || companyEntity?.withCertificate ? (
      <AttachmentList
        style={{ width: '100%' }}
        title="Certificado"
        hideType
        preventUpload
        files={values?.files || companyEntity?.withCertificate ? [{ filename: 'certificado.pfx' }] : []}
        setFiles={() => {
          setField('files')([]);
          companyEntity?.withCertificate && setCompany({ ...companyEntity, withCertificate: false });
        }}
        mdSize={14}
        gap={spaces.space4}
      />
    ) : null;
  };

  const PasswordAccessInput = (
    <Div direction="column" align="start" gap={spaces.space1} $fullWidth>
      <Label color={colors.neutral600} fontWeight={fonts.weight500} marginBottom="0">
        Senha
      </Label>
      <Input
        type="password"
        id="accessPassword"
        name="accessPassword"
        status={errors.accessPassword ? 'error' : ''}
        iconRender={visible =>
          visible ? (
            <FontAwesomeIcon icon={faEye} color={colors.neutral300} />
          ) : (
            <FontAwesomeIcon icon={faEyeSlash} color={colors.neutral300} />
          )
        }
        placeholder="Senha de acesso "
        onChange={handleChange('accessPassword')}
        onBlur={handleBlur('accessPassword')}
        value={withAccessPassword ? '******' : values.accessPassword}
        onFocus={handleFocus}
      />
    </Div>
  );

  return (
    <Div direction="column" align="start" gap={spaces.space2}>
      {searchNFe}
      <Div direction="column" align="start" gap={spaces.space1}>
        <Div align="start" gap={spaces.space0}>
          <Subtitle>Dados de acesso</Subtitle>
          {isSendNFSe && companyEntity?.NFSe && (
            <Paragraph type="small" color={colors.red500}>
              *
            </Paragraph>
          )}
        </Div>

        <Paragraph type="small">{paragraphText}</Paragraph>
      </Div>
      <Div direction="column" width="384px" align="start" gap={spaces.space2}>
        {AddCertificate}
        {loadingAll ? <CenteredLoader /> : HasFiles()}
        <Row style={{ width: '100%' }}>{CertificatePasswordInput}</Row>
        {isSendNFSe && (
          <>
            <Divider id="divider-section" style={{ margin: '0' }} />
            <Row style={{ width: '100%' }}>{UserAccessInput}</Row>
            <Row style={{ width: '100%' }}>{PasswordAccessInput}</Row>
          </>
        )}
      </Div>
      <Button type="primary" loading={loadingAll} align="end" onClick={handleSave}>
        Enviar dados de acesso
      </Button>
    </Div>
  );
};

CredentialSection.propTypes = {
  companyEntity: PropTypes.instanceOf(Object),
  setCompany: PropTypes.func,
  isSendNFSe: PropTypes.bool,
  setLoadingAll: PropTypes.func,
  loadingAll: PropTypes.bool
};

export default CredentialSection;
