import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { Row, Col } from 'antd';

import FileCard from '../Card/FileCard';
import FileUpload from '../File/FileUpload';
import { Paragraph } from '../Text/Text';
import CenteredLoader from '../Loader/CenteredLoader';

import css from './AttachmentList.module.scss';
import { TypeCol, FileStatus, IconRemove } from './AttachmentList.style';

// Hook
import useViewport from '../../_Hooks/useViewport';
import useCRUD from '../../_Hooks/useCRUD';
import { spaces, Div, colors } from '../../styles/style';
import Select from '../Select/Select';

const AttachmentList = ({
  style,
  files,
  setFiles,
  readOnly,
  idRefurbish,
  preventUpload,
  onRemoveFile,
  hideType,
  dataType = 'fileType',
  enumsName = 'enums',
  hideTitle = false,
  title = 'Arquivos',
  mdSize = 18,
  gap
}) => {
  const { user } = useSelector(state => state.authReducer) || {};
  const { userType, [dataType]: enumData = {} } = useSelector(state => state.setup[enumsName]);
  const { handleUpdate: updateFileType } = useCRUD({
    model: 'file',
    immediatelyLoadData: false
  });
  const { isMobile } = useViewport(window.innerWidth);
  const [isLoading, setLoading] = useState(false);

  const handleRemoveFile = index => {
    let _file = null;
    setFiles(prev => {
      _file = { ...prev?.[index] };
      prev.splice(index, 1);
      return [...prev];
    });
    onRemoveFile && onRemoveFile(_file);
  };

  const handleAddFile = newFiles => {
    setFiles(prev => [...prev, ...newFiles]);
    setLoading(false);
  };

  const handleChangeFileType = index => filetype => {
    const file = files[index];
    if (file && file.id) {
      updateFileType({ id: file.id, values: { ...file, idRefurbish, filetype }, refresh: false }).then();
    }

    setFiles(prev => {
      const _prev = [...(prev || [])];
      _prev[index].filetype = filetype;

      return [..._prev];
    });
  };

  const translateType = value => {
    const data = Array.isArray(enumData) && enumData?.find(item => item?.value === value);
    return data ? data?.label : null;
  };

  const renderFile = ({ file }) => (
    <Paragraph type="small" ellipsis color={colors.neutral600}>
      {file?.filename || file?.name}
    </Paragraph>
  );

  return (
    <div className={css['c-attachment']} style={style}>
      {isLoading ? (
        <CenteredLoader />
      ) : (
        <>
          {files?.length ? (
            <div style={{ width: '100%' }}>
              {!isMobile() && !hideTitle && (
                <Row className={css['c-attachment--header']}>
                  <Col md={12} sm={12} xs={12}>
                    <Paragraph>{title}</Paragraph>
                  </Col>
                  <Col md={12} sm={12} xs={12}>
                    {hideType ? null : <Paragraph>Tipo</Paragraph>}
                  </Col>
                </Row>
              )}
              {files?.map((file, index) => (
                <Row
                  gutter={8}
                  key={file.fullpath}
                  className={css['c-attachment--list']}
                  style={{ marginBottom: spaces.space1, width: '100%', gap }}
                >
                  <Col md={2} sm={2} xs={3}>
                    <Div padding={spaces.space1} width="fit-content">
                      <FileCard data={file} onlyIcon width={spaces.space3} height={spaces.space4} />
                    </Div>
                  </Col>
                  <Col md={hideType ? mdSize : 10} sm={10} xs={10} className={css['c-attachment--vertical-center']}>
                    <a href={file.fullpath} target="_blank" rel="noopener noreferrer">
                      {renderFile({ file })}
                    </a>
                  </Col>

                  <TypeCol style={{ gap: spaces.space1 }} md={hideType ? 4 : 12} sm={12} xs={11}>
                    {readOnly ? (
                      <Paragraph type="small">{translateType(file.filetype)}</Paragraph>
                    ) : (
                      <Select
                        style={{ ...(hideType ? { display: 'none' } : {}) }}
                        dataType="fileType"
                        name="type"
                        value={file.filetype}
                        disabled={readOnly}
                        fullWidth
                        onChange={handleChangeFileType(index)}
                        placeholder={!readOnly && 'Tipo'}
                        id={`file-type-${index + 1}`}
                      />
                    )}
                    {!readOnly && user?.userType !== userType?.customer?.value && !file?.status ? (
                      <Div padding={spaces.space1}>
                        <IconRemove
                          icon={faTrashCan}
                          onClick={() => handleRemoveFile(index)}
                          id={`file-remove-${index + 1}`}
                        />
                      </Div>
                    ) : null}
                    {file?.status && (
                      <FileStatus color={enumData[file?.status]?.color}>{enumData[file?.status]?.label}</FileStatus>
                    )}
                  </TypeCol>
                </Row>
              ))}
            </div>
          ) : (
            <Paragraph color={colors.neutral500}>Nenhum arquivo adicionado.</Paragraph>
          )}
        </>
      )}

      {!readOnly && !preventUpload ? (
        <>
          <Paragraph>Selecione um arquivo de no máximo 50mb</Paragraph>
          <FileUpload
            id="file-input"
            onChange={handleAddFile}
            multiple
            showGallery={false}
            text="Adicionar documento"
            listType={null}
            buttonProps={{ type: 'primary' }}
            type="dragger"
            startLoading={setLoading}
          />
        </>
      ) : null}
    </div>
  );
};

AttachmentList.propTypes = {
  files: PropTypes.instanceOf(Object),
  setFiles: PropTypes.func,
  readOnly: PropTypes.bool,
  idRefurbish: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  preventUpload: PropTypes.bool,
  style: PropTypes.instanceOf(Object),
  onRemoveFile: PropTypes.func,
  hideType: PropTypes.bool,
  dataType: PropTypes.string,
  enumsName: PropTypes.string,
  hideTitle: PropTypes.bool,
  title: PropTypes.string,
  mdSize: PropTypes.number,
  gap: PropTypes.string
};

export default AttachmentList;
