import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';
import { spaces } from '../../styles/style';
import Description from './Description';
import SimpleAccordion from '../Accordion/SimpleAccordion';
import { mappingDescriptionConf } from '../../lib/mapping/Description/mappingDescriptionConf';
import WarningBar from '../Alert/WarningBar';

const StyledDiv = styled.div`
  padding-top: ${spaces.space2};
  padding-bottom: ${spaces.space5};
  td {
    padding-right: ${spaces.space2};
  }
`;

const InvoiceNfseContent = ({ data }) => {
  const { invoiceStatus } = useSelector(state => state.setup.enums);

  return (
    <>
      {data?.situationCode === invoiceStatus.errorWithMessage && (
        <WarningBar
          warning={`Erro ${data?.situationCode} - ${data?.situationDescription}`}
          width="auto"
          padding={spaces.space2}
          iconProps={{ size: 'md' }}
          $justifyContent="center"
          margin={spaces.space2}
        />
      )}
      <StyledDiv>
        <SimpleAccordion title="Dados do negócio" initOpen id="business-info" withPaddingContainer>
          <Description
            column={{ md: 3, xs: 1 }}
            noPadding
            mapping={mappingDescriptionConf?.companyEntityInfo}
            data={data?.companyEntityInfo}
            $customAfter=" "
          />
        </SimpleAccordion>
        <Divider />
        <SimpleAccordion title="Dados da emissão" initOpen id="emit-invoice" withPaddingContainer>
          <Description
            column={{ md: 4, xs: 1 }}
            noPadding
            mapping={mappingDescriptionConf?.emitNfse}
            data={data}
            $customAfter=" "
          />
        </SimpleAccordion>
        <Divider />
        <SimpleAccordion title="Dados do cliente" initOpen id="customer-invoice" withPaddingContainer>
          <Description
            column={{ md: 12, xs: 1 }}
            noPadding
            mapping={mappingDescriptionConf?.customerNfse({ personType: data?.personType })}
            data={data}
            $customAfter=" "
          />
        </SimpleAccordion>
        <Divider />
        <SimpleAccordion title="Dados do serviço" initOpen id="service-invoice" withPaddingContainer>
          <Description
            column={{ md: 12, xs: 1 }}
            noPadding
            mapping={mappingDescriptionConf?.serviceProfile({ isConstructionService: data?.isConstructionService })}
            data={data}
            $customAfter=" "
          />
        </SimpleAccordion>
        <Divider />
        <SimpleAccordion title="Impostos retidos e outras deduções" initOpen id="taxes" withPaddingContainer>
          <Description
            column={{ md: 4, xs: 2 }}
            noPadding
            mapping={mappingDescriptionConf?.taxes}
            data={data}
            $customAfter=" "
          />
        </SimpleAccordion>
        <Divider />
        <SimpleAccordion title="Valor da nota" initOpen id="totals" withPaddingContainer>
          <Description
            column={{ md: 3, xs: 3 }}
            noPadding
            mapping={mappingDescriptionConf?.totals}
            data={data}
            $customAfter=" "
          />
        </SimpleAccordion>
      </StyledDiv>
    </>
  );
};

InvoiceNfseContent.propTypes = {
  data: PropTypes.instanceOf(Object)
};

export default InvoiceNfseContent;
