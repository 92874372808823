import React, { useMemo, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import fetchCEP from 'cep-promise';
import { Col, Row, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import useFormState from '../../_Hooks/useFormState';
import FormItemComponentCreator from './FormItemCreator';
import { FormSubmitData } from '../../lib/helpers/formFunctions';
import { Div, breakpoints, colors, fonts, spaces } from '../../styles/style';
import Button from '../Button/Button';
import { Error } from '../Text/Text';

const StyledLabelContainer = styled.div`
  ${props =>
    props.inline
      ? css`
          height: 24px;
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;
        `
      : css`
          margin-bottom: ${spaces.space0};
        `}
`;

const Label = styled.span`
  color: ${colors.neutral500};
  margin-bottom: ${props => !props.inline && spaces.space0};
  font-weight: ${fonts.weight400};
  font-size: ${fonts.sizeSm};
`;

const WarningCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: 10px;
  min-height: 20px;
  > span {
    font-weight: ${fonts.weight500};
    color: red;
  }
`;

const SubmitDescription = styled.span`
  color: ${colors.neutral500};
  font-size: ${fonts.sizeSm};
  font-style: normal;
  line-height: normal;
  strong {
    color: ${colors.neutral500};
  }
`;

const SubmitDiv = styled(Div)`
  gap: ${spaces.space2};
  margin-top: ${spaces.space2};
  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FormComponent = styled.form``;

const Form = ({
  data = null,
  schema,
  mapping,
  setTabIndex = f => f,
  id = '',
  loading = false,
  onSubmit,
  displayButtons = true,
  create = false,
  margin = '8px 0',
  marginTop = '0',
  displayLabel = true,
  forceUpdate = { current: false },
  onlyContent,
  keepOldValues,
  children = null,
  customButtonTitle,
  small,
  saveWarningMessage = '',
  initialParentValues = {},
  cleanForm,
  onValueChanged = f => f,
  onTouchedChanged = f => f,
  enableReinitialize,
  onFormChange = f => f,
  isFormValid = f => f,
  customToast = false,
  childWidth,
  childHeight,
  justify = 'end',
  submitDescription,
  resetValuesChanged,
  keepSchema,
  validateOnRender,
  newValues,
  ...props
}) => {
  const [parentValues, setParentValues] = useState(initialParentValues);
  const mappingObj = mapping.constructor === Object ? mapping : mapping(data || {});
  const mappingKeys = Object.keys(mappingObj);
  const _data = Object.fromEntries(mappingKeys.map(e => [e, mappingObj[e]?.defaultValue || null]));
  const initialState = { ..._data, ...data };
  const isSchemaFunction = typeof schema === 'function';
  const [_schema, setSchema] = useState(isSchemaFunction ? schema(initialState) : schema);
  const {
    formState,
    handleChange,
    handleBlur,
    handleSubmit,
    setField,
    setValues,
    valuesChanged,
    handleResetValuesChanged,
    reset,
    isValid,
    validateValues
  } = useFormState(initialState, _schema, enableReinitialize, false, customToast);
  const { values, touched, errors } = formState;
  const buttonTitle = create ? 'Enviar' : 'Salvar';

  useEffect(() => {
    onValueChanged(valuesChanged);
  }, [valuesChanged]);

  useEffect(() => {
    resetValuesChanged && handleResetValuesChanged();
  }, [resetValuesChanged]);

  useEffect(() => {
    if (Object.keys(touched || {})?.length) onTouchedChanged(values, touched);
  }, [touched]);

  useEffect(() => {
    if (errors && Object.keys(touched).length) setTabIndex(0);
    if (errors) isFormValid(isValid());
  }, [errors]);

  useEffect(() => {
    if (validateOnRender) validateValues();
  }, []);

  const handleChangeSelect = (key, selectValue, multiple, hasChildren) => {
    if (hasChildren) setParentValues(prev => ({ ...prev, [key]: selectValue }));
    if (multiple && selectValue) return setField(key)([...selectValue]);
    return setField(key)(selectValue || null);
  };

  const handleChangeZipcode = field => e => {
    const zipcode = e.target.value;
    if (zipcode && zipcode.replace(/_/g, '').length >= 9) {
      fetchCEP(zipcode).then(resp =>
        setValues({
          ...values,
          state: resp?.state || resp?.uf,
          city: resp.city,
          neighborhood: resp.neighborhood,
          street: resp.street,
          [field]: resp.cep
        })
      );
    }
  };

  const handleRemoveImage = field => index => {
    const newArr = [...(Array.isArray(values[field]) ? values[field] : [values[field]])];
    newArr.splice(index, 1);
    if (newArr.length > 0) {
      setField(field)(newArr);
    } else {
      setField(field)(null);
    }
  };

  useEffect(() => {
    if (forceUpdate.current) {
      // eslint-disable-next-line no-param-reassign
      forceUpdate.current = false;
      const newData = keepOldValues ? { ...values, ...data, ...newValues } : { ...data, ...newValues };
      setValues(newData);
      onFormChange(newData);
    }
  }, [data, forceUpdate.current]);

  useEffect(() => {
    if (!newValues) return;
    setValues({ ...values, ...newValues });
  }, [newValues]);

  useEffect(() => {
    onFormChange(values);
    if (keepSchema) return;
    if (isSchemaFunction) setSchema(schema(values));
    else setSchema(schema);
  }, [values, schema]);

  const handleSubmitForm = () => {
    if (cleanForm) {
      reset(initialState);
    }
    return onSubmit(FormSubmitData(values, mappingObj));
  };

  const _mapping = useMemo(() => (mapping.constructor === Object ? mapping : mapping(values, handleChange)), [
    data,
    mapping,
    values
  ]);

  const FormOrContent = useMemo(() => (onlyContent ? ({ children: _children }) => _children : FormComponent), [
    onlyContent
  ]);

  return (
    <FormOrContent id={id} onSubmit={handleSubmit(handleSubmitForm)} {...props}>
      <Row gutter={16}>
        {Object.keys(_mapping).map(property => {
          const {
            value,
            name = '',
            xs,
            md,
            sm,
            offset,
            options,
            style,
            tooltipText,
            type,
            model,
            propertyAlias,
            labelClassName,
            id: _id,
            overlayStyle,
            customTooltip,
            flexWidth,
            inline,
            displayLabelOnlyWhenNotEmpty,
            ExtraAction
          } = _mapping[property];
          const formValue = value
            ? value.reduce((acc, valueProperty) => `${acc ? `${acc}&` : acc}${values[valueProperty] || 0}`, '')
            : values[type === 'string' && model ? propertyAlias || model : property];
          const nameOrLabel = options?.find(o => (o.id || o.value) === Number(formValue));
          const disableValue = nameOrLabel?.name || nameOrLabel?.label || null;
          const handleFunctions = {
            setField,
            handleChange,
            handleRemoveImage,
            handleBlur,
            handleChangeSelect,
            handleChangeZipcode
          };

          return (
            <Col
              key={property}
              xs={xs || 24}
              md={md || 12}
              sm={sm || 12}
              offset={offset}
              style={{
                margin: !offset && margin,
                ...(inline
                  ? {
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }
                  : {}),
                ...style
              }}
              id={`${_id || property}-div`}
              flex={flexWidth}
            >
              {displayLabel && (!displayLabelOnlyWhenNotEmpty || formValue) && (
                <StyledLabelContainer inline={inline || ExtraAction} id="teste">
                  <Label className={labelClassName} inline={inline} id={`${property}-lbl`}>
                    {name}
                  </Label>
                  {tooltipText && (
                    <Tooltip placement="top" title={tooltipText} overlayStyle={overlayStyle} zIndex={1500}>
                      <FontAwesomeIcon
                        style={{ marginLeft: spaces.space1 }}
                        color={colors.primary600}
                        icon={faInfoCircle}
                      />
                    </Tooltip>
                  )}
                  {customTooltip && customTooltip}
                  {ExtraAction && <ExtraAction />}
                </StyledLabelContainer>
              )}
              <div style={{ width: inline || '100%' }}>
                <FormItemComponentCreator
                  formId={id}
                  key={`item${property}`}
                  mapping={_mapping}
                  disableValue={disableValue}
                  formValue={formValue}
                  property={property}
                  handleFunctions={handleFunctions}
                  small={small}
                  parentValues={parentValues}
                  formState={formState}
                  inline={inline}
                />
              </div>
              {(validateOnRender || touched[property]) && errors[property] && <Error>{errors[property][0]}</Error>}
            </Col>
          );
        })}
        {children && <div style={{ padding: 8, width: childWidth, height: childHeight }}>{children}</div>}
      </Row>

      <div style={{ display: 'flex', justifyContent: `flex-${justify}`, alignItems: 'center', gap: '30px', marginTop }}>
        {saveWarningMessage && valuesChanged && (
          <Row justify="start">
            <WarningCol>
              <span>{saveWarningMessage}</span>
            </WarningCol>
          </Row>
        )}

        {displayButtons && !onlyContent && (
          <Row justify={justify}>
            <Col style={{ justifyContent: justify }}>
              <SubmitDiv>
                <div>
                  <Button id="custom-button-form" type="primary" loading={loading} htmlType="submit">
                    {customButtonTitle || buttonTitle}
                  </Button>
                </div>
                {submitDescription && <SubmitDescription>{submitDescription}</SubmitDescription>}
              </SubmitDiv>
            </Col>
          </Row>
        )}
      </div>
    </FormOrContent>
  );
};

Form.propTypes = {
  data: PropTypes.instanceOf(Object),
  mapping: PropTypes.instanceOf(Object).isRequired,
  schema: PropTypes.instanceOf(Object).isRequired,
  id: PropTypes.string,
  margin: PropTypes.string,
  loading: PropTypes.bool,
  create: PropTypes.bool,
  customButtonTitle: PropTypes.string,
  displayLabel: PropTypes.bool,
  displayButtons: PropTypes.bool,
  setTabIndex: PropTypes.func,
  onSubmit: PropTypes.func,
  forceUpdate: PropTypes.instanceOf(Object),
  children: PropTypes.instanceOf(Object),
  onlyContent: PropTypes.bool,
  keepOldValues: PropTypes.bool,
  small: PropTypes.bool,
  saveWarningMessage: PropTypes.string,
  marginTop: PropTypes.string,
  initialParentValues: PropTypes.instanceOf(Object),
  cleanForm: PropTypes.bool,
  onValueChanged: PropTypes.func,
  onTouchedChanged: PropTypes.func,
  enableReinitialize: PropTypes.bool,
  onFormChange: PropTypes.func,
  isFormValid: PropTypes.func,
  customToast: PropTypes.string,
  childWidth: PropTypes.string,
  childHeight: PropTypes.string,
  justify: PropTypes.string,
  submitDescription: PropTypes.string,
  resetValuesChanged: PropTypes.bool,
  keepSchema: PropTypes.bool,
  validateOnRender: PropTypes.bool,
  newValues: PropTypes.instanceOf(Object)
};

export default Form;
