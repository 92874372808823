import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSelector } from 'react-redux';
import useFileUpload from '../File/useFileUpload';
import useCRUD from '../../_Hooks/useCRUD';
import { getFileNameFromURL } from '../../lib/helpers/helper';
import { useContextHook as useRefurbish } from '../../contexts/Context';

const errorUpload = 'error';
const thumbnailPrefix = 'thumbnail_';

const download = imageUrl => {
  return axios.get(imageUrl, { withCredentials: false, responseType: 'blob' });
};

const Thumbnail = ({ file, alt, ...props }) => {
  const { user } = useSelector(state => state.authReducer);
  const isAnonymous = !!user?.anonymous;
  const { data: refurbish } = useRefurbish();
  const [error, setError] = useState(false);
  const [src, setSrc] = useState(file?.thumbnail || 'error');
  const { createThumbnail, handleUpload } = useFileUpload();

  const { handleUpdate } = useCRUD({
    model: 'file',
    immediatelyLoadData: false
  });

  const updateThumbnail = str =>
    handleUpdate({
      id: file.id,
      values: { thumbnail: str || errorUpload, idRefurbish: refurbish?.id },
      refresh: false
    });

  const onError = () => {
    if (error || isAnonymous) return {};

    const newSrc = file?.fullpath;
    setSrc(newSrc);
    setError(true);

    if (!file?.id || !file?.idCompany || file?.isExternal || file?.thumbnail === errorUpload) return {};

    return download(newSrc)
      .then(downloaded => [downloaded, getFileNameFromURL(file?.fullpath)])
      .then(([downloaded, filename]) =>
        createThumbnail(new File([downloaded?.data], `${thumbnailPrefix}${filename}`, { type: downloaded?.data?.type }))
      )
      .then(thumbnail => handleUpload({ fileObject: thumbnail }))
      .then(uploaded => updateThumbnail(uploaded?.location))
      .catch(() => updateThumbnail(errorUpload));
  };

  return <img src={src} alt={alt} onError={onError} {...props} />;
};

Thumbnail.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.number,
    idCompany: PropTypes.number,
    isExternal: PropTypes.bool,
    fullpath: PropTypes.string,
    thumbnail: PropTypes.string
  }),
  alt: PropTypes.string
};

export default Thumbnail;
