import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { breakpoints, colors, radius, spaces } from '../../styles/style';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: ${props => props.padding || `${spaces.space1} ${spaces.space2}`};
  border: 1px solid ${props => (props.$error ? colors.red500 : colors.neutral100)};
  border-radius: ${radius.radius1};

  svg {
    width: 24px;
    ${props => props.$error && `color: ${colors.red500};`}
  }

  input {
    width: 90%;
    border: 0;
    padding: 0 ${spaces.space2};
  }

  .ant-input-affix-wrapper {
    width: 90%;
    border: 0;
    padding: 0 0 0 ${spaces.space2};

    @media (min-width: ${breakpoints.desktop}) {
      width: 93%;
    }
  }

  .ant-input-suffix {
    margin-left: ${spaces.space3};
  }
`;

const LoginFormRow = ({ error, children, ...props }) => {
  return (
    <Container $error={error} {...props}>
      {children}
    </Container>
  );
};

LoginFormRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  error: PropTypes.bool
};

LoginFormRow.defaultProps = {
  children: null,
  error: false
};

LoginFormRow.displayName = 'LoginFormRow';

export default LoginFormRow;
