import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Input, Radio, Space } from 'antd';
import Modal from './Modal';
import { ListContainer } from './ConfirmModal.styled';
import WarningBar from '../Alert/WarningBar';
import { spaces } from '../../styles/style';
import { Paragraph } from '../Text/Text';
import Label from '../Label/Label';

const { TextArea } = Input;

const ConfirmModal = ({
  text,
  description,
  preInfoDescription,
  onSubmit,
  showObservation,
  onClose,
  title = 'Confirmar ação',
  placeholder,
  redirectTo,
  alertInfo,
  alertInfoPadding,
  modalWidth = 440,
  formId,
  options,
  list,
  children,
  onlySubmit,
  descriptionId,
  label,
  ...props
}) => {
  const [observation, setObservation] = useState(null);
  const history = useHistory();
  const [optionValue, setOptionValue] = useState('only');

  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    if (onSubmit) {
      onSubmit({ observation, ...(options && { optionValue }) } || true);
      !onlySubmit && onClose(false);
      if (redirectTo) history.push(redirectTo);
    } else {
      onClose(true);
    }
  };

  const changeOptions = e => {
    setOptionValue(e?.target?.value);
  };

  return (
    <Modal
      formId={formId}
      open
      center
      title={title}
      contentLabel="Confirmação"
      onSubmit={!formId ? handleSubmit : null}
      onClose={opts => onClose(false, opts)}
      width={modalWidth}
      zIndex={1100}
      {...props}
    >
      {children || (
        <Space size={8} direction="vertical" style={{ width: '100%' }}>
          {preInfoDescription ? <Paragraph>{preInfoDescription}</Paragraph> : null}
          {alertInfo && (
            <WarningBar warning={alertInfo} padding={alertInfoPadding} width="100%" margin={`0 0 ${spaces.space2} 0`} />
          )}
          <Paragraph>{text}</Paragraph>
          {description ? <Paragraph id={descriptionId}>{description}</Paragraph> : null}
          {showObservation ? (
            <div>
              {label && <Label marginBottom={spaces.space0}>{label}</Label>}
              <TextArea
                id="input-text"
                value={observation}
                onChange={e => setObservation(e?.target?.value)}
                toolbar={false}
                placeholder={placeholder}
                style={{ height: '100px' }}
              />
            </div>
          ) : null}
          {list && (
            <ListContainer>
              {list.map(item => (
                <li key={item}>{item}</li>
              ))}
            </ListContainer>
          )}
          {options && (
            <Radio.Group onChange={changeOptions} value={optionValue}>
              {options.map(opt => (
                <Radio value={opt?.value}>{opt?.label}</Radio>
              ))}
            </Radio.Group>
          )}
        </Space>
      )}
    </Modal>
  );
};

ConfirmModal.propTypes = {
  description: PropTypes.string,
  preInfoDescription: PropTypes.string,
  text: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  showObservation: PropTypes.bool,
  title: PropTypes.string,
  redirectTo: PropTypes.string,
  placeholder: PropTypes.string,
  alertInfo: PropTypes.string,
  alertInfoPadding: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  modalWidth: PropTypes.number,
  formId: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  list: PropTypes.instanceOf(Array),
  children: PropTypes.instanceOf(Object),
  onlySubmit: PropTypes.bool,
  label: PropTypes.string,
  descriptionId: PropTypes.string
};

export default ConfirmModal;
