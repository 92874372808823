import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Form from './Form';
import { purchaseItemMapping, purchaseSchema } from '../../lib/mapping/Form/purchaseSchema';
import useCRUD from '../../_Hooks/useCRUD';
import formatNumber from '../../lib/helpers/formatNumber';
import useViewport from '../../_Hooks/useViewport';
import { store } from '../../lib/config/redux-store';
import { spaces } from '../../styles/style';

const AddPurchaseItemForm = ({
  idCompany,
  handleSubmit,
  paymentView,
  onClose,
  type,
  hasPurchaseValuesPermission,
  isSolicitation = false
}) => {
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();
  const forceFormUpdate = useRef(false);
  const { setup } = store.getState();
  const { refurbishItemType = {} } = setup.enums;

  const [addItemFormValues, setAddItemFormValues] = useState(null);

  const { handleCreate: handleCreateLibraryItem } = useCRUD({
    model: 'item',
    immediatelyLoadData: false
  });

  const calculateTotalValue = values => {
    const { price, quantity } = values || {};
    const cleanPrice = formatNumber(price);
    const cleanQuantity = formatNumber(quantity);

    return cleanPrice * cleanQuantity || 0;
  };

  useEffect(() => {
    if (addItemFormValues?.price || addItemFormValues?.quantity) {
      const totalPrice = calculateTotalValue(addItemFormValues);
      const newFormValues = { ...addItemFormValues, totalPrice };
      setAddItemFormValues(newFormValues);
      forceFormUpdate.current = true;
    }
  }, [addItemFormValues?.price, addItemFormValues?.quantity]);

  return (
    <Form
      style={{ width: '100%', paddingBottom: spaces.space1 }}
      schema={purchaseSchema}
      forceUpdate={forceFormUpdate}
      mapping={() =>
        purchaseItemMapping({
          idCompany,
          type,
          refurbishItemType,
          openCustomCreate: searchValue => {
            handleCreateLibraryItem({
              values: {
                ...addItemFormValues,
                idItem: undefined,
                idUnit: addItemFormValues?.idUnit || 1,
                idCompany,
                name: searchValue,
                type
              }
            }).then(newItem => {
              setAddItemFormValues(prev => ({
                ...prev,
                idItem: newItem.id,
                item: { id: newItem?.id, name: newItem?.name },
                name: newItem?.name
              }));
              forceFormUpdate.current = true;
            });
          },
          setItem: fullItem => {
            setAddItemFormValues(prev => ({
              ...prev,
              code: fullItem?.code,
              idUnit: fullItem?.idUnit,
              price: fullItem?.price,
              idItem: fullItem?.value,
              item: { id: fullItem?.value, name: fullItem?.label },
              name: fullItem?.label,
              ...(fullItem?.units && {
                units: { id: fullItem?.units?.id, name: fullItem?.units?.name },
                idUnit: fullItem?.units?.id
              })
            }));
            forceFormUpdate.current = true;
            document.getElementById('purchase-quantity').focus();
          },
          setUnits: (_, fullUnits) => {
            setAddItemFormValues(prev => ({
              ...prev,
              idUnit: fullUnits?.value,
              units: { id: fullUnits?.value, name: fullUnits?.label }
            }));
            forceFormUpdate.current = true;
          },
          handleConfirm: () =>
            addItemFormValues?.idItem
              ? handleSubmit({
                  ...addItemFormValues,
                  type
                })
              : toast.error('Selecione um item.'),
          handleCancel: () => {
            onClose();
          },
          paymentView,
          isMobile: _isMobile,
          hasPurchaseValuesPermission,
          isPurchaseSolicitation: isSolicitation
        })
      }
      data={addItemFormValues}
      onFormChange={setAddItemFormValues}
      displayButtons={false}
      margin={_isMobile && !paymentView ? undefined : '0'}
    />
  );
};

AddPurchaseItemForm.propTypes = {
  idCompany: PropTypes.number,
  handleSubmit: PropTypes.func,
  paymentView: PropTypes.bool,
  onClose: PropTypes.func,
  type: PropTypes.number,
  hasPurchaseValuesPermission: PropTypes.bool,
  isSolicitation: PropTypes.bool
};

export default AddPurchaseItemForm;
