import React from 'react';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import 'antd/lib/date-picker/style/index';
import ptBR from 'antd/es/locale/pt_BR';
import { breakpoints, colors, fonts, radius, spaces } from '../../styles/style';
import useDisabledDate from '../../_Hooks/useDisabledDate';

const Picker = generatePicker(dayjsGenerateConfig);

const StyledPicker = styled(Picker)`
  &.form-component {
    
    &.ant-picker {
      padding: ${spaces.space0} ${spaces.space1};
      ${props =>
        props.columnStyle
          ? css`
              border: 1px solid ${colors.neutral200};
              border-radius: ${radius.radius1};
            `
          : css`
              border: 1px solid transparent;
            `};
      transition: background-color 0.3s ease, border-color 0.3s ease;


      .ant-picker-input {
        input {
          font-size: ${fonts.sizeMd} !important;
          color: ${colors.neutral600} !important;
          font-weight: ${fonts.weight400} !important;
          ${props =>
            props.readOnly &&
            `
            cursor: default;
          `}
        }
      }

      ${props =>
        props.readOnly &&
        `
        background-color: transparent;
        border-color: transparent;
        cursor: default;

        &:hover {
          background-color: transparent !important;
          border-color: transparent !important;
        }
      `}

      @media (max-width: ${breakpoints.tablet}) {
        padding: 0;
        border: none;
        width: 100%;
      }

      .ant-picker-input {
        gap: ${spaces.space0};

        input {
          border-radius: 0;
          color: ${colors.neutral600};
          font-size: ${fonts.sizeSm};

          @media (max-width: ${breakpoints.tablet}) {
            font-size: ${fonts.sizeMd};
            font-weight: ${fonts.weight00};
          }

          &::placeholder {
            color: ${colors.neutral400};
            font-size: ${fonts.sizeMd} !important;
          }
        }
      }

      &:hover {
        background-color: ${colors.neutral75};
        border-color: ${colors.neutral100};

        @media (max-width: ${breakpoints.tablet}) {
          background-color: transparent;
          border-color: transparent;
        }
      }
    }

    &.ant-picker-focused {
      border: 1px solid ${colors.neutral100};
      box-shadow: none;

      @media (max-width: ${breakpoints.tablet}) {
        border: none;
      }

      &:hover {
        border-color: ${colors.neutral100};
        background-color: ${colors.white};
      }

      .ant-picker-clear {
        opacity: 1;
      }
    }

    .ant-picker-input span.ant-picker-suffix,
    &.ant-picker-range span.ant-picker-suffix {
      color: ${colors.neutral400};
      width: ${spaces.space2};
      height: ${spaces.space2};
      margin: 0;
      order: -1;
      align-items: center;
      justify-content: center;
    }

    .ant-picker-clear {
      background-color: rgba(0, 0, 0, 0);
      transition: opacity 0.3s, color 0.3s;
      width: ${spaces.space3};
      height: ${spaces.space3};
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${colors.neutral600};
      opacity: 0;
    }

    .ant-picker-input:hover .ant-picker-clear,
    &:hover .ant-picker-clear {
      opacity: 1;
      color: ${colors.neutral600};
    }

    .ant-picker-clear:hover {
      color: ${colors.neutral700} !important;
    }
  }

  &.ant-picker {
    font-family: ${fonts.family};
    border-radius: ${radius.radius1};
    color: ${colors.neutral700};
    min-height: ${spaces.space4};

    ${props => props.$width && `width: ${props.$width};`}
    ${props => props.$height && `height: ${props.$height};`}

    input {
      color: ${colors.neutral700};
      ${props => props.$textAlign && `text-align: ${props.$textAlign};`}
      ${props => props.$fontSize && `font-size: ${props.$fontSize};`}
      line-height: 100%;
    }

    &:hover {
      border-color: ${colors.neutral500};
    }
  }

  &.ant-picker-small {
    input {
      font-size: ${fonts.sizeSm};
    }
    min-height: ${spaces.space3} !important;
  }

  &.ant-picker-focused {
    border-color: ${colors.neutral700};
    box-shadow: none;
  }

  ${props => props.$padding && `padding: ${props.$padding};`}
`;

const StyledPanel = styled.div`
  font-family: ${fonts.family};
  border-radius: ${radius.radius1};
  font-size: 12px;
  color: ${colors.neutral700};

  .ant-picker-header {
    border-bottom: none;
    span {
      color: ${colors.primary600};
    }
  }

  .ant-picker-content {
    thead {
      border-bottom: 1px solid ${colors.neutral100};
      tr > th {
        color: ${colors.neutral400};
      }
    }

    .ant-picker-cell {
      color: ${colors.neutral400};

      &.ant-picker-cell-in-view {
        color: ${colors.neutral700};
      }
    }
  }
`;

const DatePicker = ({ workDays = null, dayoffs = null, minDate = null, maxDate = null, className, ...props }) => {
  const { verifyDisabledDate } = useDisabledDate({ workDays, dayoffs, minDate, maxDate });

  return (
    <StyledPicker
      {...props}
      className={className}
      disabledDate={verifyDisabledDate}
      locale={ptBR.DatePicker}
      panelRender={current => {
        return <StyledPanel onClick={e => e.stopPropagation()}>{current}</StyledPanel>;
      }}
    />
  );
};

DatePicker.propTypes = {
  workDays: PropTypes.instanceOf(Object),
  dayoffs: PropTypes.instanceOf(Array),
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  className: PropTypes.string,
  readOnly: PropTypes.bool
};

export default DatePicker;
