import React from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import WarningBar from '../Alert/WarningBar';
import { colors, Div, fonts, spaces } from '../../styles/style';
import { Paragraph } from '../Text/Text';
import Input from '../Input/Input';
import Label from '../Label/Label';
import useCRUD from '../../_Hooks/useCRUD';

const CancelNfseModal = ({ id, onClose }) => {
  const [cancelReason, setCancelReason] = React.useState('');

  const { data, handleUpdate, loading } = useCRUD({
    model: 'paymentInvoice',
    pathOptions: `/${id}`
  });

  const handleSubmit = () => {
    handleUpdate({
      updatePathOptions: `/cancel/${id}`,
      values: { cancelReason },
      refresh: false,
      displayToast: true
    }).then(resp => {
      if (!resp?.error) onClose(resp?.id);
    });
  };

  return (
    <Modal
      formId="reasonForm"
      open
      title="Cancelamento de nota fiscal"
      contentLabel="Confirmação"
      disableButton={!cancelReason}
      onSubmit={handleSubmit}
      loading={loading}
      onClose={() => onClose()}
      width={600}
      zIndex={1100}
    >
      <Div direction="column" align="start" gap={spaces.space2} $fullWidth>
        <WarningBar warning="Essa operação não poderá ser desfeita." width="100%" />
        <Paragraph>
          Para solicitarmos à prefeitura o cancelamento da nota fiscal{' '}
          <b>
            RPS {data?.rpsNumber}/Série {data?.serialNumber}
          </b>
          , descreva abaixo o motivo:
        </Paragraph>

        <Div direction="column" align="start" gap={spaces.space1} $fullWidth>
          <Div gap={spaces.space0}>
            <Label color={colors.neutral600} fontWeight={fonts.weight500} marginBottom="0">
              Motivo do cancelamento
            </Label>
            <Paragraph type="small" color={colors.red500}>
              *
            </Paragraph>
          </Div>

          <Input
            value={cancelReason}
            onChange={e => setCancelReason(e.target.value)}
            className="reason-textarea"
            type="textarea"
            placeholder="Ex: Serviço cancelado, erro nos dados do cliente, serviço incorreto."
          />
        </Div>
      </Div>
    </Modal>
  );
};

CancelNfseModal.propTypes = {
  id: PropTypes.instanceOf(Object),
  onClose: PropTypes.func.isRequired
};

export default CancelNfseModal;
