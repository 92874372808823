import styled, { css } from 'styled-components';
import { InputNumber } from 'antd';
import TextMaskInput from 'react-text-mask';
import { breakpoints, colors, fonts, radius, spaces } from '../../styles/style';

export const StyledDiv = styled.div`
  ${props =>
    props.withError &&
    css`
      border: 1px solid ${colors.red500};
      border-radius: ${radius.radius1};
    `};

  width: 100%;
  min-height: ${spaces.space3};
  min-width: ${props => props.width && props.width};
  display: flex;
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'space-between')};
  white-space: ${props => (props.noWrap ? 'nowrap' : null)};
  align-items: ${props => (props.align ? props.align : 'center')};

  @media (max-width: ${breakpoints.tablet}) {
    flex-basis: 100%;
    font-size: ${fonts.sizeSm};
    padding: ${props => (props.noPadding ? 0 : `0 ${spaces.space0}`)};
  }

  svg {
    display: none;
  }

  &.form-component-name {
    font-weight: ${fonts.weight700};
    font-size: ${fonts.sizeLg};
    width: 100%;
    cursor: pointer;
    padding: ${spaces.space1} ${spaces.space1} 0 ${spaces.space1};
    min-height: ${spaces.space5};

    .ant-input {
      padding: ${spaces.space0} ${spaces.space1};
    }

    input,
    textarea {
      font-weight: ${fonts.weight700};
      font-size: ${fonts.sizeLg};
      color: ${colors.neutral700};

      &[disabled] {
        cursor: not-allowed;
        background-color: ${colors.white};
        color: ${colors.neutral700};
        font-weight: ${fonts.weight700};
      }

      &::placeholder {
        font-size: ${fonts.sizeLg};
      }
    }
  }

  &.form-component {
    font-size: ${fonts.sizeMd} !important;

    @media (max-width: ${breakpoints.tablet}) {
      font-size: ${fonts.sizeMd};
      padding: 0;
    }

    svg {
      display: block;
    }

    .ant-input {
      &[readonly] {
        color: ${colors.neutral800} !important;
        font-weight: ${fonts.weight400} !important;
        font-size: ${fonts.sizeMd} !important;
        ${props =>
          props.columnStyle &&
          css`
            padding: 0 !important;
          `}
      }
    }

    .ant-input-affix-wrapper-readonly {
      background-color: transparent !important;
      border-color: transparent !important;
      pointer-events: none;
      font-weight: ${fonts.weight600};
      color: ${colors.neutral800} !important;
      font-weight: ${fonts.weight400} !important;
      font-size: ${fonts.sizeMd} !important;

      input {
        font-weight: ${fonts.weight600} !important;
      }
    }
    .ant-input-prefix {
      font-size: ${fonts.sizeMd};
      margin-right: 0;
      color: ${props => (parseInt(props.value, 10) === 0 || !props.value ? colors.neutral400 : colors.neutral600)};
    }

    .ant-input-suffix {
      font-size: ${fonts.sizeMd};
      color: ${props => (parseInt(props.value, 10) === 0 || !props.value ? colors.neutral400 : colors.neutral600)};

      @media (max-width: ${breakpoints.tablet}) {
        font-size: ${fonts.sizeMd};
      }
    }

    .ant-input-affix-wrapper {
      font-size: ${fonts.sizeMd};
      height: ${spaces.space4};
      padding: ${spaces.space0} ${spaces.space1} ${spaces.space0} ${spaces.space1};
      border-color: transparent;
      color: ${colors.neutral400};
      gap: ${spaces.space0};

      @media (max-width: ${breakpoints.tablet}) {
        font-size: ${fonts.sizeMd};
        padding: 0;
        background-color: ${colors.white};
        border: 0;
        gap: 0;
      }

      input {
        font-weight: ${fonts.weight400};
        border: none !important;
        background-color: transparent;
        color: ${colors.neutral600};

        &::placeholder {
          font-size: ${fonts.sizeMd};
        }
      }

      &.ant-input-affix-wrapper-disabled {
        background-color: ${colors.neutral75};
      }

      .ant-input-suffix {
        flex-direction: row-reverse;
        color: ${colors.neutral400};
        gap: ${spaces.space1};

        .ant-input-clear-icon {
          cursor: pointer;
          font-size: ${fonts.sizeMd};
          color: transparent;
          transition: color 0.3s ease;
        }
      }

      &:has(input:not([value=''])) {
        .ant-input-suffix {
          color: ${colors.neutral600};
        }
      }
    }

    .ant-input-affix-wrapper-focused {
      border-color: ${colors.neutral100};
      background-color: ${colors.white};

      &:hover {
        background-color: ${colors.white} !important;
        border-color: ${colors.neutral100} !important;
      }
    }

    .ant-input-affix-wrapper:hover {
      background-color: ${colors.neutral75};
      border-color: ${colors.neutral100};

      .ant-input-clear-icon {
        color: ${colors.neutral600};

        &:hover {
          color: ${colors.neutral700};
        }
      }
    }

    > input {
      &::placeholder {
        font-size: ${fonts.sizeMd} !important;
      }
      padding: ${spaces.space0} calc(${spaces.space1} + ${spaces.space0});
      ${props =>
        props.columnStyle
          ? css`
              border: 1px solid ${colors.neutral200};
              border-radius: ${radius.radius1};
            `
          : css`
              border: 1px solid transparent;
            `};

      transition: background-color 0.3s ease, border-color 0.3s ease;
      color: ${colors.neutral600};
      background-color: transparent;
      min-height: ${spaces.space4};
      font-weight: ${fonts.weight400};

      &.ant-input-disabled {
        color: ${colors.neutral600};
        background-color: ${colors.neutral75};
        font-weight: ${fonts.weight400};
        ${props =>
          props.columnStyle
            ? css`
                border: 1px solid ${colors.neutral200};
                border-radius: ${radius.radius1};
              `
            : css`
                border: 1px solid transparent;
              `};
      }

      &[readonly] {
        font-weight: ${fonts.weight600};
        background-color: transparent;
        border-color: transparent;
        pointer-events: none;
      }

      &:not([readonly]):hover {
        ${props =>
          props.columnStyle
            ? css`
                border: 1px solid ${colors.neutral200};
                border-radius: ${radius.radius1};
                background-color: ${colors.neutral75};
              `
            : css`
                background-color: ${colors.neutral75};
                border-color: ${colors.neutral100};
              `};
      }

      &:not([readonly]):focus {
        border-color: ${colors.neutral100};
        background-color: ${colors.white};
      }
    }
  }

  input,
  textarea {
    font-size: inherit;
    border: 1px solid transparent;
    background-color: transparent;
    width: 100%;
    color: ${colors.neutral600};
    font-weight: ${fonts.weight500};

    ${props =>
      props.padding &&
      css`
        padding: ${props.padding};
      `};

    ${props =>
      props.withError &&
      css`
        border: 1px solid ${colors.red500};
        border-radius: ${radius.radius1};
      `};

    ${props =>
      props.marginRight &&
      css`
        margin-right: ${spaces.space1};
      `};

    &:hover {
      border: ${props => !props.disabled && `1px solid ${colors.neutral500}`};
      border-radius: ${radius.radius1};
      cursor: ${props => props.cursor || 'text'};

      svg {
        display: ${props => !props.disabled && 'flex'};
      }
    }

    &:focus {
      border: ${props => !props.disabled && `1px solid ${colors.neutral500}`};
      border-radius: ${radius.radius1};
      cursor: ${props => props.cursor || 'text'};
      background-color: white;

      svg {
        display: ${props => !props.disabled && 'flex'};
      }
    }

    &[disabled] {
      background-color: transparent;
      border-color: transparent;
      color: ${colors.neutral600};
      font-weight: ${fonts.weight500};

      &:hover {
        border-color: transparent;
        cursor: default;
      }

      ${props =>
        props.readOnly &&
        css`
          background-color: transparent;
          cursor: default;
          color: inherit;
          font: inherit;

          &:hover {
            background-color: transparent;
          }

          ::placeholder {
            color: currentColor;
          }
        `};
    }
  }
`;

export const StyledInputNumber = styled(InputNumber)`
  &.form-component {
    font-size: ${fonts.sizeMd} !important;
    width: 100%;
    height: ${spaces.space4};
  }
`;

export const EditContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: ${fonts.sizeSm};
  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
    `}

  svg {
    margin-left: ${spaces.space1};
    cursor: pointer;
  }
  .renameItem {
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease;
  }
  &:hover {
    .renameItem {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const MaskedInput = styled(TextMaskInput)`
  background-color: ${props => (props.disabled && !props.formReadOnly ? colors.neutral75 : undefined)} !important;
  border-radius: ${props => (props.disabled && !props.formReadOnly ? spaces.space0 : undefined)} !important;
  font-size: ${fonts.sizeMd} !important;

  ${props =>
    props.columnStyle
      ? css`
          border: 1px solid ${colors.neutral200};
          border-radius: ${radius.radius1};
          background-color: ${colors.neutral75};
        `
      : css`
          background-color: ${colors.neutral75};
          border-color: ${colors.neutral100};
        `};
  &[disabled] {
    ${props =>
      props.columnStyle
        ? css`
            border: 1px solid ${colors.neutral200} !important;
            border-radius: ${radius.radius1};
          `
        : css`
            border: 1px solid transparent;
          `};
  }
  &[readonly] {
    color: ${colors.neutral800} !important;
    font-weight: ${fonts.weight400} !important;
    font-size: ${fonts.sizeMd} !important;
    ${props =>
      props.columnStyle &&
      css`
        padding: 0px !important;
      `};
  }
  ${props =>
    props.status === 'error' &&
    css`
      border: 1px solid ${colors.red500} !important;
      border-radius: ${radius.radius1} !important;
    `}
`;
