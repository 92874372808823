import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import Button from '../Button/Button';
import { tableDynamicHeight } from '../../lib/helpers/helper';
import TableOrListV2 from './TableOrListV2';
import { Paragraph, Subtitle } from '../Text/Text';
import { columns } from '../../lib/mapping/TableOrList/serviceProfileColumns';
import useCRUD from '../../_Hooks/useCRUD';
import CenteredLoader from '../Loader/CenteredLoader';
import ServiceProfileDrawer from '../Drawer/ServiceProfileDrawer';
import { colors, Div, spaces } from '../../styles/style';
import ConfirmModal from '../Modal/ConfirmModal';
import WarningBar from '../Alert/WarningBar';

const ServiceProfileList = ({ companyEntity, setLoadingAll, loadingAll }) => {
  const idCompanyEntity = companyEntity?.id;
  const [openServiceDrawer, setOpenServiceDrawer] = useState({ open: false, id: null });
  const [modalDeleteService, setModalDeleteService] = useState(false);
  const { list, handleGet, handleDelete, loading } = useCRUD({
    model: 'serviceProfile',
    options: { where: { idCompanyEntity }, order: ['createdAt'] },
    immediatelyLoadData: true
  });

  useEffect(() => {
    setLoadingAll(loading);
  }, [loading]);

  const handleRowClick = rowItem => {
    setOpenServiceDrawer({ open: true, id: rowItem?.id });
  };

  const deleteItem = id => {
    return handleDelete({ id, displayToast: `Perfil de serviço deletado com sucesso!` });
  };

  const _columns = columns({ onDelete: setModalDeleteService, onEdit: handleRowClick });

  return loading || loadingAll ? (
    <CenteredLoader />
  ) : (
    <>
      <Div direction="column" align="start" gap={spaces.space2}>
        <Div direction="column" align="start" gap={spaces.space1}>
          <Div align="start" gap={spaces.space0}>
            <Subtitle>Perfis de serviço</Subtitle>
            {companyEntity?.NFSe && (
              <Paragraph type="small" color={colors.red500}>
                *
              </Paragraph>
            )}
          </Div>

          <Paragraph type="small">
            Dados sobre a prestação de serviços, tais como código de tributação, alíquota e descrição da atividade.
          </Paragraph>
        </Div>

        {list.length ? (
          <TableOrListV2
            rowSelection={false}
            onClick={handleRowClick}
            $customHover="inherit"
            columns={_columns}
            pagination={false}
            list={list}
            scroll={{ y: tableDynamicHeight({ list, maxHeight: 210 }), x: '100%' }}
            bugReload
          />
        ) : null}

        <Div gap={spaces.space2}>
          <Button
            type="primary"
            text
            align="start"
            gap={spaces.space1}
            onClick={() => setOpenServiceDrawer({ open: true, id: null })}
            disabled={!companyEntity?.im}
          >
            <FontAwesomeIcon icon={faPlus} /> Perfil de serviço
          </Button>
          {!companyEntity?.im && (
            <WarningBar
              type="light"
              warning="Para criar perfis de serviço cadastre e salve suas informações fiscais."
            />
          )}
        </Div>
      </Div>

      {modalDeleteService.open && (
        <ConfirmModal
          open={modalDeleteService.open}
          alertInfo="Essa operação não poderá ser desfeita."
          description="Ao excluir, o serviço será removido permanente e ninguém mais poderá acessá-lo."
          onClose={() => setModalDeleteService({ open: false, id: null })}
          onSubmit={() => deleteItem(modalDeleteService.id)}
          loading={loading}
        />
      )}

      {openServiceDrawer.open && (
        <ServiceProfileDrawer
          id={openServiceDrawer.id}
          cnpj={companyEntity?.cnpj}
          idCompanyEntity={idCompanyEntity}
          open={openServiceDrawer.open}
          onClose={refreshList => {
            setOpenServiceDrawer({ open: false, id: null });
            refreshList && handleGet();
          }}
        />
      )}
    </>
  );
};

ServiceProfileList.propTypes = {
  companyEntity: PropTypes.instanceOf(Object),
  setLoadingAll: PropTypes.func,
  loadingAll: PropTypes.bool
};

export default ServiceProfileList;
