import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { Input } from 'antd';

import { useHistory } from 'react-router-dom';
import LoginFormRow from './LoginFormRow';
import Button from '../Button/Button';

import useFormState from '../../_Hooks/useFormState';

import schema from '../../lib/mapping/Form/resetPasswordSchema';

import { LoginStyledForm } from '../../_Pages/Login/Login.styled';
import { colors } from '../../styles/style';
import { Error } from '../Text/Text';
import OTPInput from '../Input/OTPInput';

const initState = {
  newPassword: '',
  confirmNewPassword: '',
  otp: ''
};

const ResetPasswordForm = ({ onSubmit, loading, showOTP, sendOTP }) => {
  const history = useHistory();
  const { formState, handleBlur, handleChange, isValid } = useFormState(initState, schema);
  const { values, errors, touched } = formState;
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setSubmitted(true);

    if (!isValid()) {
      return;
    }

    onSubmit(values);
  };

  return (
    <LoginStyledForm>
      <LoginFormRow error={(submitted || touched.newPassword) && !!errors.newPassword}>
        <FontAwesomeIcon icon={faLock} color={colors.neutral300} />
        <Input.Password
          id="password"
          name="password"
          placeholder="Nova senha"
          iconRender={visible =>
            visible ? (
              <FontAwesomeIcon icon={faEye} color={colors.neutral300} />
            ) : (
              <FontAwesomeIcon icon={faEyeSlash} color={colors.neutral300} />
            )
          }
          onChange={handleChange('newPassword')}
          onBlur={handleBlur('newPassword')}
          value={values.newPassword}
          autoComplete="off"
        />
        {(submitted || touched.newPassword) && errors.newPassword && <Error>{errors.newPassword[0]}</Error>}
      </LoginFormRow>
      <LoginFormRow error={(submitted || touched.confirmNewPassword) && !!errors.confirmNewPassword}>
        <FontAwesomeIcon icon={faLock} color={colors.neutral300} />
        <Input.Password
          id="confirmNewPassword"
          name="confirmNewPassword"
          placeholder="Confirmar senha"
          iconRender={visible =>
            visible ? (
              <FontAwesomeIcon icon={faEye} color={colors.neutral300} />
            ) : (
              <FontAwesomeIcon icon={faEyeSlash} color={colors.neutral300} />
            )
          }
          onChange={handleChange('confirmNewPassword')}
          onBlur={handleBlur('confirmNewPassword')}
          value={values.confirmNewPassword}
          autoComplete="off"
        />
        {(submitted || touched.confirmNewPassword) && errors.confirmNewPassword && (
          <Error>{errors.confirmNewPassword[0]}</Error>
        )}
      </LoginFormRow>
      {showOTP && (
        <OTPInput
          handleBlur={handleBlur('otp')}
          handleChange={handleChange('otp')}
          value={values.otp}
          sendOTP={sendOTP}
          showIcon
        />
      )}
      <Button type="primary" disabled={loading} fullWidth onClick={handleSubmit}>
        Confirmar
      </Button>
      <Button type="primary" ghost disabled={loading} fullWidth onClick={() => history.push('/logout')}>
        Sair
      </Button>
    </LoginStyledForm>
  );
};

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  showOTP: PropTypes.bool,
  sendOTP: PropTypes.func
};

export default ResetPasswordForm;
