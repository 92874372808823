import styled from 'styled-components';
import { breakpoints, fonts, spaces } from '../../styles/style';

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow-y: auto;
  padding: ${spaces.space4} ${spaces.space2} ${spaces.space2} ${spaces.space2};
  gap: ${spaces.space1};

  #back-to-login {
    align-self: flex-start;
    font-weight: ${fonts.weight400};
    margin-top: ${spaces.space1};
    border: 0;
  }

  @media (min-width: ${breakpoints.mobile}) {
    max-width: 470px;
    margin: auto;
  }
`;

const LoginStyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${spaces.space1};

  button {
    margin-top: ${spaces.space1};
  }
`;

const LinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: ${spaces.space1};
`;

export { LoginContainer, LoginStyledForm, LinksContainer };
