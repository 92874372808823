import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSearch, faLock } from '@fortawesome/pro-light-svg-icons';
import { faCirclePlus } from '@fortawesome/pro-duotone-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { useHistory } from 'react-router-dom';

// permissions
import { hasPermission } from '../../routes/Common/PrivateRoute';

// Hooks
import useCRUD from '../../_Hooks/useCRUD';
import useViewport from '../../_Hooks/useViewport';

// context
import { useContextHook as useRefurbish, Context as TemplateContext } from '../../contexts/Context';

// components
import CenteredLoader from '../Loader/CenteredLoader';
import NoContent from '../NoContent/NoContent';
import NoTemplates from '../Images/NoTemplates';
import TemplateCard from '../Card/TemplateCard';
import EditOrCreateTemplate from '../../_Pages/Tools/Templates/EditOrCreateTemplate';
import ConfirmModal from './ConfirmModal';
import Button from '../Button/Button';
import { TabPane } from '../Tabs/Tabs';
import Modal from './Modal';
import Input from '../Input/Input';
import BubbleModalButton from '../Button/BubbleModalButton';
import TemplateFilter from '../Filters/TemplateFilter';
import HowDoesItWorksButton from '../Button/HowDoesItWorksButton';

import { StyledDiv, StyledBtnCard } from '../Card/TemplateCard.styled';
import { StyledTabs, StyledListHeader, StyledTemplateSection, NoContentContainer } from './TemplatesModal.styled';
import { colors, Div, Grid, spaces } from '../../styles/style';
import Pagination from '../Pagination/Pagination';
import { Paragraph, Subtitle } from '../Text/Text';

const ITEMS_PAGE = 11;
const PAGINATION = {
  offset: 1,
  limit: ITEMS_PAGE
};

const panelTitle = {
  all: 'Todos templates',
  vobi: 'Templates vobi',
  myTemplates: 'Meus templates',
  community: 'Templates de comunidade'
};

const images = {
  initialState: ({ title, description }) => (
    <NoContent
      title={title || 'Otimize seu tempo'}
      image={<NoTemplates />}
      description={
        description ||
        'Crie seu primeiro template para aplicar nos seus orçamentos, ' +
          'na criação de projetos, cronogramas, tarefas e muito mais!'
      }
      renderButton={false}
    />
  ),
  notFound: ({ keyword }) => {
    const Description = () => (
      <>
        Não foram encontrados resultados para sua busca de <b>{keyword}</b>
      </>
    );
    return <NoContent renderButton={false} title="Oh-Oh..." image={<NoTemplates />} description={<Description />} />;
  }
};

images.initialState.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};

images.notFound.propTypes = {
  keyword: PropTypes.string
};

const TemplatesModal = ({
  onClose = f => f,
  isRefurbish,
  onSubmitApply,
  afterSubmit = f => f,
  isApply = false,
  idRefurbishRow,
  isProject,
  title = 'Central de templates'
}) => {
  const { data: refurbish } = useRefurbish() || { data: { id: idRefurbishRow } };
  const { id: idRefurbish } = refurbish || { id: idRefurbishRow };
  const { templateModule, subItemsInitialValue, refurbishStatus, templateType } = useSelector(
    state => state.setup.enums
  );
  const isApplyOnOpportunity =
    isApply && refurbishStatus.activeOpportunity.includes(refurbish?.idStatus) && !onSubmitApply;

  const [queryString, setQueryString] = useState(PAGINATION);
  const [keyword, setKeyword] = useState('');
  const [tabIndex, setTabIndex] = useState('all');
  const [hasSearched, setHasSearched] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [idApplyTemplate, setIdApplyTemplate] = useState(null);
  const [subItems, setSubItems] = useState(subItemsInitialValue);
  const [isApplying, setIsApplying] = useState(false);
  const [applyTimeout, setApplyTimeout] = useState(false);
  const [currentFilters, setCurrentFilters] = useState({
    module: [!isRefurbish ? templateModule?.budget.value : templateModule?.all.value],
    type: !isProject && !idRefurbishRow ? [templateType.opportunity.value] : []
  });
  const history = useHistory();
  const { isMobile } = useViewport(window.innerWidth);
  const { user } = useSelector(state => state.authReducer);
  const { plans, permissions } = useSelector(state => state.setup);
  const shouldSearchRef = useRef(false);

  const redirectTo =
    (isApplyOnOpportunity && !onSubmitApply) || (!isProject && !idRefurbishRow) ? 'oportunidades' : 'projetos';

  const _hasPermissionTemplate = hasPermission(user, ['template'], plans, permissions);

  const routeChange = () => {
    history.push('/profissional/ferramentas/meus-templates/novo');
    if (onClose) onClose(true);
  };

  const applyOpportunity = () => {
    onSubmitApply().then(() => {
      toast.success('Parabéns, sua oportunidade foi transformada em projeto!');
      history.push(`/profissional/${redirectTo}/perfil/${idRefurbish}/geral`);
    });
  };

  const handleBtnCardClick = () => {
    if (!isProject && !isApply) {
      history.push(`/profissional/oportunidades/novo`);
      onClose(true);
    }
    if (isApplyOnOpportunity || (!isRefurbish && isProject)) {
      history.push(`/profissional/ferramentas/meus-templates/novo`);
    }
    if (!isProject && isRefurbish && isApply) {
      applyOpportunity();
    }
    if (isRefurbish && isProject && !isApplyOnOpportunity) {
      history.push(`/profissional/projetos/novo`);
      onClose(true);
    }
  };

  const onSubmit = item => {
    const path = `/profissional/${redirectTo}/${isRefurbish ? 'editar' : 'perfil'}/${idRefurbish || item.id}`;
    const budgetSuffix = !isRefurbish ? '/orcamento' : '';

    history.push(`${path}${budgetSuffix}`);
    onClose(true);
  };
  const mapTabQuery = {
    all: { onlyMine: null, onlyNull: null, where: { community: null } },
    vobi: { onlyMine: null, onlyNull: true, where: { community: null } },
    myTemplates: { onlyMine: true, where: { community: null } },
    community: {
      where: { community: true }
    }
  };

  const { list, handleCreate, loading, handleGet, totalItems } = useCRUD({
    model: 'template',
    immediatelyLoadData: false
  });

  const handleSearch = () => {
    const { name, where, ..._query } = queryString;
    return handleGet({
      refetchOptions: {
        where: {
          module: isRefurbish ? templateModule.all.value : templateModule.budget.value,
          type: !isProject && !idRefurbishRow ? [templateType.opportunity.value] : [],
          ...where,
          version: 'V2',
          name: name ? { like: `%${name}%` } : {}
        },
        order: [
          ['idCompany', 'DESC'],
          ['createdAt', 'DESC']
        ],
        ..._query
      }
    });
  };

  const create = values => {
    return handleCreate({
      values,
      postPathOptions: '/apply',
      displayToast: 'Template aplicado com sucesso.',
      refresh: true,
      timeout: 8000
    }).then(resp => {
      afterSubmit();
      return resp;
    });
  };

  const applyTemplate = () => {
    const _subItems = Object.entries(subItems).reduce((acc, [key, { checked }]) => ({ ...acc, [key]: checked }), {});
    setIsApplying(true);

    if (isRefurbish && isApply) {
      const promise = onSubmitApply ? onSubmitApply() : Promise.resolve();
      return promise
        .then(() => {
          return create({
            ..._subItems,
            idTemplate: idApplyTemplate,
            isRefurbish,
            idRefurbish
          });
        })
        .then(res => {
          if (res?.error?.statusCode === 504) {
            setApplyTimeout(true);
            return;
          }

          setIsApplying(false);
          history.push(`/profissional/${redirectTo}/perfil/${idRefurbish}/geral`);
        });
    }

    return create({
      ..._subItems,
      isRefurbish,
      idTemplate: idApplyTemplate,
      source: 'Template',
      isProject: isProject || null,
      ...(!isRefurbish && { idRefurbish })
    }).then(res => {
      if (res?.error?.statusCode === 504) {
        setApplyTimeout(true);
        return;
      }

      setIsApplying(false);
      onSubmit(res);
    });
  };

  const handleQueryStringChange = e => {
    const { value } = e.target;
    setHasSearched(!!value);
    setKeyword(value);
    setQueryString({ ...queryString, name: value, offset: 1 });
    shouldSearchRef.current = true;
  };

  const handleFilter = filter => {
    const { where } = queryString;
    setQueryString({ ...queryString, where: { ...where, ...filter }, offset: 1 });
    setCurrentFilters(prev => ({ ...prev, ...filter }));
    shouldSearchRef.current = true;
  };

  const handlePagination = page => {
    const newQueryString = { ...queryString, offset: page };
    setQueryString(newQueryString);
  };

  useEffect(() => {
    const { onlyNull, onlyMine, where, ...newQueryString } = queryString;
    shouldSearchRef.current = true;
    setQueryString({
      ...newQueryString,
      ...mapTabQuery[tabIndex],
      where: { ...where, ...mapTabQuery[tabIndex]?.where },
      offset: 1
    });
  }, [tabIndex]);

  useEffect(() => {
    const timer =
      shouldSearchRef.current &&
      setApplyTimeout(() => {
        handleSearch();
        shouldSearchRef.current = true;
      }, 400);
    return () => clearTimeout(timer);
  }, [queryString]);

  const StartFromScratch = () => (
    <StyledDiv role="presentation" onClick={() => handleBtnCardClick()}>
      <StyledBtnCard id="start-scratch">
        <div className="btn-container">
          <div>
            <div className="btn-icon-container">
              <FontAwesomeIcon icon={faCirclePlus} />
            </div>
            <p className="btn-text">
              {isProject || (!isProject && !isApply) ? 'Começar do zero' : 'Converter em projeto'}
            </p>
          </div>
        </div>
      </StyledBtnCard>
    </StyledDiv>
  );

  const NoContentInfo = !hasSearched ? StartFromScratch : images.notFound;

  const NewTemplateButton = () => (
    <div className="newTemplateBtn">
      {_hasPermissionTemplate ? (
        <Button id="new-template" text type="primary" onClick={() => routeChange()} size="large">
          <FontAwesomeIcon icon={faPlus} color={colors.primary500} size="lg" /> Novo template
        </Button>
      ) : (
        <BubbleModalButton feature="template">
          <Button text type="primary">
            <FontAwesomeIcon icon={faLock} /> Novo template
          </Button>
        </BubbleModalButton>
      )}
    </div>
  );

  const RenderListHeader = () => (
    <>
      {isMobile() ? (
        <StyledListHeader>
          <Paragraph>{panelTitle[tabIndex]}</Paragraph>
          {NewTemplateButton()}
        </StyledListHeader>
      ) : (
        <>
          <Div direction="column" gap={spaces.space1} align="start">
            <Subtitle>{panelTitle[tabIndex]}</Subtitle>
            <Paragraph type="small">
              Confira aqui todos os modelos disponíveis para você utilizar. Com eles você consegue agilizar o seu dia a
              dia utilizando um modelo para criar ou atualizar um projeto. Você pode criar novos modelos do zero ou
              salvar um projeto como modelo para utilizar novamente no futuro.
            </Paragraph>
            <Div justify="space-between" $fullWidth>
              <div style={{ minWidth: '40%' }}>
                <Input
                  prefix={<FontAwesomeIcon icon={faSearch} style={{ marginRight: spaces.space1 }} />}
                  id="keyword"
                  name="keyword"
                  placeholder="Pesquise por nome, criador..."
                  onChange={handleQueryStringChange}
                  value={keyword}
                />
              </div>
              {NewTemplateButton()}
            </Div>
          </Div>
        </>
      )}
    </>
  );

  const RenderTemplateCards = () =>
    !totalItems ? (
      <NoContentContainer>
        <NoContentInfo keyword={keyword} />
      </NoContentContainer>
    ) : (
      <div>
        <Grid $gridTemplateColumns="1fr 1fr 1fr">
          {(isApply === false || onSubmitApply) && <StartFromScratch />}
          {list?.length &&
            list.map(item => (
              <TemplateCard
                data={item}
                key={item.id}
                onSelect={() => {
                  setSelectedItem(item);
                  setSubItems(prev => {
                    const newSubItems = prev;
                    Object.keys(prev).forEach(subItem => {
                      newSubItems[subItem].checked = true;
                    });
                    return newSubItems;
                  });
                }}
                onApply={() => setIdApplyTemplate(item.id)}
                icon={isRefurbish ? 'faFolder' : 'faReceipt'}
                logo={!item.idCompany}
              />
            ))}
        </Grid>
        <Pagination
          activePage={Number(queryString.offset || 1)}
          itemsCountPerPage={ITEMS_PAGE}
          totalItemsCount={totalItems}
          pageRangeDisplayed={5}
          onChange={handlePagination}
        />
      </div>
    );

  const tabContent = (
    <StyledTemplateSection>
      <TemplateFilter currentFilters={currentFilters} setFilter={handleFilter} />
      <Div direction="column" gap={spaces.space1} flex="5" align="stretch" padding={spaces.space2}>
        {RenderListHeader()}
        {loading ? <CenteredLoader /> : <RenderTemplateCards />}
      </Div>
    </StyledTemplateSection>
  );

  return (
    <>
      <Modal
        open
        noPadding
        title={
          <Div justify="space-between" padding={`0 ${spaces.space2} 0 0`}>
            <Div gap={spaces.space3}>
              {selectedItem && (
                <FontAwesomeIcon
                  size="xs"
                  icon={faArrowLeft}
                  onClick={() => setSelectedItem(null)}
                  style={{ cursor: 'pointer' }}
                />
              )}
              {title}
            </Div>
            {!isMobile() && <HowDoesItWorksButton id="howTemplatesWorks" icon={faCircleInfo} />}
          </Div>
        }
        onClose={() => onClose(true)}
        width={isMobile() ? '100%' : 1040}
        height={isMobile() ? '94%' : 710}
        hideFooter
      >
        {selectedItem ? (
          <TemplateContext model="template" immediatelyLoadData={false}>
            <EditOrCreateTemplate
              idApply={selectedItem.id}
              setConfirmApply={setIdApplyTemplate}
              community={selectedItem.community}
              subItemsProps={isRefurbish ? { subItems, setSubItems } : null}
            />
          </TemplateContext>
        ) : (
          <>
            {isMobile() && (
              <Input
                prefix={<FontAwesomeIcon icon={faSearch} style={{ marginRight: spaces.space1 }} />}
                id="keyword"
                name="keyword"
                placeholder="Procurar um template"
                onChange={handleQueryStringChange}
                value={keyword}
                style={{ margin: `0 ${spaces.space1}`, width: `calc(100vw - ${spaces.space2}` }}
              />
            )}
            <StyledTabs activeKey={tabIndex} onChange={setTabIndex}>
              <TabPane key="all" tab="Todos">
                {tabContent}
              </TabPane>
              <TabPane key="vobi" tab="Vobi">
                {tabContent}
              </TabPane>
              {_hasPermissionTemplate ? (
                <TabPane key="myTemplates" tab="Meus templates">
                  {tabContent}
                </TabPane>
              ) : (
                <TabPane
                  disabled
                  tab={
                    <BubbleModalButton feature="template">
                      <div>
                        Meus templates <FontAwesomeIcon icon={faLock} />
                      </div>
                    </BubbleModalButton>
                  }
                />
              )}
              <TabPane key="community" tab="Comunidade">
                {tabContent}
              </TabPane>
            </StyledTabs>
          </>
        )}
        {idApplyTemplate && (
          <ConfirmModal
            text={`Ao aplicar este template,
           todos os itens que fazem parte das abas escolhidas serão adicionados ao seu projeto ou oportunidade.
           Você poderá alterar livremente todos esses itens sem que o template seja afetado e também não
           afetará outros projetos ou oportunidades.`}
            onSubmit={applyTemplate}
            onClose={() => setIdApplyTemplate(null)}
          />
        )}
      </Modal>
      <Modal
        open={isApplying}
        closable={false}
        hideCloseBtn
        hideFooter={!applyTimeout}
        hideCancel
        submitText="Entendi"
        title="Estamos aplicando o seu template"
        width="500px"
        height="240px"
        onSubmit={() => {
          setIsApplying(false);
          onClose();
        }}
      >
        {applyTimeout ? (
          <div>
            <Paragraph>O template ainda está sendo processado, assim que finalizar você será notificado</Paragraph>
          </div>
        ) : (
          <CenteredLoader text="Aguarde..." />
        )}
      </Modal>
    </>
  );
};

TemplatesModal.propTypes = {
  onClose: PropTypes.func,
  afterSubmit: PropTypes.func,
  isRefurbish: PropTypes.bool,
  onSubmitApply: PropTypes.func,
  isProject: PropTypes.bool,
  idRefurbishRow: PropTypes.number,
  isApply: PropTypes.bool,
  title: PropTypes.string
};

export default TemplatesModal;
