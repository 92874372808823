import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { faEye, faFileArrowDown } from '@fortawesome/pro-solid-svg-icons';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Div, colors, fonts, radius, spaces, breakpoints } from '../../styles/style';
import { downloadFile, formatSinceTime } from '../../lib/helpers/helper';
import Thumbnail from '../Card/Thumbnail';
import CenteredLoader from '../Loader/CenteredLoader';
import EditableInput from '../Input/EditableInput';
import { Subtitle, Paragraph, Description } from '../Text/Text';

const Container = styled(Div)`
  height: 144px;
  flex: 40%;
  padding: ${spaces.space1};
  border: 1px solid ${colors.neutral100};
  border-radius: ${radius.radius1};
  position: relative;
  gap: ${spaces.space1};
  break-inside: avoid !important;

  svg {
    cursor: pointer;
  }

  @media (max-width: ${breakpoints.tablet}) {
    flex: 100%;
  }

  @media print {
    width: 48%;
    height: 340px;
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border: 0;

    img {
      border-radius: ${radius.radius1};
      margin-top: ${props => props.marginTop};
    }
  }
`;

const DownloadButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${spaces.space2};
  min-width: 128px;
  height: 128px;
  background-color: ${colors.neutral100};
  border: 0;
  &:hover {
    cursor: pointer;
  }

  @media print {
    min-width: 100%;
    height: 216px;
    margin-top: ${spaces.space4};
  }
`;

const DownloadLink = styled.a`
  padding: ${spaces.space1} ${spaces.space2};
  background-color: ${colors.neutral400};
  color: ${colors.white} !important;
`;

const EyeButton = styled.button`
  width: 128px;
  height: 128px;
  position: absolute;
  left: ${spaces.space1};
  opacity: 0;
  z-index: 10;
  background-color: ${colors.neutral700}88;
  border: 0;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  .file-description-input {
    border: 1px solid ${colors.neutral100};
    font-size: ${fonts.sizeSm};
    line-height: ${spaces.space2};
    width: 100%;
    height: 100%;
    align-items: flex-start;
    overflow: hidden;
  }
`;

const CardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${spaces.space1};

  svg {
    margin-right: ${spaces.space1};
  }
`;

const DESCRIPTION_SIZE_LIMIT = 255;

const FileCard = ({ data, viewFileFunction, onDelete, id, onEdit, isView, isPrint, title, offsetTop }) => {
  const timeToShow = formatSinceTime(data.createdAt);
  const imagesExt = ['jpg', 'jpeg', 'png', 'svg', 'jfif', 'gif'];
  const ext = (data?.filename && data?.filename.split('.').pop()).toLowerCase() || '';

  const spaceForTitle = title ? 0 : spaces.space4;

  return data.loading ? (
    <CenteredLoader />
  ) : (
    <Container key={id || data?.filename} marginTop={offsetTop ? spaces.space5 : spaceForTitle}>
      {isPrint && title && <Subtitle>{title}</Subtitle>}
      {imagesExt.includes(ext) ? (
        <Thumbnail
          id="thumbnail"
          alt={data?.name}
          file={data}
          style={{ width: isPrint ? '100%' : '128px', height: isPrint ? '216px' : '128px', objectFit: 'contain' }}
        />
      ) : (
        <DownloadButton onClick={() => downloadFile(data?.fullpath, data?.filename)}>
          <FontAwesomeIcon icon={faFileArrowDown} color={colors.neutral300} size="3x" />
          {isPrint && <DownloadLink href={data?.fullpath}>Download</DownloadLink>}
        </DownloadButton>
      )}
      {isPrint ? (
        <Div justify="center" $fullWidth>
          <Description>{data?.description || 'Sem descrição'}</Description>
        </Div>
      ) : (
        <>
          {imagesExt.includes(ext) && (
            <EyeButton
              type="secondary"
              onClick={e => {
                e.stopPropagation();
                viewFileFunction();
              }}
            >
              <FontAwesomeIcon icon={faEye} color={colors.white} size="3x" />
            </EyeButton>
          )}
          <CardInfo>
            {isView ? (
              <Description>{data?.description || 'Sem descrição'}</Description>
            ) : (
              <EditableInput
                id="file-description"
                className="file-description-input"
                value={data?.description}
                onChange={value => onEdit(value.substring(0, DESCRIPTION_SIZE_LIMIT))}
                map={value => value.substring(0, DESCRIPTION_SIZE_LIMIT)}
              />
            )}
            <CardFooter>
              <Paragraph style={{ fontSize: fonts.sizeSm, color: colors.neutral400 }}>
                {timeToShow?.charAt(0)?.toUpperCase() + timeToShow?.slice(1)}
              </Paragraph>
              {onDelete && <FontAwesomeIcon icon={faTrashCan} color={colors.red500} onClick={onDelete} size="lg" />}
            </CardFooter>
          </CardInfo>
        </>
      )}
    </Container>
  );
};

FileCard.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  viewFileFunction: PropTypes.func,
  onDelete: PropTypes.func,
  id: PropTypes.string,
  onEdit: PropTypes.func,
  isView: PropTypes.bool,
  isPrint: PropTypes.bool,
  title: PropTypes.string,
  offsetTop: PropTypes.bool
};

export default FileCard;
