import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import { useReactToPrint } from 'react-to-print';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as dayjs from 'dayjs';
import ImageParser from '../ImageParser/ImageParser';
import Modal from '../Modal/Modal';
import CenteredLoader from '../Loader/CenteredLoader';
import Drawer from './Drawer';
import Box from '../Box/Box';
import { colors, spaces, radius, Div } from '../../styles/style';
import { ComponentToPdf } from '../../lib/helpers/exportToPdf';
import { Paragraph, Title } from '../Text/Text';
import ViewEntityInfo from '../Description/ViewEntityInfo';
import ExportPdfHeader from '../Header/ExportPdfHeader';
import useViewport from '../../_Hooks/useViewport';
import WarningBar from '../Alert/WarningBar';

const ExportToPdfDrawer = ({
  open,
  onClose,
  name = 'orçamento',
  orientationPdf = 'landscape',
  customTip,
  refurbish,
  CheckComponent,
  PdfComponent,
  afterSubmit,
  printFunction,
  noPageBreak,
  withoutTip = false,
  onEvent = f => f,
  formatPdf = 'a4',
  items,
  setVisibleTimeStart,
  setVisibleTimeEnd,
  scalePdf = 'normal',
  columns,
  setIsAuto,
  isGantt,
  selectedConfigCheckbox,
  scaleWidth = 40,
  ganttType,
  listWidth = 700,
  actualScale = 'Dias',
  isLoadingContent,
  setActualScale = f => f,
  isGeneral = false
}) => {
  const { user } = useSelector(state => state.authReducer) || {};
  const [description, setDescription] = useState('');
  const [orientation, setOrientation] = useState(orientationPdf);
  const [isPrinting, setIsPrinting] = useState(false);
  const { userType } = useSelector(state => state.setup.enums);
  const isCustomer = user.userType === userType.customer.value;
  const [headerCheckboxes, setHeaderCheckboxes] = useState(isCustomer ? ['company'] : []);
  const { isTablet } = useViewport(window.innerWidth);
  const [format, setFormat] = useState(formatPdf);
  const [actualWidth, setActualWidth] = useState(0);
  const [loading, setLoading] = useState(false);
  const [scale, setScale] = useState(scalePdf);
  const [actualZoom, setActualZoom] = useState(1);
  const [changedScale, setChangedScale] = useState(null);

  const calculateInterval = data => {
    const rootObjects = data.filter(item => {
      const hasStartDate = item?.start_time || item?.start_date;
      const hasEndDate = item?.end_time || item?.end_date;
      const isRoot = ganttType === 'planning' || item?.isRoot;

      return hasStartDate && hasEndDate && isRoot && !item?.unscheduled;
    });

    if (rootObjects.length === 0) return null;

    let minDate = new Date(rootObjects[0].start_time || rootObjects[0].start_date);
    let maxDate = new Date(rootObjects[0].end_time || rootObjects[0].end_date);

    rootObjects.forEach(item => {
      const startDate = new Date(item?.start_time || item?.start_date);
      if (startDate < minDate) minDate = startDate;

      const endDate = new Date(item?.end_time || item?.end_date);
      if (endDate > maxDate) maxDate = endDate;
    });

    const interval = maxDate - minDate;
    return {
      minDate: minDate.toISOString(),
      maxDate: maxDate.toISOString(),
      intervalDays: interval / (1000 * 60 * 60 * 24)
    };
  };

  const formatDimensions = {
    a0: {
      portrait: { width: 3179, height: 4494 },
      landscape: { width: 4494, height: 3179 }
    },

    a1: {
      portrait: { width: 2245, height: 3179 },
      landscape: { width: 3179, height: 2245 }
    },

    a2: {
      portrait: { width: 1587, height: 2245 },
      landscape: { width: 2245, height: 1587 }
    },

    a3: {
      portrait: { width: 1123, height: 1587 },
      landscape: { width: 1587, height: 1123 }
    },

    a4: {
      portrait: { width: 794, height: 1123 },
      landscape: { width: 1123, height: 794 }
    }
  };

  useEffect(() => {
    if (format !== 'auto') {
      setActualWidth(formatDimensions[format][orientation].width);
      if (items && items.length > 0) {
        setIsAuto(false);
      }
      return;
    }
    if (items && items.length > 0) {
      const result = calculateInterval(items);

      if (!result) {
        setFormat('a4');
        return;
      }

      const scaleConfigurations = {
        Meses: { daysPerUnit: 30.4375 },
        Anos: { daysPerUnit: 365 },
        Semanas: { daysPerUnit: 1 },
        Dias: { daysPerUnit: 1 }
      };

      if (isGeneral && actualScale !== 'Anos') {
        setChangedScale(actualScale);
        setActualScale('Anos');
      }
      if ((actualScale === 'Dias' || actualScale === 'Semanas') && items?.length * result?.intervalDays >= 10000) {
        setChangedScale(actualScale);
        setActualScale('Meses');
      }

      const { daysPerUnit } = scaleConfigurations[actualScale];
      const unitWidth = Number(result.intervalDays) / daysPerUnit;

      const ganttWidth = scaleWidth * unitWidth;
      const totalWidth = ganttWidth + listWidth + 40;

      setActualWidth(Math.max(formatDimensions.a4.portrait.width, totalWidth));
      setIsAuto(true);
      setLoading(true);
    }
  }, [format, orientation, actualScale, scaleWidth]);

  useEffect(() => {
    if (!columns || columns.length === 0) return;
    if (scale === 'fit-width') {
      const calculateTotalWidth = cols => {
        return cols.reduce((sum, item) => {
          const itemWidth = item?.width || 0;
          const childrenWidth = item?.children ? calculateTotalWidth(item?.children) : 0;
          return sum + Math.max(itemWidth, childrenWidth);
        }, 0);
      };

      const totalWidth = calculateTotalWidth(columns) * 1.5;
      const formatWidth = formatDimensions[format][orientation].width;
      setActualZoom(Math.min(1, formatWidth / totalWidth));
    }
    if (scale === 'normal') {
      setActualZoom(1);
    }
  }, [scale, format, orientation, selectedConfigCheckbox]);

  useEffect(() => {
    if (!items || items.length === 0 || format !== 'auto') return;
    const result = calculateInterval(items);
    if (!result) return;
    setLoading(false);
    setVisibleTimeStart(
      dayjs(new Date(result.minDate))
        .subtract(1, 'day')
        .valueOf()
    );
    setVisibleTimeEnd(
      dayjs(new Date(result.maxDate))
        .add(1, 'day')
        .valueOf()
    );
  }, [actualWidth]);

  useEffect(() => {
    setLoading(false);
  }, [loading]);

  const printComponentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current
  });

  const onSubmit = () => {
    setIsPrinting(true);
    if (onEvent) onEvent();
    if (printFunction) {
      return printFunction({
        elementId: 'renderPdf',
        name,
        orientation,
        format,
        isCustom: format === 'auto'
      }).then(res => {
        setIsPrinting(false);
        if (afterSubmit) afterSubmit(res);
      });
    }
    handlePrint();
    return setIsPrinting(false);
  };

  const titleLowerCase = word => {
    return word.toLowerCase()?.replace(/(?:^|\s)(?!da |de |do |a |e )\S/g, l => l.toUpperCase());
  };

  const [title, setTitle] = useState(titleLowerCase(name));

  const vobiTip = `Escolha a visualização ou aplique filtros na aba de ${title}
  para poder exportar somente o que deseja`;

  const CompanyAndCustomerInfos = (
    <>
      <Div align="start" gap={spaces.space2} $fullHeight $fullWidth>
        {headerCheckboxes.find(check => check === 'company') && (
          <>
            <ImageParser
              id="logo"
              cors
              src={refurbish?.company?.logo || user?.company?.logo}
              style={{ borderRadius: spaces.space0, width: '80px', height: '80px' }}
            />
            <ViewEntityInfo data={refurbish?.company || user?.company} isPrinting={isPrinting} />
          </>
        )}
        {headerCheckboxes?.length === 2 && (
          <Divider type="vertical" style={{ alignSelf: 'stretch', margin: `-${spaces.space2} 0`, height: 'auto' }} />
        )}
        {headerCheckboxes.find(check => check === 'customer') && (
          <ViewEntityInfo data={refurbish?.companyCustomer} isPrinting={isPrinting} />
        )}
      </Div>
      <Divider style={{ margin: `0 0 0 -${spaces.space3}`, width: `calc(100% + ${spaces.space6})` }} />
    </>
  );

  return (
    <>
      <Drawer
        id="drawer-export-pdf"
        title="Configurar e Exportar PDF"
        submitText="Baixar"
        onClose={() => {
          onClose();
          if (changedScale) setActualScale(changedScale);
        }}
        open={open}
        onSubmit={onSubmit}
        width="100vw"
        backgroundColor={colors.neutral100}
        padding="0px"
      >
        {!withoutTip && !changedScale && (
          <Div $backgroundColor={colors.white} padding={`${spaces.space1} ${spaces.space3} 0 ${spaces.space4}`}>
            <Div
              $backgroundColor={colors.primary50}
              padding={spaces.space1}
              $borderRadius={radius.radius1}
              gap={spaces.space1}
            >
              <FontAwesomeIcon color={colors.primary500} icon={faSparkles} />
              <Paragraph>
                <strong>Dica Vobi: </strong>
                {customTip || vobiTip}
              </Paragraph>
            </Div>
          </Div>
        )}

        {changedScale && (
          <Div $backgroundColor={colors.white} padding={`${spaces.space1} ${spaces.space3} 0 ${spaces.space3}`}>
            <WarningBar
              width="calc(35% + 225px)"
              $minWidth="672px"
              padding={spaces.space1}
              margin="0px"
              align="flex-start"
              warning={`A Linha do Tempo na escala em ${changedScale} gerou um arquivo muito grande
               para exportar, então ajustamos para a escala de ${actualScale}. ${
                isGeneral
                  ? 'Se precisar visualizar a Linha do tempo de um projeto específico em uma escala ' +
                    'diferente de Anos, recomendamos acessar diretamente o projeto e exportar a ' +
                    'Linha do tempo na escala desejada.'
                  : 'Caso precise de mais detalhes, experimente filtrar um período de tempo menor e exporte novamente.'
              }`}
            />
          </Div>
        )}
        <ExportPdfHeader
          orientation={orientation}
          setOrientation={setOrientation}
          description={description}
          setDescription={setDescription}
          headerCheckboxes={headerCheckboxes}
          setHeaderCheckboxes={setHeaderCheckboxes}
          refurbish={refurbish}
          isCustomer={isCustomer}
          CheckComponent={CheckComponent}
          title={title}
          setTitle={setTitle}
          format={format}
          setFormat={setFormat}
          scale={scale}
          setScale={setScale}
          isGantt={isGantt}
        />
        <Div padding={spaces.space2} justify="center">
          <ComponentToPdf
            ref={printComponentRef}
            style={{
              ...(!isGantt && actualWidth > window.innerWidth ? { zoom: (window.innerWidth / actualWidth) * 0.9 } : {}),
              ...(isTablet() ? { overflowX: 'auto' } : {}),
              overflow: 'auto'
            }}
          >
            <Box
              id="renderPdf"
              noPageBreak={noPageBreak}
              style={{
                padding: spaces.space4,
                width: actualWidth,
                margin: 'auto'
              }}
            >
              <div style={{ zoom: actualZoom }}>
                <Div direction="column" align="flex-start" gap={spaces.space3}>
                  {headerCheckboxes?.length > 0 && CompanyAndCustomerInfos}
                </Div>

                <Div direction="column" align="flex-start" gap={spaces.space1} margin={`${spaces.space3} 0`}>
                  <Title>{title}</Title>
                  {description !== '' && (
                    <Paragraph style={{ whiteSpace: 'pre-wrap' }} size="small">
                      {description}
                    </Paragraph>
                  )}
                </Div>

                {!loading && PdfComponent}
              </div>
            </Box>
          </ComponentToPdf>
        </Div>
      </Drawer>
      <Modal
        open={isPrinting}
        closable={false}
        hideCloseBtn
        hideFooter
        title="Estamos gerando seu PDF"
        width="500px"
        height="240px"
      >
        <CenteredLoader text="Aguarde..." />
      </Modal>
      <Modal open={isLoadingContent} closable={false} hideCloseBtn hideFooter title="" width="500px" height="240px">
        <CenteredLoader text="Aguarde..." />
      </Modal>
    </>
  );
};

ExportToPdfDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.instanceOf(Object),
  name: PropTypes.string,
  orientationPdf: PropTypes.string,
  customTip: PropTypes.string,
  refurbish: PropTypes.instanceOf(Object),
  PdfComponent: PropTypes.instanceOf(Object),
  CheckComponent: PropTypes.instanceOf(Object),
  afterSubmit: PropTypes.func,
  printFunction: PropTypes.func,
  withoutTip: PropTypes.bool,
  noPageBreak: PropTypes.bool,
  onEvent: PropTypes.func,
  formatPdf: PropTypes.string,
  items: PropTypes.instanceOf(Array),
  setVisibleTimeStart: PropTypes.func,
  setVisibleTimeEnd: PropTypes.func,
  scalePdf: PropTypes.string,
  columns: PropTypes.instanceOf(Array),
  setIsAuto: PropTypes.func,
  isGantt: PropTypes.bool,
  selectedConfigCheckbox: PropTypes.instanceOf(Array),
  scaleWidth: PropTypes.number,
  ganttType: PropTypes.string,
  listWidth: PropTypes.number,
  actualScale: PropTypes.string,
  isLoadingContent: PropTypes.bool,
  setActualScale: PropTypes.func,
  isGeneral: PropTypes.bool
};

export default ExportToPdfDrawer;
