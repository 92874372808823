import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledContentDiv, DivTotal, HighlightBadGe } from './PdfTable.styled';
import ExportEstimateToPdfDrawer from '../Drawer/ExportEstimateToPdfDrawer';
import VirtualizedTable from './VirtualizedTable';
import formatCurrency from '../../lib/helpers/formatCurrency';
import { sumByField } from '../../lib/helpers/helper';
import { parseArrayAsObject } from '../../lib/helpers/parseArrayAsObject';
import { Div, fonts, spaces, colors, Grid } from '../../styles/style';
import { Paragraph, Subtitle } from '../Text/Text';

const createColumnsFromCheckBox = ({ columns, totalPrice, productPrice, laborPrice }) => {
  const checkBoxes = [];
  const checkBoxTotals = [
    ...(productPrice ? [{ key: 'checkTotalProduct', value: `productPrice`, label: 'Total Produtos' }] : []),
    ...(laborPrice ? [{ key: 'checkTotalLabor', value: `laborPrice`, label: 'Total Serviços' }] : []),
    ...(totalPrice ? [{ key: 'checkTotalPrice', value: `totalPrice`, label: 'Total Geral' }] : [])
  ];

  if (totalPrice || productPrice || laborPrice) {
    checkBoxTotals.push(
      { key: 'totalsOnTop', value: 'totalsOnTop', label: 'Totais no Topo', checked: false },
      { key: 'totalsOnBottom', value: 'totalsOnBottom', label: 'Totais na Base', checked: true }
    );
  }

  columns.forEach(
    column =>
      !column.hideCheckbox &&
      column.key &&
      checkBoxes.push({
        key: column.key,
        value: column.key,
        label: column.key === 'image' ? 'Imagem' : column.printTitle || column.title,
        checked: true
      })
  );
  checkBoxes.push(...checkBoxTotals);
  return checkBoxes;
};

const PdfTable = ({
  list,
  childrenColumnName,
  columns,
  columnsMapping,
  onlyTasks,
  total,
  priceProduct,
  priceLabor,
  pdfProps,
  hasLoaded = f => f,
  showCheckBoxes,
  customHeader,
  summary,
  bordered,
  expandedRowKeys,
  levels,
  sumField = 'totalBdi',
  customTable: CustomTable,
  setExpandedRowKeys,
  hideTotal = false,
  align,
  tableProps,
  highlightProps,
  customTableDirection,
  ...props
}) => {
  const { unit: unitsArray, refurbishItemStatus: specificationStatus, taskStatus } = useSelector(
    state => state.setup.systemData
  );
  const { user } = useSelector(state => state.authReducer) || {};
  const [tableColumns, setTableColumns] = useState([]);
  const [tableConfigCheckboxes, setTableConfigCheckboxes] = useState([]);
  const [serializedUnitsArray, setSerializedUnits] = useState({});
  const [totalPrice, setTotalPrice] = useState(total);
  const [productPrice, setProductPrice] = useState(priceProduct);
  const [laborPrice, setLaborPrice] = useState(priceLabor);
  const [serializedStatusArray, setSerializedStatusArray] = useState({});
  const [selectedConfigCheckbox, setSelectedConfigCheckbox] = useState([]);
  const [_expandedRowKeys, _setExpandedRowKeys] = useState(expandedRowKeys);

  const hasHandleToggleAllRow = !CustomTable;

  const handleToggleAllRow = () => {
    const newExpanded = !_expandedRowKeys || _expandedRowKeys?.length ? [] : levels || expandedRowKeys;
    _setExpandedRowKeys(newExpanded);
  };
  const onChangeConfig = checkedValues => {
    if (checkedValues.length > 0) {
      setTableColumns(
        columnsMapping({
          list,
          isPrint: true,
          printColumns: checkedValues,
          unitsArray,
          serializedUnitsArray,
          serializedStatusArray,
          userType: user?.type,
          onlyTasks,
          expandedRowKeys,
          ...(hasHandleToggleAllRow && { handleToggleAllRow }),
          ...props
        })
      );
    }
    setSelectedConfigCheckbox(checkedValues);
    setTotalPrice(checkedValues.includes('totalPrice'));
    setProductPrice(checkedValues.includes('productPrice'));
    setLaborPrice(checkedValues.includes('laborPrice'));
  };

  useEffect(() => {
    const _columns = columnsMapping({
      list,
      printColumns: columns.map(c => c?.key !== '' && c?.key),
      isPrint: true,
      unitsArray,
      serializedUnitsArray,
      serializedStatusArray,
      userType: user?.type,
      expandedRowKeys,
      ...(hasHandleToggleAllRow && { handleToggleAllRow }),
      ...props
    });
    setTableColumns(_columns);
    setTableConfigCheckboxes(
      createColumnsFromCheckBox({
        columns: _columns,
        totalPrice: total,
        productPrice,
        laborPrice
      })
    );
    hasLoaded(false);
  }, [list, serializedStatusArray, _expandedRowKeys, columns]);

  useEffect(() => {
    _setExpandedRowKeys(expandedRowKeys);
  }, [expandedRowKeys]);

  useEffect(() => {
    const object = parseArrayAsObject(unitsArray, true);
    setSerializedUnits(object);
  }, [unitsArray]);

  useEffect(() => {
    setSelectedConfigCheckbox(
      tableConfigCheckboxes?.filter(config => config.checked !== false).map(config => config.value)
    );
  }, [tableConfigCheckboxes]);

  useEffect(() => {
    const object = parseArrayAsObject(props.schedule ? taskStatus : specificationStatus, true);
    setSerializedStatusArray(object);
  }, [specificationStatus, taskStatus]);

  const Header = () => {
    const renderHeader = typeof customHeader === 'function' ? customHeader(selectedConfigCheckbox) : customHeader;

    return (
      renderHeader || (
        <DivTotal>
          {priceProduct && productPrice && (
            <Div align="center" gap={spaces.space0}>
              <Paragraph type="small">Produtos:</Paragraph>
              <Subtitle weight={fonts.weight700} color={colors.neutral800}>
                {formatCurrency(sumByField(list, 'totalProduct'), {
                  currencySymbol: 'R$'
                })}
              </Subtitle>
            </Div>
          )}
          {priceLabor && laborPrice && (
            <Div align="center" gap={spaces.space0}>
              <Paragraph type="small">Serviços:</Paragraph>
              <Subtitle weight={fonts.weight700} color={colors.neutral800}>
                {formatCurrency(sumByField(list, 'totalLabor'), {
                  currencySymbol: 'R$'
                })}
              </Subtitle>
            </Div>
          )}
          {total && totalPrice && (
            <Div align="center" gap={spaces.space0} margin={`0 ${spaces.space2} 0 0`}>
              <Paragraph type="small">Total Geral:</Paragraph>
              <Subtitle weight={fonts.weight700} color={colors.neutral800}>
                {formatCurrency(sumByField(list, sumField), {
                  currencySymbol: 'R$'
                })}
              </Subtitle>
            </Div>
          )}
        </DivTotal>
      )
    );
  };

  const Highlight = () => {
    return (
      <Grid
        margin={!selectedConfigCheckbox.includes('totalsOnTop') && `0 0 ${spaces.space3} 0`}
        $gridTemplateColumns="repeat(auto-fill, minmax(240px, 1fr))"
        $gridGap="1px"
      >
        {highlightProps?.map(({ label, value, icon, color, isPercentage, count }, i) => (
          <Div
            key={`pdf-highlight-${label}-${i + 1}`}
            direction="row"
            $fullWidth
            gap={spaces.space1}
            align="flex-start"
            padding={spaces.space2}
            justify="space-between"
            style={{ outline: `0.06em solid ${colors.neutral100}` }}
          >
            <FontAwesomeIcon icon={icon} color={colors.neutral400} size="s" />
            <Div direction="column" align="flex-start" gap={spaces.space0}>
              <Paragraph type="small">{label}</Paragraph>
              <Subtitle weight={fonts.weight700} color={color || colors.neutral800}>
                {isPercentage
                  ? `${formatCurrency(value?.toFixed(1))}%`
                  : formatCurrency(value, {
                      currencySymbol: `R$`
                    })}
              </Subtitle>
            </Div>
            {count !== null && count !== undefined && <HighlightBadGe>{count}</HighlightBadGe>}
          </Div>
        ))}
      </Grid>
    );
  };

  const RenderCheckDiv = (
    <StyledContentDiv>
      <Paragraph type="small">Tabela</Paragraph>
      <div style={{ display: 'flex', paddingTop: spaces.space2, overflow: 'auto' }}>
        <Checkbox.Group
          key="checkGroupKey"
          value={selectedConfigCheckbox}
          options={tableConfigCheckboxes}
          onChange={onChangeConfig}
        />
      </div>
    </StyledContentDiv>
  );

  const RenderHeader = () => {
    if (hideTotal) return null;
    return totalPrice || productPrice || laborPrice || customHeader ? <Header /> : null;
  };

  const _Table = useMemo(() => {
    const showHeader = selectedConfigCheckbox.includes('totalsOnTop') || customHeader;
    return (
      <>
        {highlightProps?.length > 0 && <Highlight />}

        {showHeader && <RenderHeader />}

        <Div padding={showHeader && `${spaces.space1} 0 0 0`} direction={customTableDirection}>
          {CustomTable ? (
            <CustomTable
              columns={tableColumns}
              list={list}
              expandedRowKeys={expandedRowKeys}
              setExpandedRowKeys={setExpandedRowKeys}
              isPrint
              {...tableProps}
            />
          ) : (
            <VirtualizedTable
              columns={tableColumns}
              list={list}
              expandedRowKeys={_expandedRowKeys}
              setExpandedRowKeys={_setExpandedRowKeys}
              childrenColumnName={childrenColumnName}
              isPrint
              rowSelection={false}
              summary={() => summary && summary(selectedConfigCheckbox)}
              bordered={bordered}
              defaultExpandAllRows
              align={align}
              resizable
              {...tableProps}
            />
          )}
        </Div>
        {selectedConfigCheckbox.includes('totalsOnBottom') && <RenderHeader />}
      </>
    );
  }, [
    CustomTable,
    tableColumns,
    expandedRowKeys,
    list,
    selectedConfigCheckbox,
    totalPrice,
    productPrice,
    customHeader
  ]);

  return (
    <ExportEstimateToPdfDrawer
      {...pdfProps}
      PdfComponent={_Table}
      CheckComponent={tableConfigCheckboxes.length > 0 ? RenderCheckDiv : null}
      selectedConfigCheckbox={selectedConfigCheckbox}
      columns={tableColumns}
      setColumns={setTableColumns}
    />
  );
};

PdfTable.propTypes = {
  list: PropTypes.instanceOf(Object),
  childrenColumnName: PropTypes.string,
  columns: PropTypes.instanceOf(Object),
  serializedPriorities: PropTypes.instanceOf(Array),
  serializedPrioritiesArray: PropTypes.instanceOf(Object),
  schedule: PropTypes.bool,
  columnsMapping: PropTypes.instanceOf(Object),
  total: PropTypes.bool,
  priceProduct: PropTypes.bool,
  priceLabor: PropTypes.bool,
  onlyTasks: PropTypes.bool,
  pdfProps: PropTypes.instanceOf(Object),
  hasLoaded: PropTypes.func,
  showCheckBoxes: PropTypes.bool,
  customHeader: PropTypes.instanceOf(Object),
  summary: PropTypes.func,
  bordered: PropTypes.bool,
  expandedRowKeys: PropTypes.instanceOf(Array),
  levels: PropTypes.instanceOf(Array),
  totalSuppliesList: PropTypes.bool,
  sumField: PropTypes.string,
  customTable: PropTypes.instanceOf(Object),
  setExpandedRowKeys: PropTypes.func,
  hideTotal: PropTypes.bool,
  align: PropTypes.string,
  tableProps: PropTypes.instanceOf(Object),
  headerPosition: PropTypes.string,
  highlightProps: PropTypes.instanceOf(Array),
  customTableDirection: PropTypes.string
};

export default PdfTable;
