import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Drawer from './Drawer';
import useCRUD from '../../_Hooks/useCRUD';
import { colors, fonts, spaces } from '../../styles/style';
import Tabs, { TabPane } from '../Tabs/Tabs';
import FormV2 from '../Form/FormV2';
import Content from '../Content/Content';
import { companyEntityMapping, companyEntitySchema } from '../../lib/mapping/Form/companyEntitySchema';
import CenteredLoader from '../Loader/CenteredLoader';
import NfseConfigForm from '../Form/NfseConfigForm';
import ServiceProfileList from '../List/ServiceProfileList';
import CredentialSection from '../Sections/CredentialSection';
import ConfirmModal from '../Modal/ConfirmModal';
import useViewport from '../../_Hooks/useViewport';
import { Paragraph, Subtitle } from '../Text/Text';
import { hasPermission } from '../../routes/Common/PrivateRoute';
import BubbleModalButton from '../Button/BubbleModalButton';

const CompanyEntity = ({ id, open, onClose, first }) => {
  const { isMobile } = useViewport(window.innerWidth);
  const { user } = useSelector(state => state?.authReducer) || {};
  const { company: userCompany } = user || {};
  const { plans, permissions } = useSelector(state => state.setup);
  const { cnpj, phone, personType, complement, legalName, name, email, zipcode, street, number, state, neighborhood } =
    userCompany || {};
  const [configSelectedTab, setConfigSelectedTab] = useState('1');
  const [company, setCompany] = useState(
    first
      ? {
          cnpj: personType === 'pj' ? cnpj : null,
          phone,
          complement,
          legalName,
          name,
          email,
          zipcode,
          street,
          number,
          state,
          neighborhood
        }
      : null
  );
  const [_id, setId] = useState(id);
  const [loadingAll, setLoadingAll] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [dataBackup, setDataBackup] = useState({});
  const [touched, setTouched] = useState(false);

  const hasPermissionEmitNfse = hasPermission(user, ['emitNfse'], plans, permissions);
  const hasPermissiontakenNfe = hasPermission(user, ['takenNFe'], plans, permissions);

  const { handleGet, handleUpdate, handleCreate, loading } = useCRUD({
    model: 'companyEntity',
    immediatelyLoadData: false
  });

  useEffect(() => {
    setLoadingAll(loading);
  }, [loading]);
  const handleSubmit = submitData => {
    setDataBackup(submitData);
    const func = _id ? handleUpdate : handleCreate;
    return func({
      postPathOptions: '/createAccount',
      updatePathOptions: '/updateAccount',
      values: submitData,
      refresh: false,
      displayToast: false
    }).then(resp => {
      if (resp?.error) {
        toast.error(resp?.error?.message);
        return;
      }

      toast.success(
        !_id ? (
          <div>
            <Subtitle>Empresa cadastrada</Subtitle>
            <Paragraph type="small">Acesse as abas de emissão e busca para habilitar as funcionalidades.</Paragraph>
          </div>
        ) : (
          <div>
            <Subtitle>Empresa atualizada com sucesso!</Subtitle>
          </div>
        )
      );

      setId(resp?.id);
      setCompany(resp);
      setDataBackup({});
      setTouched(false);
    });
  };

  const getCompany = companyId => {
    return handleGet({ refetchPathOptions: `/${companyId}` }).then(resp => {
      setCompany(resp);
      setId(resp?.id);
    });
  };

  useEffect(() => {
    if (!id) return;
    getCompany(id);
  }, [id]);

  const emitNfseComponents = {
    openned: (
      <TabPane key="2" tab="Emissão de NFS-e" disabled={loadingAll || !_id}>
        <Content>
          <NfseConfigForm
            setTouched={setTouched}
            company={company}
            setCompany={setCompany}
            setLoadingAll={setLoadingAll}
            loadingAll={loadingAll}
          />
          <Divider id="divider-section" style={{ margin: `${spaces.space1} 0 ` }} />
          <ServiceProfileList companyEntity={company} setLoadingAll={setLoadingAll} loadingAll={loadingAll} />
          <Divider id="divider-section" style={{ margin: `${spaces.space1} 0 ` }} />
          <CredentialSection
            companyEntity={company}
            setCompany={setCompany}
            isSendNFSe
            setLoadingAll={setLoadingAll}
            loadingAll={loadingAll}
          />
        </Content>
      </TabPane>
    ),
    locked: (
      <TabPane
        key="2"
        disabled
        tab={
          <BubbleModalButton feature="emitNfse">
            <div>
              Emissão de NFS-e <FontAwesomeIcon icon={faLock} />
            </div>
          </BubbleModalButton>
        }
      />
    ),
    hidden: null
  };

  const takenNfeComponents = {
    openned: (
      <TabPane key="3" tab="Busca automática de NF-e" disabled={loadingAll || !_id}>
        <Content>
          <CredentialSection
            companyEntity={company}
            setCompany={setCompany}
            isSendNFSe={false}
            setLoadingAll={setLoadingAll}
          />
        </Content>
      </TabPane>
    ),
    locked: (
      <TabPane
        key="3"
        disabled
        tab={
          <BubbleModalButton feature="takenNFe">
            <div>
              Busca automática de NF-e <FontAwesomeIcon icon={faLock} />
            </div>
          </BubbleModalButton>
        }
      />
    ),
    hidden: null
  };

  let emitNfseComponent;
  let takenNfeComponent;

  if (hasPermissionEmitNfse) {
    emitNfseComponent = emitNfseComponents.openned;
  } else if (hasPermissionEmitNfse === null) {
    emitNfseComponent = emitNfseComponents.locked;
  } else {
    emitNfseComponent = emitNfseComponents.hidden;
  }

  if (hasPermissiontakenNfe) {
    takenNfeComponent = takenNfeComponents.openned;
  } else if (hasPermissiontakenNfe === null) {
    takenNfeComponent = takenNfeComponents.locked;
  } else {
    takenNfeComponent = takenNfeComponents.hidden;
  }

  return (
    <>
      <Drawer
        title={
          <>
            <Subtitle id="title-drawer">{_id ? 'Editar' : 'Cadastrar empresa'}</Subtitle>
            <span style={{ color: colors.neutral400, fontSize: fonts.sizeSm }} id="payment-id">
              {_id ? ` ${company?.legalName}` : ''}
            </span>
          </>
        }
        width={1080}
        padding="0"
        submitText={_id ? 'Salvar' : 'Cadastrar'}
        formId="companyInvoiceForm"
        cancelText="Fechar"
        showSubmit={configSelectedTab === '1'}
        open={open}
        onClose={() => {
          if (touched) setShowConfirmModal(true);
          else onClose(company);
        }}
      >
        {loading ? (
          <CenteredLoader />
        ) : (
          <Tabs
            width="100%"
            activeKey={configSelectedTab}
            onTabClick={e => {
              setConfigSelectedTab(e);
            }}
          >
            <TabPane key="1" tab="Dados do negócio" disabled={loadingAll}>
              <Content padding={spaces.space2}>
                <FormV2
                  id="companyInvoiceForm"
                  schema={companyEntitySchema}
                  mapping={companyEntityMapping(_id, isMobile())}
                  data={Object.values(dataBackup).length ? dataBackup : company}
                  onSubmit={handleSubmit}
                  displayButtons={false}
                  columnStyle
                  onTouchedChanged={(values, _touched) => {
                    if (_touched) setTouched(Object?.keys(_touched)?.some(key => values?.[key] !== company?.[key]));
                  }}
                />
              </Content>
            </TabPane>
            {emitNfseComponent}
            {takenNfeComponent}
          </Tabs>
        )}
      </Drawer>
      {showConfirmModal && (
        <ConfirmModal
          title="Alterações não salvas"
          text="Você possui alterações que não foram salvas. Continue a edição ou descarte as alterações."
          alertInfo="Essa ação não poderá ser desfeita."
          submitText="Continuar edição"
          showDiscardButton
          onSubmit={() => setShowConfirmModal(false)}
          onClose={(_, opts) => {
            setShowConfirmModal(false);
            if (opts?.discard) onClose(company);
          }}
        />
      )}
    </>
  );
};

CompanyEntity.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  first: PropTypes.bool
};

export default CompanyEntity;
