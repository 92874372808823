import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import Button from '../Button/Button';
import { spaces } from '../../styles/style';
import Modal from '../Modal/Modal';
import useViewport from '../../_Hooks/useViewport';
import { StyledDropDown, StyledMenu } from './DropdownV2.styled';

const DropdownV2 = ({
  slim,
  menu,
  buttonType = 'primary',
  ghost = true,
  children,
  className,
  title = 'Opções',
  sectionClass,
  isModalClosed,
  showIcon = true,
  defaultMenu,
  ...props
}) => {
  const [showRenderModal, setShowRenderModal] = useState(false);
  const { isMobile } = useViewport(window.innerWidth);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (isModalClosed) setShowRenderModal(false);
  }, [isModalClosed]);

  const MobileButton = children
    ? () =>
        React.cloneElement(children, {
          onClick: e => {
            e.stopPropagation();
            setShowRenderModal(true);
          }
        })
    : null;

  const _menu = defaultMenu ? (
    <StyledMenu
      onClick={e => {
        e.domEvent.preventDefault();
        setOpen(false);
      }}
    >
      {menu}
    </StyledMenu>
  ) : (
    menu
  );

  return isMobile() ? (
    <>
      {MobileButton ? (
        <MobileButton />
      ) : (
        <Button
          type={buttonType}
          text={slim}
          ghost={ghost}
          onClick={e => {
            e.stopPropagation();
            setShowRenderModal(true);
          }}
          style={{ width: spaces.space3, height: spaces.space4 }}
        >
          {showIcon && <FontAwesomeIcon icon={faEllipsisV} />}
        </Button>
      )}
      {showRenderModal && (
        <Modal
          title={title}
          open
          hideFooter
          onClose={e => {
            e.stopPropagation();
            setShowRenderModal(false);
          }}
          sectionClass={sectionClass}
        >
          {_menu}
        </Modal>
      )}
    </>
  ) : (
    <StyledDropDown
      trigger={['click']}
      onOpenChange={visible => {
        setOpen(visible);
      }}
      open={open}
      overlay={_menu}
      overlayStyle={{ zIndex: 9999 }}
      overlayClassName="c-dropdown"
      slim
      {...props}
    >
      {children || (
        <Button
          padding={0}
          type={buttonType}
          text={slim}
          ghost={ghost}
          onClick={e => e.stopPropagation()}
          icon={showIcon ? <FontAwesomeIcon icon={faEllipsisV} /> : null}
        />
      )}
    </StyledDropDown>
  );
};

DropdownV2.propTypes = {
  slim: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  mappingKey: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  modal: PropTypes.instanceOf(Object),
  button: PropTypes.instanceOf(Object),
  menu: PropTypes.instanceOf(Object),
  model: PropTypes.string,
  options: PropTypes.instanceOf(Object),
  forceSubmit: PropTypes.func,
  afterSubmit: PropTypes.func,
  buttonType: PropTypes.string,
  ghost: PropTypes.bool,
  children: PropTypes.instanceOf(Object),
  customActions: PropTypes.instanceOf(Object),
  customTrigger: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  onItemClick: PropTypes.func,
  alignLeftOptions: PropTypes.bool,
  buttonPadding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  sectionClass: PropTypes.string,
  isModalClosed: PropTypes.bool,
  showIcon: PropTypes.bool,
  defaultMenu: PropTypes.bool
};

export default DropdownV2;
