import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import useViewport from '../../_Hooks/useViewport';

import BulkAction from './BulkAction';

import { BulkActionsChildrenContainer } from './ItemBulkActions.styles';
import { ButtonBulkAction, ModalItem } from './BulkActions.styles';
import { saveQuoteToPurchaseOrderIds } from '../../store/quoteToPurchaseOrderIds/actions/action';
import useCRUD from '../../_Hooks/useCRUD';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import { hasPermission } from '../../routes/Common/PrivateRoute';

const QuotationMapBulkActions = ({
  selectedItems,
  setSelectedItems,
  redirectToCreateOrder,
  currentSelectedSupplierCheckbox,
  reload,
  selectedQuoteSupplierStatus,
  isSplit
}) => {
  const { quoteSupplierStatus } = useSelector(state => state.setup.enums);
  const { isMobile } = useViewport(window.innerWidth);
  const dispatch = useDispatch();
  const [showOptionsModal, setShowOptionsModal] = useState(false);

  const counter = Object.keys(selectedItems).length;

  const plural = useMemo(() => (counter > 1 ? 's' : ''), [selectedItems]);

  const { user } = useSelector(state => state.authReducer);
  const { plans, permissions } = useSelector(state => state.setup);

  const hasPurchaseOrderPermission = hasPermission(user, ['purchaseOrder'], plans, permissions);

  const handleClose = () => {
    setSelectedItems({});
  };

  const { handleUpdate } = useCRUD({
    model: 'quoteItemSuppliers/bulk/unavailable',
    immediatelyLoadData: false
  });

  const handleSetUnavailable = () => {
    handleUpdate({
      values: { idQuoteSupplier: currentSelectedSupplierCheckbox, idQuoteItems: Object.values(selectedItems), isSplit },
      refresh: false
    }).then(resp => {
      if (!resp?.error) {
        reload();
        handleClose();
      }
    });
  };

  if (counter <= 0) return null;

  return (
    <BulkAction
      text={`${counter} ite${plural ? 'n' : 'm'}${plural} ${!isMobile() ? `selecionado${plural}` : ''}`}
      counter={counter}
      handleClose={handleClose}
    >
      {isMobile() ? (
        <>
          <Button text onClick={() => setShowOptionsModal(true)}>
            <FontAwesomeIcon icon={faEllipsisV} size="2x" />
          </Button>
          {showOptionsModal && (
            <Modal title="Opções" open hideFooter onClose={() => setShowOptionsModal(false)}>
              <ModalItem onClick={handleSetUnavailable}>
                <p>Marcar como indisponível</p>
              </ModalItem>

              {quoteSupplierStatus.canCreateOrder.includes(selectedQuoteSupplierStatus) && (
                <ModalItem
                  onClick={() => {
                    dispatch(saveQuoteToPurchaseOrderIds(Object.values(selectedItems)));
                    redirectToCreateOrder();
                  }}
                >
                  <p>Nova ordem de compra</p>
                </ModalItem>
              )}
            </Modal>
          )}
        </>
      ) : (
        <BulkActionsChildrenContainer>
          <ButtonBulkAction id="mark-as-unavailable" type="ghost" onClick={handleSetUnavailable}>
            Marcar como indisponível
          </ButtonBulkAction>
          {quoteSupplierStatus.canCreateOrder.includes(selectedQuoteSupplierStatus) && hasPurchaseOrderPermission && (
            <ButtonBulkAction
              id="new-purchase-order"
              type="ghost"
              onClick={() => {
                dispatch(saveQuoteToPurchaseOrderIds(Object.values(selectedItems)));
                redirectToCreateOrder();
              }}
            >
              Nova ordem de compra
            </ButtonBulkAction>
          )}
        </BulkActionsChildrenContainer>
      )}
    </BulkAction>
  );
};

QuotationMapBulkActions.propTypes = {
  selectedItems: PropTypes.instanceOf(Object),
  setSelectedItems: PropTypes.func,
  redirectToCreateOrder: PropTypes.func,
  reload: PropTypes.func,
  currentSelectedSupplierCheckbox: PropTypes.string,
  selectedQuoteSupplierStatus: PropTypes.number,
  isSplit: PropTypes.bool
};

export default QuotationMapBulkActions;
