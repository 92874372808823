import { Dropdown as AntDropDown, Menu } from 'antd';
import styled, { css } from 'styled-components';
import { spaces, colors, breakpoints, radius, fonts, opacities } from '../../styles/style';

const StyledDropDown = styled(AntDropDown)`
  cursor: pointer;
  padding: ${props => (props.padding || props.padding === 0 ? props.padding : `${spaces.space1} ${spaces.space2}`)};
`;

const DropdownBar = styled.div`
  border-top: 1px solid ${colors.neutral400};
  border-radius: ${radius.radius1};
  margin: auto;
`;

const BubbleModalButtonContentDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledMenu = styled(Menu)`
  padding: 0;
  border: none;
  max-height: ${props => (props.$menuMaxHeight ? props.$menuMaxHeight : '300px')};
  overflow: auto;
  min-width: ${props => (props.$minWidth ? props.$minWidth : undefined)};

  .ant-dropdown-menu-title-content {
    width: 100%;
  }

  .ant-dropdown-menu-submenu-title {
    display: flex;
    align-items: center;
    padding: 0;
    transition: none;

    .ant-dropdown-menu-title-content {
      button {
        justify-content: left;
        span {
          display: contents;
        }
        width: 100%;
      }
    }
  }

  .ant-menu-item {
    padding: 0;
  }

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu {
    padding: 0 ${spaces.space1};
    transition: none;

    &:hover {
      background-color: ${colors.neutral100};

      button {
        color: ${colors.neutral600};

        div {
          svg {
            color: ${props => props.$coloredIconHover && colors.primary600};
          }
        }
      }
    }

    button {
      width: 100%;

      span {
        display: contents;
      }

      svg {
        width: 14px;
      }
    }

    ${props =>
      props.$alignLeftOptions &&
      css`
        button {
          justify-content: left;
        }
      `};
  }
`;

const SubMenu = styled(Menu.SubMenu)`
  padding: 0 ${spaces.space2};
  border: none;
  max-height: ${props => (props.$menuMaxHeight ? props.$menuMaxHeight : '300px')};
  overflow: auto;

  .ant-dropdown-menu-submenu-title {
    padding: 0;
    display: flex;
    align-items: center;
    transition: none;

    .ant-dropdown-menu-title-content {
      button {
        justify-content: left;
        span {
          display: contents;
        }
        width: 100%;
      }
    }
  }

  &:hover {
    background-color: ${colors.neutral100};

    button {
      color: ${colors.neutral600};

      div {
        svg {
          color: ${props => props.$coloredIconHover && colors.primary600};
        }
      }
    }
  }

  button {
    width: 100%;

    span {
      display: contents;
    }
  }

  ${props =>
    props.$alignLeftOptions &&
    css`
      button {
        justify-content: left;
      }
    `}
`;

const StyledItemMenu = styled(Menu.Item)`
  padding: 0 ${spaces.space2};
  &:hover {
    background-color: ${colors.neutral100};

    button {
      color: ${colors.neutral600};

      div {
        svg {
          color: ${props => props.$coloredIconHover && colors.primary600};
        }
      }
    }
  }

  ${props =>
    props.selected &&
    css`
      background-color: ${colors.neutral100};
      color: black !important;
    `};

  ${props =>
    props.bar &&
    css`
      height: ${spaces.space1} !important;
      line-height: ${spaces.space1} !important;
    `};

  ${props =>
    props.$isNavBar &&
    css`
      width: auto;
      padding: ${spaces.space0};
      margin: ${spaces.space0} !important;
      .ant-dropdown-menu-title-content {
        color: ${colors.primary800};
        font-size: ${fonts.sizeSm};
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        div {
          padding: ${spaces.space0} ${spaces.space2};
        }
      }
      .submenu-item-selected {
        background-color: ${`${colors.primary300}${opacities.opacity02}`} !important;
        border-radius: ${radius.radius1};
      }
      &:hover {
        background-color: ${colors.white} !important;
      }

      .submenu-item-unselected {
        &:hover {
          background-color: ${`${colors.primary300}${opacities.opacity02}`} !important;
          border-radius: ${radius.radius1};
        }
      }
    `}

  .ant-dropdown-menu-title-content {
    width: 100%;
  }
`;

const DropdownContainer = styled.div`
  cursor: pointer;
  align-items: center;
  display: flex;

  > * {
    display: flex;
    height: ${spaces.space4};
    align-items: center;
    min-width: ${spaces.space3};
  }

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: ${props => props.mobileDirection};
    padding-top: 0;
  }
`;

export {
  StyledDropDown,
  DropdownBar,
  BubbleModalButtonContentDiv,
  StyledMenu,
  DropdownContainer,
  StyledItemMenu,
  SubMenu
};
