import React from 'react';
import * as PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Tooltip } from 'antd';
import { useSelector } from 'react-redux';

import formatCurrency from '../../lib/helpers/formatCurrency';
import { Div, colors } from '../../styles/style';
import Card from './Card';
import { Paragraph } from '../Text/Text';
import { getColorByStatusPayment } from '../../lib/helpers/helper';

const RenderCard = ({ data, cardId, isOrderModal, onClick, tooltipText, tooltipId }) => {
  const { paymentStatuses, purchaseOrderStatusProps } = useSelector(state => state.setup.enums);
  let color;

  if (data?.paymentStatus) color = getColorByStatusPayment(data?.paymentStatus?.id, paymentStatuses);
  else if (data?.purchaseStatus) color = colors[purchaseOrderStatusProps[data?.purchaseStatus?.id]?.color];

  return (
    <Tooltip title={tooltipText} id={tooltipId}>
      <Card
        width="385px"
        bodyBackgroundColor={colors.neutral75}
        footerBackgroundColor={colors.neutral75}
        borderColor="none"
        id={cardId}
        onClick={onClick ? () => onClick(data) : f => f}
        hover={!!onClick}
        footerHover={!!onClick}
        footer={
          <Div justify="space-between">
            <Paragraph type="small">
              {formatCurrency(data?.totalSplitPrice || data?.value || data?.price || data?.total, {
                currencySymbol: 'R$'
              })}
            </Paragraph>
            <Paragraph type="small" color={isOrderModal && color}>
              {isOrderModal
                ? data?.paymentStatus?.name || data?.purchaseStatus?.name.replace(/s$/, '')
                : dayjs(data?.date || data?.dueDate).format('DD/MM/YYYY')}
            </Paragraph>
          </Div>
        }
      >
        <Paragraph type="small">{isOrderModal ? data?.name || data?.code : data?.description}</Paragraph>
      </Card>
    </Tooltip>
  );
};

RenderCard.propTypes = {
  data: PropTypes.instanceOf(Object),
  cardId: PropTypes.string,
  isOrderModal: PropTypes.bool,
  onClick: PropTypes.func,
  tooltipText: PropTypes.string,
  tooltipId: PropTypes.string
};

export default RenderCard;
