import React from 'react';
import { faEdit, faPresentation, faTrash, faTrashCan, faXmark } from '@fortawesome/pro-regular-svg-icons';
import {
  duplicateProject,
  cancelProject,
  finishProject,
  deleteAction,
  bar,
  applyTemplatetoProject,
  reactivateProject,
  unarchiveOpportunity,
  archiveOpportunity,
  winOpportunity,
  deleteProject,
  restoreProject
} from './_dropdown';
import { colors } from '../../../styles/style';

const deleteActionWithIcon = {
  ...deleteAction,
  iconProps: {
    icon: faTrashCan,
    color: colors.red500
  }
};

const projectList = row =>
  row?.version !== 'V2'
    ? [
        {
          ...deleteProject,
          label: 'Excluir projeto',
          iconProps: {
            icon: faTrash
          }
        }
      ]
    : [
        {
          label: 'Editar',
          id: 'edit-project',
          redirectTo: `/profissional/projetos/editar/${row.id}`,
          hasId: true,
          iconProps: {
            icon: faEdit
          }
        },
        duplicateProject,
        cancelProject,
        finishProject,
        restoreProject,
        {
          ...deleteProject,
          label: 'Excluir projeto',
          iconProps: {
            icon: faTrash
          }
        },
        bar,
        applyTemplatetoProject,
        {
          id: 'apply-template',
          label: 'Aplicar um template',
          modal: 'templatesModal',
          verb: 'post',
          values: { type: 2 },
          iconProps: {
            icon: faPresentation
          }
        }
      ];
const projectItemList = (id, userType, idStep, fixedRefurbishNewStatus, isMobile) => [
  {
    id: 'edit-project',
    label: 'Editar',
    redirectTo: `/${userType}/projetos/editar/${id}`,
    hasId: true,
    iconProps: {
      icon: faEdit
    }
  },
  duplicateProject,
  ...(isMobile
    ? [
        {
          id: 'share-opportunity',
          label: 'Compartilhar',
          button: 'ShareProject',
          buttonParams: {
            showText: true,
            showIcon: true,
            type: 'default',
            iconColor: colors.neutral400
          }
        }
      ]
    : []),
  idStep === fixedRefurbishNewStatus.cancelled.id || idStep === fixedRefurbishNewStatus.finished.id
    ? reactivateProject
    : {
        ...cancelProject,
        displayToast: 'Seu projeto foi cancelado. Para vê-lo na lista de projetos, utilize os filtros disponíveis'
      },
  ...(idStep !== fixedRefurbishNewStatus.finished.id
    ? [
        {
          ...finishProject,
          displayToast: 'Seu projeto foi finalizado. Para vê-lo na lista de projetos, utilize os filtros disponíveis'
        }
      ]
    : []),
  {
    ...restoreProject,
    modalParams: {
      ...restoreProject.modalParams,
      shouldRedirectToOpportunity: true
    }
  },
  {
    ...deleteProject,
    label: 'Excluir projeto'
  },
  ...(isMobile
    ? [
        {
          id: 'view-as-client',
          button: 'CustomerViewAction',
          buttonParams: {
            text: true,
            type: 'default',
            iconColor: colors.neutral400,
            onClick: null
          }
        }
      ]
    : []),
  bar,
  applyTemplatetoProject,
  {
    id: 'apply-template',
    label: 'Aplicar um template',
    modal: 'templatesModal',
    verb: 'post',
    values: { type: 2 },
    iconProps: {
      icon: faPresentation
    },
    modalParams: {
      isProject: true
    }
  }
];
const opportunityItemList = (id, userType, isMobile) => [
  {
    id: 'edit-opportunity',
    label: 'Editar',
    redirectTo: `/${userType}/oportunidades/editar/${id}`,
    hasId: true,
    iconProps: {
      icon: faEdit
    }
  },
  ...(isMobile
    ? [
        {
          id: 'win-opportunity',
          button: 'WinOpportunity',
          label: 'Marcar como ganho',
          buttonParams: {
            text: true,
            type: 'default',
            iconColor: colors.neutral400,
            onClick: null
          }
        },
        {
          id: 'archive-opportunity',
          button: 'ArchiveOpportunity',
          label: 'Arquivar oportunidade',
          buttonParams: {
            text: true,
            type: 'default',
            iconColor: colors.neutral400,
            onClick: null
          }
        }
      ]
    : []),
  {
    ...duplicateProject,
    id: 'duplicate-opportunity',
    label: 'Duplicar',
    modalParams: {
      ...duplicateProject.modalParams,
      title: 'Nova Oportunidade',
      textHeader: `Você pode duplicar uma oportunidade com seus itens no orçamento,
        estrutura de pastas (sem os arquivos) e também suas anotações`,
      text: `Escolha o que deseja duplicar desta oportunidade.`,
      successText: 'Oportunidade duplicada com sucesso',
      source: 'Oportunidade',
      isOpportunity: true
    }
  },
  {
    id: 'share-opportunity',
    label: 'Compartilhar',
    button: 'ShareProject',
    buttonParams: {
      showText: true,
      showIcon: true,
      type: 'default',
      iconColor: colors.neutral400
    }
  },
  {
    id: 'view-as-client',
    button: 'CustomerViewAction',
    buttonParams: {
      text: true,
      type: 'default',
      iconColor: colors.neutral400,
      onClick: null
    }
  },
  {
    ...deleteAction,
    modalParams: {
      title: `Atenção, sua oportunidade será excluída!`,
      text: (
        <p>
          Ao confirmar essa ação, a oportunidade será <b>EXCLUÍDA</b> por completo.
        </p>
      ),
      redirectTo: '/profissional/oportunidades'
    },
    label: 'Excluir',
    iconProps: {
      icon: faTrash,
      color: colors.red500
    },
    displayToast: 'Operação realizada com sucesso!'
  },
  bar,
  {
    ...applyTemplatetoProject,
    modalParams: {
      ...applyTemplatetoProject.modalParams,
      textHeader: `Você pode criar um template usando seus itens no orçamento,
        estrutura de pastas (sem os arquivos) e também suas anotações da oportunidade.
        Assim, você terá sempre disponível esse template para utilizar em futuros projetos ou oportunidades,
        sem precisar criar do zero.`,
      isOpportunity: true
    }
  },
  {
    id: 'apply-template',
    label: 'Aplicar um template',
    modal: 'templatesModal',
    verb: 'post',
    values: { type: 2 },
    iconProps: {
      icon: faPresentation
    }
  }
];

const projectListEnd = [
  duplicateProject,
  reactivateProject,
  {
    ...deleteProject,
    label: 'Excluir projeto',
    iconProps: {
      icon: faTrash
    }
  },
  applyTemplatetoProject
];
const projectKanban = () => [
  finishProject,
  duplicateProject,
  {
    ...cancelProject,
    displayToast: 'Seu projeto foi cancelado. Para vê-lo na lista de projetos, utilize os filtros disponíveis'
  },
  {
    label: 'Alterar status',
    id: 'change-refurbish-status',
    button: 'editStatus'
  },
  {
    label: 'Remover do status',
    verb: 'put',
    iconProps: {
      icon: faXmark
    },
    pathOptions: '',
    values: { idStep: null }
  },
  applyTemplatetoProject
];
const kanbanColumn = () => [
  {
    label: 'Alterar status',
    id: 'change-refurbish-status',
    button: 'editStatus'
  },
  {
    label: 'Ocultar status',
    id: 'hide-refurbish-status',
    button: 'hideColumn'
  }
];

const opportunityKanban = [
  winOpportunity,
  archiveOpportunity,
  {
    ...duplicateProject,
    id: 'duplicate-opportunity',
    label: 'Duplicar',
    modalParams: {
      ...duplicateProject.modalParams,
      title: 'Nova Oportunidade',
      textHeader: `Você pode duplicar uma oportunidade com seus itens no orçamento,
        estrutura de pastas (sem os arquivos) e também suas anotações`,
      text: `Escolha o que deseja duplicar desta oportunidade.`,
      successText: 'Oportunidade duplicada com sucesso',
      source: 'Oportunidade',
      isOpportunity: true
    }
  },
  {
    label: 'Editar status',
    id: 'change-refurbish-status',
    button: 'editStatus'
  },
  deleteActionWithIcon
];
const listOpportunityItemArchived = [unarchiveOpportunity, deleteActionWithIcon];
const listOpportunityItem = (id, userType) => [
  {
    id: 'edit-opportunity',
    label: 'Editar',
    redirectTo: `/${userType}/oportunidades/editar/${id}`,
    hasId: true,
    iconProps: {
      icon: faEdit
    }
  },

  winOpportunity,
  archiveOpportunity,

  {
    ...duplicateProject,
    id: 'duplicate-opportunity',
    label: 'Duplicar',
    modalParams: {
      ...duplicateProject.modalParams,
      title: 'Nova Oportunidade',
      textHeader: `Você pode duplicar uma oportunidade com seus itens no orçamento,
          estrutura de pastas (sem os arquivos) e também suas anotações`,
      text: `Escolha o que deseja duplicar desta oportunidade.`,
      successText: 'Oportunidade duplicada com sucesso',
      source: 'Oportunidade',
      isOpportunity: true
    }
  },
  deleteActionWithIcon
];

export {
  projectList,
  projectItemList,
  opportunityItemList,
  projectListEnd,
  projectKanban,
  kanbanColumn,
  opportunityKanban,
  listOpportunityItemArchived,
  listOpportunityItem
};
