import React from 'react';
import PropTypes from 'prop-types';

// components
import { ReactComponent as GoogleLogo } from '../Images/googleLogo.svg';
import { ReactComponent as SSLLogo } from '../Images/SSL.svg';
import LogoIcon from '../Icons/Logo';

import css from './LoginContent.module.scss';

const LoginContentImages = ({ isInvite }) => (
  <div className={css['shell__security-images']}>
    {isInvite ? (
      <LogoIcon />
    ) : (
      <>
        <a
          href="https://transparencyreport.google.com/safe-browsing/search?url=vobi.com.br&hl=pt-PT"
          rel="noopener noreferrer"
          target="_blank"
        >
          <GoogleLogo />
        </a>
        <SSLLogo />
      </>
    )}
  </div>
);

LoginContentImages.propTypes = {
  isInvite: PropTypes.bool
};

export default LoginContentImages;
