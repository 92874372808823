import {
  faFolderOpen,
  faUserGroup,
  faHandshake,
  faListCheck,
  faPersonDolly,
  faCoins,
  faCartShopping
} from '@fortawesome/pro-duotone-svg-icons';
import { faBoxOpen, faLayerGroup, faScrewdriverWrench, faSearch } from '@fortawesome/pro-solid-svg-icons';
import {
  faCopy,
  faEye,
  faPlus,
  faTrashCan,
  faShoppingCart,
  faMessageDollar,
  faPaperPlaneTop,
  faFileCheck,
  faFileArrowUp
} from '@fortawesome/pro-regular-svg-icons';
import { faBarsProgress } from '@fortawesome/pro-light-svg-icons';
import { colors, spaces, fonts } from '../../../styles/style';
import {
  nameAsc,
  nameDesc,
  createdAtAsc,
  createdAtDesc,
  topSale,
  priceAsc,
  priceDesc,
  deleteActionList,
  addItem,
  copyLinkButton,
  exportPdf,
  addCompositionItems,
  addComposition,
  useSpecificationTemplate,
  undoReconciliation,
  deleteAction,
  deleteActionV2
} from './_dropdown';
import { generalTask, task } from './stepAndTaskDropdown';
import { instalmentChildren, instalmentItem, instalmentParent, instalmentParentGrouped } from './instalmentDropdown';
import {
  projectList,
  projectItemList,
  opportunityItemList,
  projectListEnd,
  projectKanban,
  kanbanColumn,
  opportunityKanban,
  listOpportunityItemArchived,
  listOpportunityItem
} from './projectDropdown';
import { markAsPaid, payment, paymentChildren } from './paymentDropdown';
import { buildQueryString } from '../../helpers/helper';

const styleForDropdown = {
  display: 'flex',
  justifyContent: 'left',
  gap: spaces.space1,
  padding: `${spaces.space1} ${spaces.space1} ${spaces.space1} 0`,
  fontSize: fonts.sizeSm
};

const allDropdown = {
  default: [deleteActionList()],
  sortCustom: [nameAsc, nameDesc, createdAtAsc, createdAtDesc],
  shareLink: [copyLinkButton],
  shareLinkWithWhatsapp: [
    copyLinkButton,
    {
      button: 'shareWhatsappLink',
      buttonParams: {
        text: true,
        children: 'WhatsApp'
      }
    }
  ],
  quickCreateActions: [
    {
      label: 'Projeto',
      id: 'quick-create-project',
      modal: 'templateModal',
      roles: ['project'],
      modalParams: {
        isProject: true
      },
      iconProps: {
        color: colors.neutral400,
        icon: faFolderOpen
      }
    },
    {
      label: 'Financeiro',
      id: 'quick-create-financial',
      roles: ['financial', 'vobipayCharge'],
      key: 'financial',
      iconProps: {
        color: colors.neutral400,
        icon: faCoins
      },
      children: [
        {
          label: 'Pagamento do cliente',
          roles: [
            'financialSupplier',
            'financialOwnBusiness',
            'projectSupplier',
            'projectOwnBusiness',
            'vobipayCharge'
          ],
          modal: 'newPaymentDrawer',
          id: 'payment'
        },
        {
          label: 'Despesa',
          roles: ['financialExpense', 'projectExpense'],
          modal: 'newBillDrawer',
          modalParams: {
            billType: 'expense'
          },
          id: 'expense'
        },
        {
          label: 'Receita',
          roles: ['financialIncome', 'projectIncome'],
          modal: 'newBillDrawer',
          modalParams: {
            billType: 'income'
          },
          id: 'income'
        },
        {
          label: 'Transferência',
          roles: ['financialTransfer'],
          modal: 'newTransferDrawer',
          id: 'transfer'
        },
        {
          label: 'Importar XML',
          roles: ['financialSupplier', 'projectSupplier', 'vobipayCharge', 'financialExpense', 'projectExpense'],
          id: 'import-xml',
          children: [
            {
              label: 'Despesa',
              roles: ['financialExpense', 'projectExpense'],
              modal: 'importXml',
              id: 'expense',
              modalParams: {
                type: 'expense'
              }
            },
            {
              label: 'Pagamento manual',
              roles: ['financialSupplier', 'projectSupplier'],
              modal: 'importXml',
              id: 'payment',
              modalParams: {
                type: 'charge'
              }
            },
            {
              label: 'Vobi Pay',
              roles: ['vobipayCharge'],
              modal: 'importXml',
              id: 'vobipay',
              modalParams: {
                type: 'vobipay'
              }
            }
          ]
        }
      ]
    },
    {
      label: 'Compras',
      id: 'quick-create-purchase',
      roles: ['purchaseSolicitation', 'purchaseOrder', 'purchaseQuote'],
      key: 'purchase',
      iconProps: {
        color: colors.neutral400,
        icon: faCartShopping
      },
      children: [
        {
          label: 'Solicitação',
          roles: ['purchaseSolicitation'],
          redirectTo: '/profissional/gestao-de-compras/solicitacoes/novo',
          id: 'solicitation'
        },
        {
          label: 'Ordem de compra',
          roles: ['purchaseOrder'],
          redirectTo: '/profissional/gestao-de-compras/ordem-de-compras/novo',
          id: 'order'
        },
        {
          label: 'Cotação',
          roles: ['purchaseQuote'],
          redirectTo: '/profissional/gestao-de-compras/cotacoes/novo',
          id: 'quote'
        }
      ]
    },
    {
      label: 'Oportunidade',
      id: 'quick-create-opportunity',
      modal: 'templateModal',
      roles: ['opportunity'],
      iconProps: {
        color: colors.neutral400,
        icon: faHandshake
      }
    },
    {
      label: 'Tarefa',
      id: 'quick-create-task',
      modal: 'editTaskModal',
      roles: ['tasks'],
      iconProps: {
        color: colors.neutral400,
        icon: faListCheck
      }
    },
    {
      label: 'Cliente',
      id: 'quick-create-customer',
      modal: 'createCustomerDrawer',
      roles: ['customer'],
      iconProps: {
        color: colors.neutral400,
        icon: faUserGroup
      }
    },
    {
      label: 'Fornecedor',
      id: 'quick-create-supplier',
      modal: 'createSupplierDrawer',
      roles: ['supplier'],
      iconProps: {
        color: colors.neutral400,
        icon: faPersonDolly
      }
    }
  ],
  task,
  generalTask,
  sortCatalog: [
    topSale,
    priceAsc,
    priceDesc,
    { ...nameAsc, order: ['name', 'ASC'] },
    { ...nameDesc, order: ['name', 'DESC'] }
  ],
  sortFiles: [
    { ...createdAtDesc, label: 'Mais recente' },
    { ...createdAtAsc, label: 'Mais antigo' },
    { ...nameAsc, order: [['filename', 'ASC']] },
    { ...nameDesc, order: [['filename', 'DESC']] }
  ],
  sortOrderSummary: [createdAtAsc, createdAtDesc],
  instalmentItem,
  instalmentParent,
  instalmentParentGrouped,
  instalmentChildren,
  templateList: [
    {
      id: 'duplicate-templates',
      label: 'Duplicar template',
      modal: 'useAsTemplateModal',
      modalParams: {
        title: 'Novo template',
        model: 'template',
        pathOptions: '/duplicate',
        successText: 'Template duplicado com sucesso',
        modalType: 'Template',
        text: `Escolha o que deseja duplicar deste template.`
      }
    },
    deleteActionList({ item: 'template' })
  ],
  shoppingItem: isPaid => [
    {
      label: isPaid ? 'Marcar como aguardando compra' : 'Marcar como comprado',
      verb: 'put',
      values: { purchaseStatus: isPaid ? 1 : 6, event: 'project-shopping-edited' }
    }
  ],
  projectList,
  projectItemList,
  opportunityItemList,
  projectListEnd,
  projectKanban,
  kanbanColumn,
  opportunityKanban,
  listOpportunityItemArchived,
  listOpportunityItem,
  myProducts: ({ isComposition, isMobile }) => [
    {
      label: 'Ver detalhes',
      id: 'edit-item',
      modal: 'editModal',
      iconProps: {
        icon: faEye,
        color: colors.neutral400
      },
      buttonParams: {
        style: styleForDropdown
      }
    },
    ...(!isComposition
      ? [
          {
            label: 'Duplicar',
            id: 'duplicate-item',
            modal: 'confirmModal',
            verb: 'post',
            pathOptions: '/duplicate',
            displayToast: 'Operação realizada com sucesso.',
            modalParams: {
              text: 'Deseja realmente clonar este item?'
            },
            buttonParams: {
              style: styleForDropdown
            },
            iconProps: {
              icon: faCopy,
              color: colors.neutral400
            }
          }
        ]
      : []),
    copyLinkButton,
    {
      label: 'Adicionar em um projeto',
      id: 'add-item-to-project',
      modal: 'addProductToProject',
      iconProps: {
        icon: faPlus,
        color: colors.neutral400
      },
      modalParams: {
        style: styleForDropdown
      },
      buttonParams: {
        style: styleForDropdown
      }
    },
    {
      ...deleteActionList({ item: 'produto' }),
      buttonParams: {
        style: {
          ...styleForDropdown,
          color: colors.red500,
          ...(isMobile && { padding: '0px' })
        }
      },
      iconProps: {
        icon: faTrashCan,
        color: colors.red500
      }
    }
  ],
  addItems: [...addItem, useSpecificationTemplate],
  addRefurbishItems: (isMobile, groupedReference) => {
    const [searchCatalog, addProduct, addLabor] = addItem;
    return [
      ...(!isMobile
        ? [
            {
              label: 'Composição',
              modal: 'createComposition',
              values: { type: 4, groupedReference },
              verb: 'post',
              id: 'Create-new-composition',
              roles: ['composition'],
              iconProps: {
                icon: faLayerGroup,
                color: colors.neutral400
              }
            }
          ]
        : []),
      {
        ...addProduct,
        label: 'Produto',
        iconProps: {
          icon: faBoxOpen,
          color: colors.neutral400
        }
      },
      {
        ...addLabor,
        label: 'Serviço',
        iconProps: {
          icon: faScrewdriverWrench,
          color: colors.neutral400
        }
      },
      {
        ...searchCatalog,
        label: 'Busca Avançada',
        ...(!isMobile
          ? {
              buttonParams: {
                style: {
                  color: colors.primary600,
                  padding: 0,
                  margin: `${spaces.space0} 0`
                }
              }
            }
          : null),
        iconProps: {
          icon: faSearch,
          color: isMobile ? colors.neutral400 : colors.primary600
        }
      }
    ];
  },

  addTemplateItems: [...addItem, addComposition],
  addPaymentItem: [
    {
      label: 'Buscar de orçamento',
      externalAction: true,
      modal: 'createSpecificationItem',
      id: 'create-specitication-item'
    },
    {
      label: 'Criar item não orçado',
      externalAction: true,
      modal: 'newItem',
      id: 'create-new-item'
    }
  ],
  addCompositionItems,
  proposal: isProject => [
    {
      label: 'Arquivar',
      verb: 'put',
      id: 'proposal-archive',
      modal: 'confirmModal',
      modalParams: {
        text: 'Deseja realmente arquivar esta proposta?'
      },
      values: { idStatus: 4, archivedDate: new Date(), archived: true, isProject }
    }
  ],
  addCompositionDropdown: [
    { label: 'Criar novo', id: 'new-composition', modal: 'new', roles: ['composition'] },
    { label: 'Copiar da SINAPI', id: 'copy-from-sinapi', modal: 'sinapi', roles: ['composition'] }
  ],
  notificationDropdown: userType => [
    {
      label: 'Marcar todas como lidas',
      verb: 'markAllRead'
    },
    {
      label: 'Ver todas as notificações',
      redirectTo: `/${userType}/notificacoes`
    },
    {
      label: 'Configurar notificações',
      redirectTo: `/${userType}/notificacoes/configuracoes`
    }
  ],
  notificationListItem: title => [
    {
      label: title,
      verb: 'markRead'
    }
  ],
  userProvider: [
    {
      label: 'Acessar como usuário',
      modal: 'accessAsUser'
    },
    deleteActionList({ item: 'usuário' })
  ],
  usersProvider: [
    {
      label: 'Reenviar e-mail',
      verb: 'post',
      modal: 'confirmModal',
      pathOptions: '/resend-welcome',
      displayToast: 'E-mail reenviado com sucesso!',
      model: 'user',
      modalParams: {
        text: 'Deseja reenviar o email?'
      }
    },
    deleteActionList({ item: 'usuário' })
  ],
  noteActions: [deleteActionList({ item: 'nota', gender: 'a' })],
  payment,
  paymentChildren,
  markAsPaid,
  performedHours: [
    {
      label: 'Adicionar em um projeto',
      id: 'add-item-to-project',
      modal: 'addProductToProject'
    },
    {
      label: 'Fazer uma cópia',
      id: 'duplicate-item',
      modal: 'confirmModal',
      verb: 'post',
      pathOptions: '/duplicate',
      displayToast: 'Operação realizada com sucesso.',
      modalParams: {
        text: 'Deseja realmente clonar este item?'
      }
    },
    {
      ...deleteAction,
      id: 'remove-item'
    }
  ],
  bankAccount: [
    {
      label: 'Editar',
      id: 'finance-edit',
      modal: 'financeEdit'
    },
    deleteActionList({ item: 'conta', gender: 'a' })
  ],
  transfer: ({ installment, transferId, isMobile }) => {
    const { idReconciliation } = installment || {};

    return [
      {
        label: 'Editar',
        id: 'edit-transfer',
        modal: 'editTransference',
        modalParams: {
          id: transferId || installment?.transferId
        }
      },
      ...(idReconciliation ? [undoReconciliation(installment)] : []),
      {
        ...deleteAction,
        label: 'Excluir',
        id: 'remove-transfer',
        pathOptions: `/transfer/${transferId || installment?.transferId}`,
        buttonParams: {
          style: { color: colors.red500, ...(isMobile && { padding: '0px' }) }
        },
        modalParams: {
          text: 'Ao excluir, a transferência será removida permanente da sua conta e ninguém mais poderá acessá-la.',
          alertInfo: 'Essa operação não poderá ser desfeita'
        }
      }
    ];
  },
  purchaseAction: ({ data, purchaseSolicitationStatus, hasApprovalAuthority }) => {
    const changeStatus = {
      label: 'Alterar status',
      id: 'change-status',
      roles: ['purchaseSolicitation'],
      key: 'change-status',
      iconProps: {
        color: colors.neutral400,
        icon: faBarsProgress
      },
      children: [
        {
          label: 'Rascunho',
          id: 'back-draft',
          verb: 'put',
          value: purchaseSolicitationStatus.draft,
          params: { values: { idPurchaseStatus: purchaseSolicitationStatus.draft } },
          pathOptions: `/action/${data?.id}`,
          displayToast: 'Operação realizada com sucesso.',
          show: true
        },
        {
          label: 'Aberta',
          id: 'back-open',
          verb: 'put',
          value: purchaseSolicitationStatus.open,
          params: { values: { idPurchaseStatus: purchaseSolicitationStatus.open } },
          pathOptions: `/action/${data?.id}`,
          displayToast: 'Operação realizada com sucesso.',
          show: true
        },
        {
          label: 'Aprovada',
          id: 'approved',
          verb: 'put',
          value: purchaseSolicitationStatus.approved,
          params: { values: { idPurchaseStatus: purchaseSolicitationStatus.approved } },
          pathOptions: `/action/${data?.id}`,
          displayToast: 'Operação realizada com sucesso.',
          show: hasApprovalAuthority
        },
        {
          label: 'Cotação',
          id: 'move-quote',
          modal: 'createOrMoveToQuote',
          value: purchaseSolicitationStatus.quote,
          show: true
        },
        {
          label: 'Compra e contratação',
          id: 'move-buy',
          value: purchaseSolicitationStatus.buy,
          ...(data.idPurchaseStatus === purchaseSolicitationStatus.closed
            ? {
                verb: 'put',
                params: { values: { idPurchaseStatus: purchaseSolicitationStatus.buy } },
                pathOptions: `/action/${data?.id}`,
                displayToast: 'Operação realizada com sucesso.'
              }
            : { modal: 'createOrMoveToOrder' }),
          show: true
        },
        {
          label: 'Recusada',
          id: 'rejected',
          modal: 'rejectModal',
          value: purchaseSolicitationStatus.rejected,
          hide: true,
          show: hasApprovalAuthority
        },
        {
          label: 'Finalizada',
          id: 'move-closed',
          verb: 'put',
          value: purchaseSolicitationStatus.closed,
          params: { values: { idPurchaseStatus: purchaseSolicitationStatus.closed } },
          pathOptions: `/action/${data?.id}`,
          displayToast: 'Operação realizada com sucesso.',
          show: true
        }
      ]
    };

    const createQuote = {
      label: 'Criar cotação',
      id: 'create-quote',
      roles: ['purchaseQuote'],
      redirectTo: `/profissional/gestao-de-compras/cotacoes/novo?solicitacao=${data?.id}${
        data.idRefurbish ? `&projeto=${data?.idRefurbish}` : ''
      }`,
      iconProps: {
        color: colors.neutral400,
        icon: faMessageDollar
      }
    };

    const createPurchaseOrder = {
      label: 'Criar Ordem de Compra/Contratação',
      id: 'create-purchase-order',
      roles: ['purchaseOrder'],
      redirectTo: `/profissional/gestao-de-compras/ordem-de-compras/novo?solicitacao=${data?.id}${
        data.idRefurbish ? `&projeto=${data?.idRefurbish}` : ''
      }`,
      iconProps: {
        color: colors.neutral400,
        icon: faShoppingCart
      }
    };

    const hasCreate =
      data?.idPurchaseStatus === purchaseSolicitationStatus.quote ||
      data?.idPurchaseStatus === purchaseSolicitationStatus.buy;

    const hasAlreadyQuote = data?.quote?.id;

    return [
      changeStatus,
      ...(hasCreate && !hasAlreadyQuote ? [createQuote] : []),
      ...(hasCreate ? [createPurchaseOrder] : []),
      copyLinkButton,
      deleteActionV2
    ];
  },
  orderAction: ({ data, purchaseOrderStatus, hasUserPaymentPermission, isMobile, hasApprovalAuthority }) => {
    const changeStatus = {
      label: 'Alterar status',
      id: 'change-status',
      roles: ['purchaseOrder'],
      key: 'change-status',
      iconProps: {
        color: colors.neutral400,
        icon: faBarsProgress
      },
      children: [
        {
          label: 'Rascunho',
          id: 'back-draft',
          verb: 'put',
          value: purchaseOrderStatus.draft,
          params: { values: { idOrderStatus: purchaseOrderStatus.draft } },
          pathOptions: `/action/${data?.id}`,
          displayToast: 'Operação realizada com sucesso.',
          show: true
        },
        {
          label: 'Aberta',
          id: 'back-open',
          verb: 'put',
          value: purchaseOrderStatus.open,
          params: { values: { idOrderStatus: purchaseOrderStatus.open } },
          pathOptions: `/action/${data?.id}`,
          displayToast: 'Operação realizada com sucesso.',
          show: true
        },
        {
          label: 'Aprovada',
          id: 'approved',
          verb: 'put',
          value: purchaseOrderStatus.approved,
          params: { values: { idOrderStatus: purchaseOrderStatus.approved } },
          pathOptions: `/action/${data?.id}`,
          displayToast: 'Operação realizada com sucesso.',
          show: hasApprovalAuthority
        },
        {
          label: 'Recusada pelo aprovador',
          id: 'rejected',
          modal: 'rejectModal',
          value: purchaseOrderStatus.rejected,
          show: hasApprovalAuthority
        },
        {
          label: 'Enviada',
          id: 'move-sent',
          modal: 'moveToSent',
          value: purchaseOrderStatus.sent,
          show: true
        },
        {
          label: 'Confirmada',
          id: 'move-accepted',
          value: purchaseOrderStatus.accepted,
          ...(data.idOrderStatus === purchaseOrderStatus.closed
            ? {
                verb: 'put',
                params: { values: { idOrderStatus: purchaseOrderStatus.accepted } },
                pathOptions: `/action/${data?.id}`,
                displayToast: 'Operação realizada com sucesso.'
              }
            : { modal: 'confirmOrderModal' }),
          show: true
        },
        {
          label: 'Recusada pelo fornecedor',
          id: 'refused',
          verb: 'put',
          value: purchaseOrderStatus.refused,
          params: { values: { idOrderStatus: purchaseOrderStatus.refused } },
          pathOptions: `/action/${data?.id}`,
          displayToast: 'Operação realizada com sucesso.',
          show: true
        },
        {
          label: 'Finalizada',
          id: 'move-closed',
          verb: 'put',
          value: purchaseOrderStatus.closed,
          params: { values: { idOrderStatus: purchaseOrderStatus.closed } },
          pathOptions: `/action/${data?.id}`,
          displayToast: 'Operação realizada com sucesso.',
          show: true
        }
      ]
    };

    const actions = [changeStatus];

    const isAllowedRequestRefund =
      !data?.splitId &&
      data?.idPayment &&
      !data.idPaymentRefund &&
      hasUserPaymentPermission &&
      purchaseOrderStatus.requestableRefund.includes(data?.idOrderStatus);

    const isAllowedChargeFeeAdministration =
      !data?.splitId &&
      data?.idPayment &&
      !data.idPaymentFee &&
      hasUserPaymentPermission &&
      purchaseOrderStatus.chargeableAdministrationFee.includes(data?.idOrderStatus);

    const seeSupplierPayment = {
      button: 'seeSupplierPayment'
    };

    const sendToSupplier = {
      label: 'Enviar para fornecedor',
      modal: 'shareModal',
      id: 'send-to-supplier',
      iconProps: {
        color: colors.neutral400,
        icon: faPaperPlaneTop
      }
    };

    const requestRefund = isAllowedRequestRefund && {
      label: 'Solicitar reembolso',
      button: 'requestRefund',
      id: 'request-refund'
    };

    const chargeAdministrationFee = isAllowedChargeFeeAdministration && {
      id: 'charge-administration-fee',
      label: 'Cobrar taxa de administração',
      button: 'chargeAdministrationFee'
    };

    if (data?.idOrderStatus === purchaseOrderStatus.sent) {
      actions.push(sendToSupplier);
    }

    if (purchaseOrderStatus.finished.includes(data?.idOrderStatus)) {
      actions.push(seeSupplierPayment);
    }

    if (isAllowedRequestRefund) actions.push(requestRefund);

    if (isAllowedChargeFeeAdministration) actions.push(chargeAdministrationFee);

    if (!isMobile) actions.push(exportPdf);

    actions.push(copyLinkButton);

    if (!data?.payment?.paidValue)
      actions.push({ ...deleteActionV2, ...(data?.splitId && { deletePathOptions: `/split/${data?.splitId}` }) });

    return actions;
  },
  quoteAction: ({ idQuote, idStatus, purchaseQuoteStatus }) => {
    const actions = [];

    const finishedId = purchaseQuoteStatus.finished;
    const buyId = purchaseQuoteStatus.buy;
    const draftId = purchaseQuoteStatus.draft;

    if (purchaseQuoteStatus.editable.includes(idStatus)) {
      actions.push({
        id: 'edit-quote-dropdown',
        button: 'edit'
      });
    }

    if (idStatus !== draftId && idStatus !== finishedId) {
      actions.push({
        label: 'Enviar pedidos de cotações',
        id: 'resend-request',
        modal: 'sendQuoteRequest',
        iconProps: {
          color: colors.neutral400,
          icon: faPaperPlaneTop
        }
      });
    }

    actions.push(exportPdf);

    if (idStatus === buyId) {
      actions.push({
        label: 'Ordens de compra vinculadas',
        id: 'show-orders',
        modal: 'orderDetailModal',
        iconProps: {
          color: colors.neutral400,
          icon: faShoppingCart
        }
      });
      actions.push({
        label: 'Mover para finalizada',
        id: 'move-to-finished',
        verb: 'put',
        params: { values: { idQuoteStatus: finishedId }, updatePathOptions: `/action/${idQuote}`, isAction: true },
        pathOptions: `/action/${idQuote}`,
        displayToast: 'Operação realizada com sucesso.',
        iconProps: {
          color: colors.neutral400,
          icon: faFileCheck
        }
      });
    }

    if (idStatus === finishedId) {
      actions.push({
        label: 'Mover para compra e contratação',
        id: 'move-to-buy',
        verb: 'put',
        params: { values: { idQuoteStatus: buyId }, updatePathOptions: `/action/${idQuote}`, isAction: true },
        pathOptions: `/action/${idQuote}`,
        displayToast: 'Operação realizada com sucesso.',
        iconProps: {
          color: colors.neutral400,
          icon: faShoppingCart
        }
      });
    }

    return [...actions, deleteActionV2];
  },
  quoteSupplierAction: ({ data, quoteSupplierStatus, hasPurchaseOrderPermission, idRefurbish, isDrawer, isMobile }) => {
    const { status, splitId, idQuote, idSupplier, supplierReference } = data || {};

    const changeStatus = {
      label: 'Alterar status',
      id: 'change-status',
      roles: ['purchaseQuote'],
      key: 'change-status',
      iconProps: {
        color: colors.neutral400,
        icon: faBarsProgress
      },
      children: [
        {
          label: 'Não enviado',
          id: 'pending',
          verb: 'put',
          value: quoteSupplierStatus.pending.id,
          params: { values: { status: quoteSupplierStatus.pending.id, supplierReference: data?.supplierReference } },
          pathOptions: `/${data?.id}`,
          displayToast: 'Operação realizada com sucesso.'
        },
        {
          label: 'Aguardando resposta',
          id: 'waitingResponsen',
          verb: 'put',
          value: quoteSupplierStatus.waitingResponse.id,
          params: {
            values: { status: quoteSupplierStatus.waitingResponse.id, supplierReference: data?.supplierReference }
          },
          pathOptions: `/${data?.id}`,
          displayToast: 'Operação realizada com sucesso.'
        },
        {
          label: 'Respondido',
          id: 'answered',
          verb: 'put',
          value: quoteSupplierStatus.answered.id,
          params: {
            values: { status: quoteSupplierStatus.answered.id, supplierReference: data?.supplierReference }
          },
          pathOptions: `/${data?.id}`,
          displayToast: 'Operação realizada com sucesso.'
        },
        {
          label: 'Analisado',
          id: 'analyzed',
          verb: 'put',
          value: quoteSupplierStatus.analyzed.id,
          params: { values: { status: quoteSupplierStatus.analyzed.id, supplierReference: data?.supplierReference } },
          pathOptions: `/${data?.id}`,
          displayToast: 'Operação realizada com sucesso.'
        },
        {
          label: 'Recusado',
          id: 'refused',
          verb: 'put',
          value: quoteSupplierStatus.refused.id,
          params: { values: { status: quoteSupplierStatus.refused.id, supplierReference: data?.supplierReference } },
          pathOptions: `/${data?.id}`,
          displayToast: 'Operação realizada com sucesso.'
        },
        {
          label: 'Compra e contratação',
          id: 'buy',
          verb: 'put',
          value: quoteSupplierStatus.buy.id,
          params: { values: { status: quoteSupplierStatus.buy.id, supplierReference: data?.supplierReference } },
          pathOptions: `/${data?.id}`,
          displayToast: 'Operação realizada com sucesso.'
        }
      ]
    };

    const actions = [changeStatus];

    if (isDrawer && !isMobile && quoteSupplierStatus.answeredOptions.includes(status)) {
      actions.push({
        label: 'Exportar resposta em PDF',
        id: 'quote-suppliers-see-details',
        button: 'exportPdf'
      });
    }

    if (!isDrawer) {
      actions.push({
        label: 'Ver detalhes',
        id: 'quote-suppliers-see-details',
        button: 'seeDetails'
      });
    }
    if (status !== quoteSupplierStatus.buy.id) {
      actions.push({
        label: 'Enviar pedido de cotação',
        id: 'quote-suppliers-see-details',
        modal: 'sendQuote',
        iconProps: {
          icon: faPaperPlaneTop,
          color: colors.neutral400
        }
      });
    }
    if (status === quoteSupplierStatus.waitingResponse.id && !splitId) {
      actions.push({
        label: 'Importar dados da cotação',
        id: 'quote-suppliers-import-quote',
        modal: 'importInstruction',
        params: { model: 'quoteSuppliers' },
        iconProps: {
          icon: faFileArrowUp,
          color: colors.neutral400
        }
      });
      actions.push({
        label: 'Importar itens da cotação',
        id: 'quote-suppliers-import-quote-items',
        modal: 'importInstruction',
        params: { model: 'quoteItemSuppliers' },
        iconProps: {
          icon: faFileArrowUp,
          color: colors.neutral400
        }
      });
    }
    if (quoteSupplierStatus.canCreateOrder.includes(status) && hasPurchaseOrderPermission) {
      const queryParts = [
        { key: 'cotacao', value: splitId || idQuote },
        { key: 'fornecedor', value: idSupplier },
        idRefurbish && { key: 'projeto', value: idRefurbish },
        supplierReference && { key: 'supplierReference', value: supplierReference },
        !!splitId && { key: 'isSplit', value: true }
      ];

      const queryString = buildQueryString(queryParts);

      actions.push({
        label: 'Criar ordem de compra e contratação',
        id: 'quote-suppliers-create-order',
        roles: ['purchaseOrder'],
        redirectTo: `/profissional/gestao-de-compras/ordem-de-compras/novo${queryString}`,
        iconProps: {
          color: colors.neutral400,
          icon: faShoppingCart
        }
      });
    }

    actions.push(deleteActionV2);

    return actions;
  }
};

export { allDropdown };
