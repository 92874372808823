import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Input from './Input';
import Button from '../Button/Button';
import { Paragraph } from '../Text/Text';
import LoginFormRow from '../Form/LoginFormRow';
import { colors, Div, spaces } from '../../styles/style';
import Label from '../Label/Label';

const OTPInput = ({ value, handleBlur, handleChange, sendOTP, showIcon, size }) => {
  const COUNT_DOWN = 60;
  const [countdown, setCountdown] = useState(COUNT_DOWN);
  const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else {
      setCanResend(true);
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  const handleResend = () => {
    setCanResend(false);
    setCountdown(COUNT_DOWN);

    sendOTP?.();
  };

  const onChange = e => {
    handleChange(e);
  };

  return (
    <Div direction="column" align="start" gap={spaces.space0}>
      <Label marginBottom={spaces.space0}>Insira o código enviado para o seu e-mail</Label>
      <LoginFormRow padding={size === 'small' ? spaces.space0 : null}>
        {showIcon && <FontAwesomeIcon icon={faLock} color={colors.neutral300} />}
        <Input
          id="otp"
          name="otp"
          placeholder="000000"
          onChange={onChange}
          onBlur={handleBlur}
          value={value}
          $letterSpace={spaces.space1}
          maxLength={6}
          autoFocus
        />
      </LoginFormRow>
      {canResend ? (
        <Div align="center">
          <Paragraph type="small" color={colors.neutral500}>
            Não recebeu o código?
          </Paragraph>
          <Button margin={0} text type="primary" onClick={handleResend}>
            Reenviar código
          </Button>
        </Div>
      ) : (
        <Paragraph type="small" color={colors.neutral500}>
          Aguarde {countdown} s para solicitar um novo envio.
        </Paragraph>
      )}
    </Div>
  );
};

OTPInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  sendOTP: PropTypes.func,
  showIcon: PropTypes.bool,
  size: PropTypes.string
};

export default OTPInput;
