import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Modal from './Modal';
import { Div, colors, fonts, spaces } from '../../styles/style';
import { HelperLink, Paragraph } from '../Text/Text';
import HowDoesItWorksButton from '../Button/HowDoesItWorksButton';
import WarningBar from '../Alert/WarningBar';
import Select from '../Select/Select';
import useCRUD from '../../_Hooks/useCRUD';
import CenteredLoader from '../Loader/CenteredLoader';
import Drawer from '../Drawer/Drawer';
import InvoicePage from '../../_Pages/Payments/invoicePage';
import { formatDocument } from '../../lib/helpers/helper';

const ImportInvoiceXMLModal = ({ idRefurbish, onClose, type: importType, onImportSuccess, initialError }) => {
  const { handleCreate } = useCRUD({ model: 'payment', immediatelyLoadData: false });
  const [isImporting, setIsImporting] = useState(false);
  const [error, setError] = useState(initialError);
  const [selectedRefurbish, setSelectedRefurbish] = useState(idRefurbish);
  const [payment, setPayment] = useState(null);
  const [invoiceSelected, setInvoiceSelected] = useState(null);
  const { refurbishStatus, paymentInvoiceSource } = useSelector(state => state.setup.enums);
  const attachFile = e => {
    const file = e.target.files[0];
    const values = new FormData();
    values.append('file', file);
    values.append('fileName', file?.name);
    values.append('type', importType);
    values.append('source', paymentInvoiceSource.imported);
    if (selectedRefurbish) values.append('idRefurbish', selectedRefurbish);
    setIsImporting(true);
    handleCreate({ values, postPathOptions: '/nfe', displayToast: false, customCatch: true, refresh: false })
      .then(resp => {
        if (resp?.error) {
          setError(resp?.error);
          setPayment(resp?.payment);
          return;
        }
        onImportSuccess(resp?.payment, importType);
      })
      .catch(err => {
        const { data } = err?.response || {};
        setError(data);
      })
      .finally(() => {
        setIsImporting(false);
      });
  };

  const getErrors = ({ code, extraInfo }) =>
    ({
      'API-B-00219': {
        title: 'Arquivo inválido',
        info: 'Modelo de nota fiscal inválido.',
        message: () => <Paragraph>Apenas arquivos XML de NF-e modelo 55 versão 4.0 podem ser importados.</Paragraph>,
        canBeImported: false,
        onSubmit: () => onClose(),
        onClose: () => onClose()
      },
      'API-B-00220': {
        title: 'Arquivo inválido',
        info: 'Arquivo de nota fiscal inválido.',
        message: () => <Paragraph>O arquivo xml importado possui dados inválidos.</Paragraph>,
        canBeImported: false,
        onSubmit: () => onClose(),
        onClose: () => onClose()
      },
      'API-B-00222': {
        title: 'Arquivo já importado',
        info: `NF-e duplicada no sistema com chave ${extraInfo?.invoiceKey}`,
        message: () => (
          <Paragraph>
            A NF-e{' '}
            <HelperLink
              size={fonts.sizeMd}
              onClick={() => {
                setInvoiceSelected(extraInfo);
              }}
            >
              {extraInfo?.serie}
            </HelperLink>{' '}
            com mesma chave de acesso já foi importada. deseja continuar mesmo assim?
          </Paragraph>
        ),
        canBeImported: true,
        onSubmit: () => {
          onImportSuccess(payment, importType);
        },
        onClose: () => {
          if (initialError) {
            onClose();
            return;
          }
          setError(null);
        }
      },
      'API-B-00221': {
        title: extraInfo?.personType === 'pf' ? 'CPF inválido' : 'CNPJ inválido',
        info: `Nota fiscal emitida para outro ${extraInfo?.personType === 'pf' ? 'CPF' : 'CNPJ'}`,
        message: () =>
          extraInfo?.personType === 'pf' ? (
            <Paragraph>
              A nota <b>{extraInfo?.serie}</b> tem CPF do destinatário{' '}
              <b>
                {extraInfo?.dest?.xNome} - {formatDocument(extraInfo?.dest?.document)}
              </b>{' '}
              diferente do CPF da sua conta.
            </Paragraph>
          ) : (
            <Paragraph>
              A nota <b>{extraInfo?.serie}</b> tem CNPJ do destinatário{' '}
              <b>
                {extraInfo?.dest?.xNome} - {formatDocument(extraInfo?.dest?.document)}
              </b>{' '}
              diferente do CNPJ da sua empresa.
            </Paragraph>
          ),
        onSubmit: () => onClose(),
        onClose: () => onClose()
      }
    }[code || 'API-B-00219'] || {
      title: 'Erro',
      info: 'Erro inesperado aconteceu.',
      message: () => (
        <Paragraph>
          Algum erro inesperado aconteceu, por favor tente novamente, caso o erro persista entre em contato com o
          suporte.
        </Paragraph>
      ),
      canBeImported: false,
      onSubmit: () => onClose(),
      onClose: () => onClose()
    });

  const Title = () => {
    if (isImporting) return 'Importação em andamento';
    if (error) return getErrors(error).title;

    return (
      <Div justify="space-between" padding={`0 ${spaces.space4} 0 0`}>
        Importar XML
        <HowDoesItWorksButton padding="0" id="howXMLWorks" />
      </Div>
    );
  };

  const Body = () => {
    if (isImporting) return <CenteredLoader Paragraph="Processando os dados da nota, por favor aguarde!" />;

    if (error) {
      return (
        <Div gap={spaces.space2} direction="column" $fullWidth align="flex-start">
          <WarningBar warning={getErrors(error).info} width="100%" />
          {getErrors(error).message()}
        </Div>
      );
    }

    return (
      <Div gap={spaces.space2} direction="column" $fullWidth align="flex-start">
        <WarningBar warning="Importação de arquivo XML de notas fiscais de produtos." width="100%" type="light" />
        <Paragraph>
          Escolha o projeto e selecione a NF-e para gerar um lançamento com os dados da nota automaticamente.
        </Paragraph>
        <Div direction="column" align="flex-start" $fullWidth gap={spaces.space0}>
          <Paragraph type="small">Projeto</Paragraph>
          <Select
            model="refurbish"
            modelOptions={{
              where: { id: idRefurbish, idStatus: [...refurbishStatus.activeOpportunity, ...refurbishStatus.project] }
            }}
            value={selectedRefurbish}
            placeholder="Selecione o projeto"
            onChange={setSelectedRefurbish}
          />
        </Div>
        <input type="file" id="fileInput" style={{ display: 'none' }} onChange={attachFile} accept=".xml" />
      </Div>
    );
  };

  const getButtonText = () => {
    if (error) return getErrors(error).canBeImported ? 'Continuar' : 'Fechar';
    return 'Selecionar nota fiscal';
  };
  const getCancelText = () => {
    if (error && getErrors(error).canBeImported) return initialError ? 'Fechar' : 'Voltar';
    return !initialError && !error ? 'Fechar' : '';
  };

  const handleUpload = () => document.getElementById('fileInput').click();
  return (
    <>
      <Modal
        open
        title={<Title />}
        submitText={getButtonText()}
        cancelText={getCancelText()}
        width="450px"
        onSubmit={error ? getErrors(error).onSubmit : handleUpload}
        hideFooter={isImporting}
        onClose={error ? getErrors(error).onClose : onClose}
        disableButton={!selectedRefurbish && importType !== 'expense' && !initialError}
      >
        <Body />
      </Modal>

      {invoiceSelected && (
        <Drawer
          padding="0px"
          width={992}
          onClose={() => setInvoiceSelected(null)}
          headerStyle={{ fontSize: fonts.sizeLg, fontWeight: fonts.weight600, color: colors.neutral600 }}
          open
          title="Nota fiscal"
          subtitle={`Chave de acesso: ${invoiceSelected.invoiceKey || ''}`}
          showSubmit={false}
          cancelText="Fechar"
        >
          <InvoicePage id={invoiceSelected.invoiceId} />
        </Drawer>
      )}
    </>
  );
};

ImportInvoiceXMLModal.propTypes = {
  idRefurbish: PropTypes.number,
  onClose: PropTypes.func,
  type: PropTypes.string,
  onImportSuccess: PropTypes.func,
  initialError: PropTypes.instanceOf(Object)
};

export default ImportInvoiceXMLModal;
