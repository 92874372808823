import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import useCRUD from '../../_Hooks/useCRUD';
import LoginContent from '../../components/Content/LoginContent';
import Form from '../../components/Form/ResetPasswordForm';
import CenteredLoader from '../../components/Loader/CenteredLoader';
import { login as loginAction } from '../../store/auth/actions/action';

import { LoginContainer } from './Login.styled';
import { Paragraph, Subtitle, Title } from '../../components/Text/Text';

const ExpiresPassword = () => {
  const history = useHistory();
  const { user } = useSelector(state => state.authReducer) || {};
  const { userType: userTypes } = useSelector(state => state.setup.enums);
  const [showOTP, setSHowOTP] = useState();
  const [loading, setLoading] = useState();

  const dispatch = useDispatch();

  const { handleUpdate, loading: fetchLoading } = useCRUD({
    model: 'auth',
    immediatelyLoadData: false
  });

  const handleSubmit = submitData => {
    setLoading(true);
    const { newPassword, confirmNewPassword, otp } = submitData;

    if (!otp || otp.length !== 6) {
      toast.error('Informe os 6 digitos do código enviado no seu e-mail.');
      setLoading(false);
      return;
    }

    const values = {
      withLogin: true,
      email: user?.email,
      otp,
      newPassword,
      confirmNewPassword
    };

    const getDevice = window.flutter_inappwebview
      ? window.flutter_inappwebview.callHandler('vobiMessageLogin', values)
      : Promise.resolve();

    getDevice.then(deviceInfo => {
      values.deviceInfo = deviceInfo;

      handleUpdate({ updatePathOptions: `/${user.id}`, values, refresh: false }).then(resp => {
        if (resp?.error || !resp) {
          setLoading(false);
          return;
        }

        const { user: responseUser } = resp;

        setTimeout(() => {
          setLoading(false);
          dispatch(
            loginAction({
              user: responseUser
            })
          );
          history.push('/');
        }, 1000);
      });
    });
  };

  const sendOTP = () => {
    handleUpdate({ updatePathOptions: `/send-otp`, values: { email: user?.email }, refresh: false }).then(resp => {
      if (resp?.error || !resp) return;

      setSHowOTP(true);
      toast.success(
        <div>
          <Subtitle>E-mail enviado com sucesso!</Subtitle>
          <Paragraph type="small">Lembre-se de conferir a caixa de SPAM.</Paragraph>
        </div>
      );
    });
  };

  return (
    <LoginContent userType={userTypes.customer.value} between noImage>
      <LoginContainer>
        <Title>Redefina sua senha</Title>
        <Paragraph>Sua senha expirou, para sua segurança é necessário redefinir a senha periodicamente.</Paragraph>
        <Form
          onSubmit={showOTP ? handleSubmit : sendOTP}
          loading={loading || fetchLoading}
          showOTP={showOTP}
          sendOTP={sendOTP}
        />
        {loading ? <CenteredLoader /> : null}
      </LoginContainer>
    </LoginContent>
  );
};

export default ExpiresPassword;
