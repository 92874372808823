import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from '@fortawesome/pro-light-svg-icons';

import styled from 'styled-components';
import Button from '../Button/Button';
import useBackToContext from '../../_Hooks/useBackToContext';
import ProjectMigrationV2Button from '../Button/ProjectMigrationV2Button';
import { colors, spaces, Div } from '../../styles/style';
import { Subtitle } from '../Text/Text';

const Header = styled.header`
  background-color: ${colors.white};
  transition: padding-left 0.4s;
  padding: ${spaces.space1} ${spaces.space2};
  position: relative;

  border-bottom: 1px solid ${colors.neutral100};
`;

const SubHeader = ({ id, title, children, Actions, HasDropdown, showBack = true, data }) => {
  const backToContext = useBackToContext();
  if (!children && !title && !Actions) return null;

  return (
    <Header id={id}>
      <div>
        <Div>
          {showBack && (
            <Button id="back-icon" text onClick={backToContext}>
              <FontAwesomeIcon icon={faLongArrowLeft} />
            </Button>
          )}
          {HasDropdown ? (
            <div>
              {title && <Subtitle>{title}</Subtitle>}
              {HasDropdown && <HasDropdown />}
              <ProjectMigrationV2Button data={data} />
            </div>
          ) : (
            title && <Subtitle>{title}</Subtitle>
          )}
        </Div>

        {Actions && <Actions />}
      </div>
      {children && <div>{children}</div>}
    </Header>
  );
};

SubHeader.propTypes = {
  id: PropTypes.string,
  Actions: PropTypes.instanceOf(Object),
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  HasDropdown: PropTypes.instanceOf(Object),
  showBack: PropTypes.bool,
  data: PropTypes.instanceOf(Object)
};

export default SubHeader;
