import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FormV2 from './FormV2';
import { nfseConfigMapping, nfseConfigSchema } from '../../lib/mapping/Form/nfseConfigSchema';
import useCRUD from '../../_Hooks/useCRUD';

const NfseConfigForm = ({ company = {}, setCompany, setLoadingAll, loadingAll, setTouched = f => f }) => {
  const { handleUpdate, loading } = useCRUD({
    model: 'companyEntity',
    pathOptions: '/updateAccount',
    immediatelyLoadData: false
  });

  const handleSubmit = e => {
    const { withCertificate, withCredentials, ...companyData } = e;

    return handleUpdate({
      values: companyData,
      refresh: false,
      displayToast: `Empresa atualizada com sucesso!`
    }).then(resp => {
      if (resp?.error) return;
      setTouched(false);
      setCompany(resp);
    });
  };

  useEffect(() => {
    setLoadingAll(loading);
  }, [loading]);

  return (
    <FormV2
      id="sendNfseForm"
      schema={nfseConfigSchema}
      mapping={nfseConfigMapping()}
      data={company}
      onSubmit={handleSubmit}
      loading={loadingAll}
      displayButtons
      customButtonTitle="Salvar informações fiscais"
      columnStyle
      onFormChange={({ NFSe }) => setCompany(prev => ({ ...prev, NFSe }))}
      onTouchedChanged={(values, _touched) => {
        if (_touched) setTouched(Object.keys(_touched).some(key => values[key] !== company[key]));
      }}
    />
  );
};

NfseConfigForm.propTypes = {
  company: PropTypes.instanceOf(Object),
  setCompany: PropTypes.instanceOf(Object),
  setLoadingAll: PropTypes.func,
  loadingAll: PropTypes.bool,
  setTouched: PropTypes.func
};

export default NfseConfigForm;
